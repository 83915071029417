import { FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';

interface PageSizeOptionsProps {
  sizes?: Array<number>;
  defaultValue?: number;
}

const defaultProps: PageSizeOptionsProps = {
  sizes: [10, 25, 50, 100],
  defaultValue: 10,
};

const PageSizeOptions = ({ sizes, defaultValue, handlePageSizeChange }: any) => {
  const { t } = useTranslation();
  const handleChange = (e: any) => {
    const size = e.currentTarget.value;
    handlePageSizeChange(size);
  };

  const renderPageSizeOptions = () =>
    sizes.map((size: number) => (
      <option value={size} defaultValue={defaultValue} key={size}>
        {size}
      </option>
    ));

  return (
    <FormGroup className="flex-grow-1 flex-md-grow-0">
      <div className="d-flex align-items-center">
        <p className="me-2 mb-0">{t('Show')}</p>
        <select
          className="me-2 form-control fit-content text-color-primary"
          onChange={handleChange}
        >
          {renderPageSizeOptions()}
        </select>
        <p className="mb-0">{t('Entries')}</p>
      </div>
    </FormGroup>
  );
};

PageSizeOptions.defaultProps = defaultProps;

export default PageSizeOptions;
