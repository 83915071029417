import styled from 'styled-components';
import './GleamJob.style.scss';
import config from 'src/config';
import { useHistory } from 'react-router';
import useWindowDimensions from 'src/components/useWindowDimensions';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import resizeImage from 'src/utils/resizeImage';
import { createRef, useEffect, useRef, useState } from 'react';
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import notify from 'src/utils/notify';
import i18n from 'src/i18n';
import { uploadSocialJobImage } from 'src/graphql/users';
import { floorToFixed } from 'src/common/data/mathToFixed';
import {
  getSocialGleamJob,
  reportJobDecline,
  setLoading,
  unsetLoading,
} from 'src/store/actions';
import paramsFromURL from 'src/constants/paramsFromURL';
import Role from 'src/constants/enum/role';
import ContentType from 'src/constants/enum/contentType';
import {
  addNewGleamPostGql,
  getUserHasReportGQL,
  reportJobDeclineGQL,
  updateSocialMediaHasJobGql,
} from 'src/graphql/advertiser/socialMediaInfo';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Swal from 'sweetalert2';
import choice from 'src/assets/images/choice.png';
import { Dialog, DialogContent } from '@mui/material';
import {
  InfluencerContentType,
  InfluencerSocialIcon,
} from './status/InfluencerJobStatus';
import CustomButton from './CustomButton';
import GleamJobTaskPost from './GleamJobTaskPost';
import { Approve, Reject } from './approval/approvalStatus';

const GleamJobTaskInfluencer = ({ job_tasks, open }: any) => {
  const [jobId, tasked, key] = paramsFromURL(['job', 'task', 'key']);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const {
    task,
    job,
    user,
    declinedJob,
    userHasReportDecline,
    onUserHasReportDeclineLoading,
  } = useSelector((state: any) => ({
    task: state.SocialMediaInfo.socialHasJobTask,
    declinedJob: state.SocialMediaInfo.declinedJob,
    job: state?.MarketerJob?.marketerJob,
    user: state.login.user,
    userHasReportDecline: state.SocialMediaInfo.userHasReportDecline,
    onUserHasReportDeclineLoading:
      state.SocialMediaInfo.onUserHasReportDeclineLoading,
  }));

  const [linkPost, setLinkPost] = useState('');
  const { windowWidth } = useWindowDimensions();
  const [imageKeys, setImageKeys] = useState<any[]>([]);
  const ref = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  // eslint-disable-next-line no-unneeded-ternary
  const [isOpen, setOpen] = useState(key === null ? false : true);
  const [imagesWidth, setImagesWidth] = useState(0);
  const [displayImageKey, setDisplayImageKey] = useState([]);
  const [scrollLeft, setScrollLeft] = useState<any>(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<any>(null);

  const old_content = [ContentType.PHOTO, ContentType.SHARE, ContentType.VIDEO];
  const new_content = [
    ContentType.LIKE_AND_COMMENT,
    ContentType.REVIEW,
    ContentType.SUBSCRIBE,
  ];
  const [hasReportJob, setHasReportJob] = useState(false);

  const getReportJobDecline = async () => {
    const res = await getUserHasReportGQL(job_tasks?.key);
    if (res) {
      setHasReportJob(true);
    }
  };

  useEffect(() => {
    if (tasked) {
      dispatch(getSocialGleamJob(tasked));
    }

    getReportJobDecline();
  }, []);

  useEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = job_tasks?.job_task?.description;
    }
  }, [ref.current, job_tasks]);

  useEffect(() => {
    if (open) {
      setOpen(open);
    }
  }, [open]);

  const renderUploadedImages = () => {
    return imageKeys.map((imageKey: any, index: number) => (
      <div
        style={{
          position: 'relative',
          borderRadius: '8px',
          width: 'fit-content',
          background: '#77CE6F',
          margin: '3px',
          overflow: 'hidden',
          height: '200px',
          padding: '8px',
          cursor: 'pointer',
        }}
      >
        <img
          src={`${config.backendUrl}api/socialJob/${imageKey.key}`} // Replace with the actual URL
          alt={`Uploaded  ${index + 1}`}
          style={{
            width: 'auto',
            height: '100%',
          }}
        />
      </div>
      // <img
      //   src={`${config.backendUrl}api/socialJob/${imageKey.key}`} // Replace with the actual URL
      //   alt={`Uploaded  ${index + 1}`}
      //   style={{ maxWidth: '200px', height: '200px' }}
      // />
    ));
  };

  const handleFileSelected = async (e: any) => {
    const files: any = Array.from(e.target.files);
    const uploadedImageKey = [];
    for (let i = 0; i < files?.length; i++) {
      const file = files[i];
      // eslint-disable-next-line no-await-in-loop
      const newSizeFile = await resizeImage({ file, maxSize: 1000 });
      if (files?.length <= 10) {
        // eslint-disable-next-line no-await-in-loop
        const imageKey = await uploadSocialJobImage(newSizeFile, job_tasks.key);

        const uploadedData = { key: imageKey, marketer_verified: false };
        if (imageKey) {
          uploadedImageKey.push(uploadedData);
        }
      }
    }
    if (files?.length > 10)
      notify(i18n.t('error:You maximum images upload is 10 images'), 'error');
    setImageKeys([...imageKeys, ...uploadedImageKey]);
    // dispatch(getSocialJob(socialJobId));
  };
  // console.log('imageKeys', imageKeys);
  const onSubmitPhoto = async () => {
    if (imageKeys?.length !== 0) {
      const link_posts = linkPost;
      if (!link_posts) {
        notify(i18n.t('error:Please enter link'), 'error');
      } else {
        await updateSocialMediaHasJobGql({
          key: job_tasks.key,
          influencer_submit_date: new Date(),
          post_date: new Date(),
          link_post: link_posts,
        });
        if (tasked) {
          dispatch(getSocialGleamJob(tasked));
        }
      }
    } else {
      notify(i18n.t('error:Please upload at least 1 image'), 'error');
    }
  };

  const slideImage: any = useRef();

  const refs: any = useRef([
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ]);

  const sumImageWidth = () => {
    setTimeout(() => {
      let newImagesWidth = 0;
      for (let i = 0; i < refs.current?.length; i++) {
        const ref = refs.current[i];
        const imageWidth = ref?.current?.offsetWidth;

        newImagesWidth += imageWidth || 0;
      }
      setImagesWidth(newImagesWidth);
    }, 300);
  };
  sumImageWidth();
  useEffect(() => {
    sumImageWidth();
  }, [imageKeys]);

  const screenWidth: any = slideImage?.current?.scrollWidth;

  let currentScreenWidth: any = slideImage?.current?.clientWidth;
  const SlideWidth: any = slideImage?.current?.scrollLeft;

  if (displayImageKey?.length === 0) {
    currentScreenWidth = 0;
  }

  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const renderPhotoInput = () => {
    return (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
            margin: '0px 0px 10px 0px',
          }}
        >
          {' '}
          <div className="image-carousel-container">
            <div
              ref={slideImage}
              onScroll={(e: any) => {
                setScrollLeft(e?.currentTarget?.scrollLeft);
              }}
              style={{
                overflowX: 'auto',
                display: '-webkit-box',
                width: '100%',
                scrollBehavior: 'smooth',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  position: 'absolute',
                }}
              >
                <div
                  style={{
                    color: 'white',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    background: '#ff7785',
                    width: '30px',
                    height: '30px',
                    zIndex: '1',
                    marginLeft: '35px',
                    marginTop: '85px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    visibility: !scrollLeft ? 'hidden' : 'visible',
                  }}
                  onClick={() => {
                    slideImage.current.scrollLeft -= windowWidth * 0.3;
                  }}
                >
                  <KeyboardArrowLeftIcon />
                </div>
                <div
                  style={{
                    color: 'white',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    background: '#ff7785',
                    width: '30px',
                    marginTop: '85px',
                    zIndex: '1',
                    visibility:
                      (screenWidth - SlideWidth - currentScreenWidth !== 0 ||
                        (SlideWidth === 0 && currentScreenWidth < imagesWidth)) &&
                      currentScreenWidth
                        ? 'visible'
                        : 'hidden',
                    marginLeft: `${currentScreenWidth - 120 || 0}px`,
                    height: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={() => {
                    slideImage.current.scrollLeft += windowWidth * 0.3;
                  }}
                >
                  <KeyboardArrowRightIcon />
                </div>
              </div>
              {imageKeys && renderUploadedImages()}
            </div>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            border: '1px solid rgb(148, 157, 157)',
            borderRadius: '10px',
            margin: '10px 0',
          }}
          onClick={() => {
            fileInputRef?.current?.click();
          }}
        >
          <input
            onChange={async (e: any) => {
              handleFileSelected(e);
              setSelectedImage(e);
            }}
            ref={fileInputRef}
            type="file"
            multiple
            style={{
              display: 'none',
              width: '100%',
              height: '100%',
            }}
          />
          <div className="text-center">
            <div>
              <i
                className="display-6 text-muted bx bxs-cloud-upload me-4 "
                style={{ display: 'inline-block' }}
              />
              <br />
              <h6 style={{ display: 'inline-block', fontSize: '12px' }}>
                {t('creative:Drop files here or click to upload')}
              </h6>
            </div>
            <h6 style={{ color: 'red', fontSize: '12px' }}>
              {t('marketer:job_detail:**Up to 10 images can be added.')}
            </h6>
          </div>
        </div>
        <h6 style={{ marginTop: '15px', fontSize: '12px' }}>
          {t('Link ( optional ) ')}
        </h6>
        <input
          onChange={(e) => {
            setLinkPost(e.target.value);
          }}
          className="form-control"
          type="text"
        />{' '}
        <div
          style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}
          onClick={(e) => onSubmitPhoto()}
        >
          <CustomButton label={t('Send')} status="submit" size="xxs" />
        </div>
      </div>
    );
  };

  const backgroundColorStatus = (status: string) => {
    switch (status) {
      case 'DRAFT':
        return '#979797';
      case 'NEW_JOB':
      case 'INPROGRESS':
        return 'rgb(83, 198, 183)';
      case 'FINISHED':
        return 'rgb(34, 148, 237)';
      case 'CANCEL':
        return '#ff7784';
      case 'SUSPENDED':
        return '#ffbb54';
      case 'DECLINE':
        return '#ff7784';
      default:
        return '';
    }
  };

  const clickCancelStatus = (job_task: any) => {
    setModalOpen(true);
    setSelectedTask(job_task);
  };

  const rendorStatus = () => {
    const { post_date, marketer_approve_date, status } = job_tasks;
    if (post_date) {
      if (status === 'INPROGRESS') {
        if (marketer_approve_date) {
          return (
            <div
              className="gleam-job-status-new"
              style={{ backgroundColor: `${backgroundColorStatus(status)}` }}
            >
              <b>{t('ACTIVE')}</b>
            </div>
          );
        } else {
          return (
            <div
              className="gleam-job-status-new"
              style={{ backgroundColor: `${backgroundColorStatus('DRAFT')}` }}
            >
              <b>{t('Waiting Marketer')}</b>
            </div>
          );
        }
      } else if (status === 'DECLINE') {
        return (
          <div
            className="gleam-job-status-new"
            style={{
              backgroundColor: `${backgroundColorStatus(status)}`,
              cursor: 'pointer',
            }}
            onClick={() => {
              clickCancelStatus(job_tasks);
            }}
          >
            <b>
              {t('DECLINE')} <i className="fa fa-list-ul" />
            </b>
          </div>
        );
      } else if (status === 'CANCEL') {
        return (
          <div
            className="gleam-job-status-new"
            style={{
              backgroundColor: `${backgroundColorStatus('CANCEL')}`,
              cursor: 'pointer',
            }}
            onClick={() => {
              clickCancelStatus(job_tasks);
            }}
          >
            <b>
              {t('REJECTED')} <i className="fa fa-list-ul" />
            </b>
          </div>
        );
      } else {
        return (
          <div
            className="gleam-job-status-new"
            style={{ backgroundColor: `${backgroundColorStatus(status)}` }}
          >
            <b>{t(status)}</b>
          </div>
        );
      }
    } else {
      if (status === 'DRAFT') {
        return (
          <div
            className="gleam-job-status-new"
            style={{ backgroundColor: `${backgroundColorStatus(status)}` }}
          >
            <b>{t('PENDING')}</b>
          </div>
        );
      } else if (marketer_approve_date) {
        return (
          <div
            className="gleam-job-status-new"
            style={{
              backgroundColor: `${backgroundColorStatus('CANCEL')}`,
              cursor: 'pointer',
            }}
            onClick={() => {
              clickCancelStatus(job_tasks);
            }}
          >
            <b>
              {t('REJECTED')} <i className="fa fa-list-ul" />
            </b>
          </div>
        );
      } else if (status === 'DECLINE') {
        return (
          <div
            className="gleam-job-status-new"
            style={{
              backgroundColor: `${backgroundColorStatus(status)}`,
              cursor: 'pointer',
            }}
            onClick={() => {
              clickCancelStatus(job_tasks);
            }}
          >
            <b>
              {t('DECLINE')} <i className="fa fa-list-ul" />
            </b>
          </div>
        );
      } else if (status === 'CANCEL') {
        return (
          <div
            className="gleam-job-status-new"
            style={{
              backgroundColor: `${backgroundColorStatus(status)}`,
              cursor: 'pointer',
            }}
            onClick={() => {
              clickCancelStatus(job_tasks);
            }}
          >
            <b>
              {t('REJECTED')} <i className="fa fa-list-ul" />
            </b>
          </div>
        );
      } else {
        return (
          <div
            className="gleam-job-status-new"
            style={{ backgroundColor: `${backgroundColorStatus('DRAFT')}` }}
          >
            <b>{t('Waiting Influencer')}</b>
          </div>
        );
      }
    }
  };

  const rendorDetail = () => {
    const { content_type, socialmedia_type } = job_tasks?.job_task?.task_type;
    if (job_tasks?.post_date) {
      //rendor post detail
      return (
        <div className="gleam-job-task-detail" style={{ marginTop: '16px' }}>
          <GleamJobTaskPost socialHasJob={job_tasks} />
        </div>
      );
    } else {
      if (user.role === Role.PUBLISHER) {
        if (job_tasks.status === 'CANCEL' || job_tasks.status === 'DECLINE') {
          return <></>;
        }
        //rendor send job form
        if (socialmedia_type === 'NOSOCIAL') {
          if (job_tasks?.marketer_approve_date) {
            return <></>;
          }
          // rendor select image to send job
          return (
            <div className="gleam-job-task-detail" style={{ marginTop: '0px' }}>
              {renderPhotoInput()}
            </div>
          );
        } else {
          if (
            old_content.includes(content_type) &&
            socialmedia_type !== 'FACEBOOK' &&
            socialmedia_type !== 'TWITTER'
          ) {
            if (job_tasks?.marketer_approve_date) {
              return <></>;
            }
            return (
              <div className="gleam-job-task-detail" style={{ marginTop: '0px' }}>
                <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                  <input
                    onChange={(e) => {
                      setLinkPost(e.target.value);
                    }}
                    className="form-control"
                    type="text"
                  />{' '}
                  {/* addNewGleamPostGql {link, social_job_key, post_date} */}
                  <div
                    onClick={async () => {
                      await addNewGleamPostGql({
                        link: linkPost,
                        social_job_key: job_tasks.key,
                        post_date: new Date(),
                      });
                      if (tasked) {
                        dispatch(getSocialGleamJob(tasked));
                      }
                    }}
                  >
                    <CustomButton label={t('Send')} status="submit" size="xxs" />
                  </div>
                </div>
              </div>
            );
          } else {
            if (job_tasks?.status === 'FINISH' || job_tasks?.status === 'CANCEL') {
              return t(job_tasks?.status);
            }
            //rendor select image to send job
            return (
              <div className="gleam-job-task-detail" style={{ marginTop: '0px' }}>
                {renderPhotoInput()}
              </div>
            );
          }
        }
      } else {
        if (job_tasks?.marketer_approve_date) {
          return <></>;
        }
        return <></>;
      }
    }
  };

  const renderApproveStatus = (item: any, key: string) => {
    if (item.status === 'CANCEL') {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Reject message="Rejected" />
        </div>
      );
    } else if (item.marketer_approve_date) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Approve message="Approved" />
        </div>
      );
    } else {
      return (
        <div
          className="gleam-job-percent"
          style={{ paddingTop: '4px', paddingBottom: '4px' }}
        >
          <UncontrolledDropdown className="">
            <DropdownToggle className="text-muted font-size-16" tag="a">
              <i className="bx bx-pencil" style={{ color: 'white' }} />
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                onClick={async () => {
                  await Swal.fire({
                    title: t('swal:Are you sure ?'),
                    text: t(`swal:Do you want to Approve!`),
                    imageUrl: choice,
                    width: 300,
                    imageHeight: 200,
                    imageAlt: 'A tall image',
                    showCancelButton: true,
                    cancelButtonColor: 'red',
                    confirmButtonColor: '#009a78',
                    customClass: {},
                    confirmButtonText: t(`button:Yes`),
                    cancelButtonText: t('swal:Cancel'),
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      await onApproveInfluencerPost(key);
                    }
                  });
                }}
                className="text-success"
              >
                <i className="mdi mdi-account-check text-success" />
                &nbsp;&nbsp;{t('table:Approve')}
              </DropdownItem>
              <DropdownItem
                onClick={async () => {
                  await Swal.fire({
                    title: t('Reject'),
                    text: t(`Tell influcencer why you reject ( If any )`),
                    input: 'textarea',
                    inputPlaceholder: 'input here',
                    inputAttributes: {
                      'aria-label': 'Type your message here',
                    },
                    width: 600,
                    imageHeight: 200,
                    imageAlt: 'A tall image',
                    showCancelButton: true,
                    cancelButtonColor: 'red',
                    confirmButtonColor: '#009a78',
                    customClass: {},
                    confirmButtonText: t(`button:Yes`),
                    cancelButtonText: t('swal:Cancel'),
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      const reason = result.value;
                      await onRejectInfluencerPost(key, reason);
                    }
                  });
                }}
                className="text-danger"
              >
                <i className="mdi mdi-account-cancel text-danger" />
                &nbsp;&nbsp;{t('table:Reject')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      );
    }
  };

  const onApproveInfluencerPost = async (key: string) => {
    const res: any = {
      key,
      marketer_approve_date: new Date(),
    };
    await updateSocialMediaHasJobGql(res);
    if (res) {
      dispatch(getSocialGleamJob(tasked));
      notify(i18n.t('success:Job has approved.'), 'success');
    } else notify(i18n.t('error:Job has not approve .'), 'error');
  };

  const onRejectInfluencerPost = async (key: string, reason: any) => {
    const res: any = await updateSocialMediaHasJobGql({
      key,
      reject_message: reason,
      status: 'DECLINE',
      cancel_job_reason: 'MARKETER_REJECT_JOB',
    });
    if (res) {
      dispatch(getSocialGleamJob(tasked));
      notify(i18n.t('success:Job has rejected.'), 'sucess');
    } else notify(i18n.t('error:Job has not reject .'), 'error');
  };

  const onReportJobDecline = async (taskKey: string) => {
    //dispatch(reportJobDecline(taskKey));
    dispatch(setLoading());
    const response = await reportJobDeclineGQL({ key: taskKey });
    if (response) {
      notify(i18n.t('success:Submit a dispute success'), 'success');
      setModalOpen(false);
      await getReportJobDecline();
    } else {
      console.log('Dispute error');
    }
    dispatch(unsetLoading());
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  // const renderDispute = (dispute: any) => {
  //   if (dispute?.status === 'DECLINE' && user.role === Role.PUBLISHER) {
  //     return (
  //       <button
  //         className="btn btn-primary btn-sm"
  //         type="button"
  //         onClick={async () => {
  //           dispatch(setLoading());
  //           const response = await reportJobDeclineGQL({ key: dispute?.key });
  //           if (response) {
  //             notify(i18n.t('success:Submit a dispute success'), 'success');
  //             setModalOpen(false);
  //             dispatch(getSocialGleamJob(tasked));
  //           } else {
  //             console.log('Dispute error');
  //           }
  //           dispatch(unsetLoading());
  //         }}
  //       >
  //         {t('Submit a dispute')}
  //       </button>
  //     );
  //   } else {
  //     return <></>;
  //   }
  // };

  const displayModal = (taskKey: string) => {
    return (
      <Dialog
        maxWidth={false}
        onClose={handleClose}
        open={isModalOpen}
        disableEnforceFocus
      >
        <DialogContent style={{ cursor: 'pointer' }}>
          <div
            style={{
              minWidth: '600px',
              overflowX: 'auto',
              overflowY: 'auto',
              paddingBottom: '20px',
            }}
          >
            <h3>Cancel reason: {t(`enum:${selectedTask?.cancel_job_reason}`)}</h3>
            <h5>Marketer message: </h5>
            <div
              style={{
                padding: '16px',
                borderRadius: '6px',
                border: '2px #e9edee solid',
                marginBottom: '8px',
              }}
            >
              {selectedTask?.reject_message ?? 'No comment'}
            </div>
            {job_tasks.status === 'DECLINE' && (
              <div className="gleam-jobs-task-sub-header-right">
                <button
                  type="button"
                  className="gleam-jobs-task-report-button"
                  style={{ backgroundColor: `${backgroundColorStatus('DECLINE')}` }}
                  disabled={hasReportJob}
                  onClick={() => onReportJobDecline(taskKey)}
                >
                  <b>{hasReportJob ? t('Disputed') : t('Dispute')}</b>
                </button>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div>
      {displayModal(job_tasks?.key)}
      <div
        className="gleam-job-task-container "
        style={{ paddingLeft: '0px', paddingRight: '0px', paddingTop: '5px' }}
      >
        <div
          className={isOpen ? 'gleam-job-task-header-open' : 'gleam-job-task-header'}
          style={{ height: 'fit-content', cursor: 'default' }}
        >
          <div className="gleam-jobs-task-header ">
            <div
              className="col-v-center col-center  gleam-jobs-task-sub-header-left"
              style={{ paddingLeft: '16px' }}
            >
              <InfluencerSocialIcon
                social_media_type={job_tasks?.job_task?.task_type.socialmedia_type}
                alt={job_tasks?.job_task?.task_type.socialmedia_type}
                size={24}
              />
              <InfluencerContentType
                content_type={job_tasks?.job_task?.task_type.content_type}
                size={24}
              />
              <div className="campaign-name-title">{job_tasks?.job_task?.title}</div>
            </div>
            {/* /// */}
            <div className=" gleam-jobs-task-sub-header-right">
              <div className="gleam-job-percent-new">
                <b>
                  {floorToFixed(
                    job_tasks?.price +
                      (user.role !== Role.PUBLISHER ? job_tasks?.tax : 0),
                    2,
                  )}{' '}
                  {job?.currency?.main_symbol ?? 'USDT'}
                </b>
              </div>
              <>{rendorStatus()}</>
              {user.role === Role.ADVERTISER && (
                <div style={{ marginLeft: '5px' }}>
                  {renderApproveStatus(job_tasks, job_tasks?.key)}
                </div>
              )}
              <div
                onClick={() => {
                  setOpen(!isOpen);
                }}
                style={{ cursor: 'pointer' }}
              >
                {isOpen ? (
                  <i
                    className="fa fa-chevron-down fa-icon icon-down"
                    style={{ border: '5px' }}
                  />
                ) : (
                  <i className="fa fa-chevron-right fa-icon icon-down" />
                )}
              </div>
            </div>
          </div>
        </div>
        <GleamJobTaskDetail isOpen={isOpen}>
          <div className="gleam-job-task-detail">
            <div
              className="text-editor-display "
              style={{ wordBreak: 'break-all', padding: '5px' }}
            >
              <div ref={ref} />

              {job_tasks.approve_job_date ? (
                rendorDetail()
              ) : (
                // <div
                //   style={{
                //     display: 'flex',
                //     justifyContent: 'center',
                //     alignItems: 'center',
                //     marginTop: '10px',
                //   }}
                // >
                //   <h3>Waiting for approval</h3>
                // </div>
                <></>
              )}
            </div>
          </div>
        </GleamJobTaskDetail>
      </div>
      {/* {key === null && (
        <div
          style={{
            padding: '6px',
            // border: '1px solid',
            fontSize: '14px',
            fontWeight: 'bold',
            cursor: 'pointer',
            width: 'fit-content',
            // borderRadius: '5px',
          }}
          onClick={(e) => {
            e.preventDefault();
            history.push(
              `/campaign/JobDetail/CampaignChat?job=${jobId}&task=${tasked}&key=${job_tasks.social_media_info.key}`,
            );
          }}
        >
          <i className="fa fa-comments" aria-hidden="true" />{' '}
          {t('Contact  marketer')}
        </div>
      )} */}
    </div>
  );
};

const GleamJobTaskDetail = styled.div<{ isOpen: boolean }>`
  /* border: 5px solid; */
  width: 100%;
  gap: 1rem;
  overflow: hidden;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: all 0.5s ease;
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
`;

export default GleamJobTaskInfluencer;
