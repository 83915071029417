import { useEffect, useState } from 'react';
import { Col, Row, FormGroup, Label, UncontrolledAlert } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { Formik, Field, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { registerUser } from '../../../store/auth/register/actions';
import config from '../../../config';
import PasswordCheckList from '../../Common/PasswordCheckList';
import PasswordField from '../../Common/PasswordField';
import i18n from '../../../i18n';

const initialValues = {
  email: '',
  password: '',
  passwordConfirmation: '',
};

const withdrawSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t('invalid.email must be a valid email'))
    .required(i18n.t('invalid.Required')),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&"#'(),./;:<=>[\]^_`{|}~\\])[A-Za-z\d@$!%*?&"#'(),./:;<=>[\]^_`{|}~\\]{8,}$/,
      ' ',
    )
    .required('invalid.Password is required'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], i18n.t('invalid.Password must match'))
    .required(i18n.t('invalid.Password Confirmation is required')),
});

const RegisterByEmail = ({ location, history, role }: any) => {
  const [_role, setRole] = useState(role);
  useEffect(() => {
    if (role === 'INFLUENCER') {
      setRole('PUBLISHER');
    }
    if (role === 'MARKETER') {
      setRole('ADVERTISER');
    }
  }, [role]);

  let recaptcha: any;
  const siteKey: any = config.recaptcha.SITE_KEY;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isRecaptchaSucceed, setRecaptcha] = useState(true);

  useEffect(() => {
    if (!config.recaptcha.USE_CAPTCHA) {
      setRecaptcha(true);
    }
  }, []);

  const { error, message, referralEmail } = useSelector((state: any) => ({
    error: state.register.error,
    message: state.register.message,
    referralEmail: state.Referral.referralEmail,
  }));

  const { ref } = queryString.parse(location.search);

  const onSubmit = (values: any, { setSubmitting }: any) => {
    setSubmitting(true);
    if (!isRecaptchaSucceed) return;

    const data = {
      ...values,
      loginType: 'EMAIL',
      role: _role,
      ref: ref ?? null,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone ?? null,
    };

    recaptcha.reset();

    dispatch(registerUser(data, history));
  };

  const getInvitedEmail = () => {
    const splits = referralEmail.split('@');

    const emailFirst = [...splits[0]].map((char: string, index: any) => {
      return index === 0 ? char.toUpperCase() : '*';
    });

    return `${emailFirst.join('')}@${splits[1]}`;
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={withdrawSchema}
        render={({ errors, touched, isSubmitting, values }) => {
          const isVerified: boolean =
            !values.email ||
            !values.password ||
            !values.passwordConfirmation ||
            !!errors.email ||
            !!errors.password ||
            !!errors.passwordConfirmation;
          return (
            <FormikForm>
              <Row>
                <Col md="12">
                  {error && message && (
                    <UncontrolledAlert color="danger" className="alert">
                      {message}
                    </UncontrolledAlert>
                  )}
                </Col>
                <Col md="8" className="m-auto">
                  {referralEmail && (
                    <FormGroup className="mb-3">
                      <Field
                        name="role"
                        type="text"
                        disabled
                        className="form-control text-center"
                        value={`${t('Invited By')} ${getInvitedEmail()}`}
                        disable
                      />
                    </FormGroup>
                  )}
                </Col>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="email" className="input-label">
                      {t('Form.Role')}
                    </Label>
                    <Field
                      name="role"
                      type="text"
                      disabled
                      className="form-control"
                      id="role"
                      value={t(`${role}`)}
                    />
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Label htmlFor="email" className="input-label">
                      {t('Form.Email')}
                    </Label>
                    <Field
                      name="email"
                      type="email"
                      className={`form-control ${
                        errors.email && touched.email ? 'is-invalid' : ''
                      }`}
                      id="email"
                      placeholder={t('Form.Email Placeholder')}
                    />
                    {errors.email && (
                      <div className="invalid-message">
                        {t('invalid.emailIsRequired')}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="password" className="input-label">
                      {t('Form.Password')}
                    </Label>
                    <PasswordField
                      name="password"
                      placeholder={t('Form.Password')}
                    />

                    <PasswordCheckList password={values.password} />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="passwordConfirmation" className="input-label">
                      {t('Form.Password Confirmation')}
                    </Label>
                    <PasswordField
                      name="passwordConfirmation"
                      placeholder={t('Form.Password Confirmation Placeholder')}
                    />
                  </FormGroup>
                </Col>
                <Col md="12" className="d-flex flex-column align-items-center">
                  <FormGroup className="mb-3">
                    <ReCAPTCHA
                      ref={(r) => {
                        recaptcha = r;
                      }}
                      sitekey={siteKey}
                      onChange={() => {
                        setRecaptcha(true);
                      }}
                    />
                  </FormGroup>
                  {isSubmitting && !isRecaptchaSucceed && (
                    <UncontrolledAlert color="danger" className="alert">
                      {t('Form.I am not a robot')}
                    </UncontrolledAlert>
                  )}
                </Col>
              </Row>
              <button
                className={`${
                  isVerified
                    ? 'btn btn-secondary bg-gray'
                    : 'btn btn-success bg-green'
                } w-100 waves-effect waves-light `}
                // className="btn btn-secondary w-100 waves-effect waves-light"
                type="submit"
                disabled={isVerified}
              >
                {t('Button.Register')}
              </button>
            </FormikForm>
          );
        }}
      />
    </>
  );
};
export default withRouter(RegisterByEmail);
