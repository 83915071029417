import { Col, Row } from 'reactstrap';
import AddCampaignWidget from 'src/components/Advertiser/Widgets/AddCampaignWidget';
import CampaignSummaryWidget from 'src/components/Advertiser/Widgets/CampaignSummaryWidget';
import CampaignTotalWidget from 'src/components/Advertiser/Widgets/CampaignTotalWidget';
import PerformanceSummary from 'src/components/Advertiser/Widgets/PerformanceSummary';
import './AdvertiserDashboard.style.scss';
import useWindowSize from 'src/utils/windowSize';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WalletWidget from 'src/components/Common/WalletWidget';
import Wallet from 'src/assets/images/wallet.png';
import influ from 'src/assets/images/comimg-soon.png';
import blog1 from 'src/assets/images/Blog1.jpeg';
import blog2 from 'src/assets/images/Blog2.jpeg';
import blog3 from 'src/assets/images/Blog3.jpeg';
import blog4 from 'src/assets/images/Blog4.jpeg';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  filterGleamJob,
  getSumBalanceWallet,
  marketerSumPendingBalance,
  getMarketerFollowUp,
} from 'src/store/actions';
import { floorToFixed } from 'src/common/data/mathToFixed';

const AdvertiserDashboardNew = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const walletSymbol = 'USDT';
  const [totalBalance, setTotalBalance] = useState<any>(0);
  const [pendingBalance, setPendingBalance] = useState<any>(0);
  const [activeJob, setActiveJob] = useState(0);
  const [totalJob, setTotalJob] = useState(0);
  const [pendingAccept, setPendingAccept] = useState(0);
  const [penddingApprove, setPendingApprove] = useState(0);
  const { user, userInfo, wallet, marketerPendingBalance, filter, followUp } =
    useSelector((state: any) => ({
      user: state.login.user,
      userInfo: state.Users.userInfo,
      wallet: state.Wallet.sumWallet,
      marketerPendingBalance: state.SocialMediaInfo.marketerPendingBalance,
      filter: state.MarketerJob.filter,
      followUp: state.MarketerJob.followUp,
    }));

  useEffect(() => {
    dispatch(getSumBalanceWallet());
    dispatch(marketerSumPendingBalance());
    dispatch(
      filterGleamJob({
        limit: 10,
        page: 1,
        status: 'INPROGRESS',
      }),
    );
    dispatch(getMarketerFollowUp({ key: null }));
  }, [user]);

  useEffect(() => {
    if (wallet?.balance) {
      const balance = floorToFixed(wallet?.balance, 2);
      setTotalBalance(balance);
    }
  }, [wallet]);

  useEffect(() => {
    if (marketerPendingBalance?.balance) {
      const balance = floorToFixed(marketerPendingBalance?.balance, 2);
      setPendingBalance(balance);
    }
  }, [marketerPendingBalance]);

  useEffect(() => {
    if (filter?.statusCount !== null) {
      let allStatusCount = 0;
      setActiveJob(filter?.totalCount);
      for (let i = 0; i < filter?.statusCount?.length; i++) {
        if (filter?.statusCount[i]?.status === 'ALL') {
          allStatusCount = filter?.statusCount[i]?.count;
        }
      }
      setTotalJob(allStatusCount);
    }
  }, [filter]);

  useEffect(() => {
    if (followUp) {
      setPendingAccept(followUp?.pendingAccept ?? 0);
      setPendingApprove(followUp?.pendingReviewApprove ?? 0);
    }
  }, [followUp]);

  return (
    <div
      className="page-content"
      style={{
        paddingTop: `${width < 993 ? '4vh' : '2vh'}`,
        justifyContent: `${width < 646 ? 'center' : ''}`,
      }}
    >
      <div className="dashboard-wraper ">
        <h4>{t('Dashboard')} </h4>
        <p className="ml-2">
          {t('dashboard.Welcome back')} {userInfo.name} 👋🏼
        </p>
        <h4>{t('dashboard.Get to know our apps')}</h4>
        <p className="ml-2">
          {t('dashboard.Use our suite of platform to reach all your growth goals.')}{' '}
          👋🏼
        </p>
        {/* Card Dashboard */}
        <div className="maketer-dashboard-card-wraper ">
          <div className="card-dashboard " style={{ background: '#53c6b7' }}>
            <div style={{ display: 'flex', gap: '10px' }}>
              <i
                className="fas fa-wallet font-size-20"
                style={{ marginLeft: '8px', color: 'white' }}
              />
              <p className="title-card">{t('dashboard.Total Balance')}</p>
            </div>
            <div className="center" style={{ marginTop: '20px' }}>
              <h2 style={{ marginBottom: '0px' }}>
                <b> {totalBalance} </b>
                {walletSymbol}
              </h2>
            </div>
            <div className="center">
              <p style={{ marginBottom: '0px' }}>
                <b>
                  {t('Pending')} : {pendingBalance} {walletSymbol}
                </b>
              </p>
            </div>
            <a href="/wallet" className="goto">
              <div className="button-job" style={{ display: 'block' }}>
                {t('dashboard.Go to wallet')}
              </div>
            </a>
          </div>
          <div className="card-dashboard  " style={{ background: '#53c6b7' }}>
            <div style={{ display: 'flex', gap: '10px' }}>
              <i
                className="fa fa-bullhorn font-size-20"
                style={{ marginLeft: '8px', color: 'white' }}
              />
              <p className="title-card">{t('Campaign')}</p>
            </div>
            <div className="center" style={{ marginTop: '20px' }}>
              <h2 style={{ marginBottom: '0px' }}>
                <b>{totalJob}</b>
              </h2>
            </div>
            <div className="center">
              <p style={{ marginBottom: '0px' }}>
                <b>
                  {t('dashboard.Active campaign')}: {activeJob}
                </b>
              </p>
            </div>
            <a href="/campaigns/markerter/job" className="goto">
              <div className="button-job">{t('dashboard.Go to campaign')}</div>
            </a>
          </div>
          <div className="card-dashboard" style={{ background: '#53c6b7' }}>
            <div style={{ display: 'flex', gap: '10px' }}>
              {' '}
              <i
                className="fa fa-calendar-check-o font-size-20"
                style={{ marginLeft: '8px', color: 'white' }}
              />
              <p className="title-card">{t('dashboard.Follow up campaign')}</p>
            </div>
            <div style={{ marginTop: '28px' }}>
              <b>
                {t('dashboard.Pending accept')}: {pendingAccept}
              </b>
            </div>
            <div>
              <b>
                {t('dashboard.Pending review & approve')}: {penddingApprove}
              </b>
            </div>
            <a href="/campaigns/markerter/job" className="goto">
              <div className="button-job">{t('dashboard.Go to campaign')}</div>
            </a>
          </div>
        </div>
        {/* template */}
        <h4 style={{ marginTop: '20px' }}>{t('dashboard.Our template')}</h4>
        <div className="maketer-template-card-wraper ">
          <div className="card-template">
            <img
              alt="template-logo"
              className="template-img"
              src={influ}
              width={280}
              height={200}
              style={{ border: '2px #e9edee solid' }}
            />
            <div>Coming soon</div>
          </div>
        </div>
        {/* Guide */}
        <h4 style={{ marginTop: '20px' }}>{t('dashboard.Guide')}</h4>
        <div className="maketer-guide-card-wraper ">
          <a href="https://blog.afbrother.com/th/afbrother-%E0%B8%9B%E0%B8%A5%E0%B9%88%E0%B8%AD%E0%B8%A2%E0%B8%9F%E0%B8%B5%E0%B9%80%E0%B8%88%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B9%80%E0%B8%94%E0%B9%87%E0%B8%94-%E0%B8%8A%E0%B9%88%E0%B8%A7%E0%B8%A2%E0%B9%82/">
            <div className="guide-template">
              <img
                alt="template-logo"
                className="template-img"
                src={blog1}
                width={280}
                height={200}
              />
              <h5 className="center-text ">{t('dashboard.Guide1')}</h5>
            </div>
          </a>
          <a href="https://blog.afbrother.com/th/%E0%B8%96%E0%B9%89%E0%B8%B2%E0%B9%80%E0%B8%88%E0%B9%89%E0%B8%B2%E0%B8%82%E0%B8%AD%E0%B8%87%E0%B9%80%E0%B8%AB%E0%B8%A3%E0%B8%B5%E0%B8%A2%E0%B8%8D%E0%B8%84%E0%B8%A3%E0%B8%B4%E0%B8%9B%E0%B9%82%E0%B8%95/#pll_switcher">
            <div className="guide-template">
              <img
                alt="template-logo"
                className="template-img"
                src={blog2}
                width={280}
                height={200}
              />
              <h5 className="center-text">{t('dashboard.Guide2')}</h5>
            </div>
          </a>
          <a href="https://blog.afbrother.com/th/online-marketing-strategy/">
            <div className="guide-template">
              <img
                alt="template-logo"
                className="template-img"
                src={blog3}
                width={280}
                height={200}
              />
              <h5 className="center-text">{t('dashboard.Guide3')}</h5>
            </div>
          </a>
          <a href="https://blog.afbrother.com/th/%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B8%99%E0%B8%B5%E0%B9%89%E0%B8%81%E0%B9%87%E0%B8%A1%E0%B8%B5%E0%B8%94%E0%B9%89%E0%B8%A7%E0%B8%A2%E0%B9%80%E0%B8%AB%E0%B8%A3%E0%B8%AD-%E0%B8%88%E0%B9%89%E0%B8%B2%E0%B8%87/">
            <div className="guide-template">
              <img
                alt="template-logo"
                className="template-img"
                src={blog4}
                width={280}
                height={200}
              />
              <h5 className="center-text">{t('dashboard.Guide4')}</h5>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AdvertiserDashboardNew;
