import styled from 'styled-components';
import './GleamJob.style.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import notify from 'src/utils/notify';
import i18n from 'src/i18n';
import { floorToFixed } from 'src/common/data/mathToFixed';
import { getSocialGleamJobForReview } from 'src/store/actions';
import paramsFromURL from 'src/constants/paramsFromURL';
import Role from 'src/constants/enum/role';
import ContentType from 'src/constants/enum/contentType';
import { updateSocialMediaHasJobGql } from 'src/graphql/advertiser/socialMediaInfo';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Swal from 'sweetalert2';
import choice from 'src/assets/images/choice.png';
import { Dialog, DialogContent } from '@mui/material';
import {
  InfluencerContentType,
  InfluencerSocialIcon,
} from './status/InfluencerJobStatus';
import CustomButton from './CustomButton';
import GleamJobTaskPost from './GleamJobTaskPost';
import { Approve, Reject } from './approval/approvalStatus';

const GleamJobTaskMarketerChat = ({ job_tasks, open }: any) => {
  const { t } = useTranslation();

  const { task, job, user } = useSelector((state: any) => ({
    task: state.SocialMediaInfo.socialHasJobTask,
    job: state?.MarketerJob?.marketerJob,
    user: state.login.user,
  }));
  const [linkPost, setLinkPost] = useState('');
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState(false);
  const [jobId, tasked, social_key] = paramsFromURL(['job', 'task', 'key']);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<any>(null);

  const old_content = [ContentType.PHOTO, ContentType.SHARE, ContentType.VIDEO];
  const new_content = [
    ContentType.LIKE_AND_COMMENT,
    ContentType.REVIEW,
    ContentType.SUBSCRIBE,
  ];

  useEffect(() => {
    if (tasked && social_key) {
      // dispatch(getSocialGleamJobForReview({ key: tasked, social_key }));
    }
  }, []);
  useEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = job_tasks?.job_task?.description;
    }
  }, [ref.current, job_tasks]);

  useEffect(() => {
    if (open) {
      setOpen(open);
    }
  }, [open]);

  const backgroundColorStatus = (status: string) => {
    switch (status) {
      case 'DRAFT':
        return '#979797';
      case 'NEW_JOB':
      case 'INPROGRESS':
        return 'rgb(83, 198, 183)';
      case 'FINISHED':
        return 'rgb(34, 148, 237)';
      case 'CANCEL':
        return '#ff7784';
      case 'SUSPENDED':
        return '#ffbb54';
      default:
        return '';
    }
  };

  const clickCancelStatus = (job_task: any) => {
    setModalOpen(true);
    setSelectedTask(job_task);
  };

  const rendorStatus = () => {
    const { post_date, marketer_approve_date, status } = job_tasks;
    if (post_date) {
      if (status === 'INPROGRESS') {
        if (marketer_approve_date) {
          return (
            <div
              className="gleam-job-status-new-advertiser "
              style={{
                backgroundColor: `${backgroundColorStatus(status)}`,
              }}
            >
              <b>{t('ACTIVE')}</b>
            </div>
          );
        } else {
          return (
            <div
              className="gleam-job-status-new-advertiser "
              style={{ backgroundColor: `${backgroundColorStatus('DRAFT')}` }}
            >
              <b>{t('Waiting Marketer')}</b>
            </div>
          );
        }
      } else if (status === 'CANCEL') {
        return (
          <div
            className="gleam-job-status-new-advertiser  center"
            style={{
              backgroundColor: `${backgroundColorStatus('CANCEL')}`,
              cursor: 'pointer',
            }}
            onClick={() => {
              clickCancelStatus(job_tasks);
            }}
          >
            <b>
              {t('REJECTED')} <i className="fa fa-list-ul" />
            </b>
          </div>
        );
      } else {
        return (
          <div
            className="gleam-job-status-new-advertiser "
            style={{ backgroundColor: `${backgroundColorStatus(status)}` }}
          >
            <b>{t(status)}</b>
          </div>
        );
      }
    } else {
      if (status === 'DRAFT') {
        return (
          <div
            className="gleam-job-status-new-advertiser "
            style={{ backgroundColor: `${backgroundColorStatus(status)}` }}
          >
            <b>{t('PENDING')}</b>
          </div>
        );
      } else if (marketer_approve_date) {
        return (
          <div
            className="gleam-job-status-new-advertiser "
            style={{
              backgroundColor: `${backgroundColorStatus('CANCEL')}`,
              cursor: 'pointer',
            }}
            onClick={() => {
              clickCancelStatus(job_tasks);
            }}
          >
            <b>
              {t('REJECTED')} <i className="fa fa-list-ul" />
            </b>
          </div>
        );
      } else if (status === 'CANCEL') {
        return (
          <div
            className="gleam-job-status-new-advertiser "
            style={{
              backgroundColor: `${backgroundColorStatus(status)}`,
              cursor: 'pointer',
            }}
            onClick={() => {
              clickCancelStatus(job_tasks);
            }}
          >
            <b>
              {t('REJECTED')} <i className="fa fa-list-ul" />
            </b>
          </div>
        );
      } else {
        return (
          <div
            className="gleam-job-status-new-advertiser "
            style={{ backgroundColor: `${backgroundColorStatus('DRAFT')}` }}
          >
            <b>{t('Waiting Influencer')}</b>
          </div>
        );
      }
    }
  };

  const rendorDetail = () => {
    const { content_type, socialmedia_type } = job_tasks?.job_task?.task_type;
    if (job_tasks?.post_date) {
      //rendor post detail
      return (
        <div className="gleam-job-task-detail" style={{ marginTop: '0' }}>
          <GleamJobTaskPost socialHasJob={job_tasks} />
        </div>
      );
    } else {
      if (user.role === Role.PUBLISHER) {
        if (job_tasks.status === 'CANCEL') {
          return <></>;
        }
        //rendor send job form
        if (socialmedia_type === 'NOSOCIAL') {
          if (job_tasks?.marketer_approve_date) {
            return (
              // <div
              //   style={{
              //     display: 'flex',
              //     alignItems: 'center',
              //     justifyContent: 'center',
              //   }}
              // >
              //   <Reject message="Marketer has approved this job while not submitted this task." />
              // </div>
              <></>
            );
          }
          // rendor select image to send job
          return <></>;
        } else {
          if (old_content.includes(content_type)) {
            if (job_tasks?.marketer_approve_date) {
              return (
                // <div
                //   style={{
                //     display: 'flex',
                //     alignItems: 'center',
                //     justifyContent: 'center',
                //   }}
                // >
                //   <Reject message="Marketer has approved this job while not submitted this task." />
                // </div>
                <></>
              );
            }
            return <></>;
          } else {
            if (job_tasks?.status === 'FINISH' || job_tasks?.status === 'CANCEL') {
              return job_tasks?.status;
            }
            //rendor select image to send job
            return <></>;
          }
        }
      } else {
        if (job_tasks?.marketer_approve_date) {
          return (
            // <div
            //   style={{
            //     display: 'flex',
            //     alignItems: 'center',
            //     justifyContent: 'center',
            //   }}
            // >
            //   <Reject message="Marketer has approved this job while not submitted this task." />
            // </div>
            <></>
          );
        }
        return (
          // <div
          //   style={{
          //     display: 'flex',
          //     alignItems: 'center',
          //     justifyContent: 'center',
          //   }}
          // >
          //   <Reject message="Influencer hasn't submitted work yet." />
          // </div>
          <></>
        );
      }
    }
  };

  const renderApproveStatus = (item: any, key: string) => {
    if (item.status === 'CANCEL') {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Reject message="Rejected" />
        </div>
      );
    } else if (item.marketer_approve_date) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Approve message="Approved" />
        </div>
      );
    } else {
      return (
        <div
          className="gleam-job-percent"
          style={{ paddingTop: '4px', paddingBottom: '4px', cursor: 'pointer' }}
        >
          <UncontrolledDropdown className="">
            <DropdownToggle className="text-muted font-size-16" tag="a">
              <i className="bx bx-pencil" style={{ color: 'white' }} />
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                onClick={async () => {
                  await Swal.fire({
                    title: t('swal:Are you sure ?'),
                    text: t(`swal:Do you want to Approve!`),
                    imageUrl: choice,
                    width: 300,
                    imageHeight: 200,
                    imageAlt: 'A tall image',
                    showCancelButton: true,
                    cancelButtonColor: 'red',
                    confirmButtonColor: '#009a78',
                    customClass: {},
                    confirmButtonText: t(`button:Yes`),
                    cancelButtonText: t('swal:Cancel'),
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      await onApproveInfluencerPost(key);
                    }
                  });
                }}
                className="text-success"
              >
                <i className="mdi mdi-account-check text-success" />
                &nbsp;&nbsp;{t('table:Approve')}
              </DropdownItem>
              <DropdownItem
                onClick={async () => {
                  await Swal.fire({
                    title: t('Reject'),
                    text: t(`Tell influcencer why you reject ( If any )`),
                    input: 'textarea',
                    inputPlaceholder: 'input here',
                    inputAttributes: {
                      'aria-label': 'Type your message here',
                    },
                    width: 600,
                    imageHeight: 200,
                    imageAlt: 'A tall image',
                    showCancelButton: true,
                    cancelButtonColor: 'red',
                    confirmButtonColor: '#009a78',
                    customClass: {},
                    confirmButtonText: t(`button:Yes`),
                    cancelButtonText: t('swal:Cancel'),
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      const reason = result.value;
                      await onRejectInfluencerPost(key, reason);
                    }
                  });
                }}
                className="text-danger"
              >
                <i className="mdi mdi-account-cancel text-danger" />
                &nbsp;&nbsp;{t('table:Reject')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      );
    }
  };

  const onApproveInfluencerPost = async (key: string) => {
    const res: any = {
      key,
      marketer_approve_date: new Date(),
    };
    await updateSocialMediaHasJobGql(res);
    if (res) {
      dispatch(getSocialGleamJobForReview({ key: tasked, social_key }));
      notify(i18n.t('success:Job has approved.'), 'success');
    } else notify(i18n.t('error:Job has not approve .'), 'error');
  };

  const onRejectInfluencerPost = async (key: string, reason: any) => {
    const res: any = await updateSocialMediaHasJobGql({
      key,
      reject_message: reason,
      status: 'CANCEL',
    });
    if (res) {
      dispatch(getSocialGleamJobForReview({ key: tasked, social_key }));
      notify(i18n.t('success:Job has rejected.'), 'sucess');
    } else notify(i18n.t('error:Job has not reject .'), 'error');
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const displayModal = () => {
    return (
      <Dialog
        maxWidth={false}
        onClose={handleClose}
        open={isModalOpen}
        disableEnforceFocus
      >
        <DialogContent style={{ cursor: 'pointer' }}>
          <div
            style={{
              minWidth: '600px',
              overflowX: 'auto',
              overflowY: 'auto',
              paddingBottom: '20px',
            }}
          >
            <h3>Cancel reason: {t(`enum:${selectedTask?.cancel_job_reason}`)}</h3>
            <h5>Marketer message: </h5>
            <div
              style={{
                padding: '16px',
                borderRadius: '6px',
                border: '2px #e9edee solid',
              }}
            >
              {selectedTask?.reject_message ?? 'No comment'}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div style={{ marginBottom: '11px' }}>
      {displayModal()}
      <div
        className="gleam-job-task-container "
        style={{ cursor: 'default', borderRadius: '6px' }}
      >
        <div
          className={
            isOpen ? 'gleam-job-task-header-open-adv' : 'gleam-job-task-header-adv'
          }
          style={{ cursor: 'default', paddingBottom: '40px' }}
        >
          <div
            className="gleam-jobs-task-header-adver"
            style={{
              alignItems: 'center',
            }}
          >
            <div className="col-v-center col-center" style={{ paddingLeft: '16px' }}>
              <InfluencerSocialIcon
                social_media_type={job_tasks?.job_task?.task_type.socialmedia_type}
                alt={job_tasks?.job_task?.task_type.socialmedia_type}
                size={24}
              />
              <InfluencerContentType
                content_type={job_tasks?.job_task?.task_type.content_type}
                size={24}
              />
              <div className="campaign-name-title">{job_tasks?.job_task?.title}</div>
            </div>
            <div className="col-v-center col-end" style={{ paddingRight: '0px' }}>
              <div className="gleam-job-percent" style={{ marginRight: '5px' }}>
                <b>
                  {floorToFixed(
                    job_tasks?.price +
                      (user.role !== Role.PUBLISHER ? job_tasks?.tax : 0),
                    2,
                  )}{' '}
                  {job?.currency?.main_symbol ?? 'USDT'}
                </b>
              </div>
              <>{rendorStatus()}</>
              {/* {user.role === Role.ADVERTISER && (
                <div style={{ marginLeft: '5px' }}>
                  {renderApproveStatus(job_tasks, job_tasks?.key)}
                </div>
              )} */}
              <div
                onClick={() => {
                  setOpen(!isOpen);
                }}
                style={{ cursor: 'pointer' }}
              >
                {isOpen ? (
                  <i
                    className="fa fa-chevron-down fa-icon"
                    style={{ paddingRight: '0 0 0 16px' }}
                  />
                ) : (
                  <i
                    className="fa fa-chevron-right fa-icon"
                    style={{ paddingRight: '0 0 0 16px' }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <GleamJobTaskDetail isOpen={isOpen}>
          <div className="gleam-job-task-detail">
            <div className="text-editor-display" style={{ wordBreak: 'break-all' }}>
              <div ref={ref} />
              {job_tasks.approve_job_date ? rendorDetail() : <></>}
            </div>
          </div>
        </GleamJobTaskDetail>
      </div>
    </div>
  );
};

const GleamJobTaskDetail = styled.div<{ isOpen: boolean }>`
  /* border: 5px solid; */
  width: 100%;
  gap: 1rem;
  overflow: hidden;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: all 0.5s ease;
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
`;

export default GleamJobTaskMarketerChat;
