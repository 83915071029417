import styled from 'styled-components';

export const PageContent = styled.div`
  height: 100%;
`;

export const ChanelDetailContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: auto;
  box-sizing: border-box;
`;

export const CardContainer = styled.div`
  position: relative;
  height: max-content;
  min-height: 300px;
  width: 100%;
  max-width: 50rem;
  margin-top: 2.5rem;
  margin-bottom: 3rem;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  background-color: #fbfaff;
`;

export const StaticContainer = styled.div`
  text-align: center;
  padding-top: 4rem;
  width: 100%;
`;

export const NameUser = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  width: 100%;
  height: 1.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

export const StaticContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  row-gap: 1rem;
  margin-top: 2rem;

  @media only screen and (max-width: 535px) {
    justify-content: space-around;
  }
  .follower,
  .post-engagement,
  .job-score,
  .rating,
  .like {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;

    p {
      font-size: 1.2em;
      font-weight: bold;
      margin: 0;
    }
  }

  .follower {
    .icon {
      font-size: 2em;
      color: #60aaad;
    }

    .follower-count {
      font-size: 1.5rem;
      font-weight: normal;
    }

    .btn-edit {
      margin-top: 0.5rem;
      width: 80px;
      height: 30px;
      background: transparent;
      border-radius: 3rem;
      border: 1px solid #333;
    }
  }

  .rating {
    .star {
      color: #ffd124;
      font-size: 1.8em;
    }
    .rating-text {
      font-size: 2em;
      font-weight: normal;
    }
  }

  .like {
    .icon {
      color: #ff6b6b;
      font-size: 1em;
      width: 10px;
      animation: heart 1s linear alternate;
      font-weight: bold;
    }

    .like-count {
      font-size: 2em;
      font-weight: normal;
    }
  }
`;

export const PriceContainer = styled.div`
  text-align: center;
  padding-top: 0.5rem;
  width: 100%;
  border-top: 1px solid rgb(221, 218, 218);

  width: 100%;
  margin-top: 0.5rem;
`;
