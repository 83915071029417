/* eslint-disable react/jsx-no-comment-textnodes */
import Switch from 'react-switch';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FormGroup, Table } from 'reactstrap';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import paramsFromURL from 'src/constants/paramsFromURL';
import Role from 'src/constants/enum/role';
import {
  createRequestNewPriceGql,
  updateRequestNewPriceGql,
  updateSocialMediaInfo,
  deleteRequestPriceGql,
  calculateSocialMediaPriceGQL,
} from 'src/graphql/advertiser/socialMediaInfo';
import {
  Approve,
  Pending,
  Reject,
} from 'src/components/Common/approval/approvalStatus';
import choice from 'src/assets/images/choice.png';
import Swal from 'sweetalert2';
import {
  getInfluencerPriceRate,
  getOneSocialMediaInfo,
  getInfluencerRequestNewPrice,
  getDefaultWebsite,
  getRecommendContentPrice,
  setLoading,
  unsetLoading,
} from 'src/store/actions';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { OnLoading } from 'src/components/Common/Loading';
import { floorToFixed } from 'src/common/data/mathToFixed';
// eslint-disable-next-line import/order
import controls from './ContentPrice.control';
import './ContentPrice.style.scss';

// eslint-disable-next-line import/order
import notify from 'src/utils/notify';
// eslint-disable-next-line import/order
import i18n from 'src/i18n';
import { Tooltip } from '@mui/material';
import ContentType from 'src/constants/enum/contentType';
import { InfluencerContentType } from 'src/components/Common/status/InfluencerJobStatus';
import pageStyles from '../../../../../pages/Publisher/Influencer/ChannelDetail/ChannelDetail.styled';

const { TitleStyle, FlexRow, PricePost, TableStyle, TheadStyle, TbodyStyle, Texts } =
  pageStyles();

type ContentPrice = {
  share_content_price: number;
  photo_content_price: number;
  video_content_price: number;
};
const ContentPriceComponent = ({
  userProfile,
  formValues,
  setFormValues,
  disableBtn,
  firstCalculate,
  handleUpdateNewEngagement,
}: any) => {
  const { initialStateForm, calculatePrice } = controls();
  const [radioCheck, setRadioCheck] = useState<
    'half-price' | 'suggestion-price' | 'request-price' | ''
  >('');
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation();
  const [key]: any = paramsFromURL(['channel']);
  const dispatch = useDispatch();
  const {
    //userProfileSelected$,
    influencerPriceRate$,
    user,
    influencerNewPriceRate$,
    defaultTax,
    recommendContentPrice,
  } = useSelector((state: any) => ({
    defaultTax: state.DefaultValue.defaultWebsite.valueAddedTax,
    userProfileSelected$: state.CalculateSocialMedia.userProfileSelected,
    influencerPriceRate$: state.SocialMediaInfo.influencerPriceRate,
    influencerNewPriceRate$: state.SocialMediaInfo.influencerRequestNewPrice,
    user: state.login.user,
    recommendContentPrice: state.SocialMediaInfo.recommendContentPrice,
  }));

  const tax = defaultTax / 100;

  const share_price = formValues?.share_content_price || 0;
  const post_price = formValues?.photo_content_price || 0;
  const video_price = formValues?.video_content_price || 0;

  const [requestNewPrice, setRequestNewPrice] = useState<any>({
    share_content_price: 0,
    photo_content_price: 0,
    video_content_price: 0,
  });
  const [suggestionPrice, setSuggestionPrice] = useState<any>({
    share_content_price: 0,
    photo_content_price: 0,
    video_content_price: 0,
  });
  const [halfPrice, setHalfPrice] = useState<ContentPrice>({
    share_content_price: 0,
    photo_content_price: 0,
    video_content_price: 0,
  });
  const [contentPrice, setContentPrice] = useState<ContentPrice>({
    share_content_price: 0,
    photo_content_price: 0,
    video_content_price: 0,
  });

  let isPending = false;
  // influencerNewPriceRate$ != null
  //   ? !Object.keys(influencerNewPriceRate$)
  //       .filter((key) => key !== 'verify_status')
  //       .every((key) => {
  //         return influencerNewPriceRate$[key] === null;
  //       })
  //   : false;

  useEffect(() => {
    dispatch(getDefaultWebsite());
  }, []);

  useEffect(() => {
    if (formValues?.key && user.role === Role.PUBLISHER) {
      dispatch(getInfluencerRequestNewPrice(formValues?.key));
      calculateSocialMediaPrice(formValues);
    }
    dispatch(getInfluencerPriceRate());
  }, [formValues?.key]);

  useEffect(() => {
    setRequestNewPrice({
      ...requestNewPrice,
      share_content_price: influencerNewPriceRate$?.share_content_price,
      photo_content_price: influencerNewPriceRate$?.photo_content_price,
      video_content_price: influencerNewPriceRate$?.video_content_price,
    });

    if (influencerNewPriceRate$ != null) {
      if (influencerNewPriceRate$.verify_status === 'PENDING') {
        isPending = true;
      } else {
        isPending = false;
      }
    } else {
      isPending = false;
    }
  }, [influencerNewPriceRate$]);
  const CheckStatus = () => {
    switch (influencerNewPriceRate$.verify_status) {
      case 'PENDING':
        return <Pending message="Pending" />;

      case 'APPROVED':
        return <Approve message="Approve" />;

      case 'REJECTED':
        return <Reject message="Reject" />;

      default:
        return <></>;
    }
  };

  const calculateAndSetValue = (valueFn: any) => {
    for (let i = 0; i < influencerPriceRate$?.length; i++) {
      const {
        start_rank,
        multiply,
        end_rank,
        engagement_multiply,
        social_type,
        share_multiply,
        post_multiply,
        video_multiply,
        power,
        offset,
      } = influencerPriceRate$[i];
      const socialTypeValue = formValues?.social_media_type;
      const checkSocialType = socialTypeValue?.includes('PERSONAL')
        ? socialTypeValue?.split('_PERSONAL')[0]
        : socialTypeValue;
      if (social_type === checkSocialType) {
        const SocialCalculate = calculatePrice({
          follower: formValues?.fan_count || 0,
          engagement: formValues?.post_engagement || 1,
          start_rank,
          multiply,
          end_rank,
          engagement_multiply,
          share_multiply,
          post_multiply,
          video_multiply,
          power,
          offset,
        });

        return {
          ...valueFn,
          share_content_price: SocialCalculate.share_price,
          photo_content_price: SocialCalculate.post_price,
          video_content_price: SocialCalculate.video_price,
        };
      }
    }
    return formValues;
  };

  const calculateSocialMediaPrice = (value: any) => {
    const socialTypeValue = value?.social_media_type;
    const checkSocialType = socialTypeValue?.includes('PERSONAL')
      ? socialTypeValue?.split('_PERSONAL')[0]
      : socialTypeValue;
    if (firstCalculate) {
      dispatch(
        getRecommendContentPrice({
          social_id: value.id,
          access_token: value.access_token,
          social_media_type: checkSocialType,
          follower: value.fan_count,
          name: value.name,
          key: value.key,
        }),
      );
    }
  };

  const renderButton = () => {
    if (userProfile?.isPendinUpdatePrize) {
      return (
        <button
          type="button"
          onClick={() => {
            notify(i18n.t('error:Please wait 24 hours to continue'), 'error');
          }}
          className="price-rate-button"
        >
          <span
            style={{
              color: 'white',
            }}
          >
            {t('In queue')}
          </span>
          <Tooltip
            title={t('tooltip.Can be updated 1 time per day')}
            placement="top-start"
          >
            <i
              className="fas fa-info-circle ms-2 font-size-12"
              style={{ cursor: 'pointer', color: 'white' }}
              id="tooltipInfo"
            />
          </Tooltip>
        </button>
      );
    } else if (userProfile.nextUpdate > 3600 * 24) {
      return (
        <button
          type="button"
          onClick={() => {
            handleUpdateNewEngagement();
          }}
          className="price-rate-button"
        >
          <span
            style={{
              color: 'white',
            }}
          >
            {t('influencer:channel_detail.update_prize_rate')}
          </span>
          <Tooltip
            title={t('tooltip.Can be updated 1 time per day')}
            placement="top-start"
          >
            <i
              className="fas fa-info-circle ms-2 font-size-12"
              style={{ cursor: 'pointer', color: 'white' }}
              id="tooltipInfo"
            />
          </Tooltip>
        </button>
      );
    } else {
      return (
        <button
          type="button"
          onClick={() => {
            notify(i18n.t('error:Please wait 24 hours to continue'), 'error');
          }}
          className="price-rate-button"
        >
          <span
            style={{
              color: 'white',
            }}
          >
            {t('Pending')}
          </span>
          <Tooltip
            title={t('tooltip.Can be updated 1 time per day')}
            placement="top-start"
          >
            <i
              className="fas fa-info-circle ms-2 font-size-12"
              style={{ cursor: 'pointer', color: 'white' }}
              id="tooltipInfo"
            />
          </Tooltip>
        </button>
      );
    }
  };

  useEffect(() => {
    if (!formValues || !formValues?.fan_count || !firstCalculate) return;
    setFormValues(calculateAndSetValue(formValues));
    calculateSocialMediaPrice(formValues);
  }, [influencerPriceRate$, formValues?.fan_count]);

  useEffect(() => {
    if (!formValues || !formValues?.fan_count) return;
    setSuggestionPrice(calculateAndSetValue(suggestionPrice));
    //calculateSocialMediaPrice(formValues);
  }, [influencerPriceRate$, formValues?.fan_count]);

  useEffect(() => {
    if (recommendContentPrice) {
      if (firstCalculate) {
        setFormValues({
          ...formValues,
          share_content_price: recommendContentPrice?.recommend_share_price || 0,
          photo_content_price: recommendContentPrice?.recommend_photo_price || 0,
          video_content_price: recommendContentPrice?.recommend_video_price || 0,
        });
      }
      setSuggestionPrice({
        share_content_price: recommendContentPrice?.recommend_share_price || 0,
        photo_content_price: recommendContentPrice?.recommend_photo_price || 0,
        video_content_price: recommendContentPrice?.recommend_video_price || 0,
      });
    }
  }, [recommendContentPrice]);

  useEffect(() => {
    setHalfPrice({
      share_content_price: suggestionPrice.share_content_price / 2,
      photo_content_price: suggestionPrice.photo_content_price / 2,
      video_content_price: suggestionPrice.video_content_price / 2,
    });
  }, [suggestionPrice]);

  const inputRequestDisabled = () => {
    if (radioCheck === 'request-price') return false;
    return true;
  };

  const handleClose = () => {
    setIsEdit(false);
  };
  // console.log(influencerNewPriceRate$);
  if (!formValues) return <OnLoading />;

  return (
    <>
      <TitleStyle style={{ marginBottom: '1rem' }}>
        {t('influencer:account:Price_Rate')}
      </TitleStyle>
      <PricePost className="price-post">
        <FormGroup className="text-start">
          <TableStyle>
            <TheadStyle>
              <tr style={{ fontSize: '1rem' }}>
                {['content_type', 'Prize'].map((headerTable: string) => (
                  <th className="text-center" key={headerTable}>
                    <p>{t(`influencer:account:${headerTable}`)}</p>
                  </th>
                ))}
              </tr>
            </TheadStyle>
            {Object.keys(initialStateForm).map(
              (keyOfInitialStateForm: any, index: number) => {
                const value = initialStateForm[keyOfInitialStateForm];
                const { icon, iconColor, content_type } = value;
                let { price, label } = value;
                //let activeType = 'is_enable_share_price';
                if (formValues?.social_media_type === 'FACEBOOK') {
                  if (
                    content_type === ContentType.VIDEO ||
                    content_type === ContentType.SUBSCRIBE
                  ) {
                    return <></>;
                  } else {
                    if (content_type === ContentType.PHOTO) {
                      price = `${
                        user.role === Role.ADVERTISER
                          ? floorToFixed(post_price, 2)
                          : floorToFixed(post_price - post_price * tax, 2)
                      }`;
                    } else {
                      price = `${
                        user.role === Role.ADVERTISER
                          ? floorToFixed(share_price, 2)
                          : floorToFixed(share_price - share_price * tax, 2)
                      }`;
                    }
                  }
                } else if (formValues?.social_media_type === 'INSTAGRAM') {
                  if (content_type === ContentType.LIKE_AND_COMMENT) {
                    price = `${
                      user.role === Role.ADVERTISER
                        ? floorToFixed(share_price, 2)
                        : floorToFixed(share_price - share_price * tax, 2)
                    }`;
                  } else if (content_type === ContentType.PHOTO) {
                    price = `${
                      user.role === Role.ADVERTISER
                        ? floorToFixed(post_price, 2)
                        : floorToFixed(post_price - post_price * tax, 2)
                    }`;
                  } else {
                    return <></>;
                  }
                } else if (formValues?.social_media_type === 'YOUTUBE') {
                  if (
                    content_type === ContentType.LIKE_AND_COMMENT ||
                    content_type === ContentType.SUBSCRIBE
                  ) {
                    price = `${
                      user.role === Role.ADVERTISER
                        ? floorToFixed(share_price, 2)
                        : floorToFixed(share_price - share_price * tax, 2)
                    }`;
                  } else if (content_type === ContentType.VIDEO) {
                    price = `${
                      user.role === Role.ADVERTISER
                        ? floorToFixed(video_price, 2)
                        : floorToFixed(video_price - video_price * tax, 2)
                    }`;
                  } else {
                    return <></>;
                  }
                } else if (formValues?.social_media_type === 'TIKTOK') {
                  if (content_type === ContentType.LIKE_AND_COMMENT) {
                    price = `${
                      user.role === Role.ADVERTISER
                        ? floorToFixed(share_price, 2)
                        : floorToFixed(share_price - share_price * tax, 2)
                    }`;
                  } else if (content_type === ContentType.VIDEO) {
                    price = `${
                      user.role === Role.ADVERTISER
                        ? floorToFixed(video_price, 2)
                        : floorToFixed(video_price - video_price * tax, 2)
                    }`;
                  } else {
                    return <></>;
                  }
                } else {
                  if (content_type === ContentType.LIKE_AND_COMMENT) {
                    price = `${
                      user.role === Role.ADVERTISER
                        ? floorToFixed(share_price, 2)
                        : floorToFixed(share_price - share_price * tax, 2)
                    }`;
                  } else if (content_type === ContentType.SHARE) {
                    price = `${
                      user.role === Role.ADVERTISER
                        ? floorToFixed(video_price, 2)
                        : floorToFixed(video_price - video_price * tax, 2)
                    }`;
                    label = 'Quote';
                  } else if (content_type === ContentType.PHOTO) {
                    price = `${
                      user.role === Role.ADVERTISER
                        ? floorToFixed(post_price, 2)
                        : floorToFixed(post_price - post_price * tax, 2)
                    }`;
                  } else {
                    return <></>;
                  }
                }
                // if (
                //   formValues?.social_media_type === 'YOUTUBE' ||
                //   formValues?.social_media_type === 'TIKTOK'
                // ) {
                //   if (keyOfInitialStateForm === 'video_content_price') {
                //     activeType = 'is_enable_video_price';
                //     active = formValues?.is_enable_video_price;
                //     price = `${
                //       user.role === Role.ADVERTISER
                //         ? floorToFixed(video_price, 2)
                //         : floorToFixed(video_price - tax, 2) // TODO เมื่อกด submit จะขึ้นสเตตัส pending แทนราคา และมีปุ่ม cancel ด้านหลัง
                //     }`;
                //   }
                //   if (keyOfInitialStateForm === 'share_content_price') {
                //     return <></>;
                //   }

                //   if (keyOfInitialStateForm === 'photo_content_price') {
                //     return <></>;
                //   }
                // } else {
                //   if (keyOfInitialStateForm === 'share_content_price') {
                //     activeType = 'is_enable_share_price';
                //     active = formValues.is_enable_share_price;
                //     price = `${
                //       user.role === Role.ADVERTISER
                //         ? floorToFixed(share_price, 2)
                //         : floorToFixed(share_price - tax, 2)
                //     }`;
                //   }

                //   if (keyOfInitialStateForm === 'photo_content_price') {
                //     activeType = 'is_enable_photo_price';
                //     active = formValues.is_enable_photo_price;
                //     price = `${
                //       user.role === Role.ADVERTISER
                //         ? floorToFixed(post_price, 2)
                //         : floorToFixed(post_price - tax, 2)
                //     }`;
                //   }
                //   if (keyOfInitialStateForm === 'video_content_price') {
                //     return <></>;
                //   }
                // }
                const submitButton = (onConfirm: any) => {
                  return (
                    <button
                      className="confirm-button"
                      type="submit"
                      onClick={async () => {
                        const radioIsCheck = radioCheck !== '' && true;
                        const radioSubmit =
                          radioCheck === 'half-price' ||
                          radioCheck === 'suggestion-price';
                        const requestHasValue = !!(
                          radioCheck === 'request-price' &&
                          Object.values(requestNewPrice).some((v: any) => v > 0)
                        );
                        if (radioIsCheck && (requestHasValue || radioSubmit)) {
                          await Swal.fire({
                            width: 300,
                            title: t('swal:Are you sure ?'),
                            text: t("swal:You won't be able to revert this!"),
                            imageUrl: choice,
                            imageHeight: 200,
                            imageAlt: 'A tall image',
                            customClass: {},
                            showCancelButton: true,
                            confirmButtonColor: '##60a9ac',
                            confirmButtonText: t('button:Yes'),
                            cancelButtonText: t('swal:Cancel'),
                          }).then(async (result) => {
                            if (result.isConfirmed) {
                              onConfirm();
                            }
                          });
                        }
                      }}
                    >
                      {t('influencer:account:submit')}
                    </button>
                  );
                };
                const requestButton = (typeKey: any) => {
                  if (influencerNewPriceRate$ != null) {
                    if (influencerNewPriceRate$?.verify_status !== 'PENDING') {
                      return submitButton(() => {
                        createRequestNewPriceGql({
                          [typeKey]: +requestNewPrice[typeKey] || null,
                          social_media_info_key: formValues?.key,
                        });
                        dispatch(getInfluencerRequestNewPrice(formValues?.key));
                      });
                    }
                    if (influencerNewPriceRate$[typeKey]) {
                      return (
                        <button
                          type="button"
                          className="btn-delete-request"
                          onClick={async () => {
                            await Swal.fire({
                              width: 300,
                              title: t('swal:Are you sure ?'),
                              text: t("swal:You won't be able to revert this!"),
                              imageUrl: choice,
                              imageHeight: 200,
                              imageAlt: 'A tall image',
                              customClass: {},
                              showCancelButton: true,
                              confirmButtonColor: '#60a9ac',
                              confirmButtonText: t('button:Yes'),
                              cancelButtonText: t('swal:Cancel'),
                            }).then(async (result) => {
                              if (result.isConfirmed) {
                                // await updateRequestNewPriceGql({
                                //   social_media_info_key: formValues?.key,
                                //   [typeKey]: null,
                                // });
                                await deleteRequestPriceGql(formValues?.key);
                                dispatch(
                                  getInfluencerRequestNewPrice(formValues?.key),
                                );
                              } // TODO Cancel action
                            });
                            dispatch(getInfluencerRequestNewPrice(formValues?.key));
                          }}
                        >
                          {t('influencer:account:Cancel')}
                        </button>
                      );
                    }
                  }

                  return submitButton(async () => {
                    console.log('submit radioCheck', radioCheck);
                    if (radioCheck === 'request-price') {
                      // eslint-disable-next-line no-restricted-syntax
                      const payload = {
                        social_media_info_key: formValues?.key,
                        share_content_price:
                          +requestNewPrice.share_content_price > 0
                            ? +requestNewPrice.share_content_price
                            : null,
                        photo_content_price:
                          +requestNewPrice.photo_content_price > 0
                            ? +requestNewPrice.photo_content_price
                            : null,
                        video_content_price:
                          +requestNewPrice.video_content_price > 0
                            ? +requestNewPrice.video_content_price
                            : null,
                      };
                      // if (requestNewPrice.share_content_price > 0) {
                      //   payload.share_content_price =
                      //     requestNewPrice.share_content_price;
                      // }
                      // if (requestNewPrice.photo_content_price > 0) {
                      //   payload.photo_content_price =
                      //     requestNewPrice.photo_content_price;
                      // }
                      // if (requestNewPrice.video_content_price > 0) {
                      //   payload.video_content_price =
                      //     requestNewPrice.video_content_price;
                      // }
                      createRequestNewPriceGql(payload).then(() => {
                        dispatch(getInfluencerRequestNewPrice(formValues?.key));
                        setRadioCheck('');
                        setIsEdit(false);
                      });
                      // for (const _key in requestNewPrice) {
                      //   if (requestNewPrice[_key] > 0) {
                      //     // eslint-disable-next-line no-await-in-loop
                      //     updateRequestNewPriceGql({
                      //       social_media_info_key: formValues?.key,
                      //       [_key]: +requestNewPrice[_key] || null,
                      //     }).then(() => {
                      //       dispatch(getInfluencerRequestNewPrice(formValues?.key));
                      //       setRadioCheck('');
                      //       setIsEdit(false);
                      //     });
                      //   }
                      // }
                    } else {
                      updateSocialMediaInfo({
                        share_content_price: contentPrice.share_content_price,
                        photo_content_price: contentPrice.photo_content_price,
                        video_content_price: contentPrice.video_content_price,
                        key: formValues?.key,
                      }).then(() => {
                        dispatch(getOneSocialMediaInfo(formValues?.key));
                        setIsEdit(false);
                      });
                    }
                    // TODO 3 กรณีกด submit ต้องเช็คด้วยว่า radio จาก request หรือ 50%/suggest ต้องยิงต่างกัน
                  });
                };

                return (
                  <>
                    <Dialog
                      maxWidth="lg"
                      onClose={handleClose}
                      open={isEdit}
                      style={{ zIndex: 1050 }}
                    >
                      <DialogTitle>
                        {t('influencer:account:Edit Your Price Rate')}
                      </DialogTitle>
                      <DialogContent className="content-price-table-container">
                        <Table
                          bordered
                          responsive
                          hover
                          style={{ textAlign: 'center' }}
                        >
                          <thead>
                            <tr>
                              <th>{t('influencer:account:content_type')}</th>
                              <th>{t('influencer:account:Current Price')}</th>
                              <th>
                                <input
                                  type="radio"
                                  onChange={(e) => {
                                    if (!isPending) {
                                      setRadioCheck('half-price'); // TODO กรณีเป็น Pending ต้องกดเช็คไม่ได้
                                      setContentPrice({
                                        ...contentPrice,
                                        share_content_price:
                                          halfPrice?.share_content_price || 0,
                                        photo_content_price:
                                          halfPrice?.photo_content_price || 0,
                                        video_content_price:
                                          halfPrice?.video_content_price || 0,
                                      });
                                    }
                                  }}
                                  checked={radioCheck === 'half-price'}
                                  style={{
                                    marginRight: '0.25rem',
                                    position: 'relative',
                                    top: '2px',
                                  }}
                                />
                                {t('influencer:account:50% Off Price')}
                              </th>
                              <th>
                                <input
                                  type="radio"
                                  onChange={(e) => {
                                    if (!isPending) {
                                      setRadioCheck('suggestion-price');
                                      setContentPrice({
                                        ...contentPrice,
                                        share_content_price:
                                          parseFloat(
                                            suggestionPrice?.share_content_price,
                                          ) || 0,
                                        photo_content_price:
                                          parseFloat(
                                            suggestionPrice?.photo_content_price,
                                          ) || 0,
                                        video_content_price:
                                          parseFloat(
                                            suggestionPrice?.video_content_price,
                                          ) || 0,
                                      });
                                    }
                                  }}
                                  checked={radioCheck === 'suggestion-price'}
                                  style={{
                                    marginRight: '0.25rem',
                                    position: 'relative',
                                    top: '2px',
                                  }}
                                />
                                {t('influencer:account:Suggestion Price')}
                              </th>
                              <th>
                                <input
                                  type="radio"
                                  onChange={(e) => {
                                    if (!isPending) {
                                      setRadioCheck('request-price');
                                      setContentPrice({
                                        ...contentPrice,
                                        share_content_price:
                                          parseFloat(
                                            requestNewPrice?.share_content_price,
                                          ) || 0,
                                        photo_content_price:
                                          parseFloat(
                                            requestNewPrice?.photo_content_price,
                                          ) || 0,
                                        video_content_price:
                                          parseFloat(
                                            requestNewPrice?.video_content_price,
                                          ) || 0,
                                      });
                                    }
                                  }}
                                  checked={radioCheck === 'request-price'}
                                  style={{
                                    marginRight: '0.25rem',
                                    position: 'relative',
                                    top: '2px',
                                  }}
                                />
                                {t('influencer:account:Your Request Price')}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {keyOfInitialStateForm !== 'video_content_price' ? (
                              <>
                                <tr>
                                  <td>
                                    <i
                                      className="fas fa-share-alt"
                                      style={{
                                        color: '#6495ED',
                                        marginRight: '0.5rem',
                                      }}
                                    />
                                    Share
                                  </td>
                                  <td>{floorToFixed(share_price, 2)} USDT</td>
                                  <td style={{ color: '#60a9ac' }}>
                                    {floorToFixed(halfPrice.share_content_price, 2)}{' '}
                                    USDT
                                  </td>

                                  <td style={{ color: '#60a9ac' }}>
                                    {floorToFixed(
                                      suggestionPrice?.share_content_price,
                                      2,
                                    )}{' '}
                                    USDT
                                  </td>
                                  <td>
                                    <input
                                      min={0}
                                      autoComplete="off"
                                      name="share"
                                      type="number"
                                      disabled={inputRequestDisabled()} // BUG 1 ดักให้ติ๊ก radio ช่อง request ก่อนค่อยพิมพ์ได้
                                      // TODO ไว้ดักตอน pending ให้ radio กดไม่ได้
                                      className="input-container"
                                      value={
                                        requestNewPrice.share_content_price || ''
                                      }
                                      onChange={(e: any) => {
                                        setRequestNewPrice({
                                          ...requestNewPrice,
                                          share_content_price: e.target.value,
                                        });
                                        setContentPrice({
                                          ...contentPrice,
                                          share_content_price: parseFloat(
                                            e.target.value,
                                          ),
                                        });
                                      }}
                                    />
                                  </td>
                                  {/* <td>
                                    {influencerNewPriceRate$ !== null &&
                                    influencerNewPriceRate$?.share_content_price !==
                                      null
                                      ? CheckStatus()
                                      : '-'}
                                  </td> */}
                                </tr>
                                <tr>
                                  <td>
                                    <i
                                      className="fas fa-camera-retro"
                                      style={{
                                        color: '#F08080',
                                        marginRight: '0.5rem',
                                      }}
                                    />
                                    Photo
                                  </td>
                                  <td>{floorToFixed(post_price, 2)} USDT</td>
                                  <td style={{ color: '#60a9ac' }}>
                                    {floorToFixed(halfPrice.photo_content_price, 2)}{' '}
                                    USDT
                                  </td>

                                  <td style={{ color: '#60a9ac' }}>
                                    {floorToFixed(
                                      suggestionPrice?.photo_content_price,
                                      2,
                                    )}{' '}
                                    USDT
                                  </td>
                                  <td>
                                    <input
                                      min={0}
                                      autoComplete="off"
                                      name="post"
                                      disabled={inputRequestDisabled()}
                                      className="input-container"
                                      type="number"
                                      value={
                                        requestNewPrice.photo_content_price || ''
                                      }
                                      onChange={(e: any) => {
                                        setRequestNewPrice({
                                          ...requestNewPrice,
                                          photo_content_price: e.target.value, // BUG set content price ด้วย
                                        });
                                        setContentPrice({
                                          ...contentPrice,
                                          photo_content_price: parseFloat(
                                            e.target.value,
                                          ),
                                        });
                                      }}
                                    />
                                  </td>
                                  {/* <td>
                                    {influencerNewPriceRate$ !== null &&
                                    influencerNewPriceRate$?.photo_content_price !==
                                      null
                                      ? CheckStatus()
                                      : '-'}
                                  </td> */}
                                </tr>
                              </>
                            ) : (
                              <></>
                            )}

                            {keyOfInitialStateForm === 'video_content_price' ? (
                              <tr>
                                <td>
                                  <i
                                    className="fas fa-solid fa-video"
                                    style={{
                                      color: '#ee5555',
                                      marginRight: '0.5rem',
                                    }}
                                  />
                                  Video
                                </td>{' '}
                                <td>{floorToFixed(video_price, 2)} USDT</td>
                                <td style={{ color: '#60a9ac' }}>
                                  {floorToFixed(halfPrice.video_content_price, 2)}{' '}
                                  USDT
                                </td>
                                <td style={{ color: '#60a9ac' }}>
                                  {floorToFixed(
                                    suggestionPrice?.video_content_price,
                                    2,
                                  )}
                                </td>
                                <td>
                                  <input
                                    autoComplete="off"
                                    className="input-container"
                                    name="video"
                                    disabled={inputRequestDisabled()}
                                    type="number"
                                    value={requestNewPrice.video_content_price || ''}
                                    onChange={(e: any) => {
                                      setRequestNewPrice({
                                        ...requestNewPrice,
                                        video_content_price: e.target.value,
                                      });
                                      setContentPrice({
                                        ...contentPrice,
                                        video_content_price: parseFloat(
                                          e.target.value,
                                        ),
                                      });
                                    }}
                                  />
                                </td>
                                {/* <td>
                                  {influencerNewPriceRate$ !== null &&
                                  influencerNewPriceRate$?.video_content_price !==
                                    null
                                    ? CheckStatus()
                                    : '-'}
                                </td> */}
                              </tr>
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </Table>
                        <div
                          style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {!isPending && requestButton('share_content_price')}
                        </div>
                      </DialogContent>
                    </Dialog>
                    <TbodyStyle
                      key={uniqueId()}
                      //background={index % 2 === 0 ? 'none' : '#d9f4f9'}
                      background="none"
                    >
                      <tr>
                        <td>
                          {/* <FlexRow>
                            <i
                              className={`${icon} fa-2x color-success`}
                              style={{ color: iconColor }}
                            />
                            <InfluencerContentType
                              content_type={content_type}
                              size={24}
                            />
                            <Texts style={{ fontSize: '1rem' }}>{label}</Texts>
                          </FlexRow> */}
                          <div
                            style={{
                              display: 'flex',
                              gap: '10px',
                              alignItems: 'center',
                              marginLeft: '80px',
                            }}
                          >
                            <InfluencerContentType
                              content_type={content_type}
                              size={24}
                            />
                            <span style={{ fontSize: '1rem' }}>{label}</span>
                          </div>
                        </td>
                        <td className="text-center">
                          <Texts
                            style={{ color: '#60a9ac', fontSize: '1rem' }}
                            className="content-price-table-container"
                          >
                            {/* {influencerNewPriceRate$ &&
                            influencerNewPriceRate$[keyOfInitialStateForm] &&
                            influencerNewPriceRate$.verify_status === 'PENDING' ? (
                              <span>
                                Pending {requestButton(keyOfInitialStateForm)}
                              </span>
                            ) : (
                              <>{floorToFixed(price, 2)} USDT</>
                            )} */}
                            <>{floorToFixed(price, 2)} USDT</>
                          </Texts>
                        </td>
                        {/* <td className="text-center">
                          {userProfile?.isOwner === true ? (
                            <Switch
                              disabled={user.role !== Role.PUBLISHER}
                              onChange={async () => {
                                setFormValues({
                                  ...formValues,
                                  [activeType]: !active,
                                });
                                if (!disableBtn) {
                                  await updateSocialMediaInfo({
                                    key,
                                    //[activeType]: !active,
                                  });
                                  dispatch(getOneSocialMediaInfo(key));
                                }
                              }}
                              checked={active}
                              checkedIcon={false}
                              uncheckedIcon={false}
                              id="normal-switch"
                            />
                          ) : (
                            <></>
                          )}
                        </td> */}
                      </tr>
                    </TbodyStyle>
                  </>
                );
              },
            )}
          </TableStyle>
          {user.role === Role.PUBLISHER &&
          formValues?.key &&
          userProfile.isOwner === true ? (
            <div className="content-price-table-container">
              <div className="price-edit-button-container">
                {/* <button
                  type="button"
                  onClick={() => {
                    dispatch(getInfluencerRequestNewPrice(formValues?.key));
                    setRequestNewPrice({
                      ...requestNewPrice,
                      share_content_price:
                        influencerNewPriceRate$?.share_content_price,
                      photo_content_price:
                        influencerNewPriceRate$?.photo_content_price,
                      video_content_price:
                        influencerNewPriceRate$?.video_content_price,
                    });
                    setIsEdit(true);
                  }}
                  className="price-edit-button"
                >
                  <i
                    className="fas fa-pen"
                    style={{
                      margin: '5px 5px 0 0',
                      color: 'white',
                    }}
                  />
                  <span
                    style={{
                      color: 'white',
                    }}
                  >
                    {t('influencer:account.edit')}
                  </span>
                </button> */}
                {renderButton()}
              </div>
            </div>
          ) : (
            <></>
          )}
        </FormGroup>
      </PricePost>
    </>
  );
};

export default ContentPriceComponent;
