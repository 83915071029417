import { Form as FormikForm, Formik } from 'formik';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardHeader, CardTitle, FormGroup } from 'reactstrap';
import CustomButton from 'src/components/Common/CustomButton';
import { getUserInfo } from 'src/store/actions';
import control from './InfluencerAccount.control';
import './InfluencerAccount.style.scss';

const InfluencerAccountPage = () => {
  const {
    handleSubmit,
    validationSchema,
    showInfo,
    formValues,
    inputs,
    setOnEdit,
    t,
  } = control();
  const dispatch = useDispatch();

  //? Constructor
  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  return (
    <div className="influencer-account-container">
      <Card>
        <CardHeader>
          <CardTitle className="mb-0">
            <h5 className="m-0">{t('Profile')}</h5>
          </CardTitle>
        </CardHeader>
        <div className="influencer-card-container">
          <FormGroup className="mb-3">
            <Formik
              initialValues={formValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              enableReinitialize
            >
              {({ errors, touched, handleChange }: any) => {
                return (
                  <FormikForm style={{ width: 'auto', marginTop: '2rem' }}>
                    {inputs(errors, touched, handleChange)}
                    <div className="btn-container">
                      {showInfo ? (
                        <div
                          onClick={(e) => {
                            e.preventDefault();
                            setOnEdit(true);
                            dispatch(getUserInfo());
                          }}
                        >
                          <CustomButton
                            size="s"
                            status="submit"
                            label={t('marketer:account.edit')}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '2rem',
                            marginBottom: '2rem',
                            gap: '2rem',
                          }}
                        >
                          <CustomButton
                            size="s"
                            status="submit"
                            label={t('marketer:account.submit')}
                          />
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              setOnEdit(false);
                            }}
                          >
                            <CustomButton
                              size="s"
                              status="cancel"
                              label={t('marketer:account.cancel')}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </FormikForm>
                );
              }}
            </Formik>
          </FormGroup>
        </div>
      </Card>
    </div>
  );
};

export default InfluencerAccountPage;
