import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import choice from 'src/assets/images/choice.png';
import { Card, CardBody, CardHeader, CardTitle, Col, FormGroup } from 'reactstrap';
import Select from 'react-select';
import { createDeleteSocialMedia } from 'src/graphql/advertiser/socialMediaInfo';
import Swal from 'sweetalert2';
import './AllPage.style.scss';
import CustomButton from 'src/components/Common/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { getSocialMediaInfo, setLoading, unsetLoading } from 'src/store/actions';

const RemoveData = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [request, setRequest] = useState('');
  const [options, setOptions] = useState([
    {
      value: 'FACEBOOK_INSTAGRAM',
      label: `${t('influencer:Request to deauthorize Facebook and Instagram data')}`,
    },
    {
      value: 'FACEBOOK',
      label: `${t('influencer:Request to delete Facebook data')}`,
    },
    {
      value: 'vanilla',
      label: `${t('influencer:Request to delete Instagram data')}`,
    },
  ]);

  const { socialMediaInfo } = useSelector((state: any) => ({
    socialMediaInfo: state.SocialMediaInfo.socialMediaInfo,
  }));

  useEffect(() => {
    dispatch(getSocialMediaInfo());
  }, []);

  useEffect(() => {
    // console.log(socialMediaInfo);
    // if (socialMediaInfo?.length === 0) {
    //   dispatch(getSocialMediaInfo());
    //   return;
    // }
    const socialOptions = [];
    for (let i = 0; i < socialMediaInfo?.length; i++) {
      socialOptions.push({
        value: socialMediaInfo[i].key,
        label: `${socialMediaInfo[i].social_media_type} | ${socialMediaInfo[i].name}`,
      });
    }
    setOptions(socialOptions);
  }, [socialMediaInfo]);

  const handleChange = (selectedOption: any) => {
    setRequest(selectedOption.value);
  };
  const onSubmit = () => {
    dispatch(setLoading());
    createDeleteSocialMedia(request);
    dispatch(unsetLoading());
    // switch (request) {
    //   case 'FACEBOOK_INSTAGRAM':
    //     createDeleteThirdPartyData('DEAUTHORIZE');
    //     break;
    //   case 'FACEBOOK':
    //     createDeleteThirdPartyData('FACEBOOK');
    //     break;
    //   case 'INSTAGRAM':
    //     createDeleteThirdPartyData('INSTAGRAM');
    //     break;
    //   default:
    //     break;
    // }
  };
  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle className="mb-0">
            <h5 className="m-0" style={{ fontSize: '16px' }}>
              {t('Menus.Remove social authentication')}
            </h5>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Col md="12">
            <FormGroup className="mb-3">
              <Select
                options={options}
                onChange={handleChange}
                isSearchable={false}
                placeholder={t('Select')}
              />
              <div
                style={{ marginTop: '1.5rem' }}
                onClick={async () => {
                  if (request) {
                    await Swal.fire({
                      width: 300,
                      title: t('swal:Are you sure ?'),
                      text: t("swal:You won't be able to revert this!"),
                      imageUrl: choice,
                      imageHeight: 200,
                      imageAlt: 'A tall image',
                      customClass: {},
                      cancelButtonColor: 'red',
                      showCancelButton: true,
                      confirmButtonColor: '#009a78',
                      confirmButtonText: t('button:Yes'),
                      cancelButtonText: t('swal:Cancel'),
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        await onSubmit();
                      }
                    });
                  }
                }}
              >
                <CustomButton size="xs" status="submit" label={t('Button.Submit')} />
              </div>
            </FormGroup>
          </Col>
        </CardBody>
      </Card>
    </>
  );
};

export default RemoveData;
