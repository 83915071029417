import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  UncontrolledAlert,
} from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import Role from 'src/constants/enum/role';
import WalletWidgetHome from 'src/components/Common/WalletWidgetHome';
import WithdrawHistory from 'src/components/Publisher/WithdrawHistory';
import JobIncomeTable from 'src/components/Publisher/JobIncomeTable';
import {
  filterSocialJob,
  marketerSumPendingBalance,
  socialJobSummary,
} from 'src/store/actions';
import AdminWithdrawForm from 'src/components/Publisher/AdminWithdrawForm';
import AlertNew from 'src/components/Common/AlertNew';
import RequestCoinWithdraw from 'src/components/Publisher/RequestCoinWithdraw';
import EditOwnCoin from 'src/components/Publisher/EditOwnCoin';
import RequestOwnCurrencyHistory from 'src/components/Advertiser/RequestOwnCurrencyHistory';
import DepositAddressesNew from 'src/components/Advertiser/DepositAddresses_new';
import WithdrawForm from '../components/Publisher/WithdrawForm';
import RecentActivity from '../components/Publisher/RecentActivity';
import Breadcrumb from '../components/Common/Breadcrumb';
import menu from '../constants/menu';
import './WalletPage.style.scss';
import {
  getWithdrawRequest as onGetWithdrawRequest,
  getRecentWithdrawRequest as onGetRecentWithdrawRequest,
} from '../store/finance/withdraw/actions';
import {
  getAvailableCurrencies as onGetAvailableCurrencies,
  getCurrenciesByMarketerCurrencies,
} from '../store/currencies/actions';
import { getTwoFactorStatus as onGetTwoFactorStatus } from '../store/auth/twofactor/actions';
import { getDepositAddress as onGetDepositAddresses } from '../store/finance/depositAddresses/actions';
import { getDepositTransactionsRequest as onGetDepositTransactionsRequest } from '../store/finance/deposit/actions';
import DepositAddresses from '../components/Advertiser/DepositAddresses';
import DepositHistoryTable from '../components/Advertiser/DepositHistoryTable';
import CardLoading from '../components/Common/CardLoading';
import {
  getSumBalanceWallet,
  getWallet as onGetWallet,
} from '../store/finance/wallet/actions';
import config from '../config';

const WalletPage = ({ t }: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const checkNotificationWithdraw: any = location.state;
  const [tab, setTab] = useState(1);
  const [historyTab, setHistoryTab] = useState(1);
  const [editCoinName, setEditCoinName] = useState('');
  const [historyTabCoin, setHistoryTabCoin] = useState(1);
  const {
    loading,
    addresses,
    availableCurrencies,
    recentWithdrawTransactions,
    twoFactorEnabled,
    user,
    isLoggedIn,
  } = useSelector((state: any) => ({
    availableCurrencies: state.Currencies.availableCurrencies,
    recentWithdrawTransactions: state.Withdraw.recentTransactions,
    loading: state.DepositAddresses.loading,
    addresses: state.DepositAddresses.addresses,
    user: state.login.user,
    twoFactorEnabled: state.twoFactor.isEnabled,
    isLoggedIn: state.login.isLoggedIn,
  }));

  if (!isLoggedIn) window.location.replace(`/`);
  // if (user.role === Role.ADMIN) {
  //   if (!config.superadmin || !user.uuid || config.superadmin !== user.uuid)
  //     window.location.replace(`/`);
  // }

  useEffect(() => {
    // dispatch(onGetCurrencies());
    dispatch(onGetAvailableCurrencies());
    dispatch(getSumBalanceWallet());
  }, []);
  if (user.role === Role.ADVERTISER) {
    useEffect(() => {
      // dispatch(onGetCurrencies());
      // dispatch(onGetAvailableCurrencies());
      // dispatch(onGetDepositAddresses({ search: null }));
      dispatch(onGetRecentWithdrawRequest(10));
      dispatch(onGetTwoFactorStatus());
      dispatch(onGetWithdrawRequest({ limit: 10, page: 1 }));
      dispatch(onGetDepositTransactionsRequest({ limit: 10, page: 1 }));
      dispatch(marketerSumPendingBalance());
    }, [tab]);
  }
  if (checkNotificationWithdraw && user.role === Role.ADVERTISER) {
    useEffect(() => {
      setTab(3);
      setHistoryTab(2);
    }, []);
  }
  if (user.role === Role.PUBLISHER && checkNotificationWithdraw) {
    useEffect(() => {
      setTab(3);
      setHistoryTab(2);
    }, []);
  }
  if (user.role === Role.PUBLISHER) {
    useEffect(() => {
      // dispatch(onGetDepositAddresses({ search: null }));
      dispatch(onGetRecentWithdrawRequest(10));
      dispatch(onGetWithdrawRequest({ limit: 10, page: 1 }));
      dispatch(onGetTwoFactorStatus());
      dispatch(onGetWallet());
      dispatch(onGetDepositTransactionsRequest({ limit: 10, page: 1 }));
      dispatch(socialJobSummary());
    }, [tab]);
  }

  const handleChildTrigger = (name: string) => {
    // Perform actions in the parent component when the child is triggered

    setEditCoinName(name);
    setTab(3);
    setHistoryTabCoin(2);
  };

  return (
    <div
      className={` ${user.role === Role.ADVERTISER ? '' : 'page-content'} h-100`}
      style={{
        paddingTop: user.role === Role.ADVERTISER ? '6.3vh' : '',
        paddingLeft: user.role === Role.ADVERTISER ? '4.83vh' : '',
      }}
    >
      <MetaTags>
        <title>
          {t('page:Deposit')} | {config.appName}
        </title>
      </MetaTags>
      <Container fluid>
        <Breadcrumb
          pageTitle={t('page:Wallet')}
          menus={[{ menu: menu.HOME }, { menu: menu.WALLET, active: true }]}
        />
        <Row>
          <WalletWidgetHome />
        </Row>
        {user.role === Role.ADVERTISER && (
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="nav-scrollbar-none nav-tabs-custom mb-3">
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setTab(1);
                        }}
                        className={`${tab === 1 && 'active'} py-3`}
                      >
                        <div>
                          <h6 className="mb-0">{t('menu:Deposit Address')}</h6>
                        </div>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setTab(2);
                        }}
                        className={`${tab === 2 && 'active'} py-3`}
                      >
                        <div>
                          <h6 className="mb-0">{t('Menus.Withdrawal Request')}</h6>
                        </div>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setTab(3);
                        }}
                        className={`${tab === 3 && 'active'} py-3`}
                      >
                        <div>
                          <h6 className="mb-0">{t('Request your own coin')}</h6>
                        </div>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setTab(4);
                        }}
                        className={`${tab === 4 && 'active'} py-3`}
                      >
                        <div>
                          <h6 className="mb-0">{t('menu:History')}</h6>
                        </div>
                      </NavLink>
                    </NavItem>
                  </div>
                </CardHeader>
                <CardBody>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {tab === 1 && <DepositAddressesNew addresses={addresses} />}
                  {tab === 2 &&
                    (loading ? (
                      <CardLoading />
                    ) : (
                      <Row>
                        {/* {!twoFactorEnabled && <AlertNew />} */}
                        <Col xl={7}>
                          <WithdrawForm currencies={availableCurrencies} />
                        </Col>
                        <Col xl={5}>
                          <RecentActivity
                            transactions={recentWithdrawTransactions}
                          />
                        </Col>
                      </Row>
                    ))}
                  {tab === 3 && (
                    <div className="nav-scrollbar-none nav-tabs-custom mb-5">
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setHistoryTabCoin(1);
                          }}
                          className={`${historyTabCoin === 1 && 'active'} py-3`}
                        >
                          <div>
                            <h6 className="mb-0">{t('Request your own coin')}</h6>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setHistoryTabCoin(2);
                          }}
                          className={`${historyTabCoin === 2 && 'active'} py-3`}
                        >
                          <div>
                            <h6 className="mb-0">{t('Edit your own coin')}</h6>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setHistoryTabCoin(3);
                          }}
                          className={`${historyTabCoin === 3 && 'active'} py-3`}
                        >
                          <div>
                            <h6 className="mb-0">{t('Request History')}</h6>
                          </div>
                        </NavLink>
                      </NavItem>
                    </div>
                  )}
                  {historyTabCoin === 1 && tab === 3 && (
                    <RequestCoinWithdraw onTrigger={handleChildTrigger} />
                  )}
                  {historyTabCoin === 2 && tab === 3 && !editCoinName && (
                    <EditOwnCoin currencies={availableCurrencies} />
                  )}
                  {historyTabCoin === 2 && tab === 3 && editCoinName && (
                    <EditOwnCoin
                      currencies={availableCurrencies}
                      coinName={editCoinName}
                    />
                  )}
                  {historyTabCoin === 3 && tab === 3 && (
                    <RequestOwnCurrencyHistory />
                  )}

                  {tab === 4 && (
                    <div className="nav-scrollbar-none nav-tabs-custom mb-3">
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setHistoryTab(1);
                          }}
                          className={`${historyTab === 1 && 'active'} py-3`}
                        >
                          <div>
                            <h6 className="mb-0">{t('menu:Deposit History')}</h6>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setHistoryTab(2);
                          }}
                          className={`${historyTab === 2 && 'active'} py-3`}
                        >
                          <div>
                            <h6 className="mb-0">{t('menu:Withdraw History')}</h6>
                          </div>
                        </NavLink>
                      </NavItem>
                    </div>
                  )}
                  {historyTab === 1 &&
                    tab === 4 &&
                    (loading ? <CardLoading /> : <DepositHistoryTable />)}

                  {historyTab === 2 &&
                    tab === 4 &&
                    (loading ? <CardLoading /> : <WithdrawHistory />)}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {user.role === Role.PUBLISHER && (
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="nav-scrollbar-none nav-tabs-custom mb-3">
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setTab(1);
                        }}
                        className={`${tab === 1 && 'active'}`}
                      >
                        <h5 className="mb-0">{t('menu:Deposit Address')}</h5>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setTab(2);
                        }}
                        className={`${tab === 2 && 'active'}`}
                      >
                        <h5>{t('Menus.Withdrawal Request')}</h5>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setTab(3);
                        }}
                        className={`${tab === 3 && 'active'}`}
                      >
                        <h5>{t('Menus.Withdrawal History')}</h5>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setTab(4);
                        }}
                        className={`${tab === 4 && 'active'}`}
                      >
                        <h5>{t('Menus.Income History')}</h5>
                      </NavLink>
                    </NavItem>
                  </div>
                </CardHeader>
                <CardBody>
                  {tab === 1 && <DepositAddressesNew addresses={addresses} />}
                  {tab === 2 && (
                    <Row>
                      {!twoFactorEnabled && <AlertNew />}
                      <Col xl={7}>
                        <WithdrawForm currencies={availableCurrencies} />
                      </Col>
                      <Col xl={5}>
                        <RecentActivity transactions={recentWithdrawTransactions} />
                      </Col>
                    </Row>
                  )}
                  {tab === 3 && <WithdrawHistory />}
                  {tab === 4 && (
                    <div className="nav-scrollbar-none nav-tabs-custom mb-3">
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setHistoryTab(1);
                          }}
                          className={`${historyTab === 1 && 'active'}`}
                        >
                          <h5 className="mb-0">{t('menu:Deposit History')}</h5>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setHistoryTab(2);
                          }}
                          className={`${historyTab === 2 && 'active'}`}
                        >
                          <h5 className="mb-0">{t('menu:Job Income')}</h5>
                        </NavLink>
                      </NavItem>
                    </div>
                  )}
                  {historyTab === 1 &&
                    tab === 4 &&
                    (loading ? <CardLoading /> : <DepositHistoryTable />)}

                  {historyTab === 2 &&
                    tab === 4 &&
                    (loading ? <CardLoading /> : <JobIncomeTable />)}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {user.role === Role.ADMIN && (
          <Row>
            <Col>
              <AdminWithdrawForm currencies={availableCurrencies} />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default withTranslation()(WalletPage);
