//react
import { useState } from 'react';
//react style
import {
  Container,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
//general api
import { useTranslation } from 'react-i18next';
//component
import ApproveInfluencerComponent from 'src/components/Admin/ApproveInfluencer';
import menu from 'src/constants/menu';
import Breadcrumb from 'src/components/Common/Breadcrumb';

const ApproveInfluencer = () => {
  //state variables
  const [manageAdvertiserTab, setManageCreativeTab] = useState('1');
  const { t }: any = useTranslation();
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb
          pageTitle={t('New Influencer Approval')}
          menus={[
            { menu: menu.DASHBOARD },
            { menu: menu.NEW_INFLUENCER, active: true },
          ]}
        />
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader>
                <Nav className="nav-tabs-custom mb-3">
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={`${manageAdvertiserTab === '1' && 'active'}`}
                      onClick={() => {
                        setManageCreativeTab('1');
                      }}
                    >
                      <h5>{t('swal:All Approval')}</h5>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={`${manageAdvertiserTab === '2' && 'active'}`}
                      onClick={() => {
                        setManageCreativeTab('2');
                      }}
                    >
                      <h5>{t('swal:Pending Approval')}</h5>
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody>
                {manageAdvertiserTab === '1' && <ApproveInfluencerComponent />}

                {manageAdvertiserTab === '2' && (
                  <ApproveInfluencerComponent verifyStatus="PENDING" />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ApproveInfluencer;
