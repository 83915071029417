import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import useWindowSize from 'src/utils/windowSize';
import Typewriter from 'typewriter-effect';
import backgroundInfluencer from 'src/assets/images/other/background-influencer.png';
import Role from 'src/constants/enum/role';
import backgroundMarketer from 'src/assets/images/other/background-marketer.png';
import logoAF from 'src/assets/images/other/icon-AF-Homepage.png';
import textLogo from 'src/assets/images/other/text-logo.png';
import addSocial from 'src/assets/images/other/add-social.png';
import Influencer from 'src/assets/images/other/influencer.png';
import Marketer from 'src/assets/images/other/marketer.png';
import { useDispatch, useSelector } from 'react-redux';
import { getSuggestJobOnHomePage } from 'src/store/marketerJob/actions';
import EarnMoney from '../../assets/images/home/earn-money.png';
import LookingAd from '../../assets/images/home/looking-for-ad.png';
import Ad1 from '../../assets/images/home/place-ad-1.png';
import Ad2 from '../../assets/images/home/place-ad-2.png';
import Ad3 from '../../assets/images/home/place-ad-3.png';
import Ad4 from '../../assets/images/home/place-ad-4.png';
import logoColorful from '../../assets/images/AFbrother-logo.svg';
import HomePageNavBar from './HomePageNavBar';
import InfluencerIcon from '../../assets/images/home/mouth.0e0a95f.svg';
import CampaignsIcon from '../../assets/images/home/campaign.7a8d312.svg';
import ThailandFlag from '../../assets/images/home/emojione_flag-for-thailand.svg';
import ChinaFlag from '../../assets/images/home/emojione_flag-for-china.svg';
import VietnamFlag from '../../assets/images/home/emojione_flag-for-vietnam.svg';
import MalaysiaFlag from '../../assets/images/home/emojione_flag-for-malaysia.svg';
import BgWorld from '../../assets/images/home/bg-world@2x.7f8e937.png';
import InstargramIcon from '../../assets/images/home/ig@2x.27955f7.png';
import FacebookIcon from '../../assets/images/home/fb@2x.4f64514.png';
import YoutubeIcon from '../../assets/images/home/yt@2x.81f6272.png';
import TiktokIcon from '../../assets/images/home/tiktok@2x.ae31098.png';
import TwitterIcon from '../../assets/images/home/tw@2x.f2d449e.png';
import WebIcon from '../../assets/images/home/web.png';
import AppIcon from '../../assets/images/home/smartphone.png';
import Global from '../../assets/images/home/global.png';
import Tiktok from '../../assets/images/home/tiktok-svg.svg';
import CardSlider from './Card/CardSlider/CardSlider';
import 'src/components/Home/HomePageContent.style.scss';

const HomePageContent = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const homeSecondRef: any = useRef(null);
  const dispatch = useDispatch();

  const scrollToHomeSecond = () => {
    if (homeSecondRef && homeSecondRef.current) {
      homeSecondRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };
  const { states } = useSelector((state: any) => ({
    states: state.MarketerJob,
  }));
  useEffect(() => {
    dispatch(getSuggestJobOnHomePage());
  }, []);
  // console.log('states', states);

  return (
    <>
      <Helmet>
        <title>AFbrother</title>
        <link rel="canonical" href="/homepage" />
      </Helmet>
      <div>
        <Row className=" home-page-banner g-0 p-0">
          <div className=" d-flex flex-column" style={{ zIndex: '2' }}>
            <div style={{ color: 'black' }}>
              {' '}
              <HomePageNavBar />
            </div>
            <Row
              style={
                width <= 767
                  ? { marginLeft: '0px', marginRight: '0px' }
                  : { marginLeft: '137px', marginRight: '137px' }
              }
              className=" d-flex  "
            >
              {/* <Col md={6}> */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',

                  // border: '3px solid purple',
                  // marginBottom: '10rem',
                }}
              >
                <div
                  // className="home-page-banner__main-content"
                  style={{
                    width: '100%',
                    alignItems: 'flex-start',

                    // border: '3px solid blue',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      // border: '3px solid yellow',
                      maxWidth: '900px',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',

                      // marginBottom: '10rem',
                    }}
                  >
                    <div
                      style={{
                        marginBottom: '3rem',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <span
                        style={{
                          fontSize: '28px',
                          fontWeight: 'bold',
                          marginTop: '20px',
                        }}
                      >
                        {t('home.Global #1 Match maker for Influencer & Marketer')}{' '}
                      </span>
                      <h3 className="font-typing mt-3 " style={{ color: 'white' }}>
                        {t('home.everyone can be')}
                        <Typewriter
                          options={{
                            strings: [
                              t('home.an Influencer'),
                              t('home.a Reviewer'),
                              t('home.a Marketer'),
                            ],
                            autoStart: true,
                            loop: true,
                            delay: 150,
                          }}
                        />
                      </h3>
                      <span> {t('home.Influencer Marketing Hub')}</span>
                    </div>

                    {/* <div className="home-page-banner__title mb-1">
                      <img src={logo} alt="logo" />
                    </div>
                    <span
                      className="text-logo-af"
                      // style={{
                      //   fontSize: '24px',
                      //   fontWeight: 'bold',
                      //   margin: '1rem 0 4rem 0',
                      // }}
                    >
                      {t('anyone can be an influencer')}
                    </span> */}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '80%',
                      }}
                    >
                      <Link to={`/login/${Role.INFLUENCER.toLowerCase()}`}>
                        <div
                          className="btn btn-publisher btn-lg  me-4  d-flex justify-content-center align-items-center text-nowrap"
                          style={
                            width <= 405
                              ? { fontSize: '0.68rem', width: '25px' }
                              : { fontSize: '1rem', width: '25px' }
                          }
                        >
                          {t('home.Influencer/Publisher')}
                        </div>
                      </Link>
                      <Link to={`/login/${Role.MARKETER.toLowerCase()}`}>
                        <div
                          className="btn btn-advertiser btn-lg d-flex justify-content-center align-items-center text-nowrap"
                          style={
                            width <= 405
                              ? { fontSize: '0.68rem', width: '25px' }
                              : { fontSize: '1rem', width: '25px' }
                          }
                        >
                          {t('home.Marketer/Advertiser')}
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div
                    className=" img-logo-homepage"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '500px',
                      // height: '480px',
                      // position: 'absolute',
                      // right: '',
                      // marginLeft: '-5%',
                      position: 'relative',
                      marginLeft: '80px',
                    }}
                  >
                    <img
                      className="logo-af"
                      src={logoAF}
                      alt="logoaf"
                      style={{
                        width: '80%',
                        height: '80%',
                      }}
                    />
                    <img
                      className="logo-text"
                      src={textLogo}
                      alt="textlogo"
                      style={{
                        width: '500px',
                        position: 'absolute',
                        top: '85%',
                        left: 'calc(15vw - 250px)',
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* </Col> */}
            </Row>
          </div>
          {/* <div
            className="home-page-banner__learn mt-4 d-flex align-items-start c-pointer"
            style={{
              position: 'absolute',
              bottom: '1',
              left: '50%',
              top: '90%',

              transform: 'translate(-50%, -50%)',
            }}
            onClick={scrollToHomeSecond}
          >
            <i className="fas fa-sort-down me-2" />
            <span className="font-size-14">{t('Learn More')}</span>
          </div> */}
        </Row>
        <Row className=" home-page-second justify-content-center g-0">
          <h2
            className="text-center mb-md-5 mb-0 fw-bold fw-md-normal "
            ref={homeSecondRef}
            style={{ width: '80%' }}
          >
            {t('Place Ad Heading')}
          </h2>
          <Row className="g-0 justify-content-between">
            <Col lg={3} className="mt-4 mt-md-0">
              <div className="home-page-second__ad-item">
                <img src={Ad1} alt="ad-1" style={{ height: '120px' }} />
                <h6 className="text-center fw-bold fw-md-normal fs-5">
                  {t('Place Ad Item1 Heading')}
                </h6>
                <p
                  style={{ maxWidth: '280px' }}
                  className="text-center text-line-height fs-5"
                >
                  {t('Place Ad Item1 DESC')}
                </p>
              </div>
            </Col>
            <Col lg={3} className="mt-4 mt-md-0">
              <div className="home-page-second__ad-item">
                <img src={Ad2} alt="ad-2" style={{ height: '120px' }} />
                <h5 className="text-center fw-bold fw-md-normal fs-5">
                  {t('Place Ad Item2 Heading')}
                </h5>
                <p
                  style={{ maxWidth: '280px' }}
                  className="text-center text-line-height fs-5"
                >
                  {t('Place Ad Item2 DESC')}
                </p>
              </div>
            </Col>
            <Col lg={3} className="mt-4 mt-md-0">
              <div className="home-page-second__ad-item">
                <img src={Ad3} alt="ad-3" style={{ height: '120px' }} />
                <h5 className="text-center  fw-bold fw-md-normal fs-5">
                  {t('Place Ad Item3 Heading')}
                </h5>
                <p
                  style={{ maxWidth: '280px' }}
                  className="text-center text-line-height fs-5"
                >
                  {t('Place Ad Item3 DESC')}
                </p>
              </div>
            </Col>
            <Col lg={3} className="mt-4 mt-md-0">
              <div className="home-page-second__ad-item">
                <img src={Ad4} alt="ad-4" style={{ height: '120px' }} />
                <h5 className="text-center  fw-bold fw-md-normal fs-5">
                  {t('Place Ad Item4 Heading')}
                </h5>
                <p
                  style={{ maxWidth: '280px' }}
                  className="text-center text-line-height fs-5"
                >
                  {t('Place Ad Item4 DESC')}
                </p>
              </div>
            </Col>
          </Row>
        </Row>
        <Row className="home-page-third justify-content-center g-0">
          <h5 className="text-center text-white fs-4">{t('Stats Heading')}</h5>
          <Col className="mt-3">
            <Row className="g-0 justify-content-between text-white align-items-center">
              <Col md={4}>
                <div className="home-page-third__item mb-5 mb-md-0">
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', columnGap: '6px' }}>
                      <img src={InfluencerIcon} alt="Influencer Icon" />
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <h1 style={{ marginBottom: '0', color: '#ffffff' }}>
                          10,000+
                        </h1>
                        <h5 style={{ color: '#ffffff' }}>{t('Influencers')}</h5>
                      </div>
                    </div>
                    <div style={{ display: 'flex', columnGap: '13px' }}>
                      <img src={CampaignsIcon} alt="Campaigns Icon" />
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <h1 style={{ marginBottom: '0', color: '#ffffff' }}>
                          1,000+
                        </h1>
                        <h5 style={{ color: '#ffffff' }}>{t('Campaigns')}</h5>
                      </div>
                    </div>
                  </div>
                  {/* <h1 className="m-0 text-white font-size-60 me-2">
                    {t('Stats Item1 Heading')}
                  </h1>
                  <span
                    className="align-self-center align-self-end font-size-20"
                    style={width <= 767 ? { marginTop: '-15px' } : {}}
                  >
                    {t('Stats Item1 DESC')}
                  </span> */}
                </div>
              </Col>
              <Col md={4}>
                <div className="home-page-third__item mb-5 mb-md-0">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <img
                        src={BgWorld}
                        alt="Background World"
                        style={{ width: '100px', height: '100px' }}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        position: 'absolute',
                        flexDirection: 'column',
                        rowGap: '4px',
                      }}
                    >
                      <div style={{ display: 'flex', columnGap: '8px' }}>
                        <img src={ThailandFlag} alt="Thailand Flag" />
                        <img src={ChinaFlag} alt=" China Flag" />
                        <img src={VietnamFlag} alt="Vietnam Flag" />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          columnGap: '8px',
                        }}
                      >
                        <img src={MalaysiaFlag} alt="Malaysia Flag" />
                        <img src={Global} alt="World Flag" className="social-icon" />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginTop: '8px',
                    }}
                  >
                    <div style={{ display: 'flex', columnGap: '6px' }}>
                      <h5 className="text-white">{t('home.Thailand')}</h5>
                      <h5 className="text-white">{t('home.China')}</h5>
                      <h5 className="text-white">{t('home.Vietnam')}</h5>
                    </div>
                    <div style={{ display: 'flex', columnGap: '6px' }}>
                      <h5 className="text-white">{t('home.Malaysia')}</h5>
                      <h5 className="text-white">{t('home.Global')}</h5>
                    </div>
                  </div>
                  {/* <h1 className="m-0 text-white font-size-60 me-2">
                    {t('Stats Item2 Heading')}
                  </h1>
                  <span
                    className="align-self-center font-size-20"
                    style={width <= 767 ? { marginTop: '-15px' } : {}}
                  >
                    {t('Stats Item2 DESC')}
                  </span> */}
                </div>
              </Col>
              <Col md={4}>
                <div className="home-page-third__item">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      rowGap: '8px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '6px',
                      }}
                    >
                      <div style={{ display: 'flex', columnGap: '6px' }}>
                        <img
                          className="social-icon"
                          src={InstargramIcon}
                          alt="Instagram Icon"
                        />
                        <img
                          className="social-icon"
                          src={FacebookIcon}
                          alt="Facebook Icon"
                        />
                        <img
                          className="social-icon"
                          src={YoutubeIcon}
                          alt="Youtube Icon"
                        />
                        <img
                          className="social-icon"
                          src={TiktokIcon}
                          alt="Tictok Icon"
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          textAlign: 'center',
                          columnGap: '6px',
                        }}
                      >
                        <img
                          className="social-icon"
                          src={TwitterIcon}
                          alt="Twitter Icon"
                        />
                        <img className="social-icon" src={WebIcon} alt="Web Icon" />
                        <img className="social-icon" src={AppIcon} alt="App Icon" />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          columnGap: '6px',
                          marginTop: '5px',
                        }}
                      >
                        <h5 className="text-white">{t('home.Instagram')}</h5>
                        <h5 className="text-white">{t('home.Facebook')}</h5>
                        <h5 className="text-white">{t('home.Youtube')}</h5>
                        <h5 className="text-white">{t('home.Tiktok')}</h5>
                      </div>
                      <div style={{ display: 'flex', columnGap: '6px' }}>
                        <h5 className="text-white">{t('home.Twitter')}</h5>
                        <h5 className="text-white">{t('home.Website')}</h5>
                        <h5 className="text-white">{t('home.Application')}</h5>
                      </div>
                    </div>
                  </div>
                  {/* <h1 className="m-0 text-white font-size-60 me-2">
                    {t('Stats Item3 Heading')}
                  </h1>
                  <span
                    className="align-self-center font-size-20"
                    style={width <= 767 ? { marginTop: '-15px' } : {}}
                  >
                    {t('Stats Item3 DESC')}
                  </span> */}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="home-page-fourth-card justify-content-center align-items-center ">
          {/* <InfluencerWorks /> */}
          <CardSlider />
          {/* <div className="home-page-fourth__item">
            <Row className="g-0 align-self-stretch justify-content-evenly">
              <Col xs={12} sm={10} md={8}>
                <h5 className="align-self-start text-center text-md-start fs-3">
                  {t('Advertiser Heading')}
                </h5>
              </Col>
            </Row>
          </div> */}
          {/* <Col lg={7}>
            <div className="home-page-fourth__item mb-3 mb-md-0">
              <img src={LookingAd} alt="looking-for-space" />
            </div>
          </Col>
          <Col lg={5} xl={4}>
            <div className="home-page-fourth__item">
              <p
                className="home-page-fourth__desc mb-3 text-center text-line-height"
                style={width <= 767 ? { fontSize: '1.1em' } : { fontSize: '1.1em' }}
              >
                {t('Advertiser DESC')}
              </p>
              <Link to="/register">
                <div
                  className="btn btn-advertiser btn-lg align-self-center d-flex justify-content-center align-items-center"
                  style={
                    width <= 767 ? { fontSize: '1.1em' } : { fontSize: '1.1em' }
                  }
                >
                  {t('ADVERTISER')}
                </div>
              </Link>
            </div>
          </Col> */}
        </Row>
        {/* <Row className="home-page-fourth g-0 flex-lg-row-reverse justify-content-center align-items-center">
          <div className="home-page-fourth__item">
            <Row className="g-0 align-self-stretch justify-content-evenly">
              <Col xs={12} sm={10} md={8}>
                <h1 className="align-self-start text-center text-md-start fs-5">
                  {t('Publisher Heading')}
                </h1>
              </Col>
            </Row>
          </div>
          <Col lg={7}>
            <div className="home-page-fourth__item mb-3 mb-md-0">
              <img src={EarnMoney} alt="looking-for-space" />
            </div>
          </Col>
          <Col lg={5} xl={4}>
            <div className="home-page-fourth__item">
              <p
                className="home-page-fourth__desc mb-3 text-center text-line-height"
                style={width <= 767 ? { fontSize: '1.2em' } : { fontSize: '1.2em' }}
              >
                {t('Publisher DESC')}
              </p>
              <Link to="/register">
                <div
                  className="btn btn-publisher btn-lg align-self-center d-flex justify-content-center align-items-center"
                  style={
                    width <= 767 ? { fontSize: '1.2em' } : { fontSize: '1.2em' }
                  }
                >
                  {t('PUBLISHER')}
                </div>
              </Link>
            </div>
          </Col>
        </Row> */}
        <Row
          className="home-page-footer g-0"
          style={
            width <= 767
              ? { padding: '1.5em 0em 0em 1.5em' }
              : { padding: '2em 2em 0 2em' }
          }
        >
          <div className="container home-page-footer-item-container">
            <Col className="d-flex flex-column flex-sm-row">
              <div
                className="d-flex flex-grow-1"
                style={
                  width <= 767
                    ? { justifyContent: 'space-between', flexWrap: 'wrap' }
                    : {}
                }
              >
                <div className="mx-4">
                  <h6 className="font-size-18">{t('Products')}</h6>
                  <ul className="list-unstyled font-size-15">
                    <li>
                      <Link to="/register/marketer" className="text-body">
                        {t('home.Marketer/Advertiser')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/register/influencer" className="text-body">
                        {t('home.Influencer/Publisher')}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="mx-4">
                  <h6 className="font-size-18">{t('Company')}</h6>
                  <ul className="list-unstyled font-size-15">
                    <li>
                      <Link to="/contact" className="text-body">
                        {t('Contact')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/faqs" className="text-body">
                        {t('FAQ')}
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://blog.afbrother.com/"
                        className="text-body"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                      >
                        {t('Blog')}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="mx-4">
                  <h6 className="font-size-18">{t('Privacy & Policy')}</h6>
                  <ul className="list-unstyled font-size-15">
                    <li>
                      <Link to="/privacy-policy" className="text-body">
                        {t('Privacy & Policy')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/terms-of-use" className="text-body">
                        {t('Terms of Use')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="d-flex d-sm-block mx-4"
                style={{ flexDirection: 'column', alignContent: 'flex-end' }}
              >
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  <img src={logoColorful} alt="logo" width="180px" style={{}} />
                </div>
                <ul
                  className="d-flex list-unstyled mx-4"
                  style={
                    width <= 767
                      ? { padding: '0em' }
                      : { padding: '0em 0em 0em 0em' }
                  }
                >
                  <li>
                    <a
                      className="text-color-primary"
                      href="https://www.facebook.com/AFBrotherGlobal"
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      <i className="fab fa-facebook fa-2x" />
                    </a>
                  </li>
                  <li className="mx-2">
                    <a
                      className="text-color-primary"
                      href="https://twitter.com/AFBrotherGlobal"
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      <i className="fab fa-twitter fa-2x" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-color-primary"
                      href="https://www.youtube.com/@afbrotherglobal/"
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      <i className="fab fa-youtube fa-2x" />
                    </a>
                  </li>
                  <li className="mx-2">
                    <a
                      className="text-color-primary"
                      href="https://www.tiktok.com/@afbrother_global"
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      <img
                        src={Tiktok}
                        alt=""
                        style={{
                          width: '28px',
                          height: '28px',
                        }}
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </div>
        </Row>
      </div>
    </>
  );
};

export default HomePageContent;
