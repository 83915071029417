/* eslint-disable no-unused-vars */
import { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import CustomTable from 'src/components/Common/CustomTable';
import choice from 'src/assets/images/choice.png';
import PageSizeOptions from 'src/components/Table/PageSizeOptions';
import config from 'src/config';
import { resetApproveLogValueByName } from 'src/graphql/common/activity-logs';
import VerifyStatus from 'src/constants/verifyStatusEnum';
import {
  checkSocialMediaCallAPIGQL,
  updateSocialMediaInfo,
} from 'src/graphql/advertiser/socialMediaInfo';
import {
  getAllAdminApproveLog,
  setLoading,
  socialMediaFilterTable,
  unsetLoading,
} from 'src/store/actions';
import Swal from 'sweetalert2';
import { Dialog, DialogContent } from '@mui/material';
import { Link } from 'react-router-dom';
import Dropdown from '../CommonForBoth/Menus/Dropdown';
import SearchBar from '../Table/SearchBar';

const ApproveInfluencerComponent = ({ verifyStatus }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [newVerifyStatus, setNewVerifyStatus] = useState(verifyStatus || null);

  const [param, setParam] = useState<any>({
    limit: 10,
    page: 1,
    verify_status: newVerifyStatus,
    social_media_type: null,
    sort_by: null,
    sortType: null,
  });

  const defaultPostModalData = {
    isOpen: false,
    message: '',
  };
  const [postModalData, setPostModalData] = useState(defaultPostModalData);

  const data = useSelector(
    (State: any) => State.SocialMediaInfo.socialMediaFilterTable,
  );

  const clickVerifyStatus = async (verify: string, key: string) => {
    await Swal.fire({
      title: t('swal:Are you sure ?'),
      text: t(`swal:Do you want to ${verify} !`),
      imageUrl: choice,
      width: 300,
      imageHeight: 200,
      imageAlt: 'A tall image',
      cancelButtonColor: 'red',
      customClass: {},
      showCancelButton: true,
      confirmButtonColor: '#009a78',
      confirmButtonText: t(`button:Yes`),
      cancelButtonText: t('swal:Cancel'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(setLoading());
        await updateSocialMediaInfo({
          verify_status: verify,
          key,
        });
        dispatch(unsetLoading());
        dispatch(socialMediaFilterTable(param));
      }
    });
  };

  //table
  const columns = useMemo(() => {
    return [
      {
        Header: t('table:No.'),
        accessor: (row: any, index: number) =>
          index + 1 + (param.page - 1) * param.limit,

        id: 'index',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('name'),
        accessor: ({ social_id }: any) => social_id,
        Cell: ({ cell }: any) => {
          return (
            <>
              {cell.row.original?.profile_path ? (
                <a
                  href={cell.row.original?.profile_path}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {cell.row.original?.name}
                </a>
              ) : (
                cell.row.original?.name
              )}
            </>
          );
        },
        id: 'social_id',
        disableSort: true,
        width: 150,
      },

      {
        Header: t('table:social'),
        accessor: ({ social_media_type }: any) => social_media_type,
        id: 'social_media_type',
        disableSort: true,
        width: 100,
      },

      {
        Header: t(`table:view Influencer profile`),
        accessor: (item: any) => (
          <a
            className="cursor  fw-bold view-profile"
            href={`/InfluencerChannel?channel=${item?.key}`}
            onClick={(e) => {
              e.preventDefault();
              history.push(`/InfluencerChannel?channel=${item?.key}`);
            }}
          >
            {t(`table:view profile`)}
          </a>
        ),
        id: 'influencer_profile',
        width: 180,
        disableSort: true,
      },

      {
        Header: t('marketer:create_job_campaign:Follower'),
        accessor: ({ follower }: any) => follower,
        id: 'follower',
        width: 100,
      },
      {
        Header: t('marketer:create_campaign:status'),
        accessor: ({ verify_status }: any) => {
          let badgeColor: string = 'warning';
          switch (verify_status) {
            case VerifyStatus[0]:
              badgeColor = 'warning';
              break;
            case VerifyStatus[1]:
              badgeColor = 'success';
              break;
            case VerifyStatus[2]:
              badgeColor = 'danger';
              break;
            default:
              badgeColor = 'warning';
          }

          return (
            <div className="mt-1">
              <span
                className={`badge rounded-pill badge-soft-${badgeColor} font-size-12`}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
                id="span1"
              >
                {t(`enum:${verify_status}`)}
              </span>
            </div>
          );
        },
        id: 'status',
        width: 100,
        disableSort: true,
      },
      {
        Header: t('img'),
        accessor: ({ image_profile_url }: any) => (
          <img
            src={image_profile_url}
            alt="image_profile_url"
            style={{ width: 40, height: 40, borderRadius: '50%' }}
          />
        ),

        id: 'profile',
        disableSort: true,
        width: 100,
      },
      {
        Header: t('table:Action'),
        accessor: ({ key, verify_status }: any) => (
          <UncontrolledDropdown className="">
            <DropdownToggle className="text-muted font-size-28" tag="a">
              <i className="bx bx-pencil" />
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-end">
              {verify_status !== VerifyStatus[0] && (
                <DropdownItem
                  onClick={() => clickVerifyStatus('PENDING', key)}
                  className="text-warning"
                >
                  <i className="mdi mdi-account-clock text-warning" />
                  &nbsp;&nbsp;{t('table:PENDING')}
                </DropdownItem>
              )}
              {verify_status !== VerifyStatus[1] && (
                <DropdownItem
                  onClick={() => clickVerifyStatus('APPROVED', key)}
                  className="text-success"
                >
                  <i className="mdi mdi-account-check text-success" />
                  &nbsp;&nbsp;{t('table:Approve')}
                </DropdownItem>
              )}
              {verify_status !== VerifyStatus[2] && (
                <DropdownItem
                  onClick={() => clickVerifyStatus('REJECTED', key)}
                  className="text-danger"
                >
                  <i className="mdi mdi-account-cancel text-danger" />
                  &nbsp;&nbsp;{t('table:Reject')}
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        ),
        id: 'Action',
        disableSort: true,
        width: 100,
      },
      {
        Header: t('table:Test Call API'),
        accessor: ({ social_id, key, isDelete }: any) => {
          if (isDelete) {
            return <></>;
          } else {
            return (
              <button
                style={{ background: 'white' }}
                className="link-job"
                type="button"
                onClick={async () => {
                  const res = await checkSocialMediaCallAPIGQL(key, social_id);
                  if (res) {
                    setPostModalData({
                      ...postModalData,
                      isOpen: true,
                      message: res,
                    });
                  }
                }}
              >
                {t('table:Test')}
              </button>
            );
          }
        },
        id: 'test',
        disableSort: true,
      },
      {
        Header: t('table:email'),
        accessor: ({ user }: any) => {
          return <Link to={`/admin/users/${user.uuid}`}>{user.email}</Link>;
        },
        id: 'email',
        disableSort: true,
      },
      {
        Header: t('table:created_at'),
        accessor: ({ created_at }: any) => {
          if (!created_at) return 'null';
          const created_at_date = new Date(created_at);
          const Day = created_at_date.getDate();
          const month = created_at_date.getMonth() + 1;
          const year = created_at_date.getFullYear();

          return `${Day}/${month}/${year}`;
        },
        id: 'created_at',
        disableSort: true,
      },
    ];
  }, [t, param.page, param.limit, param]);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : param.page;
    const payload = {
      ...param,
      page: newPage,
    };
    dispatch(socialMediaFilterTable(payload));
    setParam(payload);
  };

  const handleStatusChange = (event: any) => {
    const payload = {
      ...param,
      page: 1,
      verify_status: event.value,
    };
    dispatch(socialMediaFilterTable(payload));
    setNewVerifyStatus(event.value);
    setParam(payload);
  };

  const handleSocialChange = (event: any) => {
    const payload = {
      ...param,
      page: 1,
      social_media_type: event.value,
    };
    dispatch(socialMediaFilterTable(payload));
    setNewVerifyStatus(event.value);
    setParam(payload);
  };

  const handlePageSizeChange = (newPageSize: string) => {
    const payload = {
      ...param,
      limit: +newPageSize,
      page: 1,
    };
    dispatch(socialMediaFilterTable(payload));
    setParam(payload);
  };

  const fetchApproveLog = async () => {
    await resetApproveLogValueByName('new_influencer');
    dispatch(getAllAdminApproveLog());
  };

  useEffect(() => {
    fetchApproveLog();
    dispatch(socialMediaFilterTable(param));
  }, []);

  const Status = [
    {
      value: 'PENDING',
      label: t('table:PENDING'),
    },
    { value: 'APPROVED', label: t('table:APPROVED') },
    { value: 'REJECTED', label: t('table:REJECTED') },
    { value: null, label: t('All') },
  ];

  const Socials = [
    { value: 'TWITTER', label: t('TWITTER') },
    { value: 'FACEBOOK', label: t('FACEBOOK') },
    { value: 'INSTAGRAM', label: t('INSTAGRAM') },
    { value: 'TIKTOK', label: t('TIKTOK') },
    { value: 'YOUTUBE', label: t('YOUTUBE') },
    { value: null, label: t('All') },
  ];

  const handleSearch = (search: string) => {
    const payload = {
      ...param,
      name: search,
      page: 1,
    };
    dispatch(socialMediaFilterTable(payload));
    setParam(payload);
  };

  const handleClear = () => {
    const payload = {
      ...param,
      name: '',
      page: 1,
    };
    dispatch(socialMediaFilterTable(payload));
    setParam(payload);
  };

  const displayModal = () => {
    // eslint-disable-next-line no-unused-vars
    const { isOpen, message } = postModalData;
    return (
      <Dialog maxWidth={false} onClose={handleClose} open={isOpen}>
        <DialogContent style={{ cursor: 'pointer' }}>
          <h3>{message}</h3>
        </DialogContent>
      </Dialog>
    );
  };

  const handleClose = () => {
    setPostModalData({ ...postModalData, isOpen: false });
  };

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage =
        newSortBy === param.sort_by && newSortType === param.sortType
          ? param.page
          : 1;

      const payload = {
        ...param,
        page: newPage,
        sort_by: newSortBy,
        sortType: newSortType,
      };

      dispatch(socialMediaFilterTable(payload));
      setParam(payload);
    },
    [param.page, param.limit],
  );
  return (
    <>
      {displayModal()}
      <div>
        <MetaTags>
          <title>
            {t('page:Job')} | {config.appName}
          </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card className="h-100">
                <CardBody className="h-100">
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <PageSizeOptions
                      defaultValue={10}
                      handlePageSizeChange={handlePageSizeChange}
                    />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: '150px', marginRight: '20px' }}>
                        <div>{t('Social')}</div>
                        <Dropdown
                          options={Socials}
                          action={handleSocialChange}
                          default_value={Socials[3]}
                        />
                      </div>
                      <div style={{ width: '150px', marginRight: '20px' }}>
                        <div>{t('Status')}</div>
                        <Dropdown
                          options={Status}
                          action={handleStatusChange}
                          default_value={Status[5]}
                        />
                      </div>
                      <SearchBar
                        placeholder={t('table:Search name')}
                        handleSearch={handleSearch}
                        handleClear={handleClear}
                        disablePadding
                      />
                    </div>
                  </div>

                  <CardBody>
                    <CustomTable
                      columns={columns || []}
                      data={data?.items || []}
                      page={param.page}
                      pageSize={param.limit}
                      total={data?.totalCount}
                      handlePageChange={handlePageChange}
                      headerCSS="fw-normal bg-header-table"
                      onSort={handleSort}
                    />
                  </CardBody>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ApproveInfluencerComponent;
