import React from 'react';

// import Breadcrumbs
import { Row, Col } from 'reactstrap';
import WalletWidget from '../../components/Common/WalletWidget';

const AdminDashboard = () => {
  return (
    <div className="page-content">
      <Col sm={100}>
        <WalletWidget />
      </Col>
    </div>
  );
};

export default AdminDashboard;
