import InfluencerJobDetail from 'src/pages/Advertiser/Marketer/InfluencerJobDetail/influencerJobDetail';
import PublisherDashboard from 'src/pages/Dashboard/PublisherDashboard';
import MockInfluencerPublishJob from 'src/pages/mock/mockPublishJob/mockInfluPublishJob';
import ApplicationInfo from 'src/pages/Publisher/Application/ApplicationInfo';
import ChannelDetailPage from 'src/pages/Publisher/Influencer/ChannelDetail/ChannelDetail.component';
import InfluencerAccount from 'src/pages/Publisher/Influencer/InfluencerAccount/InfluencerAccount.component';
import JobIndex from 'src/pages/Publisher/Influencer/jobIndex';
import RenewTokenPage from 'src/pages/Publisher/Influencer/RenewToken/RenewToken.component';
import SocialCalculatePage from 'src/pages/Publisher/Influencer/SocialCalculate/SocialCalculate.component';
import SocialRenewPage from 'src/pages/Publisher/Influencer/SocialRenew/SocialRenew.component';
import AddApplication from '../pages/Publisher/Application/AddApplication';
import ApplicationIndex from '../pages/Publisher/Application/ApplicationIndex';
import ChannelAddPage from '../pages/Publisher/Influencer/ChannelAdd/ChannelAdd.component';
import InfluencerDashboard from '../pages/Publisher/Influencer/InfluencerDashboard/InfluencerDashboard.component';
import AddWebsite from '../pages/Publisher/Website/addWebsite';
import MyWebsite from '../pages/Publisher/Website/index';
import WebsiteInfo from '../pages/Publisher/Website/InfoPage';

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const publisherRoutes: Array<RouteProps> = [
  // this route should be at the end of all other routes

  {
    path: '/mywebsite',
    exact: true,
    component: MyWebsite,
  },
  {
    path: '/mywebsite/addwebsite',
    component: AddWebsite,
  },
  {
    path: '/application/create',
    component: AddApplication,
  },
  {
    path: '/myApplication',
    component: ApplicationIndex,
  },
  {
    path: '/Application/:id',
    component: ApplicationInfo,
  },
  {
    path: '/mywebsite/websiteinfo/:id(\\d+)',
    component: WebsiteInfo,
  },
  {
    path: '/influencer',
    component: InfluencerDashboard,
  },
  {
    path: '/influencer/dashboard',
    component: InfluencerDashboard,
  },
  {
    path: '/influencer/channel-add',
    component: ChannelAddPage,
  },
  {
    path: '/influencer/my-works',
    component: WebsiteInfo,
  },
  {
    path: '/influencer/job',
    exact: true,
    component: JobIndex,
  },
  {
    path: '/JobDetail',
    exact: true,
    component: InfluencerJobDetail,
  },

  {
    path: '/influencer/account',
    component: InfluencerAccount,
  },
  {
    path: '/publisher/dashboard',
    component: PublisherDashboard,
  },
  {
    path: '/influencer/social-calculate',
    component: SocialCalculatePage,
  },
  {
    path: '/influencer/social-calculate/:type/token/:token',
    component: SocialCalculatePage,
  },
  {
    path: '/influencer/social-calculate/:type',
    component: SocialCalculatePage,
  },

  {
    path: '/influencer/create-channel-detail',
    exact: true,
    component: ChannelDetailPage,
  },

  {
    path: '/influencer/account/:account_state',
    component: InfluencerAccount,
  },
  {
    path: '/influencer/account/:account_state',
    component: InfluencerAccount,
  },
  {
    path: '/influencer/publish-job',
    component: <MockInfluencerPublishJob />,
  },
  {
    path: '/influencer/renew',
    component: RenewTokenPage,
  },
  {
    path: '/influencer/renew/:type/token/:token',
    component: RenewTokenPage,
  },
  {
    path: '/influencer/renew/:type',
    component: RenewTokenPage,
  },
  {
    path: '/influencer/social-renew',
    component: SocialRenewPage,
  },
  {
    path: '/influencer/social-renew/:type/token/:token',
    component: SocialRenewPage,
  },
  {
    path: '/influencer/social-renew/:type',
    component: SocialRenewPage,
  },
];

export default publisherRoutes;
