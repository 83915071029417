/* eslint-disable no-shadow */
import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Column } from 'react-table';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import CoinImage from '../Common/CoinImage';
import { transformStringAmountInput } from '../../utils/input';
import { transformAddressInShortFrom } from '../../utils/address';
import './DepositHistoryTable.style.scss';
import CustomTable from '../Common/CustomTable';
import { getDepositTransactionsRequest as onGetDepositTransactionRequest } from '../../store/finance/deposit/actions';
import CurrencyOptions from '../Table/CurrencyOptions';
import SearchBar from '../Table/SearchBar';
import TransactionDetailModal from '../Table/TransactionDetailModal';
import { splitWordsWithUnderscore } from '../../utils/currency';
import DateTimeTypes from '../../constants/dateTimeTypes';
import DateTimeComponent from '../Common/DateTimeComponent';

const DepositHistoryTable = ({ t }: any) => {
  const dispatch = useDispatch();

  // Local State
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [currency, setCurrency] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');

  // Redux
  const { txn, depositTransactionsLoading, influencerData } = useSelector(
    (state: any) => ({
      txn: state.Deposit.transactions,
      depositTransactionsLoading: state.Global.depositTransactionsLoading,
      influencerData: state.Users.userInfo,
    }),
  );

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleGetDetail = (rowIndex: any) => {
    setSelectedRow(rowIndex);
    setModalOpen(!modalOpen);
  };

  // const handlePageSizeChange = (size: string) => {
  //   const newPageSize = parseInt(size, 10);

  //   const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
  //   const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

  //   const payload = {
  //     ...getDefaultPayload(newPage),
  //     limit: newPageSize,
  //   };

  //   dispatch(onGetDepositTransactionRequest(payload));

  //   setPageSize(newPageSize);
  // };

  const handleCurrencyChange = (currency: string) => {
    const payload = {
      ...getDefaultPayload(1),
      currencySymbol: currency,
    };

    dispatch(onGetDepositTransactionRequest(payload));
    setCurrency(currency);
  };

  const handleSearch = (val: string) => {
    const payload = {
      ...getDefaultPayload(1),
      search: val,
    };

    dispatch(onGetDepositTransactionRequest(payload));
    setSearch(val);
  };

  const handleClear = () => {
    const payload = {
      ...getDefaultPayload(1),
      search: '',
    };

    dispatch(onGetDepositTransactionRequest(payload));
    setSearch('');
  };

  const startTime = moment('0:00 AM', 'h:mm A');
  const currentTimeZone = moment(startTime)
    .add(influencerData.timezone_offset, 'minutes')
    .format('HH:mm A');
  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('table:Date'),
        accessor: ({ created_at }) => {
          return (
            <div style={{ marginTop: '1rem', width: '200px' }}>
              <DateTimeComponent
                dateTime={created_at}
                dateTimeType={DateTimeTypes.GET_DATE_TIME_FULL_FORMAT}
              />
            </div>
          );
        },
        id: 'created_at',
        width: 150,
      },
      {
        Header: t('table:Currency'),
        accessor: ({ currency }) => {
          return (
            <div style={{ marginTop: '0.5rem' }}>
              <CoinImage symbol={currency.symbol} />
            </div>
          );
        },
        id: 'currency',
        disableSort: true,
        width: 150,
      },
      {
        Header: t('table:Crypto Amount'),
        // eslint-disable-next-line camelcase
        accessor: ({ CRYPTO_amount, currency }) => {
          return (
            <div style={{ marginTop: '1rem' }}>
              {transformStringAmountInput(CRYPTO_amount)}
              {splitWordsWithUnderscore(currency.symbol)}
            </div>
          );
        },
        id: 'CRYPTO_amount',
      },
      {
        Header: t('table:Crypto Fiat'),
        // eslint-disable-next-line camelcase
        accessor: ({ FIAT_amount }) => {
          return (
            <>
              <div style={{ marginTop: '1rem' }}>
                {transformStringAmountInput(FIAT_amount)} USDT
              </div>
            </>
          );
        },
        id: 'FIAT_amount',
        width: 150,
      },
      {
        Header: t('table:Dest Address'),
        accessor: ({ fromAddress }) => {
          return (
            <div style={{ marginTop: '1rem' }}>
              {fromAddress
                ? transformAddressInShortFrom(fromAddress, 12)
                : 'Internal Transfer'}
            </div>
          );
        },
        id: 'dest_address',
        disableSort: true,
        width: 150,
      },
      {
        Header: t('table:Detail'),
        // eslint-disable-next-line no-unused-vars
        accessor: ({ status }, rowIndex) => {
          return (
            <div style={{ marginTop: '1rem' }}>
              <i
                className="fas fa-file-alt fa-2x c-pointer"
                onClick={() => {
                  handleGetDetail(rowIndex);
                }}
              />
            </div>
          );
        },
        id: 'action',
        disableSort: true,
        width: 150,
      },
    ],
    [t, txn],
  );

  const data = React.useMemo(() => {
    return !isEmpty(txn) ? txn.items : [];
  }, [txn]);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...getDefaultPayload(newPage),
    };

    dispatch(onGetDepositTransactionRequest(payload));
  };

  const getDefaultPayload = (page: number) => {
    setPage(page);

    return {
      limit: pageSize,
      page,
      currencySymbol: currency,
      search,
    };
  };

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(newPage),
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(onGetDepositTransactionRequest(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
    },
    [search, currency, page, pageSize],
  );

  const hiddenOnXS: any = ['created_at', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnSM: any = ['CRYPTO_amount', 'dest_address'];
  const hiddenOnMD: any = ['dest_address'];
  const hiddenOnLG: any = [];

  return (
    <>
      <CustomTable
        headerCSS="fw-normal bg-header-table"
        columns={columns}
        data={data}
        page={page}
        pageSize={pageSize}
        total={txn.totalCount}
        handlePageChange={handlePageChange}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
        onSort={handleSort}
        isLoading={depositTransactionsLoading}
      >
        <TransactionDetailModal
          data={!isEmpty(txn) ? txn.items[selectedRow] : null}
          isOpen={modalOpen}
          handleToggle={handleModalToggle}
          handleClose={handleModalClose}
          title={t('Deposit Detail')}
        />

        <div className="deposit-table-history-container">
          <div style={{ width: '250px', marginRight: '10px' }}>
            <CurrencyOptions handleCurrencyChange={handleCurrencyChange} />
          </div>
          <div>
            <SearchBar
              placeholder={t('table:Search Placeholder')}
              handleSearch={handleSearch}
              handleClear={handleClear}
            />
          </div>
        </div>
      </CustomTable>
    </>
  );
};

export default withTranslation()(DepositHistoryTable);
