import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import VerifyStatus from 'src/constants/verifyStatusEnum';
import {
  getAllAdminApproveLog,
  requestDeleteSocialMediaFilterTable,
  setLoading,
  unsetLoading,
} from 'src/store/actions';
import Swal from 'sweetalert2';
import choice from 'src/assets/images/choice.png';
import config from 'src/config';
import MetaTags from 'react-meta-tags';
import { updateRequestDeleteSocialMedia } from 'src/graphql/advertiser/socialMediaInfo';
import { resetApproveLogValueByName } from 'src/graphql/common/activity-logs';
import { useEffect, useMemo, useState } from 'react';
import { verify } from 'crypto';
import {
  approveRequestOwnCurrencyGQL,
  filterRequestOwnCurrencyGQL,
  rejectRequestOwnCurrencyGQL,
} from 'src/graphql/advertiser/own-currency';
import { getFilterOwnCurrency } from 'src/store/own-currency/actions';
import i18n from 'src/i18n';
import notify from 'src/utils/notify';
import CustomTable from '../Common/CustomTable';
import PageSizeOptions from '../Table/PageSizeOptions';
import SearchBar from '../Table/SearchBar';
import { Approve, Reject } from '../Common/approval/approvalStatus';

interface verifyStatusType {
  verify_status?: string;
}
interface currencyParam {
  page: number;
  limit: number;
  status: string;
}

const CurrencyTransaction = ({ status }: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { owncurrency } = useSelector((state: any) => ({
    owncurrency: state.OwnCurrency.ownCurrencies,
  }));
  const [page, setPage] = useState(1);
  // const [verifyStatus, setVerifyStatus] = useState(Status || null);
  const [param, setParam] = useState<any>({
    page: 1,
    limit: 10,
    verify_status: status,
  });

  const handleChangePageSize = (newPagesize: string) => {
    const payload = { ...param, limit: +newPagesize };
    setParam(payload);
    dispatch(getFilterOwnCurrency(payload));
  };

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : 0;
    const payload = {
      ...param,
      page: newPage,
    };
    setParam(payload);
    dispatch(getFilterOwnCurrency(payload));
  };

  const handleSearch = (keyword: string) => {
    const payload = { ...param, name: keyword };
    setParam(payload);
    dispatch(getFilterOwnCurrency(payload));
  };
  const handleClear = () => {
    const payload = { ...param, name: undefined, page: 1, limit: 10 };
    setParam(payload);
    dispatch(getFilterOwnCurrency(payload));
  };

  useEffect(() => {
    fetchApproveLog();
    dispatch(getFilterOwnCurrency(param));
  }, []);

  const fetchApproveLog = async () => {
    await resetApproveLogValueByName('new_own_currency');
    dispatch(getAllAdminApproveLog());
  };

  let columns = useMemo(() => {
    return [
      {
        Header: t('No.'),
        accessor: (row: any, index: number) =>
          index + 1 + (param.page - 1) * param.limit,
        id: 'index',
        width: 50,
        disableSort: true,
      },
      // {
      //   Header: t('Create at'),
      //   accessor: ({ contract_address }: any) => <div>{contract_address}</div>,
      //   id: 'date',
      //   width: 150,
      //   disableSort: true,
      // },
      {
        Header: t('Token Logo'),
        accessor: ({ logo, symbol }: any) => {
          if (!logo) {
            return <></>;
          } else {
            return (
              <img
                src={`${config.backendUrl}api/currencyImage/${logo}`}
                alt={symbol}
                style={{ width: 40, height: 40, borderRadius: '50%' }}
              />
            );
          }
        },
        id: 'logo',
        width: 60,
        disableSort: true,
      },
      {
        Header: t('Token Name'),
        accessor: ({ name }: any) => <div>{name}</div>,
        id: 'name',
        width: 70,
        disableSort: true,
      },
      {
        Header: t('Token Symbol'),
        accessor: ({ symbol }: any) => <div>{symbol}</div>,
        id: 'symbol',
        width: 70,
        disableSort: true,
      },
      // {
      //   Header: t('Logo'),
      //   accessor: ({ contract_address }: any) => (
      //     <CoinImage symbol={contract_address} />
      //   ),
      //   id: 'Logo',
      //   width: 50,
      //   disableSort: true,
      // },
      // {
      //   Header: t('Owner'),
      //   accessor: ({ Owner }: any) => <div>{Owner}</div>,
      //   id: 'Owner',
      //   width: 90,
      // },
      {
        Header: t('Contract Address'),
        accessor: ({ contract_address }: any) => <div>{contract_address}</div>,
        id: 'ContractAddress',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('Network'),
        accessor: ({ network }: any) => <div>{network}</div>,
        id: 'Network',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('Reference Link'),
        accessor: ({ ref_link }: any) => <a href={ref_link}>{ref_link}</a>,
        id: 'ref_link',
        width: 150,
        disableSort: true,
      },
      {
        Header: t('Status'),
        accessor: ({ status }: any) => <div className="">{status}</div>,
        id: 'Status',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('Action'),
        accessor: ({ key, verify_status, status: _status }: any) => {
          const click = async () => {
            await Swal.fire({
              title: t('Reject'),
              text: t(`Tell influcencer why you reject ( If any )`),
              input: 'textarea',
              inputPlaceholder: 'input here',
              inputAttributes: {
                'aria-label': 'Type your message here',
              },
              width: 600,
              imageHeight: 200,
              showCancelButton: true,
              cancelButtonColor: 'red',
              confirmButtonColor: '#009a78',
              customClass: {},
              confirmButtonText: t('swal:confirm'),
              cancelButtonText: t('swal:Cancel'),
            }).then(async (result) => {
              if (result.isConfirmed) {
                const reason = result.value;
                dispatch(setLoading());
                const res = await rejectRequestOwnCurrencyGQL(key, reason);
                if (res) {
                  notify(i18n.t('Reject coin success'), 'success');
                  dispatch(getFilterOwnCurrency(param));
                }
                Swal.fire('Success');
                dispatch(unsetLoading());
              }
            });
          };
          return (
            verify_status === 'PENDING' && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  gap: '5px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={async () => {
                    dispatch(setLoading());
                    const res = await approveRequestOwnCurrencyGQL(key, _status);
                    if (res) {
                      notify(i18n.t('approve this coin success'), 'success');
                    }
                    dispatch(unsetLoading());
                    dispatch(getFilterOwnCurrency(param));
                  }}
                >
                  <Approve message="Approve" />
                </div>
                <div
                  onClick={async () => {
                    await click();
                  }}
                >
                  <Reject message="Reject" />
                </div>
              </div>
            )
          );
        },
        id: 'Action',
        width: 50,
        disableSort: true,
      },
    ];
  }, [t, param.page, param.limit, param]);

  const insert = (arr: any, index: any, newItem: any) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index),
  ];

  if (!status) {
    columns = insert(columns, 7, {
      Header: t('table:Rejected reason'),
      accessor: ({ reject_message }: any) => {
        return reject_message;
      },
      id: 'reject_message',
      width: 150,
      disableSort: true,
    });
  }

  return (
    <div>
      <MetaTags>
        <title>{t('Currency Approval')}</title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card className="h-100">
              <CardBody className="h-100">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <PageSizeOptions
                    defaultValue={10}
                    handlePageSizeChange={handleChangePageSize}
                  />
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SearchBar
                      placeholder={t('table:Search name')}
                      handleSearch={handleSearch}
                      handleClear={handleClear}
                      disablePadding
                    />
                  </div>
                </div>
                {/*dsdw */}
                <CardBody>
                  <CustomTable
                    columns={columns || []}
                    data={owncurrency?.items || []}
                    page={param.page}
                    pageSize={param.limit}
                    total={owncurrency?.totalCount}
                    handlePageChange={handlePageChange}
                    headerCSS="fw-normal bg-header-table"
                  />
                </CardBody>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CurrencyTransaction;
