import { useEffect, useState } from 'react';
import 'react-drawer/lib/react-drawer.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Role from 'src/constants/enum/role';
import useWindowSize from 'src/utils/windowSize';
import { checkIsBanned, filterSocialJob } from 'src/store/actions';
import { getAllSuggestJob } from 'src/store/suggestJob/actions';
import Warning from 'src/assets/images/other/warning.png';
import logo from '../../assets/images/AppLogo.png';
import logoSM from '../../assets/images/logo-sm.svg';
import logoWhite from '../../assets/images/logo-white-navbar.svg';
import { layoutTheme } from '../../constants/layout';
import { TemporaryDrawer } from '../CommonForBoth/Menus/drawer';
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';
import './Header.style.scss';
import SubNavBar from './subNavBar/SubNavBar';
import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown';

const Header = ({ isMobile }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { layoutMode, user, ban } = useSelector((state: any) => ({
    layoutMode: state.Layout.layoutMode,
    user: state.login.user,
    ban: state.Ban.checkIsBan,
  }));
  const { width } = useWindowSize();
  const { t } = useTranslation();
  // const [suggestJobCount, setSuggestJobCount] = useState(0);
  const [roleSubNavBar, setRoleSubNavBar] = useState<string>('');

  useEffect(() => {}, []);

  const advertiserNavItems = [
    {
      link: '/',
      text: t('menu:Home'),
    },
    {
      text: t('Menus.Discover'),
      role: Role.MARKETER,
      link: '/Discover',
      // link: '/marketer/PublicCampaign',
    },
    // {
    //   text: t('menu:Advertiser'),
    //   role: Role.ADVERTISER,
    // },
    // {
    //   link: '/Portfolio',
    //   text: t('menu:Portfolio'),
    // },
    {
      link: 'https://blog.afbrother.com/',
      text: t('menu:Blog'),
    },
  ];

  const publisherNavItem = [
    {
      link: '/',
      text: t('menu:Home'),
    },
    {
      text: t('menu:Influencer'),
      role: Role.INFLUENCER,
      link: '/',
      match: 'influencer',
    },
    {
      text: t('Menus.Discover'),
      link: user ? '/Discover' : '/login',
    },
    {
      link: '/portfolio',
      text: t('menu:Showcase'),
    },
    {
      link: 'https://blog.afbrother.com/',
      text: t('menu:Blog'),
    },
  ];

  let navbarItems: any = [];
  if (user) {
    if (!(user.role === 'ADMIN' || user.role === 'OWNER')) {
      navbarItems =
        user.role === Role.ADVERTISER || user.role === Role.MARKETER
          ? advertiserNavItems
          : publisherNavItem;
    }
  } else {
    navbarItems = publisherNavItem;
  }

  const isLogoDarkTheme = layoutMode === layoutTheme.DARKMODE;
  const data = useSelector((state: any) => ({
    filter: state.SocialMediaInfo.filter,
    suggestjobs: state?.SuggestJobs?.suggestJobs,
  }));
  // if (user.role === 'PUBLISHER') {
  //   const suggestNotify = async () => {
  //     const x = await suggestAndOtherJobCountGql();
  //     setSuggestJobCount(x.suggestJobs);
  //   };
  //   suggestNotify();
  // }
  useEffect(() => {
    if (ban) {
      if (ban.isBan === true) {
        history.push('/banned');
      }
    }
  }, [ban]);

  const checkAlert = () => {
    if (!data?.filter || !data?.suggestjobs?.suggestJobs) return 'disable-img';
    if (data?.filter?.totalCount === 0 || data.suggestjobs.suggestJobs > 0) {
      return 'enable-img';
    }
    return 'disable-img';
  };
  const [param, setParam] = useState<any>({
    limit: 10,
    page: 1,
    status: 'ALL',
  });
  useEffect(() => {
    if (user) {
      if (user.role === 'INFLUENCER' || user.role === 'PUBLISHER') {
        dispatch(getAllSuggestJob());
        // dispatch(filterSocialJob(param));
      }
    }
  }, []);
  return (
    <header id="page-topbar">
      <div
        className={`header-container ${
          (isMobile || width <= 980) && 'header-container-mobile'
        }`}
      >
        <Link
          to="/"
          className="logo "
          style={{
            margin: '0 0 5px 1.5vw',
          }}
        >
          <span className="logo-sm">
            <img src={isLogoDarkTheme ? logoSM : logoSM} alt="" width="35" />
          </span>
          <span className="logo-lg">
            <img
              src={isLogoDarkTheme ? logoWhite : logo}
              alt=""
              width="145"
              className="ms-2"
            />
          </span>
        </Link>

        {isMobile || width <= 980 ? (
          <div
            className="justify-content-center align-items-center"
            style={{ zIndex: '999' }}
          >
            <TemporaryDrawer />
          </div>
        ) : (
          <div
            style={{
              height: '100%',
              cursor: 'pointer',
              display: 'flex',
              gap: '0px',
            }}
          >
            {navbarItems.map((navbarItem: any) => {
              const activeLink = () => {
                const onLinkPage = window.location.pathname === navbarItem.link;
                if (!roleSubNavBar) return onLinkPage;
                return roleSubNavBar === navbarItem.role;
              };
              if (navbarItem.link === 'https://blog.afbrother.com/') {
                return (
                  <li className="header-container nav-items" key={navbarItem?.text}>
                    <a
                      href={navbarItem.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="justify-content-start  text-link"
                    >
                      <span className="header-container nav-text">
                        {navbarItem.text}
                      </span>
                    </a>
                  </li>
                );
              }
              return (
                <li
                  className={`header-container nav-items ${
                    activeLink() && 'text-active'
                  }`}
                  onMouseEnter={() => {
                    if (user) {
                      if (
                        user.role === Role.ADVERTISER ||
                        user.role === Role.MARKETER
                      ) {
                        setRoleSubNavBar('');
                      } else if (navbarItem.role) setRoleSubNavBar(navbarItem.role);
                    } else if (navbarItem.role) {
                      setRoleSubNavBar(navbarItem.role);
                    }
                  }}
                  onMouseLeave={() => {
                    if (navbarItem.role) setRoleSubNavBar('');
                  }}
                  key={navbarItem?.text}
                >
                  <Link
                    to={navbarItem.link || '/'}
                    className={`justify-content-start align-items-center text-link ${
                      activeLink() && 'text-active'
                    }`}
                    onClick={(e) => {
                      if (!navbarItem.link) e.preventDefault();
                    }}
                  >
                    <span
                      className={`header-container ${
                        roleSubNavBar === navbarItem?.role
                          ? 'nav-text-hover'
                          : 'nav-text'
                      }`}
                    >
                      {navbarItem.text}
                      <span>
                        <span
                          className={`${
                            navbarItem.match === 'influencer' ? '' : 'disable-img'
                          }`}
                          style={{ marginLeft: '5px' }}
                        >
                          <span className={`text-header-alert ${checkAlert()}`}>
                            {`${
                              // eslint-disable-next-line no-nested-ternary
                              data.suggestjobs?.suggestJobs
                                ? data.suggestjobs?.suggestJobs +
                                  data.suggestjobs?.otherJobs
                                : ''
                            }`}
                          </span>
                        </span>
                      </span>
                    </span>
                  </Link>
                </li>
              );
            })}
          </div>
        )}

        <div
          className={`${
            (isMobile || width <= 980) &&
            'header-container-mobile notification-mobile'
          } `}
          style={{ display: 'flex', alignItems: 'center', gap: '0px' }}
        >
          {ban && ban.isBan === true ? <></> : <NotificationDropdown />}
          <LanguageDropdown
            bodyCSS=" resposive-header-icon"
            textCSS="text-black"
            fill="white"
          />
          <ProfileMenu />
        </div>
      </div>
      {roleSubNavBar && (
        <SubNavBar
          setRoleSubNavBar={setRoleSubNavBar}
          roleSubNavBar={roleSubNavBar}
        />
      )}
    </header>
  );
};

export default Header;
