import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader, Col, Row, FormGroup, Label } from 'reactstrap';
import * as Yup from 'yup';
import { setLoading, unsetLoading } from 'src/store/global/actions';
import { useDispatch } from 'react-redux';
import notify from 'src/utils/notify';
import { getCurrenciesByMarketerGQL } from 'src/graphql/common';
import { Formik, Field, Form as FormikForm } from 'formik';
import cyptologo from 'src/assets/images/crypto/btc.svg';
import CustomButton from '../Common/CustomButton';
import { splitUnderscoreWithTuple } from '../../utils/currency';
import './EditOwnCoin.scss';
import {
  editOwnCurrencyGQL,
  uploadCurrencyImage,
} from '../../graphql/advertiser/own-currency';
// eslint-disable-next-line import/order
import config from 'src/config';
// eslint-disable-next-line import/order
import resizeImage from 'src/utils/resizeImage';

const EditOwnCoin = ({ t, currencies, coinName }: any) => {
  const dispatch = useDispatch();
  const [nameStatr, setNameState] = useState('');
  const [currency, setCurrency] = useState<any[]>([]);
  // useEffect(() => {
  //   dispatch(getCurrenciesByMarketerCurrencies());
  // }, []);

  const coin = currency;
  const [isAddressValid, setValidAddress] = useState(true);
  const [selectCoin, setSelectCoin] = useState(coinName);
  const [srcLogo, setSrcLogo] = useState<any>(cyptologo);
  const [newFile, setNewFile] = useState<any>(null);
  const renderCurrencies = () => {
    const arr: any = [];

    for (let i = 0; i < currencies?.length; i++) {
      const currency = currencies[i];
      arr.push(
        <option value={currency.symbol}>
          {splitUnderscoreWithTuple(currency.symbol)}
        </option>,
      );
    }

    return arr;
  };
  const fetchData = async () => {
    const _currencies = await getCurrenciesByMarketerGQL();
    setCurrency(_currencies);
  };
  const initislValues = {
    name: null,
    symbol: null,
    contract_address: null,
    network: null,
    decimals: null,
    ref_link: null,
    currencyLogo: null,
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [formValue, setIntitailValues] = useState<any>({
    name: null,
    symbol: '',
    contract_address: '',
    network: '',
    decimals: 0,
    ref_link: '',
    currencyLogo: '',
  });
  const withdrawSchema: any = Yup.object().shape({
    // tokenName: Yup.string().required(),
    // network: Yup.string().required(),
    // contactAddress: Yup.string().required(),
    // min: Yup.number().required(),
    // Price: Yup.number().required(),
  });
  const renderAddressError = (errors: any) => {};

  const onSubmit = async (values: any, { resetForm, setSubmitting }: any) => {
    dispatch(setLoading());
    // if (nameStatr !== formValue?.name) {
    const submitForm = {
      name: formValue?.name,
      symbol: formValue?.symbol,
      logo: formValue?.currencyLogo,
    };
    if (!submitForm.name) {
      notify(t('You have to enter name'), 'error');
      dispatch(unsetLoading());
      return;
    }

    if (newFile) {
      try {
        const file: any = newFile;
        const resizeFile = await resizeImage({ file, maxSize: 600 });
        let thumbnail_cover: any;
        if (resizeFile) {
          thumbnail_cover = resizeFile;
        } else {
          thumbnail_cover = newFile;
        }

        const imageKey = await uploadCurrencyImage(thumbnail_cover);
        if (!imageKey) {
          notify(t('Cannot upload token logo'), 'error');
          dispatch(unsetLoading());
          return;
        }
        submitForm['logo'] = imageKey;
      } catch (error) {
        notify(t('Cannot upload token logo'), 'error');
        dispatch(unsetLoading());
      }
    }

    await editOwnCurrencyGQL(submitForm).then((result) => {
      if (result) {
        notify(t('Create request coin success'), 'success');
      }
    });
    dispatch(unsetLoading());
    setSelectCoin(coinName);
    setIntitailValues(initislValues as any);
    // } else {
    //   notify(t('Duplicate name'), 'error');
    //   dispatch(unsetLoading());
    // }
  };

  useEffect(() => {
    setSelectCoin(coinName);
    if (currency?.length) {
      const arraySelected = currency.filter((index) => index?.name === coinName);
      if (arraySelected?.length) {
        setIntitailValues({
          ...formValue,
          name: arraySelected[0].name,
          symbol: arraySelected[0].main_symbol,
          contract_address: arraySelected[0].contract_address,
          network: arraySelected[0].network,
          decimals: arraySelected[0].decimals,
          ref_link: arraySelected[0].ref_link,
          currencyLogo: arraySelected[0].logo,
        });
        if (arraySelected[0].logo) {
          setSrcLogo(
            `${config.backendUrl}api/currencyImage/${arraySelected[0].logo}`,
          );
        } else {
          setSrcLogo(cyptologo);
        }
        setNameState(arraySelected[0].name);
      }
    }
  }, [currency]);

  const imageProfile = () => {
    if (formValue.currencyLogo) {
      return (
        <img
          id="currency_image"
          src={`${config.backendUrl}api/currencyImage/${formValue.currencyLogo}`}
          alt="Header Avatar"
          className="image-profile"
          style={{ width: '80px', height: '80px' }}
        />
      );
    }
    return (
      <img
        id="currency_image"
        src={srcLogo}
        alt="Header Avatar"
        className="image-profile"
        style={{ width: '80px', height: '80px' }}
      />
    );
  };

  const handleFileSelected = async (e: any): Promise<void> => {
    const files: any = Array.from(e.target.files);

    // const fileConfig: any = {
    //   file: files[0],
    //   maxSize: 500,
    // };
    //const file = await resizeImage(fileConfig);
    //dispatch(uploadProfile([file]));
    const file = files[0];

    // const newFile = await fixImageSize({ file, width: 600, height: 400 });
    const reader = new FileReader();
    const imageTag: any = document.getElementById('currency_image');
    // imageTag.title = file?.name;

    reader.onload = (event) => {
      imageTag.src = event?.target?.result;
      setSrcLogo(event?.target?.result);
    };
    reader.readAsDataURL(file);
    // setIntitailValues({ ...formValue, currencyLogo: file });
    setNewFile(file);
    e.target.files = null;
  };

  return (
    <>
      <Card>
        <CardHeader>
          <h6>{t('Your own token')}</h6>
          <p className="card-title-desc">{t('Edit your own coin')}</p>
          <div className="card-coin-container">
            {coin &&
              coin.map((item: any) => (
                <>
                  <div
                    onClick={() => {
                      setSelectCoin(item.name);
                      setNameState(item.name);
                      setIntitailValues({
                        ...formValue,
                        name: item.name,
                        symbol: item.main_symbol,
                        contract_address: item.contract_address,
                        network: item.network,
                        decimals: item.decimals,
                        ref_link: item.ref_link,
                        currencyLogo: item.logo,
                      });
                      if (item.logo) {
                        setSrcLogo(
                          `${config.backendUrl}api/currencyImage/${item.logo}`,
                        );
                      } else {
                        setSrcLogo(cyptologo);
                      }
                      setNewFile(null);
                    }}
                    className={`card-coin  ${
                      selectCoin === item.name ? 'active-coin' : ''
                    }`}
                    key={item.key}
                  >
                    {item.name}
                  </div>
                </>
              ))}
          </div>
        </CardHeader>
        <CardBody>
          <Formik
            validationSchema={withdrawSchema}
            initialValues={formValue}
            onSubmit={onSubmit}
            render={({ errors, values, touched, setFieldValue, handleChange }) => (
              <FormikForm>
                <Row>
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <Label htmlFor="symbol">{t('Token Logo')}</Label>
                      <div className="flex-shrink-0">
                        <div
                          className="avatar-xl me-3"
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginLeft: '12px',
                          }}
                        >
                          {imageProfile()}
                          <div style={{ paddingRight: '15px' }}>
                            <label htmlFor="upload-input" className="upload-icon">
                              <i className="fas fa-pen" />
                              <input
                                id="upload-input"
                                onChange={handleFileSelected}
                                type="file"
                                className="upload-input"
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Label htmlFor="symbol">{t('Token Name')}</Label>
                      <Field
                        placeholder="e.g Bitcoin"
                        name="name"
                        type="text"
                        className={`form-control ${
                          !isAddressValid ? 'is-invalid' : ''
                        }`}
                        id="name"
                        onBlur={() => {}}
                        autocomplete="off"
                        value={formValue.name || ''}
                        onChange={(e: any) => {
                          setIntitailValues({
                            ...formValue,
                            name: e.currentTarget.value,
                          });
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Label htmlFor="symbol">{t('Token Symbol')}</Label>
                      <Field
                        placeholder="e.g BTC"
                        name="symbol"
                        type="text"
                        className={`form-control ${
                          !isAddressValid ? 'is-invalid' : ''
                        }`}
                        id="symbol"
                        onBlur={() => {}}
                        autocomplete="off"
                        value={formValue.symbol || ''}
                        disabled
                      />
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Label htmlFor="symbol">{t('Withdraw Method')}</Label>
                      <Field
                        placeholder="eg BEP20"
                        type="text"
                        name="network"
                        className={`form-control ${
                          !isAddressValid ? 'is-invalid' : ''
                        } arrow-dropdown`}
                        // onBlur={() => {
                        //   handleCheckAddress(values.address, values.symbol);
                        // }}

                        value={formValue.network || ''}
                        disabled
                      />
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Label htmlFor="symbol">{t('Contract Address')}</Label>
                      <Field
                        placeholder="e.g 0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                        name="contract_address"
                        type="text"
                        className={`form-control ${
                          !isAddressValid ? 'is-invalid' : ''
                        }`}
                        id="contract_address"
                        onBlur={() => {}}
                        autocomplete="off"
                        value={formValue.contract_address || ''}
                        disabled
                      />
                      <FormGroup style={{ margin: '15px 0 0 0' }}>
                        <Label htmlFor="symbol">{t('Decimal')}</Label>
                        <Field
                          placeholder="e.g 18"
                          name="decimals"
                          type="text"
                          className={`form-control ${
                            !isAddressValid ? 'is-invalid' : ''
                          }`}
                          id="decimals"
                          onBlur={() => {}}
                          autocomplete="off"
                          value={formValue.decimals || null}
                          disabled
                        />
                      </FormGroup>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Label htmlFor="symbol">{t('Referal link')}</Label>
                      <Field
                        placeholder="(e.g https://bscscan.com/token/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c)"
                        name="ref_link"
                        type="text"
                        className={`form-control ${
                          !isAddressValid ? 'is-invalid' : ''
                        }`}
                        id="Referal link"
                        onBlur={() => {}}
                        autocomplete="off"
                        value={formValue.ref_link || ''}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <CustomButton size="xxs" status="submit" label={t('Button.Edit')} />
              </FormikForm>
            )}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default withTranslation()(EditOwnCoin);
