import { Form, Formik } from 'formik';

// eslint-disable-next-line import/order
import control from './MarketerAccount.control';
import './MarketerAccount.style.scss';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getUserInfo } from 'src/store/actions';
import { Card, CardHeader, CardTitle } from 'reactstrap';
import CustomButton from 'src/components/Common/CustomButton';

const MarketerAccountPage = () => {
  const {
    setOnEdit,
    formValues,
    validationSchema,
    showInfo,
    onSubmit,
    displays,
    t,
  } = control();

  const dispatch = useDispatch();

  //? Constructor
  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  return (
    <div className="marketer-account-container">
      <Card>
        <CardHeader>
          <CardTitle className="mb-0">
            <h5 className="m-0" style={{ fontSize: '16px' }}>
              {t('Profile')}
            </h5>
          </CardTitle>
        </CardHeader>
        <div className="card-container-marketer">
          <Formik
            initialValues={formValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ errors, touched, handleChange }: any) => (
              <Form style={{ margin: 'auto', width: 'auto', paddingTop: '20px' }}>
                {/* <p className="user-full-name">{marketerData.name}</p> */}
                {displays(errors, touched, handleChange)}

                {showInfo ? (
                  <div
                    className="btn-container-marketer"
                    onClick={(e: any) => {
                      e.preventDefault();
                      setOnEdit(true);
                      dispatch(getUserInfo());
                    }}
                  >
                    <CustomButton
                      size="s"
                      status="submit"
                      label={t('marketer:account.edit')}
                    />
                  </div>
                ) : (
                  <div className="btn-container-marketer">
                    <CustomButton
                      size="s"
                      status="submit"
                      label={t('marketer:account.submit')}
                    />
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        setOnEdit(false);
                      }}
                    >
                      <CustomButton
                        size="s"
                        status="cancel"
                        label={t('marketer:account.cancel')}
                      />
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </Card>
    </div>
  );
};

export default MarketerAccountPage;
