import { Row, Col } from 'reactstrap';
import './GleamJob.style.scss';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { floorToFixed } from 'src/common/data/mathToFixed';
import { getNetworkFromSymbol } from 'src/utils/currency';
import useWindowSize from 'src/utils/windowSize';
import { transformAddressInShortFrom } from 'src/utils/address';
import menu from 'src/constants/menu';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultWebsite } from 'src/store/actions';
import Role from 'src/constants/enum/role';
import GleamJobTask from './GleamJobTask';
import { InfluencerSocialIcon } from './status/InfluencerJobStatus';
import GleamJobHeader from './GleamJobHeader';
import Breadcrumb from './Breadcrumb';

const GleamJobComponent = ({ job, is_working }: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const workOrderRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowSize();
  //const [switchCheck, setSwitchCheck] = useState(true);
  const [isWorkOrderOpen, setWorkOrderOpen] = useState(true);
  const [tax, setTax] = useState(0);

  const { user, defaultTax } = useSelector((state: any) => ({
    user: state.login.user,
    defaultTax: state.DefaultValue.defaultWebsite.valueAddedTax,
  }));

  useEffect(() => {
    dispatch(getDefaultWebsite());
  }, []);

  useEffect(() => {
    if (defaultTax !== null) {
      const _tax = defaultTax / 100;
      setTax(_tax);
    }
  }, [defaultTax]);

  useEffect(() => {
    if (workOrderRef.current) {
      workOrderRef.current.innerHTML = job?.work_order;
    }
    // if (job) {
    //   if (job.status === 'PAUSE') {
    //     setSwitchCheck(false);
    //   } else {
    //     setSwitchCheck(true);
    //   }
    // }
  }, [workOrderRef.current, job]);

  const fixedPrice = (price: number) => {
    if (user.role === Role.PUBLISHER) {
      return price - price * tax;
    } else {
      return price;
    }
  };

  return (
    <div>
      <>
        <div
          className="gleam-job-component gleam-margin"
          style={{ marginTop: '0px' }}
        >
          <div className="gleam-job-panel">
            <div>
              <GleamJobHeader job={job} fixPrice={fixedPrice} />
              {job?.work_order && (
                <div className="gleam-work-order">
                  <GleamToggle isOpen={isWorkOrderOpen}>
                    <div className="gleam-work-order-detail">
                      <div
                        className="text-editor-display"
                        style={{ wordBreak: 'break-all' }}
                      >
                        <div ref={workOrderRef} />
                      </div>
                    </div>
                  </GleamToggle>
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  padding: '0px 16px 16px 16px',
                }}
              >
                <span
                  className="badge bg-advertiser text-center p-2"
                  style={{
                    cursor: 'no-drop',
                    fontSize: `${width <= 455 ? '9px' : ''}`,
                  }}
                >
                  {width <= 338 ? '' : `${t('Network')} :`}{' '}
                  {width <= 338
                    ? ''
                    : `${getNetworkFromSymbol(job?.currency?.symbol)}`}{' '}
                  {`${job?.currency?.network}`}
                </span>
                {job?.currency?.contract_address && (
                  <span
                    className="badge bg-advertiser text-center p-2"
                    style={{
                      cursor: 'no-drop',
                      fontSize: `${width <= 455 ? '9px' : ''}`,
                      marginTop: `${width <= 438 ? '7px' : '0px'}`,
                    }}
                  >
                    {width <= 438 ? '' : `${t('Token address')} :`}{' '}
                    {width <= 300
                      ? `${transformAddressInShortFrom(
                          job?.currency?.contract_address,
                          12,
                        )}`
                      : `${job?.currency?.contract_address}`}
                  </span>
                )}
              </div>
              {job?.job_tasks && !is_working && (
                <div style={{ paddingBottom: '13px' }}>
                  {job?.job_tasks?.map((job_task: any) => {
                    const greenBox = job?.job_requirement?.fixed_price
                      ? `${floorToFixed(
                          (fixedPrice(job?.job_requirement?.fixed_price) *
                            job_task?.percent) /
                            100,
                          2,
                        )}${' '}
                  ${job?.currency?.main_symbol ?? 'USDT'}`
                      : `${job_task?.percent} ${
                          job_task?.task_type?.socialmedia_type === 'NOSOCIAL'
                            ? `${job?.currency?.main_symbol ?? 'USDT'}`
                            : `% of ${job?.currency?.main_symbol ?? 'USDT'}`
                        }`;
                    return (
                      <div style={{ marginTop: '10px' }}>
                        {' '}
                        <GleamJobTask
                          job_task={job_task}
                          green_box_text={greenBox}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
              {job?.is_gleam_job === false && (
                <div style={{ paddingBottom: '13px' }}>
                  <div
                    className="gleam-job-task-container"
                    style={{ cursor: 'default' }}
                  >
                    <div className="gleam-job-task-header">
                      <Row style={{ height: '100%' }}>
                        <Col
                          md="1"
                          className="col-v-center col-center"
                          style={{ paddingLeft: '16px' }}
                        >
                          <InfluencerSocialIcon
                            social_media_type={job?.social_media_type}
                          />
                        </Col>
                        <Col md="8" className="col-v-center">
                          <div className="campaign-name-title">{job?.name}</div>
                        </Col>
                        <Col
                          md="3"
                          className="col-v-center col-end"
                          style={{ paddingRight: '16px' }}
                        >
                          <span>
                            <b>{job?.content_type}</b>
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

const GleamToggle = styled.div<{ isOpen: boolean }>`
  /* border: 5px solid; */
  width: 100%;
  gap: 1rem;
  overflow: hidden;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: all 0.5s ease;
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
`;

export default GleamJobComponent;
