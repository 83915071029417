const ADVERTISER_MENU = [
  {
    text: 'menu:Home',
    icon: 'mdi mdi-home-variant me-2 d-flex justify-content-center align-items-center',
    push: '/',
  },
  // {
  //   // text: 'Discover',
  //   text: 'menu:marketer',
  //   icon: 'fas fa-bullhorn me-2 d-flex justify-content-center align-items-center',
  //   push: '/marketer/PublicCampaign',
  // },
  {
    text: 'Menus.Discover',
    icon: 'fas fa-bullhorn me-2 d-flex justify-content-center align-items-center',
    push: '/Discover',
    // items: [
    //   {
    //     text: 'Menus.Invite-Only Campaign',
    //     icon: 'bx bx-shopping-bag me-2 d-flex justify-content-center align-items-center',
    //     push: '/marketer/campaign',
    //   },
    //   {
    //     text: 'Menus.Suggested Campaign',
    //     icon: 'bx bx-add-to-queue me-2 d-flex justify-content-center align-items-center',
    //     push: '/marketer/PublicCampaign',
    //   },
    // ],
  },
  // {
  //   text: 'menu:advertiser',
  //   icon: 'mdi mdi-signal-cellular-3 me-2 d-flex justify-content-center align-items-center',
  //   items: [
  //     {
  //       text: 'menu:dashboard',
  //       icon: 'fas fa-tachometer-alt d-inline me-2 d-flex justify-content-center align-items-center',
  //       push: '/campaigns/dashboard',
  //     },
  //     {
  //       text: 'menu:new_campaign',
  //       icon: 'bx bx-add-to-queue d-inline me-2 d-flex justify-content-center align-items-center',
  //       push: '/campaign/create',
  //     },
  //     {
  //       text: 'menu:campaigns',
  //       icon: 'bx bx-shopping-bag me-2 d-flex justify-content-center align-items-center',
  //       push: '/campaigns',
  //     },
  //     {
  //       text: 'menu:my_creatives',
  //       icon: 'bx bx-photo-album me-2 d-flex justify-content-center align-items-center',
  //       push: '/gallery',
  //     },
  //   ],
  // },
  // {
  //   text: 'menu:Portfolio',
  //   icon: 'mdi mdi-file-account me-2 d-flex justify-content-center align-items-center',
  //   push: '/Portfolio',
  // },
  {
    text: 'menu:Blog',
    icon: 'mdi mdi-text-box-multiple me-2 d-flex justify-content-center align-items-center',
    link: 'https://blog.afbrother.com/',
  },
];
const ADVERTISER_MENU_NEW = [
  {
    text: 'Dashboard',
    icon: 'fas fa-tachometer-alt d-inline  d-flex justify-content-start align-items-center',
    push: '/campaigns/dashboard',
  },
  {
    text: 'Campaign',
    icon: 'fa fa-bullhorn  d-inline  d-flex justify-content-start align-items-center',
    push: '/campaigns/markerter/job',
  },
  {
    text: 'Template',
    icon: 'bx bx-file d-flex justify-content-start align-items-center',
    push: '/template',
  },
  {
    text: 'Wallet',
    icon: 'fas fa-wallet d-flex justify-content-start align-items-center',
    push: '/wallet',
  },

  {
    text: 'Menus.Discover',
    icon: 'fas fa-bullhorn me-2 d-flex justify-content-center align-items-center',
    push: '/Discover',
    // items: [
    //   {
    //     text: 'Menus.Invite-Only Campaign',
    //     icon: 'bx bx-shopping-bag me-2 d-flex justify-content-center align-items-center',
    //     push: '/marketer/campaign',
    //   },
    //   {
    //     text: 'Menus.Suggested Campaign',
    //     icon: 'bx bx-add-to-queue me-2 d-flex justify-content-center align-items-center',
    //     push: '/marketer/PublicCampaign',
    //   },
    // ],
  },
  // {
  //   text: 'menu:advertiser',
  //   icon: 'mdi mdi-signal-cellular-3 me-2 d-flex justify-content-center align-items-center',
  //   items: [
  //     {
  //       text: 'menu:dashboard',
  //       icon: 'fas fa-tachometer-alt d-inline me-2 d-flex justify-content-center align-items-center',
  //       push: '/campaigns/dashboard',
  //     },
  //     {
  //       text: 'menu:new_campaign',
  //       icon: 'bx bx-add-to-queue d-inline me-2 d-flex justify-content-center align-items-center',
  //       push: '/campaign/create',
  //     },
  //     {
  //       text: 'menu:campaigns',
  //       icon: 'bx bx-shopping-bag me-2 d-flex justify-content-center align-items-center',
  //       push: '/campaigns',
  //     },
  //     {
  //       text: 'menu:my_creatives',
  //       icon: 'bx bx-photo-album me-2 d-flex justify-content-center align-items-center',
  //       push: '/gallery',
  //     },
  //   ],
  // },
  // {
  //   text: 'menu:Portfolio',
  //   icon: 'mdi mdi-file-account me-2 d-flex justify-content-center align-items-center',
  //   push: '/Portfolio',
  // },
  {
    text: 'menu:Blog',
    icon: 'mdi mdi-text-box-multiple me-2 d-flex justify-content-center align-items-center',
    link: 'https://blog.afbrother.com/',
  },
  {
    text: 'Profile',
    icon: 'bx bx-user d-inline  d-flex justify-content-start align-items-center',
    push: '/profile',
  },
  {
    text: 'Invite friend',
    icon: 'bx bx-add-to-queue d-inline  d-flex justify-content-start align-items-center',
    push: '/referral',
  },
  {
    text: 'Logout',
    icon: 'bx bx-power-off d-flex justify-content-start align-items-center',
    push: '/logout',
  },
];

const PUBLISHER_MENU = [
  // {
  //   text: 'menu:dashboard',
  //   icon: 'mdi mdi-home-variant me-2 d-flex justify-content-start align-items-center',
  //   push: '/',
  // },
  {
    text: 'menu:Home',
    icon: 'mdi mdi-home-variant me-2 d-flex justify-content-center align-items-center',
    push: '/',
  },

  {
    text: 'menu:influencer',
    icon: 'fas fa-bullhorn me-2 d-flex justify-content-start align-items-center',
    match: 'influencer',
    items: [
      // {
      //   text: 'menu:overview',
      //   icon: 'fas fa-chart-line me-2 d-flex justify-content-center align-items-center',
      //   push: '/influencer/dashboard',
      // },

      {
        text: 'influencer:My Jobs',
        icon: 'fas fa-tasks me-2 d-flex justify-content-center align-items-center',
        push: '/influencer/job',
        match: 'My Jobs',
      },
      {
        text: 'influencer:Suggested jobs',
        icon: 'fas fa-tasks me-2 d-flex justify-content-center align-items-center',
        push: '/influencer/publish-job',
        match: 'Suggested jobs',
      },
    ],
  },
  {
    icon: 'fas fa-user me-2 d-flex justify-content-center align-items-center',
    text: 'Discover',
    push: '/Discover',
  },
  // {
  //   text: 'menu:publisher',
  //   icon: 'fas fa-ad me-2 d-flex justify-content-start align-items-center',
  //   items: [
  //     {
  //       text: 'menu:dashboard',
  //       icon: 'fas fa-tachometer-alt d-inline me-2 d-flex justify-content-center align-items-center',
  //       push: '/publisher/dashboard',
  //     },
  //     {
  //       text: 'menu:my_website',
  //       icon: 'far fa-window-maximize me-2 d-flex justify-content-center align-items-center',
  //       push: '/mywebsite',
  //     },
  //     {
  //       text: 'menu:my_application',
  //       icon: 'fas fa-mobile-alt me-2 d-flex justify-content-center align-items-center',
  //       push: '/myapplication',
  //     },
  //   ],
  // },
  {
    text: 'menu:Showcase',
    icon: 'mdi mdi-file-account me-2 d-flex justify-content-center align-items-center',
    push: '/Portfolio',
  },
  {
    text: 'menu:Blog',
    icon: 'mdi mdi-text-box-multiple me-2 d-flex justify-content-center align-items-center',
    link: 'https://blog.afbrother.com/',
  },
];

const ADMIN_MENU = [
  {
    text: 'Dashboard',
    icon: 'mdi mdi-home-variant',
    push: '/',
  },
  {
    text: 'System Balance',
    icon: 'bx bx-pie-chart-alt',
    items: [
      {
        text: 'Menus.Overview',
        icon: '',
        push: '/admin/system-balance-overview',
      },
      {
        text: 'Menus.System Balance Log',
        icon: '',
        push: '/admin/system-balance-log',
      },
      {
        text: 'Menus.Crypto Balance Log',
        icon: '',
        push: '/admin/crypto-balance-log',
      },
      {
        text: 'Menus.Role Balance Log',
        icon: '',
        push: '/admin/role-balance-log',
      },
      {
        text: 'Menus.Profit Balance Log',
        icon: '',
        push: '/admin/profit-balance-log',
      },
    ],
  },
  {
    text: 'Menus.User',
    icon: 'fas fa-user-friends',
    items: [
      {
        text: 'Menus.All',
        icon: '',
        push: '/admin/users',
      },
    ],
  },
  {
    text: 'Menus.Approval',
    icon: 'fas fa-unlock',
    isMainMenu: true,
    items: [
      {
        text: 'page:advertiser_campaign',
        icon: '',
        push: '/admin/approve/approvalAdvertiser',
        match: 'advertiser_campaign',
      },
      {
        text: 'page:publisher_website',
        icon: '',
        push: '/admin/approve/publisher',
        match: 'publisher_website',
      },
      {
        text: 'page:New Influencer Approval',
        icon: '',
        push: '/admin/approve/influencer',
        match: 'new_influencer',
      },
      {
        text: 'page:Request update Price',
        icon: '',
        push: '/admin/approve/influencer-request',
        match: 'new_influencer_request',
      },
      {
        text: 'page:Request update follower',
        icon: '',
        push: '/admin/approve/influencer-request',
      },
      {
        text: 'page:Job Approval',
        icon: '',
        push: '/admin/approve/manage-job',
        match: 'new_job',
      },
      {
        text: 'page:Withdraw Transactions Approval',
        icon: '',
        push: '/admin/approve/withdraw',
        match: 'withdraw',
      },
      {
        text: 'page:Speed Up Job',
        icon: '',
        push: '/admin/approve/speed-up-job',
      },
      {
        text: 'page:Request Delete Social',
        icon: '',
        push: '/admin/approve/delete-social',
        match: 'delete_social',
      },
      {
        text: 'Suspend Influencer Post',
        icon: '',
        push: '/admin/approve/suspend-influencer-post',
      },
      {
        text: 'page:Currency',
        icon: '',
        push: '/admin/approve/currency-approval',
      },
      {
        text: 'page:Ban User',
        icon: '',
        push: '/admin/reportJob',
      },
    ],
  },
  {
    text: 'Menus.Transaction',
    icon: 'mdi mdi-grid-large',
    items: [
      {
        text: 'Menus.Withdraw Transaction',
        icon: '',
        push: '/admin/withdraw',
      },
      {
        text: 'Menus.Deposit Transaction',
        icon: '',
        push: '/admin/deposit',
      },
      {
        text: 'Menus.Payment Transaction',
        icon: '',
        push: '/admin/payment',
      },
    ],
  },
  {
    text: 'Menus.Movement Balance',
    icon: 'mdi mdi-shuffle',
    push: '/admin/movement-balance',
  },
  {
    text: 'Menus.Admin Settings',
    icon: 'dripicons-gear',
    items: [
      {
        text: 'Menus.Overview',
        icon: '',
        push: '/admin/settings',
      },
      {
        text: 'Page.Advertiser',
        icon: '',
        push: '/admin/settingAdvertiser',
      },
      {
        text: 'Page.Influencer Price Rate',
        icon: '',
        push: '/admin/setting-influencer',
      },
      {
        text: 'Page.FAQs',
        icon: '',
        push: '/admin/settingFAQs',
      },
    ],
  },
];

export { ADVERTISER_MENU, ADVERTISER_MENU_NEW, PUBLISHER_MENU, ADMIN_MENU };
