import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';
import Role from 'src/constants/enum/role';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { floorToFixed } from 'src/common/data/mathToFixed';
import Breadcrumb from '../components/Common/Breadcrumb';
import menu from '../constants/menu';
import ReferralTransactionsTable from '../components/Common/ReferralTransactionsTable';
import config from '../config';
import {
  getReferralTransactions,
  getSystemTaxReferral,
} from '../store/referral/actions';
import earnMoney from '../assets/images/home/earn-money.png';

const ReferralPage = ({ t }: any) => {
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);

  const { user, referralTransactions, tax } = useSelector((state: any) => ({
    user: state.login.user,
    referralTransactions: state.Referral.referralTransactions,
    tax: state.Referral.referralSystemTax,
  }));

  useEffect(() => {
    dispatch(getSystemTaxReferral());
    dispatch(
      getReferralTransactions({
        limit: 10,
        page: 1,
      }),
    );
  }, []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) setCopied(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [copied]);

  const handleCopy = (val: string) => {
    navigator.clipboard.writeText(val);

    setCopied(true);
  };

  return (
    <>
      <div
        className={` ${user.role === Role.ADVERTISER ? '' : 'page-content'} h-100`}
        style={{
          paddingTop: user.role === Role.ADVERTISER ? '6.3vh' : '',
          paddingLeft: user.role === Role.ADVERTISER ? '4.83vh' : '',
        }}
      >
        <MetaTags>
          <title>Form Validation | Minia - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb
            pageTitle={t('Page.Referral')}
            menus={[{ menu: menu.HOME }, { menu: menu.REFERRAL, active: true }]}
          />

          <Row className="justify-content-center">
            <Col lg={12}>
              <Row className="align-items-end mb-4">
                <Col
                  lg={6}
                  className="d-none d-md-flex flex-column align-items-center"
                >
                  <h1 className="font-size-large">{t('Refer Friends')}</h1>
                  <h2 className="font-size-medium">
                    {t('Make Advertise Together')}
                  </h2>
                  <img src={earnMoney} alt="" width="350" />
                </Col>
                <Col lg={6}>
                  <Card>
                    <h5 className="card-header bg-transparent border-bottom">
                      {t('Total Referral Earnings')}
                    </h5>
                    <CardBody>
                      <Row className="referral-background w-100 p-4 g-0 mb-3">
                        <Col lg={6} className="d-flex flex-column mb-2">
                          <span style={{ fontWeight: '600' }}>
                            {t('You received')} ({tax}%)
                          </span>
                          <span className="font-size-medium">
                            {referralTransactions &&
                            referralTransactions?.sumTotalEarningBySymbols &&
                            referralTransactions.sumTotalEarningBySymbols?.length !==
                              0
                              ? referralTransactions.sumTotalEarningBySymbols.map(
                                  (item: any) => {
                                    return (
                                      <div>
                                        <span>
                                          {floorToFixed(item.sum_total_earnings, 2)}{' '}
                                        </span>
                                        <span className="referral_symbol">
                                          {item?.symbol ?? 'USDT'}
                                        </span>
                                      </div>
                                    );
                                  },
                                )
                              : '0 USDT'}
                          </span>
                        </Col>
                        <Col lg={6} className="d-flex flex-column">
                          <span style={{ fontWeight: '600' }}>
                            {referralTransactions &&
                            referralTransactions.totalCount > 1
                              ? t('Friends')
                              : t('Friend')}
                          </span>
                          <span className="font-size-medium">
                            {referralTransactions
                              ? referralTransactions.totalCount
                              : 0}
                          </span>
                        </Col>
                      </Row>
                      <Row className="g-0">
                        <Col>
                          {user && user.referral_code && (
                            <InputGroup>
                              <Input
                                value={`${config.appUrl}register?ref=${user.referral_code}`}
                                disabled
                              />
                              <InputGroupAddon addonType="append">
                                <Button
                                  style={{
                                    backgroundColor: '#6B99CA',
                                  }}
                                  onClick={() => {
                                    handleCopy(
                                      `${config.appUrl}register?ref=${user.referral_code}`,
                                    );
                                  }}
                                >
                                  <i className="fas fa-copy" />
                                  &nbsp;
                                  {copied ? t('button:Copied') : t('button:Copy')}
                                </Button>
                              </InputGroupAddon>
                            </InputGroup>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <ReferralTransactionsTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(ReferralPage);
