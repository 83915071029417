import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import './IconRadioInput.style.scss';

interface IconRadioInputProps {
  id?: string;
  isChecked?: boolean;
  onClick?: Function;
  icon?: string;
  imageUrl?: string;
  background?: string;
  iconColor?: string;
  label?: string;
  labels?: string;
  labelSize?: string;
  images?: string;
}

const IconRadioInputComponentNew = (props: IconRadioInputProps) => {
  const { t } = useTranslation();
  const {
    background,
    icon,
    iconColor,
    id,
    imageUrl,
    isChecked,
    label,
    labels,
    onClick,
    labelSize,
    images,
  } = props;

  const inputId: string = `${id} radio`;

  return (
    <>
      <Label
        className="icon-radio-input-container"
        id={id}
        for={inputId}
        style={{ width: 120 }}
      >
        <div
          className="radio radio-input"
          id={id}
          onClick={() => (onClick ? onClick() : null)}
        >
          {/* <input id={inputId} type="radio" defaultChecked={isChecked} /> */}

          {images && (
            <img
              src={images}
              alt="icon radio"
              className="images-social"
              style={{
                background: background || '#8FBDD3',
              }}
            />
          )}
          {imageUrl && (
            <img
              src={imageUrl}
              alt="icon radio"
              className="image-icon"
              style={{
                background: background || '#8FBDD3',
              }}
            />
          )}
          {icon && !imageUrl && (
            <i
              className={`radio-icon ${icon}`}
              style={{
                background: background || '#8FBDD3',
                color: iconColor || '#ffffff',
              }}
            />
          )}
          {isChecked && !images && !icon ? (
            <span className="fas fa-check " />
          ) : (
            <></>
          )}
          {isChecked && images ? <i className="fas fa-check " /> : <></>}
          {isChecked && icon ? <i className="fas fa-check " /> : <></>}
          <Label for={id} style={{ fontSize: !labelSize ? '14px' : labelSize }}>
            {t(label || '')}
          </Label>
          <Label
            for={id}
            style={{ fontSize: !labelSize ? '14px' : labelSize, bottom: '-40%' }}
          >
            {t(labels || '')}
          </Label>
        </div>
      </Label>
    </>
  );
};

IconRadioInputComponentNew.defaultProps = {
  id: null,
  isChecked: null,
  onClick: null,
  icon: null,
  imageUrl: null,
  background: null,
  iconColor: null,
  label: null,
  labels: null,
  labelSize: null,
  images: null,
};

export default IconRadioInputComponentNew;
