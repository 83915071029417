import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card as CardEl, CardBody, CardTitle } from 'reactstrap';
import noCampaignImage from 'src/assets/images/no-campaign-image.png';
import config from 'src/config';
import { filterFromRequirementsGql } from 'src/graphql/advertiser/job-requirement';
import {
  getDefaultWebsite,
  getSocialMediaInfo,
  setLoading,
  unsetLoading,
} from 'src/store/actions';
import './mockInfluPublishJob.style.scss';
import avatar from 'src/assets/images/users/avatar-1.jpg';
import avatar2 from 'src/assets/images/china-town.jpg';
import {
  InfluencerContentType,
  InfluencerSocialIcon,
} from 'src/components/Common/status/InfluencerJobStatus';
import FilterPublicJob from 'src/components/Advertiser/FilterPublicJob';
import { floorToFixed, removeDecimal } from 'src/common/data/mathToFixed';
import capitalize from 'src/utils/captilalize';
import { getCurrencyByIdGQL, suggestAndOtherJobCountGql } from 'src/graphql/common';
import { getDateTime } from 'src/utils/dateTime';
import { Pagination } from '@mui/material';
import SortPublicJob from 'src/components/Advertiser/SortPublicJob';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import menu from 'src/constants/menu';
import { Link } from 'react-router-dom';
import moment from 'moment';
import * as formatter from 'src/utils/convert-value';
import { filterGleamSuggestJob } from 'src/graphql/advertiser/job';

interface Card {
  id: number;
  title: string;
  description: string;
}
enum SocialMediaType {
  FACEBOOK,
  INSTAGRAM,
  TIKTOK,
  YOUTUBE,
  TWITTER,
}
enum ContentType {
  SHARE,
  PHOTO,
  VIDEO,
  LIVE,
}
enum FilterJobSortBy {
  RECENT,
  NAME,
}

const MockInfluencerPublishJob: React.FC = () => {
  // Redux
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currency, setCurrency] = useState<any>(null);
  const [currencyId, setCurrencyId] = useState<any>(+9);
  const [data, setData] = useState<any>({});
  const [tax, setTax] = useState(0);

  const { defaultTax, influencerData, suggest } = useSelector((state: any) => ({
    defaultTax: state.DefaultValue.defaultWebsite.valueAddedTax,
    influencerData: state.Users.userInfo,
    suggest: state.SuggestJobs.suggestJobs,
    // marketerJobNew: state.MarketerCampaignJobsNew.marketerJobNew,
  }));

  const fetchSuggestionJobs = async () => {
    try {
      dispatch(setLoading());
      const response = await suggestAndOtherJobCountGql();
      setData(response);
    } catch (error) {
      setData({});
    } finally {
      dispatch(unsetLoading());
    }
  };

  console.log('data', data);

  useEffect(() => {
    fetchSuggestionJobs();
  }, []);

  useEffect(() => {
    if (defaultTax !== null) {
      const _tax = defaultTax / 100;
      setTax(_tax);
    }
  }, [defaultTax]);
  const { SocialMediaInfo } = useSelector((state: any) => {
    return {
      SocialMediaInfo: state.SocialMediaInfo.socialMediaInfo,
    };
  });

  const [cardData, setCardData] = useState<any>();
  const [cards, setCards] = useState<any>();
  const [able_get_job, setAbleGetjob] = useState(true);
  const [filterData, setFilterData] = useState<any>({
    limit: 10,
    page: 1,
    social_job_status: 'NEW_JOB',
    social_media_key: null,
    is_able_get_job: true,
    // filter_social_media_types: ['4'],
    // filter_content_types: ['1'],
  });
  const [param, setParam] = useState<any>({
    limit: 10,
    page: 1,
    checked: {
      type: [
        // 'FACEBOOK_PROFILE',
        SocialMediaType.FACEBOOK,
        SocialMediaType.INSTAGRAM,
        SocialMediaType.TIKTOK,
        SocialMediaType.YOUTUBE,
        SocialMediaType.TWITTER,
      ],
      content: [
        ContentType.SHARE,
        ContentType.PHOTO,
        ContentType.VIDEO,
        // ContentType.LIVE,
      ],
    },
    sort: 0,
  });
  useEffect(() => {
    const { currency_id } = currencyId;
    if (currencyId) {
      fetchCurrency(currencyId);
    }
  }, [currencyId]);

  const fetchCurrency = async (currency_id: number) => {
    const _currency = await getCurrencyByIdGQL(currency_id);
    setCurrency(_currency.main_symbol);
  };

  const handlePageChange = async (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : param.page;
    const payload = {
      ...param,
      page: newPage,
    };
    setParam(payload);
    const pageChange = { ...filterData, page: newPage };
    setFilterData(pageChange);
    const data = await filterGleamSuggestJob(pageChange);
    setCardData(data);
    setCards(data);
  };
  const handleFilterType = async (item: string, filterType: string) => {
    if (filterType === 'type') {
      const payload = {
        ...param,
        page: 1,
        checked: {
          ...param.checked,
          type: param?.checked?.type?.includes(item)
            ? param?.checked?.type?.filter((e: any) => e !== item)
            : [...param.checked?.type, item],
        },
      };

      if (payload?.checked?.type?.length >= 1) {
        setParam(payload);
        const newData: any = payload?.checked?.type;
        const filter = {
          ...filterData,
          filter_social_media_types: newData,
          page: 1,
        };
        setFilterData(filter);
        const data = await filterGleamSuggestJob(filter);
        setCardData(data);
        setCards(data);
      }
    } else {
      const payload = {
        ...param,
        page: 1,
        checked: {
          ...param.checked,
          content: param?.checked?.content?.includes(item)
            ? param?.checked?.content?.filter((e: any) => e !== item)
            : [...param.checked?.content, item],
        },
      };

      if (payload?.checked?.content?.length >= 1) {
        setParam(payload);
        const newData: any = payload?.checked?.content;
        const filter = { ...filterData, filter_content_types: newData, page: 1 };
        setFilterData(filter);
        const data = await filterGleamSuggestJob(filter);
        setCardData(data);
        setCards(data);
      }
    }

    // cards?.items.forEach((e: any) => {
    //   if (payload.checked.includes(e?.job?.social_media_type)) {
    //     newData.push(e);
    //   }
    // });
  };
  const handleSort = async (item: any) => {
    const payload = {
      ...param,
      page: 1,
      sort: item,
    };
    setParam(payload);

    const filter = {
      ...filterData,
      sort_by: FilterJobSortBy[payload.sort],
      page: 1,
    };
    setFilterData(filter);
    const data = await filterGleamSuggestJob(filter);
    setCardData(data);
    setCards(data);
  };

  const getCardData = async () => {
    //const data = await filterFromRequirementsGql(filterData);
    const job = await filterGleamSuggestJob(filterData);
    setCardData(job);
    setCards(job);
  };
  // useEffect(() => {
  //   if (socialJobId) {
  //     dispatch(getSocialJob(socialJobId));
  //   } else {
  //     setCollapse({ ...collapse, collapse1: true });
  //   }
  //   dispatch(getMarketerJob(jobId));
  // }, []);
  useEffect(() => {
    getCardData();
    dispatch(getSocialMediaInfo());
    dispatch(getDefaultWebsite());
  }, []);

  useEffect(() => {
    // getCardData();
  }, [filterData]);

  const priceRange = (prices: any[]) => {
    const sortPrices = prices?.sort((a, b) => a - b);
    const minPrice = sortPrices ? sortPrices[0] : 1;
    const maxPrice = sortPrices ? sortPrices[prices?.length - 1] : 2;
    return { minPrice, maxPrice };
  };

  const handleClickJobs = (card: any) => {
    if (filterData.is_able_get_job)
      window.location.href = `/JobDetail?job=${card?.key}&able_get_job=${able_get_job}`;
  };

  const handleChangeStatus = async (status: boolean) => {
    const filter = {
      ...filterData,
      page: 1,
      is_able_get_job: status,
    };
    setFilterData(filter);
    const data = await filterGleamSuggestJob(filter);
    setCardData(data);
    setCards(data);
    setAbleGetjob(status);
  };

  // console.log('cardData', cardData);
  // if (!cardData) return <OnLoading />;

  const startTime = moment('0:00 AM', 'h:mm A');
  const currentTimeZone = moment(startTime)
    .add(influencerData.timezone_offset, 'minutes')
    .format('HH:mm A');

  const symbolSprit = (symbol: any) => {
    const xx = symbol.split('_');
    return xx[0];
  };
  return (
    <div className="page-content">
      <div className="bread-crumb-20">
        <Breadcrumb
          pageTitle={t('Menus.Suggested Jobs')}
          menus={[{ menu: menu.HOME, active: true }, { menu: menu.SUGGESTED_JOBS }]}
        />
      </div>
      <div className="mock-influ-publish-job">
        <div className="filter-sort-public">
          {/* <FilterPublicJob param={param} handleStatusChange={handleFilterType} /> */}

          <SortPublicJob param={param} handleStatusChange={handleSort} />
        </div>
        <div className="check-match-button">
          <div
            className={filterData.is_able_get_job ? `active` : ''}
            onClick={() => handleChangeStatus(true)}
            style={{ cursor: 'pointer' }}
          >
            {t('Menus.Suggested Jobs')}
            {/* {cardData?.totalCount && <span>{cardData?.totalCount}</span>} */}
          </div>
          <div className="alert-suggested-job">{suggest?.suggestJobs}</div>

          <div
            className={!filterData.is_able_get_job ? `active` : ''}
            onClick={() => handleChangeStatus(false)}
            style={{ cursor: 'pointer', marginLeft: '10px' }}
          >
            {t('Menus.Other Job')}
            {/* {cardData?.totalCount && <span>{cardData?.totalCount}</span>} */}
          </div>
          <div className="alert-other-job">{suggest?.otherJobs}</div>
        </div>
        <div
          style={{
            maxWidth: '800px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: '2.5rem',
            marginTop: '20px',
          }}
        >
          {cardData?.items?.length > 0 ? (
            cardData?.items?.map((card: any, index: any) => {
              const price_rate = card?.job?.price_rate ?? 1;
              const price = priceRange(card?.price);
              return (
                <div
                  style={{
                    margin: '0px',
                  }}
                  className={`
                     card-job-public ${
                       !filterData.is_able_get_job && 'card-disabled'
                     } 
                  }`}
                  onClick={() => handleClickJobs(card)}
                >
                  <CardEl
                    style={{
                      marginBottom: '0px',
                      // background:
                      //   'linear-gradient(271.74deg, rgba(255, 255, 255, 0.4) 30.83%, rgba(255, 255, 255, 0) 68.18%), #50D0B9',
                      cursor: filterData?.is_able_get_job
                        ? 'pointer'
                        : 'not-allowed',
                    }}
                  >
                    <CardTitle
                      style={{
                        background: 'white',
                        borderTopLeftRadius: '18px',
                        borderTopRightRadius: '18px',
                        borderRadius: '18px',
                        paddingRight: '0px',
                        paddingLeft: '0px',
                        padding: '15px 15px',
                        marginBottom: '0px',
                      }}
                    >
                      <div
                        className="header-publish-job-card"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline',
                          flexWrap: 'nowrap',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: '50%',
                            paddingBottom: '2px',
                          }}
                        >
                          <div className="brand-name">{card?.name}</div>
                          <div className="product-name">{card?.company}</div>
                          {/* <div className="company-name">
                        {t('influencer:From')}: {card.job.company_name}
                      </div> */}
                        </div>
                        <div className="date">
                          <span>{t('Get a job by')}</span>
                          {getDateTime(card?.last_day_accept_job, 'TH')}{' '}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          src={`${config.backendUrl}api/jobThumbnail/${card?.thumbnail_cover}`}
                          // src={avatar2}
                          alt={card?.job?.thumbnail_cover}
                          style={{
                            maxWidth: '350px',
                            width: '100%',
                            height: '100%',
                            padding: '8px 0 8px 0',
                          }}
                        />
                      </div>
                      <CardBody>
                        <div
                          className="group-social"
                          style={{
                            width: '100%',
                            padding: '0',
                          }}
                        >
                          {/* {filterData?.is_able_get_job && ( */}
                          <div className="column-group">
                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                wordBreak: 'break-all',
                              }}
                            >
                              {card?.social_media_types.map((item: any) => {
                                return (
                                  <InfluencerSocialIcon social_media_type={item} />
                                );
                              })}
                            </div>
                          </div>
                          {/* )} */}
                          <div
                            className="column-group"
                            style={{ marginLeft: 'auto' }}
                          >
                            <div style={{ textAlign: 'right' }}>
                              <h5>
                                {card?.taksCount ?? 0}{' '}
                                {card?.taksCount > 1 ? 'Tasks' : 'Tasks'}
                              </h5>
                              <div>
                                {card?.fixed_price !== null
                                  ? card?.fixed_price - card?.fixed_price * tax
                                  : ''}
                                {'   '}
                                {symbolSprit(card?.symbol)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </CardTitle>
                  </CardEl>
                </div>
              );
            })
          ) : (
            <div className="no-job-container">
              <img
                src={noCampaignImage}
                className="no-job-image-container"
                alt="noCampaignImage"
              />
              <div className="text-container">
                <div className="text-top" style={{ textAlign: 'center' }}>
                  {t(
                    'table:There are no jobs that match you now. Do you have a social channel ?',
                  )}
                </div>
              </div>
              <Link to="/influencer/dashboard">
                <CardEl className="add-social-job-card">
                  <i className="fas fa-plus-circle icon" />
                  <p>{t('influencer:dashboard.Add social channel')}</p>
                </CardEl>
              </Link>
            </div>
          )}
        </div>
        <Pagination
          count={Math.ceil(cardData?.totalCount / param.limit)}
          page={param.page}
          color="standard"
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          style={{ width: 'fit-content', margin: 'auto', marginTop: '30px' }}
          boundaryCount={2}
        />
      </div>
    </div>
  );
};

export default MockInfluencerPublishJob;
