import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { toLower } from 'lodash';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CryptosBalancePieChart from './CryptosBalancePieChart';
import { transformStringAmountInput } from '../../../utils/input';

const CryptosBalance = ({ t }: any) => {
  const { cryptoWallets } = useSelector((state: any) => ({
    cryptoWallets: state.SystemBalance.cryptoWallets,
  }));

  return (
    <>
      <Col xl={6}>
        <Link to="/admin/crypto-balance-log">
          <Card className="card-h-100">
            <CardBody>
              <div className="d-flex flex-wrap align-items-center mb-4">
                <h5 className="card-title me-2">
                  {t('Crypto Wallets Balance')} (Fairmarketcoin)
                </h5>
              </div>

              <Row className="align-items-center">
                <Col xl={5}>
                  <div id="wallet-balance" className="apex-charts">
                    <CryptosBalancePieChart />
                  </div>
                </Col>
                <Col xl={7}>
                  <div className="mt-4 mt-sm-0">
                    <Row>
                      {cryptoWallets &&
                        cryptoWallets.map((wallet: any) => {
                          return (
                            <Col xl={6}>
                              <div className="pt-2">
                                <p className="mb-2">
                                  <i
                                    className={`mdi mdi-circle align-middle font-size-14 me-2 text-${toLower(
                                      wallet.symbol,
                                    )}`}
                                  />{' '}
                                  {wallet.symbol}
                                </p>
                                <h6>
                                  {transformStringAmountInput(wallet.CRYPTO_amount)}{' '}
                                  {wallet.symbol} ={' '}
                                  <span className="text-muted font-size-18 fw-normal">
                                    {transformStringAmountInput(wallet.FIAT_amount)}{' '}
                                    USDT
                                  </span>
                                </h6>
                              </div>
                            </Col>
                          );
                        })}
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Link>
      </Col>
    </>
  );
};

export default withTranslation()(CryptosBalance);
