import styled from 'styled-components';
import './GleamJob.style.scss';
import { useEffect, useRef, useState } from 'react';
import { Row, Col } from 'reactstrap';
import {
  InfluencerContentType,
  InfluencerSocialIcon,
} from './status/InfluencerJobStatus';

const GleamJobTask = ({ job_task, green_box_text }: any) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = job_task?.description;
    }
  }, [ref.current, job_task]);

  return (
    <div className="gleam-job-task-container">
      <div
        className={isOpen ? 'gleam-job-task-header-open' : 'gleam-job-task-header'}
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        <Row style={{ height: '100%' }}>
          <Col
            md="7"
            xs="7"
            className="col-v-center col-center"
            style={{ paddingLeft: '16px' }}
          >
            <InfluencerSocialIcon
              social_media_type={job_task?.task_type?.socialmedia_type}
              alt={job_task?.task_type?.socialmedia_type}
              size={24}
            />
            <InfluencerContentType
              content_type={job_task?.task_type?.content_type}
              size={24}
            />
            <div title={job_task?.title} className="campaign-name-title">
              {job_task?.title}
            </div>
          </Col>
          <Col
            md="5"
            xs="5"
            className="col-v-center col-end"
            style={{ paddingRight: '16px' }}
          >
            {green_box_text && (
              <div className="gleam-job-percent">
                <b>{green_box_text}</b>
              </div>
            )}

            <div>
              {isOpen ? (
                <i
                  className="fa fa-chevron-down fa-icon"
                  style={{ paddingRight: '0 0 0 16px' }}
                />
              ) : (
                <i
                  className="fa fa-chevron-right fa-icon"
                  style={{ paddingRight: '0 0 0 16px' }}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
      <GleamJobTaskDetail isOpen={isOpen}>
        <div className="gleam-job-task-detail">
          <div className="text-editor-display" style={{ wordBreak: 'break-word' }}>
            <div ref={ref} />
          </div>
        </div>
      </GleamJobTaskDetail>
    </div>
  );
};

const GleamJobTaskDetail = styled.div<{ isOpen: boolean }>`
  /* border: 5px solid; */
  width: 100%;
  gap: 1rem;
  overflow: hidden;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: all 0.5s ease;
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
`;

export default GleamJobTask;
