import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import MetaTags from 'react-meta-tags';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getRoleBalanceLogTransactions } from '../../store/systembalance/actions';
import RoleBalanceChart from '../../components/Admin/SystemBalance/RoleBalanceChart';
import RoleBalanceLogTransactionsTable from '../../components/Admin/SystemBalance/RoleBalanceLogTransactionsTable';
import balanceLogRangeEnum from '../../constants/balanceLogRangeEnum';
import menu from '../../constants/menu';

const CryptoBalanceLogPage = ({ t }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getRoleBalanceLogTransactions({
        limit: 10,
        page: 1,
        log_range: balanceLogRangeEnum.DAILY,
      }),
    );
  }, []);

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>{t('Page.Role Balance Log')}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            pageTitle={t('Page.Role Balance Log')}
            menus={[
              { menu: menu.DASHBOARD },
              { menu: menu.ROLE_BALANCE_LOG, active: true },
            ]}
          />
          <div className="card-title-desc">{t('Role Balance Log Helper')}</div>

          <Row className="justify-content-center">
            <Col className="mb-4" xl={8}>
              <RoleBalanceChart />
            </Col>
            <Col xl={12}>
              <RoleBalanceLogTransactionsTable />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(CryptoBalanceLogPage);
