import { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Form } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getFaqsSearch as onGetFaqsSearch } from 'src/store/actions';
import SimpleBar from 'simplebar-react';
import logo from '../assets/images/logo-colorful.svg';

const initFAQs = [
  {
    title: 'FAQ init.title 1',
    detail: 'FAQ init.DESC 1',
  },
  {
    title: 'FAQ init.title 2',
    detail: `FAQ init.DESC 2`,
  },
  {
    title: 'FAQ init.title 3',
    detail: 'FAQ init.DESC 3',
  },
  {
    title: 'FAQ init.title 4',
    detail: `FAQ init.DESC 4`,
  },
  {
    title: 'FAQ init.title 5',
    detail: 'FAQ init.DESC 5',
  },
  {
    title: 'FAQ init.title 6',
    detail: 'FAQ init.DESC 6',
  },
  {
    title: 'FAQ init.title 7',
    detail: 'FAQ init.DESC 7',
  },
  {
    title: 'FAQ init.title 8',
    detail: 'FAQ init.DESC 8',
  },
  {
    title: 'FAQ init.title 9',
    detail: 'FAQ init.DESC 9',
  },
  {
    title: 'FAQ init.title 10',
    detail: 'FAQ init.DESC 10',
  },
];

const FAQPage = ({ t }: any) => {
  const dispatch = useDispatch();

  const { faqs } = useSelector((state: any) => ({ faqs: state.FAQs.faqs }));

  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(onGetFaqsSearch(search));
  }, []);

  const handleSearch = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(onGetFaqsSearch(search));
  };

  const renderInitFaqData = () => {
    return initFAQs.map((item: any, key: number) => {
      return (
        <Col xl={4} sm={6}>
          <Card>
            <CardBody
              className="overflow-hidden position-relative"
              style={{ height: '200px' }}
            >
              <div>
                <i className="bx bx-help-circle widget-box-1-icon text-primary" />
              </div>
              <div className="faq-count">
                <h5 className="text-primary">0{key + 1}.</h5>
              </div>
              <h5 className="mt-3">{t(`${item.title}`)}</h5>

              <p className="text-muted mt-3 mb-0">
                <SimpleBar
                  className="table-responsive"
                  style={{ maxHeight: '120px' }}
                >
                  {t(`${item.detail}`)}{' '}
                </SimpleBar>
              </p>
            </CardBody>
          </Card>
        </Col>
      );
    });
  };

  const renderFaqData = () => {
    return (Object(faqs)?.length ? faqs : []).map((item: any, key: number) => {
      return (
        <Col xl={4} sm={6}>
          <Card>
            <CardBody
              className="overflow-hidden position-relative"
              style={{ height: '200px' }}
            >
              <div>
                <i className="bx bx-help-circle widget-box-1-icon text-primary" />
              </div>
              <div className="faq-count">
                <h5 className="text-primary">0{key + 1}.</h5>
              </div>
              <h5 className="mt-3">{item.title}</h5>

              <p className="text-muted mt-3 mb-0">
                <SimpleBar
                  className="table-responsive"
                  style={{ maxHeight: '120px' }}
                >
                  {item.detail}{' '}
                </SimpleBar>
              </p>
            </CardBody>
          </Card>
        </Col>
      );
    });
  };

  return (
    <>
      <Row style={{ padding: '0 30px' }}>
        <Col lg={12}>
          <Row className="p-4">
            <div className="back-btn">
              <Link to="/">
                <i className="mdi mdi-arrow-left" /> {t('Back To Homepage')}
              </Link>
            </div>
          </Row>
          <Row className="justify-content-center mt-4">
            <div className="col-xl-5 col-lg-8">
              <div className="text-center">
                <img src={logo} alt="" width="200" className="mb-3" />
                <h5>{t(`Frequently Asked Questions (FAQ)`)}</h5>
              </div>
            </div>
          </Row>
          <div className="text-center">
            <div className="w-50" style={{ display: 'inline-block' }}>
              <Form
                className="app-search d-block"
                onSubmit={(e: any) => handleSearch(e)}
                onChange={(e: any) => setSearch(e.target.value)}
              >
                <div className="position-relative ">
                  <input
                    type="text"
                    placeholder={t('placeHolder.Search')}
                    className="form-control text-color-primary"
                  />
                  <button
                    className="btn btn-blue bg-color-cornflowerblue"
                    type="submit"
                  >
                    <i className="bx bx-search-alt align-middle" />
                  </button>
                </div>
              </Form>
            </div>
          </div>

          <Row className="mt-5">
            {renderInitFaqData()}
            {renderFaqData()}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default withTranslation()(FAQPage);
