import { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Col, Container, Row } from 'reactstrap';
import registerBackground from 'src/assets/images/Background-homepage-jpg.jpg';
import LoginForm from 'src/components/Authentication/login/LoginForm';
import SelectRole from 'src/components/Authentication/register/SelectRole';
import useWindowSize from 'src/utils/windowSize';
import { useTranslation } from 'react-i18next';
import { layoutTheme } from 'src/constants/layout';
import { Link } from 'react-router-dom';
import Role from 'src/constants/enum/role';
import './Login.style.scss';
import logo from 'src/assets/images/AFbrother-logo.svg';
import logoWhite from 'src/assets/images/logo-white.svg';
import RegisterByThirdParty from 'src/components/Authentication/register/RegisterByThirdParty';
import LoginByEmail from 'src/components/Authentication/login/LoginByEmail';
import CarouselPage from './CarouselPage';
import config from '../../config';
import { clearErrorMessage } from '../../store/auth/login/actions';

const Login = () => {
  const { t } = useTranslation();
  const { layoutMode } = useSelector((state: any) => state.Layout);
  const history = useHistory();
  const path = '';
  const { role: roleParam }: any = useParams();

  useEffect(() => {
    if (roleParam) {
      if (roleParam.toUpperCase() === 'ADMIN') {
        setRole(Role.ADMIN);
      } else if (
        roleParam.toUpperCase() === 'INFLUENCER' ||
        roleParam.toUpperCase() === 'PUBLISHER'
      ) {
        setRole(Role.PUBLISHER);
      } else if (
        roleParam.toUpperCase() === 'MARKETER' ||
        roleParam.toUpperCase() === 'ADVERTISER'
      ) {
        setRole(Role.ADVERTISER);
      }
    } else {
      setRole(Role.PUBLISHER);
    }
  }, [roleParam]);

  const [role, setRole] = useState('');
  const images = ['logo', 'logo', 'logo'];

  const [_role, setRoles] = useState(role);
  useEffect(() => {
    if (role === Role.PUBLISHER) {
      setRoles(Role.INFLUENCER);
    }
    if (role === Role.ADVERTISER) {
      setRoles(Role.MARKETER);
    }
  }, [role]);
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Col lg={4} md={5} className="auth-by-email-container">
        <div
          className="back-btn mb-4"
          onClick={() => {
            history.push('/');
          }}
        >
          <i className="mdi mdi-arrow-left" /> {t('Back')}
        </div>

        <div className="mb-4 text-center">
          <Link to="/" className="d-block auth-logo">
            <img
              src={layoutMode === layoutTheme.DARKMODE ? logoWhite : logo}
              alt="AFbrother"
              height="28px"
              width="28px"
              style={{ transform: 'scale(8)' }}
            />
          </Link>
        </div>

        <div className="auth-content mt-4">
          <div className="text-center">
            {role === 'PUBLISHER' && (
              <h5 className="mb-0">{t(`Page.Welcome Back Influencer/Publisher`)}</h5>
            )}
            {role === 'ADVERTISER' && (
              <h5 className="mb-0">{t(`Page.Welcome Back Marketer/Advertiser`)}</h5>
            )}
            <p className="text-muted mt-2">
              {t('Page.Login To Continue To', {
                name: config.appName,
              })}
            </p>
          </div>
          {role !== Role.ADMIN && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '3px',
              }}
            >
              <div
                onClick={() => {
                  setRole(Role.PUBLISHER);
                }}
                className={` ${
                  role === Role.PUBLISHER ? 'circle-select-role' : 'deselect'
                }`}
              >
                <h4>Influencer</h4>
              </div>
              <div
                onClick={() => {
                  setRole(Role.ADVERTISER);
                }}
                className={`${
                  role === Role.ADVERTISER ? 'circle-select-role' : 'deselect'
                }`}
              >
                <h4>Marketer</h4>
              </div>
            </div>
          )}

          <RegisterByThirdParty role={role} path={path} />
          <div className="register-line">or</div>
          <div className="needs-validation custom-form pt-2">
            <LoginByEmail role={role} />
          </div>

          <div className="mt-3 text-center">
            <p className="text-muted mb-0">
              {t('Form.Dont have an account')}
              <Link to={`/register/${_role}`} className="text-primary fw-semibold">
                &nbsp;{t('Button.Register')}
              </Link>
            </p>
          </div>
          {/* <div className="register-line">or</div>

          <RegisterByThirdParty role={role} path={path} /> */}
        </div>
      </Col>
      <Col>
        <CarouselPage />
      </Col>
    </div>
  );
};

export default Login;
