import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import { logGraphDate } from '../../../utils/dateTime';
import { layoutTheme } from '../../../constants/layout';

const ProfitBalanceChart = ({ i18n, t }: any) => {
  const labels = [];
  const graphData = [];
  let maxAmount = 0;

  const { logTransactions, layoutMode } = useSelector((state: any) => ({
    logTransactions: state.SystemBalance.logTransactions,
    layoutMode: state.Layout.layoutMode,
  }));

  if (!isEmpty(logTransactions)) {
    for (let i = logTransactions.graphItems?.length - 1; i >= 0; i--) {
      const txn = logTransactions.graphItems[i];
      if (txn.amount > maxAmount) {
        maxAmount = txn.amount;
      }

      labels.push(logGraphDate(txn.day, txn.month, txn.year, i18n.language));
      graphData.push(txn.amount);
    }
  }

  const data = {
    labels,
    datasets: [
      {
        label: t('Balance'),
        fill: true,
        backgroundColor: 'rgba(0, 154, 120, 0.8)',
        data: graphData,
      },
    ],
  };

  const option = {
    responsive: true,
    legend: {
      labels: {
        fontColor: layoutMode === layoutTheme.DARKMODE ? '#adb5bd' : '#495057',
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: layoutMode === layoutTheme.DARKMODE ? '#adb5bd' : '#495057',
          },
          gridLines: {
            color: '#adb5bd',
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontColor: layoutMode === layoutTheme.DARKMODE ? '#adb5bd' : '#495057',
          },
          gridLines: {
            color: '#adb5bd',
          },
        },
      ],
    },
  };

  return <Line data={data} options={option} />;
};

export default withTranslation()(ProfitBalanceChart);
