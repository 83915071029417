import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader, Col, Row, FormGroup, Label } from 'reactstrap';
import * as Yup from 'yup';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAvailableNetwork, setLoading, unsetLoading } from 'src/store/actions';
import notify from 'src/utils/notify';
import { getCurrenciesByMarketerGQL } from 'src/graphql/common';
import cyptologo from 'src/assets/images/crypto/btc.svg';
import resizeImage from 'src/utils/resizeImage';
import CustomButton from '../Common/CustomButton';
import {
  createRequestNewOwnCurenncyGQL,
  uploadCurrencyImage,
} from '../../graphql/advertiser/own-currency';

interface ChildComponentProps {
  onTrigger: (data: string) => void;
}

const RequestCoinWithdraw: React.FC<ChildComponentProps> = ({ onTrigger }: any) => {
  const dispatch = useDispatch();
  const history = useDispatch();
  const { t } = useTranslation();
  const [isAddressValid, setValidAddress] = useState(true);
  const [selectCoin, setSelectCoin] = useState('USDT');
  const [currency, setCurrency] = useState<any[]>([]);
  const [srcLogo, setSrcLogo] = useState<any>(cyptologo);
  // useEffect(() => {
  //   dispatch(getCurrenciesByMarketerCurrencies());
  // }, []);
  const handleClick = (name: string) => {
    if (onTrigger) {
      onTrigger(name);
    }
  };

  const { currencies } = useSelector((state: any) => ({
    currencies: state.Currencies.availableNetworks,
  }));
  const coin = currency;
  const [formValue, setIntitailValues] = useState<any>({
    name: null,
    symbol: null,
    contract_address: null,
    network: 'BEP20',
    decimals: null,
    ref_link: null,
    currencyLogo: null,
  });
  const withdrawSchema: any = Yup.object().shape({});
  const renderAddressError = (errors: any) => {};
  useEffect(() => {
    dispatch(getAvailableNetwork());
    fetchData();
  }, []);

  const fetchData = async () => {
    const _currencies = await getCurrenciesByMarketerGQL();
    setCurrency(_currencies);
  };

  const imageProfile = () => {
    return (
      <img
        id="currency_image"
        src={srcLogo}
        alt="Header Avatar"
        className="image-profile"
        style={{ width: '80px', height: '80px' }}
      />
    );
  };

  const handleFileSelected = async (e: any): Promise<void> => {
    const files: any = Array.from(e.target.files);

    // const fileConfig: any = {
    //   file: files[0],
    //   maxSize: 500,
    // };
    //const file = await resizeImage(fileConfig);
    //dispatch(uploadProfile([file]));
    const file = files[0];

    // const newFile = await fixImageSize({ file, width: 600, height: 400 });
    const reader = new FileReader();
    const imageTag: any = document.getElementById('currency_image');
    // imageTag.title = file?.name;

    reader.onload = (event) => {
      imageTag.src = event?.target?.result;
      setSrcLogo(event?.target?.result);
    };
    reader.readAsDataURL(file);
    setIntitailValues({ ...formValue, currencyLogo: file });
    e.target.files = null;
  };

  const renderCurrencies = () => {
    const arr: any = [];
    for (let i = 0; i < currencies?.length; i++) {
      arr.push(<option value={currencies[i]}>{currencies[i]}</option>);
    }

    return arr;
  };
  const onSubmit = async (values: any, { resetForm, setSubmitting }: any) => {
    // const response = await createRequestNewOwnCurenncyGQL(formValue);
    // if (response) {
    //   notify(t('Create request coin success'), 'success');
    // }

    dispatch(setLoading());

    const submitValues = {
      name: formValue?.name,
      symbol: formValue?.symbol,
      contract_address: formValue?.contract_address,
      network: formValue?.network,
      decimals: formValue?.decimals,
      ref_link: formValue?.ref_link,
      logo: formValue?.currencyLogo,
    };

    if (!formValue?.currencyLogo) {
      notify(t('Please upload token logo'), 'error');
      dispatch(unsetLoading());
      return;
    }

    if (!submitValues.name) {
      notify(t('Please input name'), 'error');
      dispatch(unsetLoading());
      return;
    }
    if (!submitValues.symbol) {
      notify(t('Please input symbol'), 'error');
      dispatch(unsetLoading());
      return;
    }
    if (!submitValues.contract_address) {
      notify(t('Please input contract address'), 'error');
      dispatch(unsetLoading());
      return;
    }
    if (!submitValues.network) {
      notify(t('Please input network'), 'error');
      dispatch(unsetLoading());
      return;
    }
    if (!submitValues.decimals) {
      notify(t('Please input decimals'), 'error');
      dispatch(unsetLoading());
      return;
    }
    if (!submitValues.ref_link) {
      notify(t('Please input referal link'), 'error');
      dispatch(unsetLoading());
      return;
    }
    try {
      const file: any = formValue['currencyLogo'][0] || formValue['currencyLogo'];
      const newFile = await resizeImage({ file, maxSize: 600 });
      let thumbnail_cover: any;
      if (newFile) {
        thumbnail_cover = newFile;
      } else {
        thumbnail_cover = formValue['currencyLogo'];
      }

      const imageKey = await uploadCurrencyImage(thumbnail_cover);
      if (!imageKey) {
        notify(t('Cannot upload token logo'), 'error');
        dispatch(unsetLoading());
        return;
      }
      submitValues['logo'] = imageKey;
    } catch (error) {
      console.log('Upload Image', error);
      notify(t('Cannot upload token logo'), 'error');
      dispatch(unsetLoading());
    }

    await createRequestNewOwnCurenncyGQL(submitValues).then((result) => {
      if (result) {
        notify(t('Create request coin success'), 'success');
      }
    });
    setIntitailValues({
      name: null,
      symbol: null,
      contract_address: null,
      network: 'BEP20',
      decimals: 0,
      ref_link: '',
      currencyLogo: null,
    });
    setSrcLogo(cyptologo);
    dispatch(unsetLoading());
    useEffect(() => {
      fetchData();
    }, []);
    setIntitailValues(null);
    window.location.reload();
    // if (response) {
    //   notify(t('Create request coin success'), 'success');
    // }
  };
  return (
    <>
      <Card>
        <CardHeader>
          <h6>{t('Request your own coin')}</h6>
          <p className="card-title-desc">
            {t('Request your own coin to use in AFBrother platform')}
          </p>
          <h6 style={{ marginTop: '10px' }}>{t('Your own coin')}</h6>
          <div className="card-coin-container">
            {coin &&
              coin.map((item: any) => (
                <>
                  <div
                    onClick={() => {
                      setSelectCoin(item.name);
                      handleClick(item.name);
                    }}
                    className="card-coin static-color-coin"
                    key={item.key}
                  >
                    {item.name}
                  </div>
                </>
              ))}
          </div>
          {/* {renderCoin(coin)} */}
        </CardHeader>
        <CardBody>
          <Formik
            validationSchema={withdrawSchema}
            initialValues={formValue}
            onSubmit={onSubmit}
            render={({ errors, values, touched, setFieldValue, handleChange }) => (
              <FormikForm>
                <Row>
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <Label htmlFor="symbol">{t('Token Logo')}</Label>
                      <div className="flex-shrink-0">
                        <div
                          className="avatar-xl me-3"
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginLeft: '12px',
                          }}
                        >
                          {imageProfile()}
                          <div style={{ paddingRight: '15px' }}>
                            <label htmlFor="upload-input" className="upload-icon">
                              <i className="fas fa-pen" />
                              <input
                                id="upload-input"
                                onChange={handleFileSelected}
                                type="file"
                                className="upload-input"
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Label htmlFor="symbol">{t('Token Name')}</Label>
                      <Field
                        placeholder="e.g Bitcoin"
                        name="name"
                        type="text"
                        className={`form-control ${
                          !isAddressValid ? 'is-invalid' : ''
                        }`}
                        id="name"
                        onBlur={() => {}}
                        autocomplete="off"
                        value={formValue.name || ''}
                        onChange={(e: any) => {
                          setIntitailValues({
                            ...formValue,
                            name: e.currentTarget.value,
                          });
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Label htmlFor="symbol">{t('Token Symbol')}</Label>
                      <Field
                        placeholder="e.g BTC"
                        name="symbol"
                        type="text"
                        className={`form-control ${
                          !isAddressValid ? 'is-invalid' : ''
                        }`}
                        id="symbol"
                        onBlur={() => {}}
                        autocomplete="off"
                        value={formValue.symbol || ''}
                        onChange={(e: any) => {
                          setIntitailValues({
                            ...formValue,
                            symbol: e.currentTarget.value,
                          });
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Label htmlFor="symbol">{t('Withdraw Method')}</Label>
                      <Field
                        as="select"
                        name="network"
                        className={`form-control ${
                          !isAddressValid ? 'is-invalid' : ''
                        } arrow-dropdown`}
                        // onBlur={() => {
                        //   handleCheckAddress(values.address, values.symbol);
                        // }}
                        value={formValue.network}
                        onChange={(e: any) => {
                          console.log('network', e.currentTarget.value);
                          setIntitailValues({
                            ...formValue,
                            network: e.currentTarget.value,
                          });
                        }}
                      >
                        <option value="">{t('Select your withdraw method')}</option>
                        {renderCurrencies()}
                      </Field>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Label htmlFor="symbol">{t('Contract Address')}</Label>
                      <Field
                        placeholder="e.g 0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                        name="contract_address"
                        type="text"
                        className={`form-control ${
                          !isAddressValid ? 'is-invalid' : ''
                        }`}
                        id="contract_address"
                        onBlur={() => {}}
                        autocomplete="off"
                        value={formValue.contract_address || ''}
                        onChange={(e: any) => {
                          setIntitailValues({
                            ...formValue,
                            contract_address: e.currentTarget.value,
                          });
                        }}
                      />
                      <FormGroup style={{ margin: '15px 0 0 0' }}>
                        <Label htmlFor="symbol">{t('Decimal')}</Label>
                        <Field
                          placeholder="e.g 18"
                          name="decimals"
                          type="number"
                          className={`form-control ${
                            !isAddressValid ? 'is-invalid' : ''
                          }`}
                          step="any"
                          id="decimals"
                          onBlur={() => {}}
                          autocomplete="off"
                          value={formValue.decimals}
                          onChange={(e: any) => {
                            setIntitailValues({
                              ...formValue,
                              decimals: +e.currentTarget.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Label htmlFor="symbol">{t('Price reference link')}</Label>
                      <Field
                        placeholder="(e.g https://bscscan.com/token/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c)"
                        name="ref_link"
                        type="text"
                        className={`form-control ${
                          !isAddressValid ? 'is-invalid' : ''
                        }`}
                        id="Referal link"
                        onBlur={() => {}}
                        autocomplete="off"
                        value={formValue.ref_link || ''}
                        onChange={(e: any) => {
                          setIntitailValues({
                            ...formValue,
                            ref_link: e.currentTarget.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <CustomButton
                  size="xxs"
                  status="submit"
                  label={t('Button.Submit')}
                />
              </FormikForm>
            )}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default withTranslation()(RequestCoinWithdraw);
