import { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import CoinImage from '../Common/CoinImage';
import WithdrawStatus from '../Common/WithdrawStatus';
import { transformAddressInShortFrom } from '../../utils/address';
import { transformStringAmountInput } from '../../utils/input';
import transactionModalTypes from '../../constants/transactionModalTypes';
import withdrawStatuses from '../../constants/withdrawStatuses';
import { splitUnderscoreWithTuple } from '../../utils/currency';
import ChecksumBadge from '../Common/ChecksumBadge';
import { roleText } from '../../constants/role';
import DateTimeTypes from '../../constants/dateTimeTypes';
import DateTimeComponent from '../Common/DateTimeComponent';

const TransactionDetailModal = ({
  title,
  isOpen,
  handleToggle,
  handleClose,
  data,
  t,
  handleApproveWithdraw,
  handleCancelWithdraw,
  modalType,
}: any) => {
  const [copied1, setCopied1] = useState(false);
  const [copied2, setCopied2] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied1) setCopied1(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [copied1]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied2) setCopied2(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [copied2]);

  const { user } = useSelector((state: any) => ({
    user: state.login.user,
  }));

  const renderExplorerLink = () => {
    return modalType === transactionModalTypes.WITHDRAW &&
      data.status !== withdrawStatuses.APPROVED ? (
      '-'
    ) : (
      <Link
        to={{
          pathname: `${data.currency.explorer_link}${data.real_txHash}`,
        }}
        target="_blank"
      >
        <i className="fas fa-external-link-alt" /> {t('table:Explorer Detail')}
      </Link>
    );
  };

  const handleCopy = (val: string, copyButton: number) => {
    navigator.clipboard.writeText(val);

    if (copyButton === 1) {
      setCopied1(true);
    } else if (copyButton === 2) {
      setCopied2(true);
    }
  };

  const onCancelWithdraw = () => {
    handleCancelWithdraw(data);
  };

  const onApproveWithdraw = () => {
    handleApproveWithdraw(data);
  };

  return (
    <Modal size="lg" isOpen={isOpen} toggle={handleToggle}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {title}
        </h5>
        <button
          type="button"
          onClick={handleClose}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body p-0">
        <div className="table-responsive">
          <table className="table table-nowrap mb-0">
            {data && (
              <tbody>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:ID')}:
                  </th>
                  <td>{data.uuid}</td>
                </tr>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:Transaction Hash')}:
                  </th>
                  <td>
                    {transformAddressInShortFrom(data.tx_hash, 15)}{' '}
                    {!copied1 ? (
                      <i
                        className="far fa-copy"
                        onClick={() => {
                          handleCopy(data.tx_hash, 1);
                        }}
                      />
                    ) : (
                      <>
                        <i className="fas fa-check-circle" /> Copied
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:Status')}:
                  </th>
                  <td>
                    <WithdrawStatus status={data.status} />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:Timestamp')}:
                  </th>
                  <td>
                    <code>
                      <DateTimeComponent
                        dateTime={data.updated_at}
                        dateTimeType={DateTimeTypes.TRANSACTION_DETAIL_DATE_TIME}
                      />
                    </code>
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:Email')}:
                  </th>
                  <td colSpan={6}>{data.user.email}</td>
                </tr>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:To')}:
                  </th>
                  <td colSpan={6}>
                    {data.address}{' '}
                    {!copied2 ? (
                      <i
                        className="far fa-copy"
                        onClick={() => {
                          handleCopy(data.address, 2);
                        }}
                      />
                    ) : (
                      <>
                        <i className="fas fa-check-circle" /> Copied
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:Amount')}:
                  </th>
                  <td colSpan={6}>
                    <div className="d-flex align-items-center">
                      <span className="m">
                        {transformStringAmountInput(data.CRYPTO_amount)}
                      </span>
                      <span className="mx-2">
                        ({transformStringAmountInput(data.FIAT_amount)}{' '}
                        {data.currency.main_symbol})
                      </span>
                      <CoinImage size="sm" symbol={data.currency.symbol} />
                      <span className="mx-2">
                        {data.currency.name}&nbsp;(
                        {splitUnderscoreWithTuple(data.currency.symbol)})
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:Fiat Rate')}:
                  </th>
                  <td colSpan={6}>
                    {transformStringAmountInput(data.FIAT_rate)} (
                    {splitUnderscoreWithTuple(data.currency.symbol)}
                    -USD)
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap" scope="row">
                    {t('table:Explorer Link')}:
                  </th>
                  <td colSpan={6}>{renderExplorerLink()}</td>
                </tr>
                {user.role === roleText.ADMIN && (
                  <tr>
                    <th className="text-nowrap" scope="row">
                      {t('table:Checksum')}:
                    </th>
                    <td colSpan={6}>
                      <ChecksumBadge checksum_status={data.checksum_status} />
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
      {modalType === transactionModalTypes.WITHDRAW &&
      !isEmpty(data) &&
      data.status === withdrawStatuses.PENDING ? (
        <div className="modal-footer d-flex justify-content-center">
          {user.role === roleText.ADMIN && (
            <button
              type="button"
              onClick={onApproveWithdraw}
              className="btn btn-primary waves-effect btn-label waves-light"
            >
              <i className="bx bx-check-double label-icon" />
              {t('table:Approve')}
            </button>
          )}
          <button
            type="button"
            onClick={onCancelWithdraw}
            className="btn btn-danger waves-effect btn-label waves-light"
            data-dismiss="modal"
          >
            <i className="bx bx-trash label-icon" />
            {t('table:Reject')}
          </button>
        </div>
      ) : (
        ''
      )}
    </Modal>
  );
};

export default withTranslation()(TransactionDetailModal);
