import { gql } from '@apollo/client';
import config from 'src/config';
import Role from 'src/constants/enum/role';

import i18n from 'src/i18n';
import notify from 'src/utils/notify';
import { client } from '../client';
import { logout } from '../common/auth';

const jobResData = `
key
status
name
isDisableEdit
social_media_type
product_detail
link_download_data
buyable_product_location
product_price
content_type
review_format
work_order
target_group
product_name
market_challenge
highlight_of_product
forbidden_phrase
thumbnail_cover
hashtag
link_accompanying_work
brands_prohibit
is_review_before_posting
date_submission
last_day_accept_job
last_day_approve_job
last_day_influencer_submit
last_day_to_approve
influencer_post_internal_job
finish_internal_work
link_share
message_to_influencer
link_accompanying_work
brands_prohibit
job_type
job_requirement {
  min_budget
  max_budget
  is_male
  is_female
  is_male_lgbt
  is_female_lgbt
  min_age
  max_age
  fixed_price
  aptitudes{
    id
    name
  }
  countries{
    name
  }
}
currency_id
currency {
  symbol
  network
  rate_in_USD
  price_in_USDT
  is_marketer_currency
}
marketer_campaign {
  key
  name
  brand_name
  product_name
  product_detail
  user {
    user_info {
      company
    }
  }
  marketerCategories {
    name
    id
  }
}
social_media_has_jobs {
  key
  status
  price
  social_media_info {
    key
    follower
    share_content_price
    photo_content_price
    video_content_price
  }
}`;

const jobSummaryResData = `
  key
  status
  name
  isDisableEdit
  social_media_type
  product_detail
  link_download_data
  buyable_product_location
  product_price
  content_type
  review_format
  work_order
  target_group
  product_name
  market_challenge
  highlight_of_product
  forbidden_phrase
  thumbnail_cover
  hashtag
  link_accompanying_work
  brands_prohibit
  is_review_before_posting
  date_submission
  last_day_accept_job
  last_day_approve_job
  last_day_influencer_submit
  last_day_to_approve
  influencer_post_internal_job
  finish_internal_work
  link_share
  message_to_influencer
  link_accompanying_work
  brands_prohibit
  job_type
  job_requirement {
      min_budget
      max_budget
      is_male
      is_female
      is_male_lgbt
      is_female_lgbt
      min_age
      max_age
      fixed_price
      aptitudes{
          id
          name
      }
      countries{
          name
      }
  }
  marketer_campaign {
      key
      name
      brand_name
      product_name
      product_detail
      user {
          user_info {
              company
          }
          image_storage {
            key
          }
      } 
  }
  currency {
    id
    symbol
    network
    rate_in_USD
    price_in_USDT
    is_marketer_currency
    main_symbol
    contract_address
  }
  budget
  follower
  totalInfluencer
  job_tasks {
    key
    title
    description
    percent
    order
    task_type {
      id
      content_type
      socialmedia_type
    }
  }
  is_gleam_job
  timezone
  timezone_offset
  `;

export const createJobGql = async (param: any) => {
  const mutation = gql`
    mutation createJob($param: CreateJobInput!) {
      createJob(createJobInput: $param) {
        key
        name
        social_media_type
        content_type
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { param },
    });
    return res.data.createJob;
  } catch (error) {
    return null;
  }
};

export const getJobGql = async (key: any) => {
  const query = gql`
    query getJob($key: String!) {
      getJob(key: $key) {
        ${jobResData}
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { key },
      fetchPolicy: 'no-cache',
    });

    return res.data.getJob;
  } catch (error: any) {
    if (error?.message !== 'Unauthorized')
      notify(i18n.t('error:Get marketer category error .'), 'error');
    return null;
  }
};

export const getJobSummaryGql = async (key: any) => {
  const query = gql`
    query getJobSummary($key: String!) {
      getJobSummary(key: $key) {
        ${jobSummaryResData}
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { key },
      fetchPolicy: 'no-cache',
    });

    return res.data.getJobSummary;
  } catch (error: any) {
    if (error?.message !== 'Unauthorized')
      notify(i18n.t('error:Get marketer category error .'), 'error');
    return null;
  }
};

export const createJobGoalGql = async (name: string) => {
  const mutation = gql`
    mutation CreateJobGoal($name: String!) {
      createJobGoal(name: $name) {
        name
      }
    }
  `;

  try {
    await client.mutate({
      mutation,
      variables: {
        name,
      },
    });
    notify(i18n.t('success:success'), 'success');
  } catch (error: any) {
    if (error?.message !== 'Unauthorized') notify(i18n.t('error:error'), 'error');
  }
};

export const updateJobGql = async (param: any) => {
  const mutation = gql`
    mutation updateJob($param: UpdateJobInput!) {
      updateJob(updateJobInput: $param) {
        ${jobResData}
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { param },
      fetchPolicy: 'no-cache',
    });
    return res.data.updateJob;
  } catch (error) {
    return null;
  }
};

export const removeJobImageByKey = async (key: string) => {
  const mutation = gql`
    mutation removeImageByKey($key: String!) {
      removeImageByKey(key: $key, imageType: "jobThumbnail") {
        key
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { key },
    });
    return res.data.updateJob;
  } catch (error) {
    return null;
  }
};

const filterJobRes = gql`
  query JobFilterTable($param: FilterJobInput!) {
    JobFilterTable(filterJobInput: $param) {
      items {
        key
        name
        social_media_type
        content_type
        review_format
        work_order
        target_group
        product_name
        market_challenge
        highlight_of_product
        forbidden_phrase
        hashtag
        status
        thumbnail_cover
        link_accompanying_work
        brands_prohibit
        isDisableEdit
        job_type
        social_media_has_jobs {
          price
          social_media_info {
            name
            follower
          }
        }
        currency {
          symbol
          network
          rate_in_USD
          price_in_USDT
          is_marketer_currency
          main_symbol
        }
      }
      totalCount
      statusCount {
        status
        count
      }
    }
  }
`;

const filterJobResAdmin = gql`
  query JobFilterTable($param: FilterJobInput!) {
    JobFilterTable(filterJobInput: $param) {
      items {
        key
        name
        status
        created_at
        verify_status
        social_media_type
        is_gleam_job
        marketer_campaign {
          name
          user {
            user_info {
              company
            }
          }
        }
        social_media_has_jobs {
          key
          price
          tax
          rating
          example_message
          link_post
          verify_status
          rating
        }
      }
      totalCount
      statusCount {
        status
        count
      }
    }
  }
`;

export const filterGql = async (param: any) => {
  const newParam = { ...param };
  const query: any =
    param.userRole !== Role.ADMIN ? filterJobRes : filterJobResAdmin;

  delete newParam.userRole;

  try {
    const res = await client.query({
      query,
      variables: { param: newParam },
      fetchPolicy: 'no-cache',
    });
    return res.data.JobFilterTable;
  } catch (error: any) {
    if (error?.message !== 'Unauthorized')
      notify(i18n.t('error:Get Job error .'), 'error');
    return null;
  }
};

export const findSocialsByJobKeyGql = async (job_key: any) => {
  const query = gql`
    query findSocialsByJobKey($job_key: String!) {
      findSocialsByJobKey(job_key: $job_key) {
        key
        photo_content_price
        share_content_price
        video_content_price
        follower
        post_engagement
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: job_key,
      fetchPolicy: 'no-cache',
    });
    return res.data.findSocialsByJobKey;
  } catch (error) {
    return [];
  }
};

export const DisableJobEdit = async (key: string) => {
  const mutation = gql`
    mutation disableJobEdit($key: String!) {
      disableJobEdit(key: $key)
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { key },
    });
    if (res.data.disableJobEdit) {
      notify(i18n.t('success:Your Job Has been sent to influencer.'), 'success');
    } else {
      notify(i18n.t('error:Your Job Has not been sent to influencer.'), 'error');
    }
    return res.data.disableJobEdit;
  } catch (error: any) {
    if (error?.message !== 'Unauthorized')
      notify(i18n.t('error:Your Job Has not been sent to influencer.'), 'error');
    return false;
  }
};

export const uploadJobThumbnailImage = async (fileData: any, key: string) => {
  const file = fileData[0] || fileData;

  const formData = new FormData();
  formData.append(
    'operations',
    `{"query":"mutation uploadJobThumbnailImage($file:Upload!,$key:String) {\\n uploadJobThumbnailImage(file:$file,key:$key)\\n}", "variables": { "file": null ,"key": "${key}"}}\n`,
  );
  formData.append('map', '{ "0": ["variables.file"] }');
  formData.append('0', file);

  const access_token = localStorage.getItem('access-token');
  if (access_token) {
    const headers = new Headers();
    headers.append('authorization', `Bearer ${access_token}`);

    try {
      const res = await fetch(<any>config.graphql.URI, {
        method: 'POST',
        headers,
        body: formData,
        redirect: 'follow',
      });
      const resText = await res.text();
      const resData = JSON.parse(resText);

      return resData.data.uploadJobThumbnailImage;
    } catch (error: any) {
      if (error?.message !== 'Unauthorized')
        notify(i18n.t('error:Upload image error .'), 'error');
      return null;
    }
  } else {
    await logout();
    localStorage.removeItem('access-token');
    localStorage.removeItem('last-silent-refresh');
    window.location.href = '/';
    return null;
  }
};

export const createMarketerJobGql = async (param: any) => {
  const mutation = gql`
    mutation createMarketerJob($param: CreateJobMarketerInput!) {
      createMarketerJob(createJobInput: $param) {
        items {
          key
          name
          social_media_type
          content_type
        }
        totalCount
      }
    }
  `;

  try {
    const res = await client.mutate({
      mutation,
      variables: { param },
    });
    return res.data.createMarketerJob;
  } catch (error) {
    return null;
  }
};

export const getSuggestJobOnHomePageGql = async () => {
  const query = gql`
    query getSuggestJobOnHomePage {
      getSuggestJobOnHomePage {
        items {
          name
          social_media_type
          content_type
          job_type
          thumbnail_cover
          last_day_accept_job
          job_tasks {
            title
            task_type {
              socialmedia_type
            }
          }
          marketer_campaign {
            name
            brand_name
            user {
              email
              user_info {
                company
              }
            }
          }
          currency {
            main_symbol
          }
        }
        totalCount
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      fetchPolicy: 'cache-first',
    });
    return res.data.getSuggestJobOnHomePage;
  } catch (error) {
    return null;
  }
};

export const getMarketerJobCountGQL = async () => {
  const query = gql`
    query MarketerJobCount {
      marketerJobCount {
        activeJobs
        allJobs
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      fetchPolicy: 'cache-first',
    });
    return res.data.marketerJobCount;
  } catch (error) {
    return { activeJobs: 0, allJobs: 0 };
  }
};

export const createGleamJobGQL = async (input: any) => {
  const mutation = gql`
    mutation createGleamJob($input: CreateMarketerJobInput!) {
      createGleamJob(input: $input) {
        key
      }
    }
  `;
  try {
    const response = await client.mutate({
      mutation,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return response.data.createGleamJob;
  } catch (error) {
    return null;
  }
};

export const updateGleamJobGQL = async (input: any) => {
  const mutation = gql`
    mutation updateGleamJob($input: UpdateMarketerJobInput!) {
      updateGleamJob(input: $input) {
        key
      }
    }
  `;
  try {
    const response = await client.mutate({
      mutation,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return response.data.updateGleamJob;
  } catch (error) {
    return null;
  }
};

export const getGleamJobSummaryGQL = async (key: string) => {
  const query = gql`
    query getGleamJobSummary($key: String!) {
      getGleamJobSummary(key: $key) {
        key
        verify_status
        status
        isDisableEdit
        name
        job_type
        social_media_type
        content_type
        work_order
        thumbnail_cover
        date_submission
        last_day_accept_job
        last_day_approve_job
        last_day_influencer_submit
        last_day_to_approve
        finish_internal_work
        marketer_campaign {
          name
        }
        job_requirement {
          is_female
          is_male
          is_lgbt
          aptitudes {
            name
          }
          countries {
            name
          }
          fixed_price
          topup_percent
        }
        job_tasks {
          key
          title
          description
          percent
          task_type {
            id
            content_type
            socialmedia_type
          }
          order
        }
        is_gleam_job
        timezone
        timezone_offset
        totalInfluencer
        budget
        follower
      }
    }
  `;
  try {
    const response = await client.query({
      query,
      variables: { key },
      fetchPolicy: 'no-cache',
    });
    return response.data.getGleamJobSummary;
  } catch (error) {
    console.log('getGleamJobSummary', error);
    return null;
  }
};

export const getAvaliableSocialForGleamJobGQL = async (key: string) => {
  const query = gql`
    query getAvaliableSocialForGleamJob($key: String!) {
      getAvaliableSocialForGleamJob(key: $key) {
        job {
          key
          verify_status
          status
          isDisableEdit
          name
          job_type
          work_order
          thumbnail_cover
          date_submission
          last_day_accept_job
          last_day_approve_job
          last_day_influencer_submit
          last_day_to_approve
          last_day_approve_job
          finish_internal_work
          marketer_campaign {
            name
          }
          job_requirement {
            is_female
            is_male
            is_lgbt
            aptitudes {
              name
            }
            countries {
              name
            }
            fixed_price
            topup_percent
          }
          job_tasks {
            key
            title
            description
            percent
            task_type {
              content_type
              socialmedia_type
            }
            order
          }
          currency {
            symbol
            network
            rate_in_USD
            price_in_USDT
            is_marketer_currency
            main_symbol
            contract_address
          }
          is_gleam_job
          timezone
          timezone_offset
        }
        social_media_infos {
          items {
            social_id
            key
            name
            image_profile_url
            profile_path
            social_media_type
            share_content_price
            photo_content_price
            video_content_price
          }
          totalCount
        }
      }
    }
  `;
  try {
    const response = await client.query({
      query,
      variables: { key },
      fetchPolicy: 'cache-first',
    });
    return response.data.getAvaliableSocialForGleamJob;
  } catch (error) {
    return null;
  }
};

export const getAvaliableSocialForGleamJobPublicGQL = async (key: string) => {
  const query = gql`
    query getAvaliableSocialForGleamJobPublic($key: String!) {
      getAvaliableSocialForGleamJobPublic(key: $key) {
        job {
          key
          verify_status
          status
          isDisableEdit
          name
          job_type
          work_order
          thumbnail_cover
          date_submission
          last_day_accept_job
          last_day_approve_job
          last_day_influencer_submit
          last_day_to_approve
          last_day_approve_job
          finish_internal_work
          marketer_campaign {
            name
          }
          job_requirement {
            is_female
            is_male
            is_lgbt
            aptitudes {
              name
            }
            countries {
              name
            }
            fixed_price
            topup_percent
          }
          job_tasks {
            key
            title
            description
            percent
            task_type {
              content_type
              socialmedia_type
            }
            order
          }
          currency {
            symbol
            network
            rate_in_USD
            price_in_USDT
            is_marketer_currency
            main_symbol
            contract_address
          }
          is_gleam_job
          timezone
          timezone_offset
        }
        social_media_infos {
          items {
            social_id
            key
            name
            image_profile_url
            profile_path
            social_media_type
            share_content_price
            photo_content_price
            video_content_price
          }
          totalCount
        }
      }
    }
  `;
  try {
    const response = await client.query({
      query,
      variables: { key },
      fetchPolicy: 'cache-first',
    });
    return response.data.getAvaliableSocialForGleamJobPublic;
  } catch (error) {
    return null;
  }
};

const filterGlemJobSummary = `
items {
  key
  verify_status
  status
  isDisableEdit
  name
  job_type
  work_order
  thumbnail_cover
  date_submission
  last_day_accept_job
  last_day_approve_job
  last_day_influencer_submit
  last_day_to_approve
  last_day_approve_job
  finish_internal_work
  marketer_campaign {
      name
  }
  job_requirement {
      is_female
      is_male
      is_lgbt
      aptitudes {
        id
        name
      }
      countries {
        name
      }
      fixed_price
      topup_percent
  }
  job_tasks {
      key
      title
      description
      percent
      task_type {
          content_type
          socialmedia_type
      }
      order
  }
  is_gleam_job
  timezone
  timezone_offset
  social_media_has_jobs {
    price
    status
    social_media_info_id
    social_media_info {
      name
      follower
    }
    post_date
    marketer_approve_date
  }
  currency {
    symbol
    network
    rate_in_USD
    price_in_USDT
    is_marketer_currency
    main_symbol
  }
  report_status
  ban_reason
}
totalCount
statusCount {
  status
  count
}
`;
export const filterGleamJobGQL = async (param: any) => {
  const query = gql`
    query jobGleamFilterTable($filterJobInput: FilterGleamJobInput!) {
      jobGleamFilterTable(filterJobInput: $filterJobInput) {
       ${filterGlemJobSummary}
      }
    }
  `;
  try {
    const response = await client.query({
      query,
      variables: { filterJobInput: param },
      fetchPolicy: 'no-cache',
    });
    return response.data.jobGleamFilterTable;
  } catch (error) {
    return null;
  }
};

export const filterGleamSuggestJob = async (input: any) => {
  const query = gql`
    query filterGleamSuggestJob($input: filterFromRequirements!) {
      filterGleamSuggestJob(input: $input) {
        items {
          key
          name
          company
          last_day_accept_job
          thumbnail_cover
          social_media_types
          taksCount
          fixed_price
          symbol
        }
        totalCount
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { input },
      fetchPolicy: 'cache-first',
    });
    return res.data.filterGleamSuggestJob;
  } catch (error: any) {
    if (error?.message !== 'Unauthorized')
      notify(i18n.t('error:Get filterFromRequirements error .'), 'error');
    return null;
  }
};

export const gleamJobDataForEditGQL = async (key: string) => {
  const query = gql`
    query gleamJobDataForEdit($key: String!) {
      gleamJobDataForEdit(key: $key) {
        key
        name
        thumbnail_cover
        work_order
        date_submission
        last_day_accept_job
        last_day_influencer_submit
        last_day_to_approve
        last_day_approve_job
        credit_term
        finish_internal_work
        job_type
        is_male
        is_female
        is_lgbt
        country_names
        aptitude_ids
        fixed_price
        topup_percent
        job_tasks {
          key
          title
          description
          percent
          task_type_id
          task_type {
            id
            content_type
            socialmedia_type
          }
          order
        }
        timezone
        timezone_offset
        is_gleam_job
        currency_id
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { key },
      fetchPolicy: 'no-cache',
    });
    return res.data.gleamJobDataForEdit;
  } catch (error) {
    return null;
  }
};

export const followUpGQL = async (input: any) => {
  const query = gql`
    query followUp($input: FollowUpInput!) {
      followUp(input: $input) {
        pendingAccept
        pendingReviewApprove
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return res.data.followUp;
  } catch (err) {
    return null;
  }
};

export const userReportJobGQL = async (input: any) => {
  const mutation = gql`
    mutation userReportJob($input: ReportJobInput!) {
      userReportJob(input: $input) {
        id
        user_id
        job_id
        reportType
        created_at
      }
    }
  `;
  try {
    const res = await client.mutate({
      mutation,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return res.data.userReportJob;
  } catch (error) {
    return null;
  }
};

export const checkUserhasReportGQL = async (key: any) => {
  const query = gql`
    query checkUserhasReport($key: String!) {
      checkUserhasReport(key: $key)
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { key },
      fetchPolicy: 'no-cache',
    });
    return res.data.checkUserhasReport;
  } catch (error) {
    return null;
  }
};

export const filterReportJobGQL = async (input: any) => {
  const query = gql`
    query filterReportJob($input: FilterReportJobInput!) {
      filterReportJob(input: $input) {
        items {
          id
          name
          key
          report_status
          reportJobCount
          status
          marketer_campaign {
            id
            user {
              uuid
              email
            }
          }
        }
        totalCount
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return res.data.filterReportJob;
  } catch (error) {
    return null;
  }
};

export const filterReportJobDetailGQL = async (input: any) => {
  const query = gql`
    query filterReportJobDetail($input: FilterReportJobDetailInput!) {
      filterReportJobDetail(input: $input) {
        items {
          reportType
          user {
            uuid
            email
          }
          job_id
          job {
            name
          }
        }
        totalCount
      }
    }
  `;
  try {
    const res = await client.query({
      query,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return res.data.filterReportJobDetail;
  } catch (error) {
    return null;
  }
};

export const ignoreReportJobGQL = async (job_id: number) => {
  const mutation = gql`
    mutation ignoreReportJob($job_id: Int!) {
      ignoreReportJob(job_id: $job_id) {
        key
        name
      }
    }
  `;
  try {
    const res = await client.mutate({
      mutation,
      variables: { job_id },
      fetchPolicy: 'no-cache',
    });
    return res.data.ignoreReportJob;
  } catch (error) {
    return null;
  }
};

export const banReportJobSQL = async (input: any) => {
  const mutation = gql`
    mutation banReportJob($input: BanReportJobInput!) {
      banReportJob(input: $input) {
        key
        name
      }
    }
  `;
  try {
    const res = await client.mutate({
      mutation,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return res.data.banReportJob;
  } catch (error) {
    return null;
  }
};
