import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import Swal from 'sweetalert2';
import { isEmpty } from 'lodash';
import choice from 'src/assets/images/choice.png';
import { withTranslation } from 'react-i18next';
import {
  approveWithdraw as onApproveWithdraw,
  getWithdrawRequest as onGetWithdrawRequest,
  rejectWithdraw as onRejectWithdraw,
} from '../../store/finance/withdraw/actions';
import './WithdrawHistory.style.scss';
import CoinImage from '../Common/CoinImage';
import CurrencyOptions from '../Table/CurrencyOptions';
import CustomTable from '../Common/CustomTable';
import DateTimeComponent from '../Common/DateTimeComponent';
import DateTimeTypes from '../../constants/dateTimeTypes';
import SearchBar from '../Table/SearchBar';
import TransactionDetailModal from '../Table/TransactionDetailModal';
import WithdrawStatus from '../Common/WithdrawStatus';
import WithdrawStatusOptions from '../Table/WithdrawStatusOptions';
import WithdrawStatuses from '../../constants/withdrawStatuses';
import { splitWordsWithUnderscore } from '../../utils/currency';
import transactionModalTypes from '../../constants/transactionModalTypes';
import { transformAddressInShortFrom } from '../../utils/address';
import { transformStringAmountInput } from '../../utils/input';
import withdrawStatusesEnum from '../../constants/withdrawStatusesEnum';

const WithdrawHistory = ({ t, tab }: any) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [currency, setCurrency] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState(withdrawStatusesEnum.ALL);
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');

  useEffect(() => {
    dispatch(onGetWithdrawRequest({ limit: 10, page: 1 }));
  }, [tab]);

  const { txn } = useSelector((state: any) => ({
    txn: state.Withdraw.transactions,
  }));

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleGetDetail = (rowIndex: any) => {
    setSelectedRow(rowIndex);
    setModalOpen(!modalOpen);
  };

  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('table:Date'),
        accessor: ({ created_at }) => {
          return (
            <div style={{ marginTop: '1rem', width: '200px' }}>
              <DateTimeComponent
                dateTime={created_at}
                dateTimeType={DateTimeTypes.GET_DATE_TIME_FULL_FORMAT}
              />
            </div>
          );
        },
        id: 'created_at',
      },
      {
        Header: t('table:Currency'),
        // eslint-disable-next-line no-shadow
        accessor: ({ currency }) => {
          return (
            <div style={{ marginTop: '0.5rem' }}>
              <CoinImage symbol={currency.symbol} />
            </div>
          );
        },
        id: 'currency',
        disableSortBy: true,
      },
      {
        Header: t('table:Crypto Amount'),
        // eslint-disable-next-line camelcase
        // eslint-disable-next-line no-shadow
        accessor: ({ CRYPTO_amount, currency }) => {
          return (
            <div style={{ marginTop: '1rem' }}>
              {transformStringAmountInput(CRYPTO_amount)}{' '}
              {splitWordsWithUnderscore(currency.symbol)}
            </div>
          );
        },
        id: 'CRYPTO_amount',
      },
      {
        Header: t('table:Crypto Fiat'),
        // eslint-disable-next-line camelcase
        accessor: ({ FIAT_amount }) => {
          return (
            <>
              {' '}
              <div style={{ marginTop: '1rem' }}>
                {transformStringAmountInput(FIAT_amount)} USDT
              </div>
            </>
          );
        },
        id: 'FIAT_amount',
      },
      {
        Header: t('table:Dest Address'),
        accessor: ({ address }) => {
          return (
            <div style={{ marginTop: '1rem' }}>
              {transformAddressInShortFrom(address, 12)}
            </div>
          );
        },
        id: 'dest_address',
        disableSortBy: true,
      },
      {
        Header: t('table:Status'),
        // eslint-disable-next-line no-shadow
        accessor: ({ status }) => {
          return (
            <div style={{ marginTop: '1rem' }}>
              <WithdrawStatus status={status} />
            </div>
          );
        },
        id: 'status',
        disableSortBy: true,
      },
      {
        Header: t('table:Action'),
        accessor: (originalRow, rowIndex) => {
          return (
            <>
              <UncontrolledDropdown>
                <DropdownToggle className="text-muted font-size-28" tag="a">
                  <i className="fas fa-file-alt fa-1x c-pointer" />
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-start">
                  {originalRow.status === WithdrawStatuses.PENDING && (
                    <>
                      <DropdownItem
                        onClick={() => {
                          handleCancelWithdraw(originalRow);
                        }}
                      >
                        <i className="fas fa-trash icon-red" />
                        &nbsp;&nbsp;{t('table:Reject')}
                      </DropdownItem>
                      <div className="dropdown-divider" />
                    </>
                  )}
                  <DropdownItem
                    onClick={() => {
                      handleGetDetail(rowIndex);
                    }}
                  >
                    <i className="fas fa-file-alt" />
                    &nbsp;&nbsp;{t('table:Detail')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          );
        },
        id: 'action',
        disableSortBy: true,
      },
    ],
    [t, txn, modalOpen],
  );

  const data = React.useMemo(() => {
    return txn.items;
  }, [txn]);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...getDefaultPayload(newPage),
    };

    dispatch(onGetWithdrawRequest(payload));
  };

  // eslint-disable-next-line no-shadow
  const handleCurrencyChange = (currency: string) => {
    const payload = {
      ...getDefaultPayload(1),
      currencySymbol: currency,
    };

    dispatch(onGetWithdrawRequest(payload));
    setCurrency(currency);
  };

  const handleSearch = (val: string) => {
    const payload = {
      ...getDefaultPayload(1),
      search: val,
    };

    dispatch(onGetWithdrawRequest(payload));
    setSearch(val);
  };

  const handleClear = () => {
    const payload = {
      ...getDefaultPayload(1),
      search: '',
    };

    dispatch(onGetWithdrawRequest(payload));
    setSearch('');
  };

  // eslint-disable-next-line no-shadow
  const handleStatusChange = (status: string) => {
    const payload = {
      ...getDefaultPayload(1),
      status,
    };

    dispatch(onGetWithdrawRequest(payload));
    setStatus(status);
  };

  // eslint-disable-next-line no-shadow
  const getDefaultPayload = (page: number) => {
    setPage(page);

    return {
      limit: pageSize,
      page,
      currencySymbol: currency,
      search,
      status,
    };
  };

  const handleCancelWithdraw = (row: any) => {
    Swal.fire({
      title: t('swal:Are you sure'),
      text: t('swal:Withdraw Reject Confirm', {
        amount: row.FIAT_amount,
        symbol: row.currency.symbol,
        address: transformAddressInShortFrom(row.address, 6),
      }),
      imageUrl: choice,
      width: 300,
      imageHeight: 200,
      imageAlt: 'A tall image',
      customClass: {},
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('button:No'),
      confirmButtonColor: '#009a78',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(onRejectWithdraw(row.uuid));

        if (modalOpen) handleModalToggle();
      }
    });
  };

  const handleApproveWithdraw = (row: any) => {
    Swal.fire({
      title: t('swal:Are you sure'),
      text: t('swal:Withdraw Approve Confirm', {
        amount: row.FIAT_amount,
        symbol: row.currency.symbol,
        address: transformAddressInShortFrom(row.address, 6),
      }),
      imageUrl: choice,
      imageHeight: 200,
      imageAlt: 'A tall image',
      cancelButtonColor: 'red',
      showCancelButton: true,
      confirmButtonText: t('button:Yes'),
      cancelButtonText: t('button:No'),
      confirmButtonColor: '#009a78',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(onApproveWithdraw(row.uuid));

        if (modalOpen) handleModalToggle();
      }
    });
  };

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(newPage),
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(onGetWithdrawRequest(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
    },
    [status, search, currency, page, pageSize],
  );

  const hiddenOnXS: any = ['created_at', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnSM: any = ['CRYPTO_amount', 'dest_address'];
  const hiddenOnMD: any = ['dest_address'];
  const hiddenOnLG: any = [];

  return (
    <>
      <CustomTable
        headerCSS="fw-normal bg-header-table"
        columns={columns}
        data={data || []}
        page={page}
        pageSize={pageSize}
        total={txn.totalCount}
        handlePageChange={handlePageChange}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
        onSort={handleSort}
      >
        <TransactionDetailModal
          data={!isEmpty(txn) ? txn.items[selectedRow] : null}
          isOpen={modalOpen}
          handleToggle={handleModalToggle}
          handleClose={handleModalClose}
          title={t('table:Withdrawal Detail')}
          modalType={transactionModalTypes.WITHDRAW}
          handleApproveWithdraw={handleApproveWithdraw}
          handleCancelWithdraw={handleCancelWithdraw}
        />
        <div className="withdraw-history-container">
          <div className="status-container">
            <div className="withdraw-status">
              <WithdrawStatusOptions
                defaultValue={status}
                handleStatusChange={handleStatusChange}
              />
            </div>
            <div className="currency-option">
              <CurrencyOptions
                defaultValue={currency}
                handleCurrencyChange={handleCurrencyChange}
              />
            </div>
          </div>
          <div>
            <SearchBar
              placeholder={t('table:Search Placeholder')}
              handleSearch={handleSearch}
              handleClear={handleClear}
            />
          </div>
        </div>
      </CustomTable>
    </>
  );
};
export default withTranslation()(WithdrawHistory);
