import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Nav,
  NavLink,
  NavItem,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import FeeSettingComponent from 'src/components/Admin/FeeSettingComponents';
import FeeWithdrawConfigSettingComponent from 'src/components/Admin/FeeWithdrawConfigSettingComponent';
import { getMinimumWithdrawConfigs } from 'src/store/actions';
import SuggestJobBudgetsSettingComponent from 'src/components/Admin/SuggestJobBudgetsSettingComponent';
import NetworksSettingComponent from 'src/components/Admin/NetworksSettingComponent';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getCurrencies as onGetCurrencies } from '../../store/currencies/actions';
import menu from '../../constants/menu';
import CurrenciesSettingComponent from '../../components/Admin/CurrenciesSettingComponent';
import MinWithdrawConfigsSettingComponent from '../../components/Admin/MinWithdrawConfigsSettingComponent';
import config from '../../config';

const SettingsPage = ({ t }: any) => {
  const dispatch = useDispatch();

  const [tab, setTab] = useState(1);
  const [withdrawSettingTab, setWithdrawSettingTab] = useState(1);

  useEffect(() => {
    dispatch(onGetCurrencies());
    dispatch(getMinimumWithdrawConfigs());
  }, []);

  const renderTabContent = () => {
    switch (tab) {
      case 1:
        return <CurrenciesSettingComponent />;
      case 2:
        return (
          <div className="nav-scrollbar-none nav-tabs-custom mb-3">
            <NavItem>
              <NavLink
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setWithdrawSettingTab(1);
                }}
                className={`${withdrawSettingTab === 1 && 'active'}`}
              >
                <h5 className="mb-0">{t('Menus.Min Withdraw Setting')}</h5>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setWithdrawSettingTab(2);
                }}
                className={`${withdrawSettingTab === 2 && 'active'}`}
              >
                <h5 className="mb-0">{t('Menus.Fee Withdraw Setting')}</h5>
              </NavLink>
            </NavItem>
          </div>
        );

      case 3:
        return <FeeSettingComponent />;
      case 4:
        return <SuggestJobBudgetsSettingComponent />;
      default:
        return <NetworksSettingComponent />;
    }
  };

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>
            {t('Page.Admin Settings')} | {config.appName}
          </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            pageTitle={t('Page.Admin Settings')}
            menus={[
              { menu: menu.DASHBOARD },
              { menu: menu.ADMIN_SETTINGS, active: true },
            ]}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Nav tabs className="nav-tabs-custom mb-3">
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setTab(1);
                        }}
                        className={`${tab === 1 && 'active'}`}
                      >
                        <h5>{t('Menus.Available Currencies Setting')}</h5>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setTab(2);
                        }}
                        className={`${tab === 2 && 'active'}`}
                      >
                        <h5>{t('Menus.Withdraw Setting')}</h5>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setTab(3);
                        }}
                        className={`${tab === 3 && 'active'}`}
                      >
                        <h5>{t('Menus.Fee Setting')}</h5>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setTab(4);
                        }}
                        className={`${tab === 4 && 'active'}`}
                      >
                        <h5>{t('Menus.Suggest Job Budget')}</h5>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setTab(5);
                        }}
                        className={`${tab === 5 && 'active'}`}
                      >
                        <h5>{t('Menus.Available Networks Setting')}</h5>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody>{renderTabContent()}</CardBody>
                <CardBody style={{ padding: '0 16px 0 16px' }}>
                  {withdrawSettingTab === 1 && tab === 2 && (
                    <MinWithdrawConfigsSettingComponent />
                  )}
                  {withdrawSettingTab === 2 && tab === 2 && (
                    <FeeWithdrawConfigSettingComponent />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(SettingsPage);
