import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';
import CustomTable from 'src/components/Common/CustomTable';
import {
  getDefaultWebsite,
  getMarketerJobSummary,
  getSocialJobByJobKey,
  getUserWalletByCurrency,
  getWallet,
  setLoading,
  unsetLoading,
  updateMarketerJob,
} from 'src/store/actions';
import './jobDetail.style.scss';
import {
  Approve,
  Pending,
  Reject,
} from 'src/components/Common/approval/approvalStatus';

import FavoriteIcon from '@mui/icons-material/Favorite';
import userAvatar from 'src/assets/images/users/avatar-1.jpg';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { pink } from '@mui/material/colors';
import Rating from '@mui/material/Rating';
import {
  rejectSocialJob,
  updateSocialMediaHasJobGql,
  requesPriceOnSuggestJobGQL,
} from 'src/graphql/advertiser/socialMediaInfo';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import menu from 'src/constants/menu';
import Tooltip from '@mui/material/Tooltip';
import { OnLoading } from 'src/components/Common/Loading';
import Role from 'src/constants/enum/role';
import { Dialog, DialogContent } from '@mui/material';
import JobDetailPost from 'src/components/Publisher/Influencer/JobDetail/jobDetailPost';
import paramsFromURL from 'src/constants/paramsFromURL';
import { floorToFixed } from 'src/common/data/mathToFixed';
import notify from 'src/utils/notify';
import i18n from 'src/i18n';
import { result } from 'lodash';
import Pagination from '@mui/material/Pagination';
import SearchBar from 'src/components/Table/SearchBarJobDetail';
import RequestPrice from './RequestPrice.componant';

export enum JobStatus {
  DRAFT = 'DRAFT',
  NEW_JOB = 'NEW_JOB',
  INPROGRESS = 'INPROGRESS',
  FINISHED = 'FINISHED',
  CANCEL = 'CANCEL',
  ALL = 'ALL',
  PAUSE = 'PAUSE',
}

const JobDetail = ({ jobType }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [jobId]: any = paramsFromURL(['job']);
  const history = useHistory();
  const isPublish = jobType === 'PUBLISH';
  const [switchCheck, setSwitchCheck] = useState(true);
  const [changePrice, setChangePrice] = useState(true);
  const [isDisabledAppove, setIsDisabledAppove] = useState(false);
  const defaultPostModalData = {
    isOpen: false,
    link_post: '',
    social_media_info: {
      social_media_type: '',
      key: '',
    },
  };
  const [postModalData, setPostModalData] = useState(defaultPostModalData);
  const [param, setParam] = useState<any>({
    limit: 20,
    page: 1,
    job_key: jobId,
    sortBy: '',
    sortType: '',
  });

  useEffect(() => {
    dispatch(setLoading());
    dispatch(getSocialJobByJobKey(param));
    // if (isPublish) {
    //   dispatch(getWallet());
    // }
    if (jobId) {
      dispatch(getMarketerJobSummary(jobId));
    }

    dispatch(unsetLoading());
  }, []);
  //table
  const { data, totalCount, defaultTax, user, wallet, job } = useSelector(
    (state: any) => ({
      data: state.SocialMediaInfo.socialJob?.items,
      totalCount: state.SocialMediaInfo.socialJob?.totalCount,
      // campaign_key: state.SocialMediaInfo.socialJob?.marketer_campaign_key,
      defaultTax: state.DefaultValue.defaultWebsite.valueAddedTax,
      user: state.login.user,
      wallet: state?.Wallet?.wallet,
      job: state?.MarketerJob?.marketerJob,
    }),
  );
  const balance = wallet?.balance || 0;
  //const tax = defaultTax / 100 + 1;
  const onFavorite = async (social_key: string, isFavorite: boolean) => {
    const res: any = await updateSocialMediaHasJobGql({
      key: social_key,
      isFavorite,
    });
    if (res) dispatch(getSocialJobByJobKey(param));
  };

  useEffect(() => {
    if (job) {
      if (job.status === 'PAUSE') {
        setSwitchCheck(false);
      } else {
        setSwitchCheck(true);
      }

      if (isPublish && job?.currency?.id) {
        dispatch(getUserWalletByCurrency(job?.currency?.id));
      }
    }
  }, [job]);
  const displayModal = () => {
    // eslint-disable-next-line no-unused-vars
    const { isOpen, link_post, social_media_info } = postModalData;
    return (
      <Dialog maxWidth={false} onClose={handleClose} open={isOpen}>
        <DialogContent style={{ cursor: 'pointer' }}>
          <JobDetailPost socialJobItem={postModalData} />
        </DialogContent>
      </Dialog>
    );
  };

  const handleClose = () => {
    setPostModalData({ ...postModalData, isOpen: false });
  };

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : param.page;
    const payload = {
      ...param,
      page: newPage,
    };
    dispatch(getSocialJobByJobKey(payload));
    setParam(payload);
  };

  const handleSearch = (search: string) => {
    const payload = {
      ...param,
      search,
      page: 1,
    };
    dispatch(getSocialJobByJobKey(payload));
    setParam(payload);
  };

  const setPauseStatusOn = () => {
    const payload = {
      key: jobId,
      status: 'INPROGRESS',
    };
    dispatch(updateMarketerJob(payload));
  };
  const setPauseStatusOff = () => {
    const payload = {
      key: jobId,
      status: 'PAUSE',
    };
    dispatch(updateMarketerJob(payload));
  };
  const handleClear = () => {
    const payload = {
      ...param,
      search: '',
      page: 1,
    };
    dispatch(getSocialJobByJobKey(payload));
    setParam(payload);
  };

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage =
        newSortBy === param.sortBy && newSortType === param.sortType
          ? param.page
          : 1;

      const payload = {
        ...param,
        page: newPage,
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(getSocialJobByJobKey(payload));
      setParam(payload);
    },
    [param.search, param.page],
  );

  const setRating = async (social_key: string, rating: number) => {
    const res: any = await updateSocialMediaHasJobGql({
      key: social_key,
      rating,
    });
    if (res) dispatch(getSocialJobByJobKey(param));
  };

  let columnsData = [
    {
      Header: <div className="text-header">{t('table:No.')}</div>,
      accessor: (row: any, index: number) =>
        index + 1 + (param.page - 1) * param.limit,
      id: 'index',
      width: 50,
      disableSort: true,
    },

    {
      Header: <div className="text-header">{t('table:Status')}</div>,
      accessor: ({ status }: any) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <MarketerCampaignStatus status={status} /> */}
          {status}
          {/* {cancel_job_reason && (
            <div
              style={{
                color: 'rgb(163, 163, 163)',
                fontSize: '15px',
                fontWeight: 500,
                lineHeight: '29px',
              }}
            >
              {t(`enum:${cancel_job_reason}`)}
            </div>
          )} */}
        </div>
      ),
      id: 'status',
      width: 50,
      //disableSort: true,
    },
    {
      Header: <div className="text-header">{t('table:Influencer photo')}</div>,
      accessor: ({ social_media_info }: any) => (
        <img
          src={social_media_info.image_profile_url || userAvatar}
          alt="icons"
          className="image-icon"
          style={{
            width: '30px',
            height: '30px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: '50%',
            objectFit: 'cover',
          }}
        />
      ),
      id: 'influencer_image',
      disableSort: true,
    },
    {
      Header: <div className="text-header">{t('table:Influencer name')}</div>,
      accessor: ({ social_media_info }: any) => (
        <a href={social_media_info.profile_path} target="_blank" rel="noreferrer">
          {social_media_info.name}
        </a>
      ),
      id: 'influencer_name',
      disableSort: true,
    },
    {
      Header: <div className="text-header">{t('table:Influencer follower')}</div>,
      accessor: ({ social_media_info }: any) => social_media_info.follower,
      id: 'influencer_follower',
      width: 50,
      disableSort: true,
    },
    {
      Header: <div className="text-header">{t('table:Price')}</div>,
      accessor: ({
        price,
        tax,
        key: social_key,
        status,
        approve_job_date,
        job: _job,
      }: any) => {
        return (
          <>
            {' '}
            <>
              <RequestPrice
                price={price}
                symbol={_job.currency.symbol}
                tax={tax}
                social_key={social_key}
                status={status}
                approve_job_date={approve_job_date}
                isPublish={isPublish}
                payload={param}
              />
            </>
          </>
        );
      },
      id: 'price',
      width: 100,
      //disableSort: true,
    },
    {
      Header: <div className="text-header">{t('table:job submit')}</div>,
      accessor: ({ submit_date }: any) => {
        if (!submit_date) return <Pending message="pending" />;
        const newSubmitData = new Date(submit_date);
        const Day = newSubmitData.getDate();
        const month = newSubmitData.getMonth() + 1;
        const year = newSubmitData.getFullYear();

        return <Approve message={`${Day}/${month}/${year}`} />;
      },
      id: 'submit_date',
      width: 100,
      disableSort: true,
    },
    {
      Header: <div className="text-header">{t('table:influencer accept')}</div>,
      accessor: ({ accept_date }: any) => {
        if (!accept_date) return <Pending message="pending" />;
        const newAcceptDate = new Date(accept_date);
        const Day = newAcceptDate.getDate();
        const month = newAcceptDate.getMonth() + 1;
        const year = newAcceptDate.getFullYear();

        return <Approve message={`${Day}/${month}/${year}`} />;
      },
      id: 'accept_date',
      width: 150,
      //disableSort: true,
    },
    {
      Header: <div className="text-header">{t(`table:Review job`)}</div>,
      accessor: ({ link_post }: any) => link_post,
      Cell: ({ cell }: any) => (
        <>
          {cell.row.original.influencer_submit_date ? (
            <button
              style={{
                background: 'white',
                cursor: `${user.role === Role.ADMIN ? 'no-drop' : 'pointer'}`,
              }}
              className="check-detail"
              disabled={user.role === Role.ADMIN}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  `/InfluencerPostDetail?job=${jobId}&detail=${cell.row.original.key}`,
                );
              }}
            >
              {t('marketer:job_detail:Check detail')}
            </button>
          ) : (
            <Pending message="pending" />
          )}
        </>
      ),
      width: 100,
      id: 'influencer_submit_date',
      //disableSort: true,
    },
    {
      Header: <div className="text-header">{t(`table:Influencer Post`)}</div>,
      accessor: ({ link_post, social_media_info, post_date }: any) =>
        post_date ? (
          <button
            style={{ background: 'white' }}
            className="link-job"
            type="button"
            onClick={() => {
              setPostModalData({
                isOpen: true,
                link_post,
                social_media_info,
              });
            }}
          >
            {t('table:View post')}
          </button>
        ) : (
          <Pending message="pending" />
        ),
      id: 'influencer_post_date',
      disableSort: true,
    },
    {
      Header: <div className="text-header">{t('table:Pending period')}</div>,
      accessor: ({ status, day_left }: any) => {
        if (day_left) return <Approve message={`${day_left}`} />;
        if (status === 'FINISHED') {
          return <Approve message="Finished" />;
        }
        if (status === 'CANCEL') {
          return <Reject message="Cancel" />;
        }
        return <Pending message="pending" />;
      },
      id: 'recieved_date',
      width: 150,
      disableSort: true,
    },
    {
      Header: <div className="text-header">{t('table:Job end')}</div>,
      accessor: ({ end_date }: any) => {
        if (!end_date) return <Pending message="pending" />;
        const newEnd_date = new Date(end_date);
        return newEnd_date.toLocaleDateString();
      },
      id: 'end_date',
      //disableSort: true,
    },
    {
      Header: <div className="text-header">{t('table:Rating')}</div>,
      accessor: ({ key: social_key, rating }: any) => (
        <div style={{ display: 'flex' }}>
          <Rating
            name="rating"
            value={rating}
            precision={0.5}
            disabled={user.role === Role.ADMIN}
            onChange={async (e, newRating: any) => {
              if (!newRating) return;
              await setRating(social_key, newRating);
            }}
          />
          {user.role === Role.ADVERTISER && (
            <Tooltip
              title={t('tooltip.Give rating to your selected influencer')}
              placement="top-start"
            >
              <i
                className="fas fa-info-circle ms-2 font-size-16"
                style={{ cursor: 'pointer' }}
                id="tooltipInfo"
              />
            </Tooltip>
          )}
        </div>
      ),

      id: 'rating',
      disableSort: true,
    },
    {
      Header: <div className="text-header">{t('table:Favorite')}</div>,
      accessor: ({ key: social_key, isFavorite }: any) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{ cursor: 'pointer' }}
            onClick={async () => {
              if (user.role === Role.ADVERTISER)
                await onFavorite(social_key, !isFavorite);
            }}
          >
            {isFavorite ? (
              <FavoriteIcon sx={{ color: pink[500] }} />
            ) : (
              <FavoriteBorderOutlinedIcon sx={{ color: pink[500] }} />
            )}
          </div>
          {user.role === Role.ADVERTISER && (
            <Tooltip
              title={t('tooltip.Save your favorite influencer to hire them later')}
              placement="top-start"
            >
              <i
                className="fas fa-info-circle ms-2 font-size-16"
                style={{ cursor: 'pointer' }}
                id="tooltipInfo"
              />
            </Tooltip>
          )}
        </div>
      ),

      id: 'favorite',
      disableSort: true,
    },
  ];

  const insert = (arr: any, index: any, newItem: any) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index),
  ];

  if (isPublish) {
    columnsData = insert(columnsData, 7, {
      Header: <div className="text-header">{t('table:approve influencer')}</div>,
      accessor: ({ approve_job_date }: any) => {
        if (!approve_job_date) return <Pending message="pending" />;
        const newAcceptDate = new Date(approve_job_date);
        const Day = newAcceptDate.getDate();
        const month = newAcceptDate.getMonth() + 1;
        const year = newAcceptDate.getFullYear();

        return <Approve message={`${Day}/${month}/${year}`} />;
      },
      id: 'approve_job_date',
      width: 150,
      disableSort: true,
    });

    columnsData = insert(columnsData, 3, {
      Header: <div className="text-header">{t('table:Match')}</div>,
      accessor: ({ is_match }: any) => {
        if (is_match) {
          return <i className="fas fa-check" />;
        } else return <i className="fas fa-ban" />;
      },
      id: 'is_match',
      width: 50,
      disableSort: true,
    });

    columnsData = insert(columnsData, 4, {
      Header: <div className="text-header">{t('table: approval')}</div>,
      accessor: ({ key, status, approve_job_date, price, tax }: any) => {
        if (status === 'CANCEL')
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Reject message="Rejected" />
            </div>
          );
        if (approve_job_date)
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Approve message="Approve" />
            </div>
          );
        const click = async () => {
          await Swal.fire({
            title: t('Reject'),
            text: t(`Tell influcencer why you reject ( If any )`),
            input: 'textarea',
            inputPlaceholder: 'input here',
            inputAttributes: {
              'aria-label': 'Type your message here',
            },
            width: 600,
            imageHeight: 200,
            showCancelButton: true,
            cancelButtonColor: 'red',
            confirmButtonColor: '#009a78',
            customClass: {},
            confirmButtonText: t('swal:confirm'),
            cancelButtonText: t('swal:Cancel'),
          }).then(async (result) => {
            if (result.isConfirmed) {
              dispatch(setLoading());
              const reason = result.value;
              await rejectSocialJob(key, reason);
              dispatch(getSocialJobByJobKey(param));
              notify(i18n.t('success:reject influencer has accepted.'), 'success');
              Swal.fire('Success');
              dispatch(unsetLoading());
            }
          });
        };
        return (
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                className={isDisabledAppove ? 'disabled' : ''}
                onClick={async () => {
                  setIsDisabledAppove(true);
                  if (balance - (price + tax) < 0) {
                    notify(i18n.t('error:your balance is not enough.'), 'error');
                    setIsDisabledAppove(false);
                  } else {
                    const res = await updateSocialMediaHasJobGql({
                      key,
                      approve_job_date: new Date(),
                    });
                    if (res) {
                      dispatch(getSocialJobByJobKey(param));
                      dispatch(getWallet());
                      notify(
                        i18n.t('success:approve influencer has accepted.'),
                        'success',
                      );
                      setIsDisabledAppove(false);
                    } else
                      notify(
                        i18n.t(
                          'error:approve influencer has accepted has not accept .',
                        ),
                        'error',
                      );
                    setIsDisabledAppove(false);
                  }
                }}
              >
                <Approve message="Approve" />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '0.5rem',
              }}
            >
              <div
                onClick={async () => {
                  await click();
                }}
              >
                <Reject message="Rejected" />
              </div>
            </div>
          </div>
        );
      },
      id: 'approve job',
      width: 150,
      disableSort: true,
    });
  }

  const columns = useMemo(() => columnsData, [t, jobId, getDefaultWebsite()]);
  if (!data) return <OnLoading />;

  return (
    <div className="page-content">
      <div style={{ padding: '0 2.5rem' }}>
        {user.role === Role.ADVERTISER && (
          <Breadcrumb
            pageTitle={t('marketer:job_detail:job tracking', {
              jobName: data[0]?.job?.name,
            })}
            menus={[
              { menu: menu.HOME, active: true },
              {
                menu: isPublish
                  ? menu.MARKETER_ANNOUNCEMENT_CAMPAIGN
                  : menu.MARKETER_INVITEONLY_CAMPAIGN,
              },

              { menu: menu.JOB_TRACKING, id: jobId, isSamePage: true },
            ]}
          />
        )}
      </div>
      {displayModal()}
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '94%',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <div style={{ visibility: 'hidden' }}>space</div>
          <div>
            <SearchBar
              placeholder={t('table:Search name')}
              handleSearch={handleSearch}
              handleClear={handleClear}
              disablePadding
            />
          </div>
          {isPublish && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div className="form-check form-switch form-switch-lg">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizelg"
                  checked={switchCheck}
                  onChange={() => {
                    if (switchCheck === false) {
                      setSwitchCheck(true);
                      // setPauseStatusOff();
                      setPauseStatusOn();
                    }
                    if (switchCheck === true) {
                      setSwitchCheck(false);
                      setPauseStatusOff();
                    }
                  }}
                />
                <span style={{ fontSize: '16px' }}>
                  {' '}
                  {switchCheck === true ? 'On' : 'Off'}
                </span>
              </div>
            </div>
          )}
          <button
            type="button"
            style={{
              float: 'right',
              display: 'inline-block',
              boxShadow: 'none',
              background: 'linear-gradient(104deg,#6B99CA, #6BC6B3)',
              maxHeight: '55px',
              border: 'none',
            }}
            className="btn btn-info fw-normal"
            onClick={() => {
              history.push(`/JobSummary?job=${jobId}`);
            }}
          >
            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
              {t('button:View job details')}
            </div>
          </button>
        </div>
      </div>
      <CustomTable
        columns={columns || []}
        data={data || []}
        page={param.page}
        pageSize={param.limit}
        total={totalCount}
        headerCSS="fw-normal bg-header-table "
        handlePageChange={handlePageChange}
        onSort={handleSort}
      />
    </div>
  );
};

export default JobDetail;
