const menu = {
  Jobs: 'Jobs',
  DISCOVER: 'Discover',
  DISCOVER_ALL: 'Discover All',
  WALLET: 'Wallet',
  ADD_SOCIAL_CHANNEL: 'Add Social Channel',
  SOCIAL_CHANNEL: 'Social Channel',
  OVERVIEW: 'Overview',
  MY_JOBS: 'My Jobs',
  SUGGESTED_JOBS: 'Suggested Jobs',
  JOB_DETAIL: 'JOB_DETAIL',
  JOBS: 'JOBS',
  CREATE_CAMPAIGN: 'Create Campaign',
  MARKETER_CAMPAIGN_JOB: 'Invite-Only Job',
  MARKETER_CAMPAIGN_JOB_ANNOUNCEMENT: 'Suggested jobs',
  MARKETER_INVITEONLY_CAMPAIGN: 'Campaign',
  MARKETER_ANNOUNCEMENT_CAMPAIGN: 'Suggested Campaign',
  JOB_TRACKING: 'Job Tracking',
  CREATE_JOB: 'Create Job',
  Wallet: 'Wallet',
  DASHBOARD: 'dashboard',
  HOME: 'home',
  WITHDRAW_TRANSACTIONS: 'withdraw transactions',
  DEPOSIT: 'deposit',
  WEBSITE: 'website',
  APPLICATION: 'Application',
  WEBSITEINFO: 'Website Info',
  APPLICATIONINFO: 'Application Info',
  CREATESOCIALCHANNEL: 'Create Social Channel',
  PROFILE: 'profile',
  ADMIN_MOVEMENT_BALANCE: 'movement balance',
  USER_DETAIL: 'user detail',
  ADMIN_SETTINGS: 'admin settings',
  NOTIFICATION: 'notification',
  APPROVAL_CREATIVE: 'approval website',
  PUBLISHER_WEBSITE: 'publisher website',
  NEW_INFLUENCER: 'new influencer',
  INFLUENCER_REQUEST_NEW_PRICE: 'influencer request new price',
  JOB_APPROVAL: 'job approval',
  PAYMENT_TRANSACTION: 'payment transaction',
  INFLUENCER_RATE_PRICE: 'influencer price rate',
  ALL_USER: 'all user',
  REFERRAL: 'referral',
  SYSTEM_BALANCE_LOG: 'system balance log',
  CRYPTO_BALANCE_LOG: 'crypto balance log',
  ROLE_BALANCE_LOG: 'role balance log',
  PROFIT_BALANCE_LOG: 'profit balance log',
  SYSTEM_BALANCE_OVERVIEW: 'system balance overview',
  ADMIN_USERS: 'admin users',
  CAMPAIGN: 'campaign',
  CAMPAIGN_SETTING: 'campaign setting',
  ADVERTISER_CAMPAIGN: 'advertiser campaign',
  CAMPAIGN_DETAIL: 'campaign detail',
  ADMIN_SETTINGS_ADVERTISER: 'admin settings advertiser',
  FAQs: 'FAQs',
  Document: 'Document',
  SPEED_UP: 'Speed Up Job',
  RENEW_SOCIAL_TOKEN: 'Renew Social Token',
  REQUEST_DELETE_SOCIAL_MEDIA: 'Requeste Delete Social',
  REQUEST_FOLLOWER: 'Request Update Follower',
  Suspend_Influencer_Post: 'Suspend Influencer Post',
  SUSPEND_INFLUENCER_POST: 'Suspend Influencer Post',
  TEMPLATE: 'Template',
  BAN_USER: 'Ban User',
  REPORT_JOB_DETAIL: 'Report Job Detail',
  REPORT_DECLINE_JOB: 'Report Decline Job',
  REPORT_DECLINE_JOB_DETAIL: 'Report Decline Job Detail',
};

export default menu;
