const getInstagramAccessToken = async (code: any) => {
  const response = await fetch(
    `https://api.afbrother.com/api/social/instagram/?code=${code}`,
    {
      method: 'GET',
      redirect: 'follow',
    },
  );

  const responseText: string = await response.text();
  return JSON.parse(responseText);
};

export default getInstagramAccessToken;
