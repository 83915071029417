import styled from 'styled-components';

const ChannelDetailStyled = () => {
  const PriceContainer = styled.div`
    text-align: center;
    padding-top: 1.5rem;
    width: 100%;
    border-top: 1px solid rgb(221, 218, 218);
    // background: red;
    width: 100%;
    margin-top: 2rem;
  `;
  const PricePost = styled.div`
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    width: 70%;

    @media only screen and (max-width: 530px) {
      width: 100%;
    }
    .title-price {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 0.1rem;
    }
  `;

  const TitleStyle = styled.p`
    font-size: 1.2rem;
    font-weight: bold;
    width: 100%;
    // height: 1.2em;
    // background: red;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `;
  const TitleHeadText = styled.p`
    font-size: 1.5em;
    font-weight: bold;
    width: 100%;
    height: 1.2em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `;
  const Texts = styled.p`
    font-size: 1.5em;
    // font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 20px;
  `;
  const TextKeyword = styled.p`
    font-size: 1.2em;
    // font-weight: bold;
    padding: 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 1px solid #999999;
    border-radius: 15px;
    margin: 10px;
    cursor: pointer;
    :hover {
      border: 1px solid #454545;
    }
  `;
  const ButtonEdit = styled.button`
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
    border-radius: 5px;
    background: linear-gradient(104deg, #6b99ca, #6bc6b3);
    margin: 10px auto 10px auto;
    width: 90px;
    height: 30px;
    cursor: pointer;
  `;
  const ButtonSave = styled.button`
    color: white;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
    border-radius: 5px;
    background: linear-gradient(104deg, #6b99ca, #6bc6b3);
    margin: 10px auto 10px auto;
    width: 90px;
    height: 30px;
    cursor: pointer;
  `;
  const ButtonCancel = styled.button`
    color: white;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
    border-radius: 5px;
    background: linear-gradient(104deg, #6b99ca, #6bc6b3);
    margin: 10px auto 10px auto;
    width: 90px;
    height: 30px;
    cursor: pointer;
  `;
  const ImgTag = styled.img`
    // position: absolute;
    object-fit: cover;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: 0 10px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    animation-delay: 2s;
    animation: channelLogo 0.5s linear alternate;
    background-color: #fbfaff;
  `;

  const FlexRow = styled.div<{ width?: string }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    width: ${({ width }) => width};
  `;

  const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
  `;

  const AptitudeGridContainer = styled.div<{ columnsLength?: number }>`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    grid-template-columns: repeat(${({ columnsLength }) => columnsLength || 4}, 1fr);
    grid-template-rows: repeat(auto, 1fr);
    gap: 1rem;
    flex-direction: row;
    width: 100%;
    height: 100%;
    max-height: 550px;
    overflow: hidden;
    overflow-y: auto;
    align-items: stretch;
    padding: 8px;
  `;

  const Chip = styled.div<{ background?: string }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    vertical-align: middle;
    text-decoration: none;
    justify-content: center;
    min-height: 36px;
    border-radius: 16px;
    border: solid 1px #dfdfdf;
    color: #000000;
    padding: 0 8px;
    cursor: pointer;
    &.active {
      border: solid 1px transparent;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, #78e4ff, #ff48fa);
      box-shadow: 2px 1000px 1px #fff inset, rgba(0, 0, 0, 0.12) 0px 1px 3px,
        rgba(0, 0, 0, 0.24) 0px 1px 2px;
      background-origin: border-box;
      background-clip: content-box, border-box;
    }
  `;

  const TableStyle = styled.table`
    width: 100%;
    padding: 16px;
    height: 100px;
  `;
  const TheadStyle = styled.thead`
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    // background: #d9f4f9;
    font-size: 18px;
    font-weight: 300;
    height: 20px;
  `;
  const TbodyStyle = styled.tbody<{ background: string }>`
    background: ${({ background }) => background};
    padding-top: 5px;
    height: 60px;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #efdfdc;
  `;

  return {
    PriceContainer,
    TitleStyle,
    TitleHeadText,
    ImgTag,
    FlexRow,
    PricePost,
    TableStyle,
    TheadStyle,
    TbodyStyle,
    Texts,
    FlexCol,
    TextKeyword,
    ButtonEdit,
    ButtonSave,
    ButtonCancel,
    AptitudeGridContainer,
    Chip,
  };
};

export default ChannelDetailStyled;
