import { gapi } from 'gapi-script';
import { uniqueId } from 'lodash';
import { useEffect, useState } from 'react';
import useWindowSize from 'src/utils/windowSize';
import GoogleLogin from 'react-google-login';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearExpiredSocialMediaToken,
  getExpiredSocialMediaToken,
  getSocialMediaInfo,
  getUserInfo,
  setLoading,
  unsetLoading,
} from 'src/store/actions';
import { SocialMediaType } from 'src/components/Publisher/Influencer/Dashboard/SocialChannel/SocialChannel.control';
import config from 'src/config';
import i18n from 'src/i18n';
import '../ChannelAdd/ChannelAdd.style.scss';
import './RenewToken.style.scss';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import menu from 'src/constants/menu';
import Swal from 'sweetalert2';
import notify from 'src/utils/notify';
import { useHistory, useLocation, useParams } from 'react-router';
import userAvatar from 'src/assets/images/users/avatar-1.jpg';
import socialCardControl from 'src/components/Publisher/Influencer/Dashboard/SocialChannel/SocialCard/SocialCard.control';
import { Dialog, DialogContent } from '@mui/material';
import CustomButton from 'src/components/Common/CustomButton';
import { deleteNotSelectedFacebook } from 'src/graphql/advertiser/socialMediaInfo';
import control, {
  SocialButtonData,
  SocialOption,
} from '../ChannelAdd/ChannelAdd.control';
import {
  onFacebookPersonalProcess,
  onFacebookProcess,
} from '../SocialCalculate/socialProcess/meta/facebook/facebookProcess';
import onTwitterProcess from '../SocialCalculate/socialProcess/twitter/twitterProcess';
import {
  onInstagramPageProcess,
  onInstagramPersonalProcess,
} from '../SocialCalculate/socialProcess/meta/instagram/instagramProcess';
import onYoutubeProcess from '../SocialCalculate/socialProcess/youtube/youtubeProcess';
import onTikTokProcess from '../SocialCalculate/socialProcess/tiktok/tiktokProcess';
import controls from '../SocialRenew/RenewList/RenewList.control';

interface ExpiredSocialMedia {
  key: string;
  name: string;
  social_id: string;
  social_media_type: SocialMediaType;
  image_profile_url: string;
}

const RenewTokenPage = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const {
    socialOptions,
    onSelectSocial,
    onSubmit,
    onSubmitWithSocial,
    socialSelected,
  } = control();
  const { onGetLogoSocialByTypeRenew } = socialCardControl();
  const history = useHistory();
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const { type: paramType, token }: any = useParams();
  const type: string | null = params.get('type');
  const { search } = useLocation();

  const { onSelectUserProfile, renewUserProfileList } = controls();

  const {
    userInfo,
    facebookError,
    socialMediaExpired,
    userProfileList$,
    userProfileStatus$,
  } = useSelector((state: any) => ({
    userInfo: state.Users.userInfo,
    facebookError: state.CalculateSocialMedia.setFacebookError,
    socialMediaExpired: state.SocialMediaInfo.socialMediaInfoExpiredToken,
    userProfileList$: state.CalculateSocialMedia.userProfileList,
    userProfileStatus$: state.CalculateSocialMedia.userProfileStatus,
  }));
  const [selectData, setSelectData] = useState<any>({});
  const [socialType, setSocialType] = useState<any>([]);
  const [selectSocial, setSelectSocial] = useState<any>([]);

  const [expiredSocial, setExpiredSocial] = useState<any>([]);
  const [hasFacebookPersonal, setHasFacebookPersonal] = useState<boolean>(false);
  const [hasFacebookPage, setHasFacebookPage] = useState<boolean>(false);
  const [notSelected, setNotSelected] = useState<any>([
    // {
    //   name: 'แมวสายลม',
    //   social_media_type: 'FACEBOOK',
    //   social_id: '107897083904736',
    //   key: '9670fnlxt',
    //   image_profile_url:
    //     'http://localhost:3001/api/socialProfile/ec54a0b8-b2bb-4e89-ad78-12af6dab75da',
    //   profile_path: 'https://www.facebook.com/107897083904736',
    // },
    // {
    //   name: 'ชมรมชาวเหนือ มหาวิทยาลัยเกษตรศาสตร์ บางเขน',
    //   social_media_type: 'FACEBOOK',
    //   social_id: '197170117108229',
    //   key: 'co7l9pcfh',
    //   image_profile_url:
    //     'http://localhost:3001/api/socialProfile/78840995-2756-4b7d-9125-f2f9502a056f',
    //   profile_path: 'https://www.facebook.com/197170117108229',
    // },
  ]);
  const [isOpen, setIsOpen] = useState(false);
  const [isRenew, setIsRenew] = useState(false);

  const checkUserInfo = () => {
    setTimeout(() => {
      notify(t('error:please fill your information.'), 'warning');
      history.push('/profile');
    }, 500);
  };
  const { t } = useTranslation();
  useEffect(() => {
    if (!userInfo.phone_number) checkUserInfo();
  }, []);

  useEffect(() => {
    if (facebookError) {
      Swal.fire({
        title: t('Sorry Facebook add channel is not available now !!'),
        text: t(`Facebook social channel add is coming soon`),
        icon: 'warning',
        width: 300,
        customClass: {},
        confirmButtonColor: '#009a78',
        confirmButtonText: t(`Agree`),
      });
    }
  }, [facebookError]);

  const socialProcess = async () => {
    switch (paramType) {
      case SocialMediaType.FACEBOOK:
        return onFacebookProcess({ params, dispatch, history }, 'renew');
      case SocialMediaType.FACEBOOK_PERSONAL:
        return onFacebookPersonalProcess({ params, dispatch, history }, 'renew');
      case SocialMediaType.TWITTER:
        return onTwitterProcess({ params, dispatch, history }, 'renew');
      case SocialMediaType.INSTAGRAM:
        return onInstagramPageProcess({ params, dispatch, history }, 'renew');
      case SocialMediaType.INSTAGRAM_PERSONAL:
        return onInstagramPersonalProcess({ params, dispatch, history });
      case SocialMediaType.YOUTUBE: {
        const email: string | null = params.get('email');
        return onYoutubeProcess({ token, dispatch, email, history }, 'renew');
      }
      case SocialMediaType.TIKTOK: {
        return onTikTokProcess({ search, dispatch });
      }
      default:
        return null;
    }
  };

  useEffect(() => {
    console.log('userProfileList$', userProfileList$);
    console.log('expiredSocial', expiredSocial);
    if (userProfileList$?.length === 0) return;
    if (userProfileList$?.length > 0 && expiredSocial?.length > 0) {
      if (userProfileList$[0].social_media_type === SocialMediaType.FACEBOOK) {
        console.log('facebook fanpage');
        const notExist = [];
        const userProfileListName = userProfileList$.map((userProfile: any) => {
          return userProfile.name;
        });
        console.log('userProfileListName', userProfileListName);
        const facebookPageExpired = expiredSocial.filter((item: any) => {
          return item.type === SocialMediaType.FACEBOOK;
        });
        console.log('facebookPageExpired', facebookPageExpired);
        for (let i = 0; i < facebookPageExpired[0].socialMediaInfos?.length; i++) {
          const socialInfo = facebookPageExpired[0].socialMediaInfos[i];
          if (!userProfileListName.includes(socialInfo.name)) {
            notExist.push(socialInfo);
          }
        }
        console.log('notExist', notExist);
        if (notExist?.length > 0) {
          setNotSelected(notExist);
        } else {
          renewSocialMedia(userProfileList$);
        }
      } else {
        console.log('other social');
        renewSocialMedia(userProfileList$);
      }
    }
  }, [userProfileList$, expiredSocial]);

  useEffect(() => {
    if (!userProfileStatus$) {
      return;
    }
    if (userProfileStatus$ !== 'PASS') {
      notify(i18n.t(userProfileStatus$), 'error');
      history.replace('/influencer/dashboard');
    }
  }, [userProfileStatus$]);

  useEffect(() => {
    if (notSelected?.length > 0) {
      setIsOpen(true);
    }
  }, [notSelected]);

  const renewSocialMedia = async (_userProfileList: any) => {
    await onSelectUserProfile(_userProfileList);
    setExpiredSocial([]);
    setIsRenew(true);
    dispatch(setLoading());
    dispatch(getExpiredSocialMediaToken(null));
  };

  useEffect(() => {
    dispatch(getUserInfo());
    const initClient = () => {
      gapi.client.init({
        clientId: config.google.CLIENT_ID,
        scope: 'https://www.googleapis.com/auth/youtube',
      });
    };
    gapi.load('client:auth2', initClient);

    if (expiredSocial?.length === 0) {
      dispatch(setLoading());
      dispatch(getExpiredSocialMediaToken(null));
    }

    if (paramType) {
      socialProcess();
    }
  }, []);

  useEffect(() => {
    if (isRenew) {
      setIsRenew(false);
      dispatch(getExpiredSocialMediaToken(null));
    }
    if (socialMediaExpired) {
      dispatch(unsetLoading());
      const social_types: any = [];
      const expired_social: any = [];
      for (let i = 0; i < socialMediaExpired?.items?.length; i++) {
        expired_social.push(socialMediaExpired.items[i]);
      }
      setExpiredSocial(expired_social);
      dispatch(unsetLoading());
      // dispatch(clearExpiredSocialMediaToken());
    }

    if (!socialMediaExpired) {
      // history.push('/influencer/dashboard');
    }
  }, [socialMediaExpired]);

  const renderSubmitButton = (social_type: string) => {
    const socialOption = socialOptions.filter((item) =>
      social_type.includes(item.type),
    );
    if (socialOption?.length !== 0) {
      return changeButton(socialOption[0], social_type);
    } else {
      return <></>;
    }
  };

  const renderCard = (totalCount: number) => {
    //console.log('totalCount', totalCount);
    if (totalCount < 3) {
      return '';
    } else if (totalCount < 4) {
      return 'item1';
    } else if (totalCount < 6) {
      return 'item3';
    } else if (totalCount > 5) {
      return 'item3';
    }
    return '';
  };
  const renderPadding = (totalCount: number) => {
    if (totalCount < 3) {
      return '';
    } else if (totalCount < 5) {
      if (width < 494) {
        return '0px';
      } else {
        return '25px';
      }
    } else if (totalCount < 6) {
      return '15px';
    } else if (totalCount < 7) {
      return '15px';
    } else if (totalCount < 8) {
      return '5px';
    } else if (totalCount > 7) {
      return '0px';
    }
    return '';
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const displayModal = () => {
    // eslint-disable-next-line no-unused-vars
    return (
      <Dialog maxWidth={false} onClose={handleClose} open={isOpen}>
        <DialogContent style={{ cursor: 'pointer' }}>
          <div className="card-container ">
            <p className="header">{t('influencer:NotSelectFromFacebook')}</p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                className="card-container-group "
                style={{ marginTop: '30px', marginBottom: '30px' }}
              >
                {notSelected.map((item: any) => {
                  return (
                    <>
                      <div
                        className="social-card-facebook-img"
                        style={{
                          margin: '0 5px 5px 5px',
                          cursor: 'pointer',
                          gap: '2rem',
                          flexWrap: 'wrap',
                        }}
                        onClick={() => {}}
                      >
                        <img
                          className="profile skeleton"
                          style={{
                            border: 'white',
                            marginBottom: '0.5rem',
                            width: '60px',
                            height: '60px',
                          }}
                          src={item.image_profile_url || userAvatar}
                          alt={item.name}
                          onLoad={(e) => {
                            e.currentTarget.classList.remove('skeleton');
                          }}
                          onError={(e) => {
                            e.currentTarget.classList.remove('skeleton');
                            e.currentTarget.src = userAvatar;
                          }}
                        />
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div>
              <div
                className="button-job"
                style={{ display: 'flex', justifyContent: 'space-around' }}
              >
                <div style={{ marginRight: '15px' }}>
                  <CustomButton
                    handleClick={async (e: any) => {
                      const keys = notSelected.map((item: any) => {
                        return item.key;
                      });
                      await deleteNotSelectedFacebook(keys);
                      setIsOpen(false);
                      renewSocialMedia(userProfileList$);
                    }}
                    size="s"
                    status="cancel"
                    label="Delete"
                  />
                </div>
                <div>
                  <CustomButton
                    handleClick={async (e: any) => {
                      setIsOpen(false);
                      renewSocialMedia(userProfileList$);
                    }}
                    size="md"
                    status="submit"
                    label="Continue without delete"
                  />
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const changeButton = (social: SocialOption, social_type: string) => {
    if (social.type === SocialMediaType.YOUTUBE) {
      return (
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            margin: '0.5rem',
          }}
        >
          <GoogleLogin
            clientId="372494151787-v07s9qqqqdjmikak3frhns4gkt1a8bf3.apps.googleusercontent.com"
            className="google-btn-channel-add"
            onSuccess={(res: any) => {
              onSubmit(res.accessToken, undefined, 'renew');
            }}
            scope="https://www.googleapis.com/auth/youtube"
            onFailure={() => {}}
            cookiePolicy="single_host_origin"
            accessType="online"
          />
        </div>
      );
    }
    return (
      <div className="social-select-bottom-group ">
        {social?.buttonData.map((buttonDatum: SocialButtonData) => {
          if (social.type.includes(SocialMediaType.FACEBOOK)) {
            if (
              buttonDatum.type === 'PERSONAL' &&
              social_type === SocialMediaType.FACEBOOK_PERSONAL
            ) {
              return (
                <div
                  style={{ backgroundColor: `${buttonDatum.backGroundColor}` }}
                  key={uniqueId()}
                  className="social-select-group"
                  onClick={() => {
                    onSelectSocial(social.type);
                    onSubmitWithSocial(null, buttonDatum.type, 'renew', social.type);
                  }}
                >
                  <div className="social-select-img">
                    <img src={social.logo} alt={social.logo} className="img" />
                  </div>
                  <div className="social-select-text-container">
                    <span
                      className={
                        buttonDatum.label === 'Sign in with Google'
                          ? 'google-text'
                          : 'social-select-text'
                      }
                    >
                      {buttonDatum?.label ? i18n.t('sign in with facebook') : ''}
                    </span>
                  </div>
                </div>
              );
            } else if (
              buttonDatum.type === 'BUSINESS' &&
              social_type === SocialMediaType.FACEBOOK
            ) {
              return (
                <div
                  style={{ backgroundColor: `${buttonDatum.backGroundColor}` }}
                  key={uniqueId()}
                  className="social-select-group"
                  onClick={() => {
                    onSelectSocial(social.type);
                    onSubmitWithSocial(null, buttonDatum.type, 'renew', social.type);
                  }}
                >
                  <div className="social-select-img">
                    <img src={social.logo} alt={social.logo} className="img" />
                  </div>
                  <div className="social-select-text-container">
                    <span
                      className={
                        buttonDatum.label === 'Sign in with Google'
                          ? 'google-text'
                          : 'social-select-text'
                      }
                    >
                      {buttonDatum?.label ? i18n.t('sign in with facebook') : ''}
                    </span>
                  </div>
                </div>
              );
            } else {
              return <></>;
            }
          }
          return (
            <div
              style={{ backgroundColor: `${buttonDatum.backGroundColor}` }}
              key={uniqueId()}
              className="social-select-group"
              onClick={() => {
                onSelectSocial(social.type);
                onSubmitWithSocial(null, buttonDatum.type, 'renew', social.type);
              }}
            >
              <div className="social-select-img">
                <img src={social.logo} alt={social.logo} className="img" />
              </div>
              <div className="social-select-text-container">
                <span
                  className={
                    buttonDatum.label === 'Sign in with Google'
                      ? 'google-text'
                      : 'social-select-text'
                  }
                >
                  {buttonDatum?.label ? i18n.t(buttonDatum?.label) : ''}
                </span>
              </div>
            </div>
          );
        })}{' '}
      </div>
    );
  };

  return (
    <>
      {displayModal()}
      <div className="page-content">
        {/* <DisableFacebookModal open setOpen={setIsFacebookError} /> */}

        <div
          className="bread-crumb-20"
          style={width <= 300 ? { fontSize: '9.2px', marginTop: '5px' } : {}}
        >
          <Breadcrumb
            pageTitle={t('Menus:Renew Social Token')}
            menus={[
              { menu: menu.HOME, active: true },
              { menu: menu.OVERVIEW },
              { menu: menu.RENEW_SOCIAL_TOKEN },
            ]}
          />
        </div>
        <div className="container-custom ">
          <div className="card-container ">
            <p className="header">{t('influencer:RenewSocialAccessToken')}</p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="card-container-group ">
                {/* อันใหญ่สุด */}
                {expiredSocial?.length > 0 &&
                  expiredSocial.map((item: any) => {
                    return (
                      <>
                        <div
                          className={`${renderCard(item.socialMediaInfos?.length)}`}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            className={` card-wrapper-s background-img-short ${renderCard(
                              item.socialMediaInfos?.length,
                            )}`}
                            style={{
                              paddingBottom: `${
                                item.socialMediaInfos?.length > 6 ? '50px' : ''
                              }`,
                            }}
                          >
                            {item.socialMediaInfos.map(
                              (socialCard: any, key: any) => {
                                return (
                                  <>
                                    <div
                                      className="social-card-facebook-img"
                                      style={{
                                        margin: '0 5px 5px 5px',
                                        cursor: 'pointer',
                                        gap: '2rem',
                                        flexWrap: 'wrap',
                                        paddingLeft: `${renderPadding(
                                          item.socialMediaInfos?.length,
                                        )}`,
                                        paddingRight: `${renderPadding(
                                          item.socialMediaInfos?.length,
                                        )}`,
                                      }}
                                      onClick={() => {}}
                                    >
                                      <img
                                        className="profile skeleton"
                                        style={{
                                          border: 'white',
                                          marginBottom: '0.5rem',
                                          width: `${
                                            item.socialMediaInfos?.length > 1
                                              ? '60px'
                                              : '85px'
                                          }`,
                                          height: `${
                                            item.socialMediaInfos?.length > 1
                                              ? '60px'
                                              : '85px'
                                          }`,
                                        }}
                                        src={
                                          socialCard.image_profile_url || userAvatar
                                        }
                                        alt={socialCard.name}
                                        onLoad={(e) => {
                                          e.currentTarget.classList.remove(
                                            'skeleton',
                                          );
                                        }}
                                        onError={(e) => {
                                          e.currentTarget.classList.remove(
                                            'skeleton',
                                          );
                                          e.currentTarget.src = userAvatar;
                                        }}
                                      />
                                    </div>
                                  </>
                                );
                              },
                            )}
                          </div>
                          <div className="button-signin">
                            {renderSubmitButton(item.type)}
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RenewTokenPage;
