import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Column } from 'react-table';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import {
  Col,
  DropdownItem,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  DropdownMenu,
  Button,
} from 'reactstrap';
import { transformStringAmountInput } from '../../../utils/input';
import CustomTable from '../../Common/CustomTable';
import { getCryptoBalanceLogTransactions } from '../../../store/systembalance/actions';
import PageSizeOptions from '../../Table/PageSizeOptions';
import balanceLogRangeEnum from '../../../constants/balanceLogRangeEnum';
import { getDateFromDatePicker, logDate } from '../../../utils/dateTime';
import DatePickerOptions from '../../Table/DatePickerOptions';
import CryptoBalanceDetailModal from './CryptoBalanceDetailModal';

const SystemBalanceLogTransactionsTable = ({ t }: any) => {
  const defaultPageSize = 10;
  const dispatch = useDispatch();

  // Local State
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [balanceLogRange, setBalanceLogRange] = useState(balanceLogRangeEnum.DAILY);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');

  // Redux
  const { txn } = useSelector((state: any) => ({
    txn: state.SystemBalance.logTransactions,
  }));

  useEffect(() => {
    setPage(1);
    setPageSize(defaultPageSize);
    setBalanceLogRange(balanceLogRangeEnum.DAILY);

    // Fetch Data With Default Payload When Tab Changed
    dispatch(
      getCryptoBalanceLogTransactions({
        limit: defaultPageSize,
        page: 1,
        log_range: balanceLogRangeEnum.DAILY,
        log_start: '',
        log_end: '',
      }),
    );
  }, []);

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handlePageSizeChange = (size: string) => {
    const newPageSize = parseInt(size, 10);

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    const payload = {
      ...getDefaultPayload(newPage),
      limit: newPageSize,
    };

    dispatch(getCryptoBalanceLogTransactions(payload));

    setPageSize(newPageSize);
  };

  const handleGetDetail = (rowIndex: any) => {
    setSelectedRow(rowIndex);
    setModalOpen(!modalOpen);
  };

  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('table:Date'),
        accessor: ({ day, month, year }) => {
          return logDate(day, month, year);
        },
        id: 'date',
      },
      {
        Header: t('table:Amount'),
        accessor: ({ amount }) => {
          return `${transformStringAmountInput(amount)} USDT`;
        },
        id: 'amount',
      },
      {
        Header: t('table:Action'),
        accessor: (data, rowIndex) => {
          return (
            <>
              <UncontrolledDropdown>
                <DropdownToggle className="text-muted font-size-28" tag="a">
                  <i className="fas fa-file-alt fa-1x c-pointer" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-start">
                  <DropdownItem
                    onClick={() => {
                      handleGetDetail(rowIndex);
                    }}
                  >
                    <i className="fas fa-file-alt" />
                    &nbsp;&nbsp;{t('table:Detail')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          );
        },
        id: 'action',
        disableSortBy: true,
      },
    ],
    [t, txn],
  );

  const data = React.useMemo(() => {
    return !isEmpty(txn) ? txn.items : [];
  }, [txn]);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...getDefaultPayload(newPage),
    };

    dispatch(getCryptoBalanceLogTransactions(payload));
  };

  // eslint-disable-next-line no-shadow
  const getDefaultPayload = (page: number) => {
    setPage(page);

    return {
      limit: pageSize,
      page,
      log_range: balanceLogRange,
      log_start: startDate,
      log_end: endDate,
    };
  };

  const handleStartDateChange = (date: any) => {
    const payload = {
      ...getDefaultPayload(1),
      log_start: getDateFromDatePicker(date, true, balanceLogRange),
    };

    dispatch(getCryptoBalanceLogTransactions(payload));
    setStartDate(date);
  };

  const handleEndDateChange = (date: any) => {
    const payload = {
      ...getDefaultPayload(1),
      log_end: getDateFromDatePicker(date, false, balanceLogRange),
    };

    dispatch(getCryptoBalanceLogTransactions(payload));
    setEndDate(date);
  };

  const handleClearFilter = () => {
    const payload = {
      ...getDefaultPayload(1),
      log_start: '',
      log_end: '',
    };

    dispatch(getCryptoBalanceLogTransactions(payload));
    setStartDate('');
    setEndDate('');
  };

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(newPage),
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(getCryptoBalanceLogTransactions(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
    },
    [startDate, endDate, balanceLogRange, page, pageSize],
  );

  const hiddenOnXS: any = [];
  const hiddenOnSM: any = [];
  const hiddenOnMD: any = [];
  const hiddenOnLG: any = [];

  return (
    <>
      <CustomTable
        headerCSS="fw-normal bg-header-table"
        columns={columns}
        data={data}
        page={page}
        pageSize={pageSize}
        total={txn.totalCount}
        handlePageChange={handlePageChange}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
        onSort={handleSort}
      >
        <CryptoBalanceDetailModal
          data={!isEmpty(txn) ? txn.items[selectedRow] : null}
          isOpen={modalOpen}
          handleToggle={handleModalToggle}
          handleClose={handleModalClose}
          title={t('Crypto Balance Detail')}
        />
        <Row className="justify-content-between m-0">
          <Col xs={12} md={3} className="d-flex align-items-center mb-3 mb-md-0">
            <PageSizeOptions
              defaultValue={pageSize}
              handlePageSizeChange={handlePageSizeChange}
            />
          </Col>
          <Col xs={12} md={9} lg={9}>
            <Row className="align-items-center justify-content-end">
              <Col sm={12} md={2} lg={3}>
                <DatePickerOptions
                  date={startDate}
                  endDate={endDate}
                  handleChange={handleStartDateChange}
                  placeholder={
                    balanceLogRange === balanceLogRangeEnum.DAILY
                      ? t('Start Date')
                      : t('Start Month')
                  }
                  rangeType={balanceLogRange}
                />
              </Col>
              <Col sm={12} md={3} lg={3}>
                <DatePickerOptions
                  date={endDate}
                  startDate={startDate}
                  handleChange={handleEndDateChange}
                  placeholder={
                    balanceLogRange === balanceLogRangeEnum.DAILY
                      ? t('End Date')
                      : t('End Month')
                  }
                  rangeType={balanceLogRange}
                />
              </Col>
              <Col sm={12} md={3} lg={2} className="p-2 p-lg-0">
                <Button
                  className="btn btn-primary d-block d-sm-inline-block w-100 mb-2 mb-sm-0"
                  type="button"
                  onClick={handleClearFilter}
                  style={{ height: '34px', padding: '0 10' }}
                >
                  {t('Clear')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </CustomTable>
    </>
  );
};

export default withTranslation()(SystemBalanceLogTransactionsTable);
