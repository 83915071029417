import { Card } from '@mui/material';
import { useHistory } from 'react-router-dom';
import userAvatar from 'src/assets/images/users/avatar-1.jpg';
import { useTranslation } from 'react-i18next';
import usdtLogo from 'src/assets/images/usdt-logo.png';
import Warning from 'src/assets/images/other/warning.png';
import { SocialCard } from '../SocialChannel.control';
import control from './SocialCard.control';
import './SocialCard.style.scss';

interface prop {
  card: SocialCard;
}
const SocialCardComponent = ({ card }: prop) => {
  const { onFilterPrice, onGetLogoSocialByType } = control();
  const history = useHistory();
  const { t } = useTranslation();
  const status = () => {
    if (card.aptitudes?.length > 1) {
      switch (card?.verify_status) {
        case 'PENDING':
          return (
            <button type="button" className="btn-pending">
              <i className="fas fa-clock" />
              <span>{t('influencer:dashboard:pending')}</span>
            </button>
          );
        case 'APPROVED':
          return (
            <button type="button" className="btn-approved">
              <i className="fas fa-check" />
              <span>{t('influencer:dashboard:approved')}</span>
            </button>
          );
        case 'REJECTED':
          return (
            <button type="button" className="btn-rejected">
              <i className="fas fa-ban" />
              <span>{t('influencer:dashboard:rejected')}</span>
            </button>
          );
        default:
      }
      return '';
    }
    return '';
  };
  const alertAttitute = () => {
    if (card.aptitudes?.length < 2) {
      return (
        <img
          className="alertAttitude"
          src={Warning}
          alt="warning"
          width="25px"
          height="25px"
        />
      );
    }
    return '';
  };
  const callCal = () => {
    if (card.aptitudes?.length > 2) {
      return (
        <span className="price" style={{ color: 'whitesmoke', fontWeight: 'bold' }}>
          {onFilterPrice(
            [card?.photo_content_price, card?.share_content_price],
            card?.video_content_price,
            card?.social_media_type,
          )}
        </span>
      );
    }
    return '';
  };
  const alertText = () => {
    if (card.aptitudes?.length < 2) {
      return (
        <span style={{ color: 'white', fontSize: '14px' }}>
          {t('Please submit your information')}
        </span>
      );
    }
    return '';
  };
  return (
    <Card
      className="social-card background-img-short "
      onClick={() => {
        history.push(`/InfluencerChannel?channel=${card.key}`);
      }}
    >
      {alertAttitute()}
      <div className="social-card-img ">
        <img
          src={onGetLogoSocialByType(card?.social_media_type)}
          alt="Social Logo"
          className="logo skeleton"
          onLoad={(e) => {
            e.currentTarget.classList.remove('skeleton');
          }}
          onError={(e) => {
            e.currentTarget.classList.remove('skeleton');
            e.currentTarget.src = userAvatar;
          }}
        />

        <img
          className="profile skeleton"
          style={{ marginBottom: '0.5rem' }}
          src={card?.image_profile_url || userAvatar}
          alt="Influencer Profile"
          onLoad={(e) => {
            e.currentTarget.classList.remove('skeleton');
          }}
          onError={(e) => {
            e.currentTarget.classList.remove('skeleton');
            e.currentTarget.src = userAvatar;
          }}
        />
      </div>
      <div className="social-card-detail">
        <div>
          <span
            style={{
              color: 'whitesmoke',
              fontWeight: 'bold',
              fontSize: '13px',
              height: '20px',
            }}
            className="name"
          >
            {card?.name}
          </span>
        </div>
        <img
          src={usdtLogo}
          alt="usdtLogo"
          style={{ width: '30px', height: '30px', marginBottom: '0.3rem' }}
        />
        {callCal()}
        {status()}
        {alertText()}
        {/* <button type="button" className="btn-change-price">
            <i className="fas fa-pen" />
            <span>{t('influencer:dashboard.Change Price')}</span>
          </button> */}
      </div>
    </Card>
  );
};

export default SocialCardComponent;
