import { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { withTranslation } from 'react-i18next';
import {
  getSumBalanceWalletByUUID,
  getBalanceWalletByUUID,
} from 'src/store/actions';
import MovementTransactionsTable from './MovementTransactionsTable';
import { getUserDetail } from '../../store/users/actions';
import { getMovement } from '../../store/movement/actions';
import DepositTransactionsTable from './DepositTransactionsTable';
import UserProfileComponent from './UserProfileComponent';
import PaymentTransactionsTable from './PaymentTransactionsTable';
import ReferralEarningsTable from './ReferralEarningsTable';
import WithdrawTransactionsTable from './WithdrawTransactionsTable';
import BanHistoryTable from './BanHistoryTable';
import WalletTable from './WalletTable';

const AdvertiserDetailComponent = ({ t }: any) => {
  const dispatch = useDispatch();
  const { uuid }: any = useParams();

  useEffect(() => {
    dispatch(getUserDetail({ id: uuid }));
    dispatch(getMovement({ limit: 10, page: 1, userId: uuid }));
    //dispatch(onGetCurrencies());
    dispatch(getSumBalanceWalletByUUID(uuid));
    dispatch(getBalanceWalletByUUID(uuid));
  }, [uuid]);

  const [activeTab, toggleTab] = useState(1);

  // const { userDetail } = useSelector((state: any) => ({
  //     userDetail: state.Users.userDetail,
  // }));

  const renderTabs = () => {
    return (
      <Nav className="nav-tabs-custom-publisher card-header-tabs border-top">
        <NavItem>
          <NavLink
            to="#"
            className={`${activeTab === 1 && 'active'} px-3`}
            onClick={() => {
              toggleTab(1);
            }}
          >
            {t('Menus.Movement Balance')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to="#"
            className={`${activeTab === 2 && 'active'} px-3`}
            onClick={() => {
              toggleTab(2);
            }}
          >
            {t('Menus.Deposit Transaction')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to="#"
            className={`${activeTab === 3 && 'active'} px-3`}
            onClick={() => {
              toggleTab(3);
            }}
          >
            {t('Menus.Withdraw Transaction')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to="#"
            className={`${activeTab === 4 && 'active'} px-3`}
            onClick={() => {
              toggleTab(4);
            }}
          >
            {t('Menus.Payment')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to="#"
            className={`${activeTab === 5 && 'active'} px-3`}
            onClick={() => {
              toggleTab(5);
            }}
          >
            {t('Menus.Referral')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to="#"
            className={`${activeTab === 6 && 'active'} px-3`}
            onClick={() => {
              toggleTab(6);
            }}
          >
            {t('Menus.Ban History')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to="#"
            className={`${activeTab === 7 && 'active'} px-3`}
            onClick={() => {
              toggleTab(7);
            }}
          >
            {t('Menus.Wallet')}
          </NavLink>
        </NavItem>
      </Nav>
    );
  };

  const renderTabsContent = () => {
    return (
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <MovementTransactionsTable userId={uuid} tab={activeTab} />
        </TabPane>
        <TabPane tabId={2}>
          <DepositTransactionsTable userId={uuid} tab={activeTab} />
        </TabPane>
        <TabPane tabId={3}>
          <WithdrawTransactionsTable userId={uuid} tab={activeTab} />
        </TabPane>
        <TabPane tabId={4}>
          <PaymentTransactionsTable userId={uuid} tab={activeTab} />
        </TabPane>
        <TabPane tabId={5}>
          <ReferralEarningsTable userId={uuid} tab={activeTab} />
        </TabPane>
        <TabPane tabId={6}>
          <BanHistoryTable userId={uuid} tab={activeTab} />
        </TabPane>
        <TabPane tabId={7}>
          <WalletTable userId={uuid} tab={activeTab} />
        </TabPane>
      </TabContent>
    );
  };

  return (
    <>
      <Card>
        <CardBody className="p-0" style={{ marginBottom: '20px' }}>
          <Row className="g-0">
            <div className="col-sm order-2 order-sm-1 p-4">
              <UserProfileComponent />
            </div>
          </Row>
          {renderTabs()}
        </CardBody>
      </Card>
      {renderTabsContent()}
    </>
  );
};

export default withTranslation()(AdvertiserDetailComponent);
