import { useState } from 'react';
import { SocialMediaType } from 'src/components/Publisher/Influencer/Dashboard/SocialChannel/SocialChannel.control';
import notify from 'src/utils/notify';
import i18n from 'src/i18n';
import FacebookLogo from '../../../../assets/images/Facebook_Logo.png';
import TwitterLogo from '../../../../assets/images/twitter_logo.png';
import YoutubeLogo from '../../../../assets/images/youtube-logo.png';
import tikTokLogo from '../../../../assets/images/tiktok-logo.png';
import InstagramLogo from '../../../../assets/images/instagram-logo.png';
import onConnectTikTok from '../SocialCalculate/socialProcess/tiktok/tiktokConnect';
import onConnectYoutube from '../SocialCalculate/socialProcess/youtube/youtubeConnect';
import onTwitterConnect from '../SocialCalculate/socialProcess/twitter/twitterConnect';
import {
  onConnectInstagram,
  onConnectPersonalInstagram,
} from '../SocialCalculate/socialProcess/meta/instagram/instagramConnect';
import {
  onFacebookConnect,
  onFacebookPersonalConnect,
} from '../SocialCalculate/socialProcess/meta/facebook/facebookConnect';

export interface SocialOption {
  logo: string;
  type: SocialMediaType;
  buttonData: SocialButtonData[];
}
export interface SocialButtonData {
  label: string;
  type: SelectChannelButtonType;
  backGroundColor: string;
}

// eslint-disable-next-line no-unused-vars, no-shadow
enum SelectChannelButtonType {
  // eslint-disable-next-line no-unused-vars
  PERSONAL = 'PERSONAL',
  // eslint-disable-next-line no-unused-vars
  BUSINESS = 'BUSINESS',
}

const ChannelAddControl = () => {
  const [socialSelected, setSocialSelected] = useState<SocialMediaType | null>(null);
  const socialOptions = [
    {
      logo: FacebookLogo,
      type: SocialMediaType.FACEBOOK,
      buttonData: [
        {
          label: 'Sign in with personal Facebook',
          type: SelectChannelButtonType.PERSONAL,
          backGroundColor: '#FFF',
        },
        {
          label: 'Sign in with Facebook page',
          type: SelectChannelButtonType.BUSINESS,
          backGroundColor: '#FFF',
        },
      ],
    },
    {
      logo: InstagramLogo,
      type: SocialMediaType.INSTAGRAM,
      buttonData: [
        {
          label: 'Sign in with Instagram',
          type: SelectChannelButtonType.BUSINESS,
          backGroundColor: '#FFF',
        },
      ],
    },
    {
      logo: TwitterLogo,
      type: SocialMediaType.TWITTER,
      buttonData: [
        {
          label: 'Sign in with Twitter',
          type: SelectChannelButtonType.PERSONAL,
          backGroundColor: '#FFF',
        },
      ],
    },
    {
      logo: YoutubeLogo,
      type: SocialMediaType.YOUTUBE,
      buttonData: [
        {
          label: 'Sign in with Google',
          type: SelectChannelButtonType.PERSONAL,
          backGroundColor: '#FFF',
        },
      ],
    },

    // {
    //   logo: tikTokLogo,
    //   type: SocialMediaType.TIKTOK,
    //   buttonData: [
    //     {
    //       label: 'Sign in with Tiktok',
    //       type: SelectChannelButtonType.PERSONAL,
    //       backGroundColor: '#FFF',
    //     },
    //   ],
    // },
  ];

  const onSelectSocial = (type: SocialMediaType): void => {
    setSocialSelected(type);
  };

  const onSubmit = async (
    access_token?: string | null,
    type?: SelectChannelButtonType,
    path?: string | null,
  ) => {
    switch (socialSelected) {
      case SocialMediaType.FACEBOOK: {
        if (type === SelectChannelButtonType.BUSINESS) {
          await onFacebookConnect(path);
          break;
        }
        await onFacebookPersonalConnect(path);
        //notify(i18n.t('error:Please contact supports.'), 'error');
        break;
      }
      case SocialMediaType.TWITTER:
        await onTwitterConnect(path);
        break;
      case SocialMediaType.INSTAGRAM: {
        if (type === SelectChannelButtonType.BUSINESS) {
          await onConnectInstagram(path);
          break;
        }
        await onConnectPersonalInstagram();
        //notify(i18n.t('error:Please contact supports.'), 'error');
        break;
      }
      case SocialMediaType.YOUTUBE:
        if (!access_token) return;
        await onConnectYoutube(access_token, path);
        break;
      case SocialMediaType.TIKTOK:
        onConnectTikTok(path);
        break;
      default:
    }
  };

  const onSubmitWithSocial = async (
    access_token?: string | null,
    type?: SelectChannelButtonType,
    path?: string | null,
    social?: any | null,
  ) => {
    switch (social) {
      case SocialMediaType.FACEBOOK: {
        if (type === SelectChannelButtonType.BUSINESS) {
          await onFacebookConnect(path);
          break;
        }
        await onFacebookPersonalConnect(path);
        //notify(i18n.t('error:Please contact supports.'), 'error');
        break;
      }
      case SocialMediaType.TWITTER:
        await onTwitterConnect(path);
        break;
      case SocialMediaType.INSTAGRAM: {
        if (type === SelectChannelButtonType.BUSINESS) {
          await onConnectInstagram(path);
          break;
        }
        await onConnectPersonalInstagram();
        //notify(i18n.t('error:Please contact supports.'), 'error');
        break;
      }
      case SocialMediaType.YOUTUBE:
        if (!access_token) return;
        await onConnectYoutube(access_token, path);
        break;
      // case SocialMediaType.TIKTOK:
      //   onConnectTikTok(path);
      //   break;
      default:
    }
  };

  return {
    socialOptions,
    onSelectSocial,
    onSubmit,
    socialSelected,
    setSocialSelected,
    onSubmitWithSocial,
  };
};

export default ChannelAddControl;
