import React, { useEffect, useRef, useCallback, useState } from 'react';

// //Import Scrollbar
import SimpleBar from 'simplebar-react';

// i18n
import { withTranslation } from 'react-i18next';
import config from 'src/config';
import Role from 'src/constants/enum/role';
import notify from 'src/utils/notify';
// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter, Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useWindowSize from 'src/utils/windowSize';
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown';
import user1 from '../../assets/images/users/avatar-1.jpg';
import logo from '../../assets/images/logo-AFbrother-white.svg';
import {
  TemporaryDrawer,
  TemporaryDrawerAdvertiser,
} from '../CommonForBoth/Menus/drawer';
import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown';
import LanguageDropdownLeft from '../CommonForBoth/TopbarDropdown/LanguageDropdownLeft';
import NotificationDropdownMaketer from '../CommonForBoth/TopbarDropdown/NotificationDropdownMaketer';

const AdvertiserSidebarContent = (props: any) => {
  const { t } = props;
  const history = useHistory();
  const ref = useRef<any>();
  const { width } = useWindowSize();
  const { user, socialMediaInfo, userInfo } = useSelector((state: any) => ({
    user: state.login.user,
    socialMediaInfo: state.login.socialMediaInfoImage,
    userInfo: state.Users.userInfo,
  }));
  const [init, setInit] = useState(false);

  // if (user.role) {
  //   useEffect(() => {
  //     if (user.role) {
  //       if (user.role === Role.MARKETER || user.role === Role.ADVERTISER)
  //         if (!userInfo.name) {
  //           setTimeout(() => {
  //             notify(t('error:please fill your information.'), 'warning');
  //             history.push('/profile');
  //           }, 1200);
  //         }
  //     }
  //   }, []);
  // }

  // useEffect(() => {
  //   if (!init) {
  //     setInit(true);
  //   } else {
  //     if (user) {
  //       if (user.role === Role.MARKETER || user.role === Role.ADVERTISER) {
  //         if (!userInfo) {
  //           setTimeout(() => {
  //             notify(t('error:please fill your information.'), 'warning');
  //             history.push('/profile');
  //           }, 1200);
  //         }
  //       }
  //     }
  //   }
  // }, [userInfo]);

  const imageProfile = () => {
    if (socialMediaInfo?.length && !user?.image_storage?.key) {
      return (
        <img
          src={socialMediaInfo[0]?.image_profile_url}
          alt="Header Avatar"
          style={{ width: '32px', height: '32px', borderRadius: '50%' }}
        />
      );
    }
    if (user?.image_storage?.key) {
      return (
        <img
          src={`${config.backendUrl}api/profile/${user?.image_storage?.key}`}
          alt="Header Avatar"
          className="navbar-profile-image"
          style={{ width: '32px', height: '32px', borderRadius: '50%' }}
        />
      );
    }
    return (
      <img
        src={user1}
        alt="Header Avatar"
        className="navbar-profile-image"
        style={{ width: '32px', height: '32px', borderRadius: '50%' }}
      />
    );
  };

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously

  return (
    <>
      {width <= 992 ? (
        <>
          <div
            className="justify-content-center align-items-center "
            // style={{ zIndex: '999', width: '30px' }}
          >
            <TemporaryDrawerAdvertiser />
          </div>

          <span className="header-container-mobile notification-mobile">
            <NotificationDropdownMaketer />
          </span>
        </>
      ) : (
        <div
          style={{
            height: '100vh',
            background: 'rgb(73, 80, 87)',
            overflow: 'auto',
          }}
        >
          <div
            style={{
              height: '100vh',
              background: 'rgb(73, 80, 87)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <div style={{ maxHeight: '100%' }} ref={ref}>
                <div id="sidebar-menu" style={{ color: 'white' }}>
                  <ul className="metismenu list-unstyled" id="">
                    <ul className="" style={{ paddingLeft: '35px' }}>
                      <li>
                        <img src={logo} alt="logo" width="180" />
                      </li>
                      <li>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Link to="/profile">
                            <span
                              className="fs-5"
                              style={{
                                marginLeft: '0px',
                                marginBottom: '5px',
                                color: 'white',
                              }}
                            >
                              {' '}
                              {imageProfile()} {userInfo.name}
                            </span>
                          </Link>
                          <span className="header-container-mobile notification-mobile">
                            <NotificationDropdownMaketer />
                          </span>
                        </div>
                      </li>
                      <li>
                        <Link
                          style={{ color: 'white' }}
                          to="/campaigns/dashboard"
                          className="fs-5 d-flex justify-content-start align-items-center "
                        >
                          <i
                            style={{ color: 'white' }}
                            className="fas fa-tachometer-alt d-inline  d-flex justify-content-start align-items-center"
                          />
                          {t('Dashboard')}
                        </Link>
                      </li>
                      <li>
                        <Link
                          style={{
                            color: 'white',
                          }}
                          to="/campaigns/markerter/job"
                          className="fs-5 d-flex justify-content-start align-items-center "
                        >
                          <i
                            style={{ color: 'white' }}
                            className="fa fa-bullhorn  d-inline  d-flex justify-content-start align-items-center"
                          />
                          {t('Campaign')}
                        </Link>
                      </li>
                      <li>
                        <Link
                          style={{ color: 'white' }}
                          to="/template"
                          className="fs-5 d-flex justify-content-start align-items-center "
                        >
                          <i
                            style={{ color: 'white' }}
                            className="bx bx-file d-flex justify-content-start align-items-center"
                          />
                          {t('Template')}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/wallet"
                          style={{ color: 'white' }}
                          className="fs-5 d-flex justify-content-start align-items-center "
                        >
                          <i
                            style={{ color: 'white' }}
                            className="fas fa-wallet d-flex justify-content-start align-items-center"
                          />
                          {t('Wallet')}
                        </Link>
                      </li>
                    </ul>
                  </ul>
                </div>
              </div>
              <SimpleBar style={{ maxHeight: '100%' }} ref={ref}>
                <div
                  id="sidebar-menu"
                  style={{
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <ul
                    style={{ color: 'white', paddingLeft: '35px' }}
                    className="metismenu list-unstyled"
                    id="side-menu"
                  >
                    {/* <li>
                          <Link
                            to="/"
                            className="fs-5 d-flex justify-content-start align-items-center "
                          >
                            <i className="mdi mdi-home-variant d-flex justify-content-start align-items-center" />
                            <span>{t('menu:dashboard')}</span>
                          </Link>
                        </li> */}
                    <ul
                      className="sub-menu"
                      style={{ color: 'white', paddingLeft: '0px' }}
                    >
                      <li>
                        <Link
                          style={{ color: 'white' }}
                          to="/discover"
                          className="fs-5 d-flex justify-content-start align-items-center "
                        >
                          <i
                            style={{ color: 'white' }}
                            className="fa fa-globe d-flex justify-content-start align-items-center"
                          />
                          {t('Discover')}
                        </Link>
                      </li>
                      <li>
                        <a
                          style={{ color: 'white' }}
                          href="https://blog.afbrother.com/"
                          className="fs-5 d-flex justify-content-start align-items-center "
                        >
                          <i
                            style={{ color: 'white' }}
                            className="bx bx-photo-album d-flex justify-content-start align-items-center"
                          />
                          {t('Blog')}
                        </a>
                      </li>
                    </ul>
                  </ul>
                </div>
              </SimpleBar>
            </div>
            <div style={{ paddingBottom: '0px' }}>
              <SimpleBar style={{ maxHeight: '100%' }} ref={ref}>
                <div
                  id="sidebar-menu"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <ul className="metismenu list-unstyled" id="side-menu">
                    {/* <li>
                          <Link
                            to="/"
                            className="fs-5 d-flex justify-content-start align-items-center "
                          >
                            <i className="mdi mdi-home-variant d-flex justify-content-start align-items-center" />
                            <span>{t('menu:dashboard')}</span>
                          </Link>
                        </li> */}
                    <ul
                      className="sub-menu"
                      style={{ color: 'white', paddingLeft: '35px' }}
                    >
                      <li>
                        <Link
                          to="/profile"
                          style={{ color: 'white' }}
                          className="fs-5 d-flex justify-content-start align-items-center "
                        >
                          <i
                            style={{ color: 'white' }}
                            className="bx bx-user d-inline  d-flex justify-content-start align-items-center"
                          />
                          {t('Profile')}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/referral"
                          style={{ color: 'white' }}
                          className="fs-5 d-flex justify-content-start align-items-center "
                        >
                          <i
                            style={{ color: 'white' }}
                            className="bx bx-add-to-queue d-inline  d-flex justify-content-start align-items-center"
                          />
                          {t('Invite Friends')}
                        </Link>
                      </li>
                      <li style={{ marginLeft: '50px' }}>
                        <LanguageDropdownLeft
                          bodyCSS="btn home-dropdown-language dropdown-menu-right"
                          textCSS="text-white"
                          fill="white"
                        />
                      </li>
                      <li>
                        <Link
                          to="/logout"
                          style={{ color: 'white' }}
                          className="fs-5 d-flex justify-content-start align-items-center "
                        >
                          <i
                            style={{ color: 'white' }}
                            className="bx bx-power-off d-flex justify-content-start align-items-center"
                          />
                          {t('Logout')}
                        </Link>
                      </li>
                    </ul>
                  </ul>
                </div>
              </SimpleBar>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withTranslation()(withRouter(AdvertiserSidebarContent));
