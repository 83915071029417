import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';

import { createWebsite, getWebsiteTypes } from 'src/store/actions';
import { Formik, Field, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

// import Breadcrumbs
import { Col, Container, Row, FormGroup, CardBody, Card, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import choice from 'src/assets/images/choice.png';
import CreativeType from 'src/components/Advertiser/CampaignSettings/CreativeType';
import { setLoading, unsetLoading } from 'src/store/global/actions';
import notify from 'src/utils/notify';
import { logout } from 'src/graphql/common/auth';
import config from '../../../config';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import menu from '../../../constants/menu';
import i18n from '../../../i18n';

const initialValues = {
  url: '',
  status: false,
  user_id: 1,
  category: '',
};

const websiteSchema = Yup.object().shape({
  url: Yup.string()
    .required(i18n.t('invalid.Required'))
    .url(
      i18n.t(
        `invalid.The URL field is invalid. Make sure to include the protocol (HTTP/HTTPS)`,
      ),
    ),
  status: Yup.boolean(),
  user_id: Yup.number(),
  category: Yup.string(),
});

const AddWebsite = ({ t, history }: any) => {
  const dispatch = useDispatch();

  const { website_types, user } = useSelector((state: any) => ({
    website_types: state.WebsiteInfo.website_type,
    user: state.login.user,
  }));

  const verify_tag = `<meta name="afbrother" content="${user.verified_key}" >`;

  // let WEBSITE_TYPES: any;
  const WEBSITE_TYPES = website_types.map((type: any) => {
    return {
      value: type.name,
      label: type.name,
      id: type.id,
    };
  });

  const [itemSelected, setItemSelected]: any = useState('');
  const isUrlValid = true;
  const [copied1, setCopied1] = useState(false);
  const [copied2, setCopied2] = useState(false);

  const [url, setUrl]: any = useState({
    url: '',
    status: false,
    userId: 1,
    category: '',
  });

  const handleSetUrl = (value: any, setFieldValue: any) => {
    setFieldValue(
      'url',
      setUrl({
        url: value,
        status: url.status,
        userId: url.userId,
        category: url.category,
      }),
    );
  };

  const getCategoriesList = async () => {
    const API_VERIFY_WEBSITE = `${config.backendUrl}creatives/website_verify?domain_name=${url.url}&verified_key=${user.verified_key}`;

    const accessToken = localStorage.getItem('access-token');

    if (accessToken) {
      const headers = new Headers();
      headers.append('authorization', accessToken);

      const typeIndex: any = [];
      for (let i = 0; i < itemSelected?.length; i++) {
        typeIndex.push(itemSelected[i].id);
      }

      await fetch(API_VERIFY_WEBSITE, { headers })
        .then(async (response) => {
          if (response.status >= 200 && response.status <= 299) {
            const URL = {
              url: url.url,
              status: 'PENDING',
              userId: url.userId,
              category: 'Empty',
              typeIndex,
            };

            dispatch(createWebsite(URL, history));
            dispatch(unsetLoading());
            throw Error(response.statusText);
          } else {
            if (isUrlValid) {
              Swal.fire({
                title: t('Verification failed'),
                text: t(`Verification was not completed. Please try again.`),
                imageUrl: choice,
                width: 300,
                imageHeight: 200,
                imageAlt: 'A tall image',
                customClass: {},
                cancelButtonColor: 'red',
                confirmButtonColor: '#009a78',
              });
              dispatch(unsetLoading());
            }
          }
        })
        .catch((error) => {
          throw error;
        });
    } else {
      await logout();
      window.location.href = '/';
      notify('authorization error please login again', 'error');
    }
  };

  const handleSubmit = async (values: any, { resetForm, setSubmitting }: any) => {
    if (isUrlValid) {
      Swal.fire({
        title: t('swal:Are you sure?'),
        imageUrl: choice,
        imageHeight: 200,
        width: 300,
        imageAlt: 'A tall image',
        customClass: {},
        cancelButtonColor: 'red',
        showCancelButton: true,
        confirmButtonText: t('button:Yes'),
        cancelButtonText: t('button:No'),
        confirmButtonColor: '#009a78',
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(setLoading());
          getCategoriesList();
          resetForm();
          setSubmitting(true);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          setSubmitting(false);
        }
      });
    }
  };

  const handleCopy = (val: string, copyButton: number) => {
    navigator.clipboard.writeText(val);

    if (copyButton === 1) {
      setCopied1(true);
    } else if (copyButton === 2) {
      setCopied2(true);
    }
  };

  const handleDownloadTxtFile = () => {
    const element = document.createElement('a');
    const file = new Blob([user.verified_key], {
      type: 'text/plain',
    });
    element.href = URL.createObjectURL(file);
    element.download = 'dns_txt_record.txt';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  useEffect(() => {
    dispatch(getWebsiteTypes());
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied1) setCopied1(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [copied1]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied2) setCopied2(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [copied2]);

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>
            {t('Page.Add Website')} | {config.appName}
          </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb
            pageTitle={t('Page.Add Website')}
            menus={[{ menu: menu.DASHBOARD }, { menu: menu.WEBSITE, active: true }]}
          />
          <Col lg={12}>
            <Card>
              <CardBody>
                <Col lg={6} md={6} sm={8}>
                  <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={websiteSchema}
                    render={({ errors, touched, setFieldValue, handleChange }) => (
                      <FormikForm>
                        <Row>
                          <FormGroup className="mb-3">
                            <label htmlFor="url" className="input-label fs-5">
                              {t('WebsiteURL')}
                              <span className="input-required">*</span>
                            </label>
                            <Field
                              name="url"
                              type="text"
                              id="url"
                              placeholder="https://"
                              className={`form-control ${
                                errors.url && touched.url && 'is-invalid '
                              } `}
                              onChange={(event: any) => {
                                handleSetUrl(
                                  event.currentTarget.value,
                                  setFieldValue,
                                );
                                handleChange(event);
                              }}
                            />
                            {errors.url && (
                              <div className="invalid-message fs-6">
                                {t('validate form.urlIsRequired')}
                              </div>
                            )}
                          </FormGroup>
                        </Row>
                        <div className="disable-category">
                          <Col className="mb-4">
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Label
                                htmlFor="choices-multiple-remove-button"
                                className="input-label fs-5"
                              >
                                {t('DisableAds')}
                              </Label>
                            </div>
                            <CreativeType
                              fromSetup
                              setTypeData={setItemSelected}
                              typeData={itemSelected}
                              typeOption={WEBSITE_TYPES}
                              style={{ width: '100%' }}
                            />
                          </Col>
                          <div className="mb-4 mt-5">
                            <strong className="font-size-24">
                              {t('Form.Owner Verification')}
                            </strong>
                            <br />
                            <Label className="input-label fs-5 mt-2">
                              {t('Form.option1 header')}
                            </Label>

                            <div className="input-group font-size-14 mb-2">
                              <input
                                name="verify"
                                type="text"
                                id="verify"
                                value={verify_tag}
                                className="form-control disabled-input-meta-tag"
                                disabled
                              />
                              <button
                                type="button"
                                className="input-group-text"
                                onClick={() => {
                                  handleCopy(verify_tag, 1);
                                }}
                              >
                                {!copied1 ? (
                                  <i className="far fa-copy" />
                                ) : (
                                  <>
                                    <i className="fas fa-check-circle" />{' '}
                                  </>
                                )}
                              </button>
                            </div>
                            <Label className="fs-6">{t('Form.option1 desc')}</Label>
                          </div>
                          <div className="mb-4">
                            <Label className="input-label fs-5">
                              {t('Form.option2 header')}
                            </Label>
                            <br />
                            <button
                              type="button"
                              className="btn btn-secondary mb-2"
                              onClick={() => handleDownloadTxtFile()}
                              style={{
                                backgroundColor: '#242639',
                              }}
                            >
                              <i className="mdi mdi-download me-2" />
                              {t('Button.downloadVerifyFile')}
                            </button>
                            <br />
                            <Label className="fs-6">{t('Form.option2 desc')}</Label>
                          </div>
                          <div className="mb-4">
                            <div>
                              <Label className="input-label fs-5">
                                {t('Form.option3 header')}
                              </Label>
                            </div>
                            <div className="input-group font-size-14 mb-2">
                              <input
                                name="verify"
                                type="text"
                                id="verify"
                                value={user.verified_key}
                                className="form-control disabled-input-meta-tag"
                                disabled
                              />
                              <button
                                type="button"
                                className="input-group-text"
                                onClick={() => {
                                  handleCopy(user.verified_key, 2);
                                }}
                              >
                                {!copied2 ? (
                                  <i className="far fa-copy" />
                                ) : (
                                  <>
                                    <i className="fas fa-check-circle" />{' '}
                                  </>
                                )}
                              </button>
                            </div>
                            <Label className="fs-6">{t('Form.option3 desc')}</Label>
                          </div>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={Object.keys(errors)?.length > 0}
                          >
                            {t('button:ValidateAndSubmit')}
                          </button>
                        </div>
                      </FormikForm>
                    )}
                  />
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(AddWebsite);
