import { uniqueId } from 'lodash';
import { useEffect, useState } from 'react';
import useWindowSize from 'src/utils/windowSize';
import GoogleLogin from 'react-google-login';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getUserInfo } from 'src/store/actions';
import { SocialMediaType } from 'src/components/Publisher/Influencer/Dashboard/SocialChannel/SocialChannel.control';
import i18n from 'src/i18n';
import './ChannelAdd.style.scss';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import menu from 'src/constants/menu';
import Swal from 'sweetalert2';
import notify from 'src/utils/notify';
import { useHistory } from 'react-router';
import control, { SocialButtonData, SocialOption } from './ChannelAdd.control';

const ChannelAddPage = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const { socialOptions, onSelectSocial, onSubmit, socialSelected } = control();
  const history = useHistory();
  const { userInfo, facebookError } = useSelector((state: any) => ({
    userInfo: state.Users.userInfo,
    facebookError: state.CalculateSocialMedia.setFacebookError,
  }));
  const [selectData, setSelectData] = useState<any>({});
  const checkUserInfo = () => {
    setTimeout(() => {
      notify(t('error:please fill your information.'), 'warning');
      history.push('/profile');
    }, 500);
  };
  const { t } = useTranslation();
  useEffect(() => {
    if (!userInfo.phone_number) checkUserInfo();
  }, []);
  useEffect(() => {
    if (facebookError) {
      Swal.fire({
        title: t('Sorry Facebook add channel is not available now !!'),
        text: t(`Facebook social channel add is coming soon`),
        icon: 'warning',
        width: 300,
        customClass: {},
        confirmButtonColor: '#009a78',
        confirmButtonText: t(`Agree`),
      });
    }
  }, [facebookError]);

  useEffect(() => {
    dispatch(getUserInfo());

    // const initClient = () => {
    //   gapi.client.init({
    //     clientId: config.google.CLIENT_ID,
    //     scope: 'https://www.googleapis.com/auth/youtube',
    //   });
    // };
    // gapi.load('client:auth2', initClient);
  }, []);

  const changeButton = (social: SocialOption) => {
    if (social.type === SocialMediaType.YOUTUBE) {
      return (
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            margin: '0.5rem',
          }}
        >
          <GoogleLogin
            clientId="372494151787-v07s9qqqqdjmikak3frhns4gkt1a8bf3.apps.googleusercontent.com"
            className="google-btn-channel-add"
            onSuccess={(res: any) => {
              onSubmit(res.accessToken);
            }}
            scope="https://www.googleapis.com/auth/youtube"
            onFailure={() => {}}
            cookiePolicy="single_host_origin"
            accessType="online"
          />
        </div>
      );
    }
    return (
      <div className="social-select-bottom-group ">
        {social?.buttonData.map((buttonDatum: SocialButtonData) => (
          <div
            style={{ backgroundColor: `${buttonDatum.backGroundColor}` }}
            key={uniqueId()}
            className="social-select-group"
            onClick={() => {
              onSelectSocial(social.type);
              onSubmit(null, buttonDatum.type);
            }}
          >
            <div className="social-select-img">
              <img src={social.logo} alt={social.logo} className="img" />
            </div>
            <div className="social-select-text-container">
              <span
                className={
                  buttonDatum.label === 'Sign in with Google'
                    ? 'google-text'
                    : 'social-select-text'
                }
              >
                {buttonDatum?.label ? i18n.t(buttonDatum?.label) : ''}
              </span>
            </div>
          </div>
        ))}{' '}
      </div>
    );
  };

  return (
    <div className="page-content">
      {/* <DisableFacebookModal open setOpen={setIsFacebookError} /> */}

      <div
        className="bread-crumb-20"
        style={width <= 300 ? { fontSize: '9.2px', marginTop: '5px' } : {}}
      >
        <Breadcrumb
          pageTitle={t('Menus:Add Social Channel')}
          menus={[
            { menu: menu.HOME, active: true },
            { menu: menu.OVERVIEW },
            { menu: menu.ADD_SOCIAL_CHANNEL },
          ]}
        />
      </div>
      <div className="container-custom">
        <div className="card-container">
          <p className="header">
            {t(
              'influencer:Add your social channels below. To let followers and brands get to know you better',
            )}
          </p>
          <div className="social-select-container">
            <div className="social-select-btn">
              {/* <GoogleLogin
                className="google-btn"
                clientId="372494151787-v07s9qqqqdjmikak3frhns4gkt1a8bf3.apps.googleusercontent.com"
                onSuccess={(res: any) => {
                  onSelectSocial(SocialMediaType.YOUTUBE, res.accessToken);
                }}
                onFailure={() => {}}
                cookiePolicy="single_host_origin"
              /> */}
              {socialOptions.map((social: SocialOption) => (
                <div>
                  <div
                    key={uniqueId()}
                    className="social-select-icon-group"
                    onClick={() => {
                      onSelectSocial(social.type);
                      setSelectData(social);
                    }}
                  >
                    <img src={social.logo} alt={social.logo} className="img" />
                    <div
                      className={
                        socialSelected === social.type
                          ? 'icon-container icon-check'
                          : 'icon-container'
                      }
                    >
                      <i className="fas fa-check icon" />
                    </div>
                    {/* <span>{i18n.t(social.label)}</span> */}
                  </div>
                </div>
              ))}
            </div>
            {/* {changeButton(social)} */}
          </div>
          {Object.keys(selectData)?.length ? changeButton(selectData) : <></>}
        </div>
      </div>
    </div>
  );
};

export default ChannelAddPage;
