import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router';
import { getSumBalanceWalletByUUID } from 'src/store/actions';
import { useEffect, useState } from 'react';
import avatar from '../../assets/images/users/avatar-1.jpg';
import ChecksumBadge from '../Common/ChecksumBadge';
import { transformStringAmountInput } from '../../utils/input';
import DateTimeTypes from '../../constants/dateTimeTypes';
import DateTimeComponent from '../Common/DateTimeComponent';

const UserProfileComponent = ({ t }: any) => {
  const dispatch = useDispatch();

  const { userDetail, sumWallet } = useSelector((state: any) => ({
    userDetail: state.Users.userDetail,
    sumWallet: state.Wallet.sumWallet,
  }));

  useEffect(() => {
    if (userDetail && userDetail.uuid) {
      dispatch(getSumBalanceWalletByUUID(userDetail.uuid));
    }
  }, [userDetail]);

  return (
    <>
      {!isEmpty(userDetail) && (
        <div className="d-flex flex-column align-items-center flex-md-row">
          <div className="flex-shrink-0 mb-4 mb-md-0">
            <div className="avatar-xl me-3">
              <img
                src={avatar}
                alt=""
                className="img-fluid rounded-circle d-block"
              />
            </div>
          </div>
          <div className="flex-grow-1">
            <div className="user-profile-container">
              <h5 className="mb-2">{t('User Profile')}</h5>
              <p className="text-muted mb-0">
                <strong className="user-profile-container__title">
                  {t('Role')}:
                </strong>
                &nbsp;&nbsp;
                <span
                  className={`font-size-12 badge bg-${userDetail.role.toLowerCase()}`}
                >
                  {t(`${userDetail.role}`)}
                </span>
              </p>
              <p className="text-muted mb-0">
                <strong className="user-profile-container__title">
                  {t('Email')}:
                </strong>
                &nbsp;&nbsp;
                {userDetail.email}
              </p>
              <p className="text-muted mb-0">
                <strong className="user-profile-container__title">
                  {t('Phone Number')}:
                </strong>
                &nbsp;&nbsp;
                {userDetail?.user_info?.country_phone_digit
                  ? `(${userDetail?.user_info?.country_phone_digit})`
                  : ''}
                &nbsp;
                {userDetail?.user_info?.phone_number
                  ? `${
                      userDetail.user_info.phone_number?.length === 10
                        ? userDetail.user_info.phone_number.slice(1)
                        : userDetail.user_info.phone_number
                    }`
                  : ''}
              </p>
              <p className="text-muted mb-0">
                <strong className="user-profile-container__title">
                  {t('Registered At')}:
                </strong>
                &nbsp;&nbsp;
                <DateTimeComponent
                  dateTime={userDetail.created_at}
                  dateTimeType={DateTimeTypes.NORMAL_DATE_TIME}
                />
              </p>
              {/* <p className="text-muted mb-0">
                <strong className="user-profile-container__title">
                  {t('Email Verified At')}:
                </strong>
                &nbsp;&nbsp;
                {userDetail.email_verified_at ? (
                  <DateTimeComponent
                    dateTime={userDetail.email_verified_at}
                    dateTimeType={DateTimeTypes.NORMAL_DATE_TIME}
                  />
                ) : (
                  <span className="badge bg-warning font-size-12">
                    {t('Unverified')}
                  </span>
                )}
              </p> */}
              <p className="text-muted mb-0">
                <strong className="user-profile-container__title">
                  {t('Balance')}:
                </strong>
                &nbsp;&nbsp;
                {!isEmpty(sumWallet)
                  ? `${transformStringAmountInput(sumWallet.balance)} USDT`
                  : t('No Wallet')}
              </p>
              <p className="text-muted">
                <strong className="user-profile-container__title">
                  {t('Checksum Wallet')}:
                </strong>
                &nbsp;&nbsp;
                <ChecksumBadge
                  checksum_status={
                    !isEmpty(userDetail.wallet) ? sumWallet.checksum_status : false
                  }
                />
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withTranslation()(UserProfileComponent);
