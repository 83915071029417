import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { useDispatch } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import registerBackground from 'src/assets/images/Background-homepage-jpg.jpg';
import RegisterForm from 'src/components/Authentication/register/RegisterForm';
import SelectRole from 'src/components/Authentication/register/SelectRole';
import useWindowSize from 'src/utils/windowSize';
import config from '../../config';
import { getReferralEmail } from '../../store/actions';
import CarouselPage from './CarouselPage';

const Register = ({ location }: any) => {
  const dispatch = useDispatch();
  const [role, setRole] = useState(null);
  const { ref } = queryString.parse(location.search);
  const { width } = useWindowSize();
  const { role: roleParam }: any = useParams();

  useEffect(() => {
    if (ref) {
      dispatch(getReferralEmail(ref));
    }
  }, [ref]);

  useEffect(() => {
    if (roleParam) setRole(roleParam.toUpperCase());
  }, []);

  return (
    <>
      <MetaTags>
        <title>Register | {config.appName}</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            {isEmpty(role) ? (
              <div
                style={{
                  backgroundImage: `url(${registerBackground})`,
                  backgroundSize: 'cover',
                }}
              >
                <SelectRole setRole={setRole} authType="register" />
              </div>
            ) : (
              <>
                <RegisterForm setRole={setRole} role={role} path={location} />
                {width >= 768 && <CarouselPage />}
              </>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withRouter(Register);
