import { Formik, Form as FormikForm, ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import capitalize from 'src/utils/captilalize';
import { CategoriesMarketerCampaign } from 'src/common/data/categories-marketer-campaign';
import { Card, FormGroup, Label, Progress } from 'reactstrap';
import './MarketerJobCampaignDetail.style.scss';
import './stepper.style.scss';
import BTC from 'src/assets/images/crypto/btc.svg';
import { getAvailableCurrencies } from 'src/store/actions';
import stepCreate from 'src/assets/images/Step1.png';
import Trash from 'src/assets/images/Trash-01.png';
import dropdown from 'src/assets/images/arrow-down-sign-to-navigate-black.png';
import Tooltips from '@mui/material/Tooltip';
import stepSummary from 'src/assets/images/Step4.png';
import stepSelectSocial from 'src/assets/images/Step2.png';
import stepChooseInfu from 'src/assets/images/Step3.png';
import CustomButton from 'src/components/Common/CustomButton';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';

import config from 'src/config';

import FloatingInputComponent from 'src/components/shared/FloatingInput/FloatingInput.component';
import TextEditorInput from 'src/components/Common/input/textEditorInput';
import ErrorFocus from 'src/utils/ErrorFocus';
import { useDispatch, useSelector } from 'react-redux';
import { DescriptionJobNew } from 'src/store/marketerJobNew/reducer';
import { useHistory } from 'react-router';
import control from './CreateMarketerJobCampaignPageNewstep1.control';
import CreateMarketerPublicJobMock from '../CreateMarketerCampaign/CreateMarketerPublicJob/CreateMarketerPublicJobMock';
import DropdownSearch from './DropdownSearch';

const CreateMarketerJobCampaignPageNewstep1 = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    handleSubmit,
    validationSchema,
    formValues,
    marketerJob,
    setFormValues,
    data,
  } = control();
  const [defaultCurrency, setDefaultCurrency] = useState(9);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const formControls: FormControl[] = [
    {
      label: 'marketer:create_job_campaign.job_name',
      name: 'name',
      type: FormInputType.STRING,
      required: true,
      tooltip: t(
        'marketer:create_job_campaign.Your job name for example, Promote AFbrother website',
      ),
    },
  ];

  const { currency } = useSelector((state: any) => ({
    currency: state.Currencies.availableCurrencies,
  }));
  useEffect(() => {
    dispatch(getAvailableCurrencies());
    const values = localStorage.getItem('descriptionJobNew');
    if (values) {
      setFormValues(JSON.parse(values));
    }
  }, []);
  const dispatch = useDispatch();

  const inputsName = (errors: any, touched: boolean, handleChange: any) => {
    return formControls.map((input: FormControl, index: number) => {
      switch (input.type) {
        case FormInputType.STRING:
          return textInput(input, errors, touched, handleChange, index);

        default:
          return textInput(input, errors, touched, handleChange, index);
      }
    });
  };
  // const inputEdit = (errors: any, touched: boolean, handleChange: any) => {
  //   return formControls.map((input: FormControl) => {
  //     switch (input.type) {
  //       case FormInputType.TEXT_EDITOR_INPUT:
  //         return textEditorInput(input, errors, touched, handleChange);
  //       default:
  //         return textEditorInput(input, errors, touched, handleChange);
  //     }
  //   });
  // };

  const [selectedImage, setSelectedImage]: any = useState(null);
  const [cropper, setCropper]: any = useState(null);
  const [cropData, setCropData] = useState(null);
  const [jobImage, setJobImage] = useState<any>(
    marketerJob?.thumbnail_cover
      ? `${config.backendUrl}api/jobThumbnail/${marketerJob?.thumbnail_cover}`
      : '',
  );

  useEffect(() => {
    if (marketerJob?.thumbnail_cover) {
      setJobImage(
        `${config.backendUrl}api/jobThumbnail/${marketerJob?.thumbnail_cover}`,
      );
    }
  }, [marketerJob]);

  useEffect(() => {
    if (currency?.length) {
      const defaultCurrency = currency.filter(
        (index: any) => index?.symbol === 'USDT_BEP20',
      );
      setDefaultCurrency(defaultCurrency.id);
      console.log('defaultCurrency', defaultCurrency);
    }
  }, [currency]);

  useEffect(() => {
    setTimeout(() => {
      cropper?.setData({ width: 600, height: 400 });
    }, 500);
  }, [selectedImage, cropper]);

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setCropData(cropper.getCroppedCanvas().toDataURL());

      const url = cropper.getCroppedCanvas().toDataURL();
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          const uuid = crypto.randomUUID();
          const file: any = new File([blob], uuid, { type: 'image/png' });

          setFormValues({ ...formValues, thumbnail_cover: file });
        });
    }
  };

  const handleFileSelected = async (e: any) => {
    const files: any = Array.from(e.target.files);

    const file = files[0];

    // const newFile = await fixImageSize({ file, width: 600, height: 400 });
    const reader = new FileReader();
    const imageTag: any = document.getElementById('jobImageRef');
    // imageTag.title = file?.name;

    reader.onload = (event) => {
      imageTag.src = event?.target?.result;
      setSelectedImage(event?.target?.result);
    };
    reader.readAsDataURL(file);
    setJobImage(file);
    e.target.files = null;

    // await uploadSocialJobImage(newSizeFile, file);
  };

  const deleteUploadedImage = async () => {
    setJobImage('');
    setFormValues({ ...formValues, thumbnail_cover: '' });
    setSelectedImage(null);
    setCropData(null);
  };
  const [toggleMore, setToggleMore] = useState(true);

  const handleMore = () => setToggleMore((prev) => !prev);

  const uploadJobImageView = () => {
    // if (marketerJob.job_type !== 'PUBLISH') return <></>;
    return (
      <div style={{ width: '100%' }}>
        <div
          style={{
            maxWidth: '620px',
            border: '1px solid rgb(148, 157, 157)',
            borderRadius: '10px',
            margin: '10px 0',
          }}
        >
          <input
            onChange={async (e: any) => {
              await handleFileSelected(e);
            }}
            ref={fileInputRef}
            type="file"
            multiple
            style={{
              display: 'none',
              width: '100%',
              height: '100%',
            }}
          />

          <div
            className="text-center"
            style={{ padding: '0.5rem', maxWidth: '620px', position: 'relative' }}
          >
            <h5> {t('marketer:job_detail:Product Image')}</h5>
            <img
              id="jobImageRef"
              src={jobImage}
              alt="jobImage"
              style={{
                maxHeight: '372px',
                maxWidth: '620px',
                // objectFit: 'cover',
                aspectRatio: '6/4',
                width: '100%',
                height: '100%',
                visibility: jobImage ? 'visible' : 'hidden',
              }}
            />
            <button
              type="button"
              onClick={() => {
                deleteUploadedImage();
              }}
              style={{
                cursor: 'pointer',
                right: '0',
                position: 'absolute',
                visibility: jobImage ? 'visible' : 'hidden',
                border: '0',
                backgroundColor: 'inherit',
              }}
            >
              <img
                src={Trash}
                alt="trash"
                style={{ width: '30px', height: '30px' }}
              />
            </button>
            <div
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                fileInputRef?.current?.click();
              }}
            >
              {!jobImage && (
                <>
                  <h6>{t('marketer:job_detail:Add an image of your product.')} </h6>
                  <h6>
                    {t(
                      'marketer:job_detail:To make influencers more interested in your products.',
                    )}
                  </h6>
                </>
              )}
              <div>
                <i
                  className="display-6 text-muted bx bxs-cloud-upload lg-4 "
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
                <h5 style={{ display: 'inline-block' }}>
                  {t('creative:Drop files here or click to upload')}
                </h5>
              </div>
              <h6 style={{ color: 'red' }}>
                {t('marketer:job_detail:Image file .jpg or .png')}
              </h6>
            </div>
          </div>
        </div>
        {selectedImage && (
          <>
            <Cropper
              // className="cropper"
              style={{
                width: '100%',
                height: '400px',
                border: '1px solid',
              }}
              src={selectedImage}
              // viewMode={2}
              center={false}
              aspectRatio={6 / 4}
              zoomable={false}
              dragMode="move"
              // cropBoxResizable={false}
              initialAspectRatio={1}
              background={false}
              // viewMode={1}
              // minCropBoxHeight={10}
              // minCropBoxWidth={10}
              toggleDragModeOnDblclick={false}
              data={{
                width: 600,
                height: 400,
              }}
              checkOrientation={false}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              // zoomable={false}
            />
            <button
              type="button"
              id="leftbutton"
              onClick={getCropData}
              className="crop-button"
              style={{ margin: '1rem 0' }}
            >
              <span>{t('button:Crop Image')}</span>
            </button>
            {cropData && (
              <div
                style={{
                  maxWidth: '600px',
                  maxHeight: '400px',
                  border: '1px solid rgb(148, 157, 157)',
                  borderRadius: '10px',
                }}
              >
                <img
                  src={cropData}
                  alt="cropped"
                  className="crop-image"
                  style={{
                    maxWidth: '600px',
                    maxHeight: '400px',
                    // objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                    // visibility: cropData ? 'visible' : 'hidden',
                  }}
                />
              </div>
            )}
            {/* {cropData && (
              <span style={{ fontSize: '24px', fontWeight: 'bold' }}>
                {t('Crop')}
              </span>
            )} */}
          </>
        )}
      </div>
    );
  };

  const campaignData = () => {
    return (
      <div
        className="create-campaign-detail-container"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Card className="detail-card">
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div className="header-text">
              {t('marketer:create_job_campaign:Campaign details')}
            </div>
            <button
              type="button"
              onClick={() => {
                handleMore();
              }}
              style={{
                display: 'flex',
                gap: '1rem',
                alignItems: 'center',
                fontWeight: 'bold',
                border: 'none',
                backgroundColor: 'transparent',
              }}
            >
              {t('More details')}
              <img src={dropdown} alt="" width={20} />
            </button>
          </div>

          <MoreDetailContainer toggleMore={toggleMore}>
            <div
              style={{
                borderTop: '1px solid #495057',
                width: '100%',
                marginBottom: '0.3rem',
              }}
            />
            <div className="title-head">
              {t('marketer:create_job_campaign:Campaign name')}
            </div>
            <div className="detail-text">{data?.name}</div>
            <div className="title-head">
              {t('marketer:create_job_campaign:Brand name')}
            </div>
            <div className="detail-text">{data?.brand_name}</div>
            <div className="title-head">
              {t('marketer:create_job_campaign:Product name')}
            </div>
            <div className="detail-text">{data?.product_name}</div>
            <div className="title-head">
              {t('marketer:create_job_campaign:Product detail')}
            </div>
            <div
              className="detail-text"
              style={{ lineHeight: '20px' }}
              dangerouslySetInnerHTML={{
                __html: data?.product_detail,
              }}
            />
          </MoreDetailContainer>
        </Card>
      </div>
    );
  };

  const jobType = () => {
    return (
      <div>
        <h5
          style={{
            display: 'flex',
            textAlign: 'start',
            margin: '0.5rem 0 0 0.5rem',
          }}
        >
          {t('Job Type')}
        </h5>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '1rem',
            marginTop: '1rem',
          }}
        >
          {/* <CustomButton size="xs" status="submit" label={t('Button:Invite-Only')} /> */}
          <button
            type="button"
            className={`button-type-job ${
              formValues.job_type === 'INVITE_ONLY' && 'btn-bg-active'
            }`}
            style={{ position: 'relative' }}
            onClick={() => {
              setFormValues({
                ...formValues,
                job_type: 'INVITE_ONLY',
                aptitude_ids: null,
                country_name: null,
                is_female: null,
                is_female_lgbt: null,
                is_male: null,
                is_male_lgbt: null,
                max_age: null,
                min_age: null,
                fixed_price: null,
                currency_id: defaultCurrency || 9,
                name: '',
                thumbnail_cover: '',
              });
            }}
          >
            <span>{t('Button:Invite-Only')}</span>
            {formValues.job_type === 'INVITE_ONLY' && (
              <i className="fas fa-check " />
            )}
          </button>
          {/* <CustomButton size="xs" status="submit" label={t('Button:Suggest Job')} /> */}
          <button
            type="button"
            className={`button-type-job ${
              formValues.job_type === 'PUBLISH' && 'btn-bg-active'
            }`}
            style={{ position: 'relative' }}
            onClick={() => {
              setFormValues({
                ...formValues,
                job_type: 'PUBLISH',
                marketer_campaign_key: '',
                country_name: 'ALL',
                aptitude_ids: [],
                is_male: false,
                is_female: false,
                is_male_lgbt: false,
                is_female_lgbt: false,
                min_age: 0,
                max_age: 120,
                fixed_price: null,
                currency_id: defaultCurrency || 9,
                name: '',
                thumbnail_cover: '',
              });
            }}
          >
            <span>{t('Button:Suggest Job')}</span>
            {formValues.job_type === 'PUBLISH' && <i className="fas fa-check " />}
          </button>
        </div>
      </div>
    );
  };

  const textInput = (
    input: FormControl,
    errors: any,
    touched: any,
    handleChange: any,
    index: number,
  ) => {
    return (
      <FormGroup
        key={index}
        className="text-start"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <FloatingInputComponent
          name={input.name}
          // type={input.input_type || 'input'}
          value={formValues[input.name as keyof DescriptionJobNew] as string}
          isInvalid={errors[input.name] && touched[input.name]}
          onChange={(e: any) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
            handleChange(e);
          }}
          label={input.label}
        />
        {input.tooltip && input.name !== 'textarea' ? (
          <Tooltips title={t(input.tooltip || '-')} placement="top-start">
            <i
              className="fas fa-info-circle ms-2 font-size-16"
              style={{
                cursor: 'pointer',
                marginTop: '2.9rem',
              }}
              id="tooltipInfo"
            />
          </Tooltips>
        ) : (
          ''
        )}
      </FormGroup>
    );
  };
  const uploadImg = () => {
    return <div>{uploadJobImageView()}</div>;
  };
  const options: any = [
    { label: 'Option 1', value: '1', price: 0.001 },
    { label: 'Option 2', value: '2', price: 0.01 },
    { label: 'Option 3', value: '3', price: 1 },
  ];
  const addPaymentMethod = () => {
    return (
      <>
        <h5
          style={{
            display: 'flex',
            textAlign: 'start',
            margin: '0.5rem 0 0 0.5rem',
          }}
        >
          {t('Pay method')}
        </h5>
        {/* <DropdownSearch
          options={currency}
          formData={formValues}
          setFormData={setFormValues}
        /> */}
      </>
    );
  };

  const stepDisplay = () => {
    return (
      <div className="stepper-container">
        <>
          <img src={stepCreate} alt="stepCreate" className="stepper-active-img" />

          <div className="stepper-dot" />
        </>
        {formValues.job_type === 'INVITE_ONLY' && (
          <>
            <img
              src={stepSelectSocial}
              alt="stepCreate"
              className="stepper-active-img"
            />

            <div className="stepper-dot" />
          </>
        )}
        <>
          <img
            src={stepChooseInfu}
            alt="stepChooseInfu"
            className="stepper-active-img"
          />

          <div className="stepper-dot" />
        </>

        <img src={stepSummary} alt="stepSummary" className="stepper-active-img" />
      </div>
    );
  };

  return (
    <div className="page-content text-center">
      <div className="stepper-progressbar-container">
        <div style={{ marginBottom: '38px' }}>{stepDisplay()}</div>
        <Progress
          style={{
            transform: 'scale(0.8)',
            height: '25px',
            width: '100%',
            borderRadius: '44px',
            background: '#D9D9D9',
          }}
          max="3"
          value="0.2"
        />
        <div
          style={{
            marginTop: '24px',
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '20px',
            letterSpacing: '0em',
            color: '#495057',
          }}
        >
          {t('marketer:create_job_campaign:Campaign step1')}
        </div>
      </div>

      <div className="job-detail-container">
        <div className="card-container">
          <Formik
            initialValues={formValues}
            onSubmit={handleSubmit}
            enableReinitialize
            validationSchema={() => {
              return Yup.object(validationSchema);
            }}
          >
            {({ input, errors, touched, handleChange }: any) => {
              return (
                <>
                  <FormikForm>
                    {campaignData()}
                    {jobType()}
                    {inputsName(errors, touched, handleChange)}
                    {uploadImg()}
                    {addPaymentMethod()}
                    {/* {inputs(errors, touched, handleChange)} */}
                    {/* {inputEdit(errors, touched, handleChange)} */}

                    {formValues.job_type === 'PUBLISH' && (
                      <CreateMarketerPublicJobMock
                        formData={formValues}
                        setFormData={setFormValues}
                      />
                    )}
                    <div
                      className="mt-5 text-center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <CustomButton
                        status="submit"
                        size="s"
                        label={t('marketer:create_campaign.next')}
                      />
                    </div>
                  </FormikForm>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const MoreDetailContainer = styled.div<{ toggleMore: boolean }>`
  /* border: 5px solid; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  /* gap: 1rem; */
  max-height: ${({ toggleMore }) => (toggleMore ? '1000px' : '0')};
  overflow: hidden;
  opacity: ${({ toggleMore }) => (toggleMore ? '1' : '0')};
  transition: all 0.5s ease;
`;

export default CreateMarketerJobCampaignPageNewstep1;

enum FormInputType {
  // eslint-disable-next-line no-unused-vars
  STRING,
  // eslint-disable-next-line no-unused-vars
  TEXT_EDITOR_INPUT,
}

interface FormControl {
  label: string;
  name: any;
  type: FormInputType;
  required?: boolean;
  disabled?: boolean;
  max_length?: number;
  min_length?: number;
  tooltip?: string;
}
