import { SocialMediaType } from 'src/components/Publisher/Influencer/Dashboard/SocialChannel/SocialChannel.control';
import config from 'src/config';

export const onConnectInstagram = async (
  path: string | null = 'social-calculate',
) => {
  const permissionScope: string =
    'email,read_insights,pages_show_list,instagram_basic,public_profile,pages_read_engagement';
  // 'public_profile,read_insights,pages_show_list,instagram_basic,instagram_manage_insights,instagram_content_publish,email,user_photos,user_posts,user_friends';
  const clientId: string | undefined = config.facebook.id;
  if (!clientId) return;
  const redirectUrl: string = `${config.appUrl}influencer/${path}/${SocialMediaType.INSTAGRAM}/`;
  window.location.href = `https://www.facebook.com/v14.0/dialog/oauth?client_id=${clientId}&scope=${permissionScope}&redirect_uri=${redirectUrl}`;
};

export const onConnectPersonalInstagram = async () => {
  window.location.href = `${config.backendUrl}api/instagram/register`;
};
