/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Column } from 'react-table';
import { isEmpty, set } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@mui/material';
import menu from 'src/constants/menu';
import Swal from 'sweetalert2';
import moment from 'moment';
import { useHistory } from 'react-router';
import Modalban from 'src/components/Advertiser/Models/SelectInfuencer/ModalBan';
import { getUsers } from 'src/graphql/admin/user';
import { setLoading, unsetLoading } from 'src/store/actions';
import { transformStringAmountInput } from 'src/utils/input';
import CustomTable from '../Common/CustomTable';
import { delete2FA } from '../../store/users/actions';
import PageSizeOptions from '../Table/PageSizeOptions';
import SearchBar from '../Table/SearchBar';
import TransactionDetailModal from '../Table/TransactionDetailModal';
import UserRoleOptions from '../Table/UserRoleOptions';
import DateTimeTypes from '../../constants/dateTimeTypes';
import DateTimeComponent from '../Common/DateTimeComponent';
import 'src/pages/Admin/admin.style.scss';
import Breadcrumb from '../Common/Breadcrumb';

import SortByAptitude from '../Advertiser/Models/SelectInfuencer/SortByAptitude';
import CustomButton from '../Common/CustomButton';
// eslint-disable-next-line import/order
import { createBanAccountGql } from 'src/graphql/common/ban';

const UsersTable = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [uuidState, setUuidState] = useState('');
  // Local State
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const selectedRow = 0;
  const [role, setRole] = useState('');
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');
  const [users, setUsers] = useState<any>({});
  const [param, setParam] = useState({
    page,
    limit: pageSize,
    role,
    search,
  });

  // Redux
  const { _users } = useSelector((state: any) => ({
    _users: state.Users.users,
  }));

  const fetchGleamJob = async (payload: any) => {
    try {
      dispatch(setLoading());
      const response = await getUsers(payload);
      setUsers(response);
    } catch (error) {
      setUsers({});
    } finally {
      dispatch(unsetLoading());
    }
  };

  useEffect(() => {
    fetchGleamJob({ limit: 10, page: 1 });
  }, []);

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handlePageSizeChange = async (size: string) => {
    const newPageSize = parseInt(size, 10);

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    const payload = {
      ...getDefaultPayload(newPage),
      limit: newPageSize,
    };

    await fetchGleamJob(payload);
    setPageSize(newPageSize);
    setParam({ ...param, limit: newPageSize });
  };

  const handleSearch = async (val: string) => {
    const payload = {
      ...getDefaultPayload(1),
      search: val,
    };

    await fetchGleamJob(payload);
    setSearch(search);
    setParam(payload);
  };

  const handleClear = async () => {
    const payload = {
      ...getDefaultPayload(1),
      search: '',
    };

    await fetchGleamJob(payload);
    setSearch('');
    setParam(payload);
  };

  const dataTimezone = (created_at: any) => {
    return moment(created_at);
  };

  const banSwal = async () => {
    setIsOpen(true);
  };

  const columns: Column<any>[] = React.useMemo(
    () => [
      // {
      //   Header: t('table:Detail'),
      //   accessor: (originalRow) => {
      //     const { uuid } = originalRow;

      //     return (
      //       <>
      //         <Link to={`/admin/users/${uuid}`}>
      //           <i className="fas fa-file-alt fa-2x c-pointer" />
      //         </Link>
      //       </>
      //     );
      //   },
      //   id: 'action',
      //   disableSortBy: true,
      // },
      {
        Header: t('table:Registered At'),
        accessor: ({ created_at }) => {
          return (
            <DateTimeComponent
              dateTime={dataTimezone(created_at)}
              dateTimeType={DateTimeTypes.GET_DATE_TIME_FULL_FORMAT}
            />
          );
        },
        id: 'created_at',
      },
      {
        Header: t('table:Email'),
        accessor: ({ email }) => {
          return email;
        },
        id: 'email',
        disableSortBy: true,
      },
      {
        Header: t('table:Social'),
        accessor: ({ social_name }) => {
          return social_name;
        },
        id: 'social_name',
        disableSortBy: false,
      },
      {
        Header: t('table:Role'),
        accessor: ({ role }) => {
          return t(`${role}`);
        },
        id: 'role',
        width: 100,
        disableSortBy: true,
      },
      {
        Header: <div className="text-header">{t(`table:Action`)}</div>,
        accessor: ({ uuid }: any) => {
          return (
            <a
              className="cursor  fw-bold view-profile"
              href={`users/${uuid}`}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/admin/users/${uuid}`);
              }}
            >
              {t(`table:user detail`)}
            </a>
          );
        },
        id: 'user_detail',
        width: 150,
        disableSort: true,
      },
      {
        Header: <div className="text-header">{t(`table:2FA`)}</div>,
        accessor: ({ uuid, two_factor_secret }: any) => {
          if (two_factor_secret !== null) {
            return (
              <div
                onClick={async (e) => {
                  await dispatch(delete2FA(uuid));
                  window.location.reload();
                }}
              >
                <CustomButton label={t('table:Delete')} status="cancel" size="sss" />
              </div>
            );
          } else {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                .
              </div>
            );
          }
        },
        id: 'delete_2FA',
        disableSort: true,
      },
      {
        Header: <div className="text-header">{t(`table:Ban`)}</div>,
        accessor: ({ uuid, isBan }: any) => {
          if (isBan) {
            return 'Banned';
          }
          return (
            <div
              onClick={async (e) => {
                setUuidState(uuid);
                await banSwal();
              }}
            >
              <CustomButton label={t('Ban')} status="cancel" size="ssss" />
            </div>
          );
        },
        id: 'user_ban',
        disableSort: true,
      },
      {
        Header: <div className="text-header">{t('table:Balance')}</div>,
        accessor: ({ balance }) => {
          return `${transformStringAmountInput(balance)} USDT`;
        },
        id: 'balance',
        width: 180,
        disableSort: true,
      },
      // {
      //   Header: t('table:Email Verified'),
      //   accessor: ({ email_verified_at }) => {
      //     return email_verified_at ? 'Yes' : 'No';
      //   },
      //   id: 'email_verified_at',
      //   disableSortBy: true,
      // },
    ],
    [t, users],
  );
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const data = React.useMemo(() => {
    return !isEmpty(users) ? users.items : [];
  }, [users]);

  const handlePageChange = async (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...getDefaultPayload(newPage),
    };
    setParam({ ...param, page: newPage });

    await fetchGleamJob(payload);
  };

  const handleBan = async (value: any) => {
    try {
      await createBanAccountGql(value);
      window.location.reload();
    } catch (error) {
      //
    }
    toggle();
  };

  const handleRoleChange = async (role: string) => {
    const payload = {
      ...getDefaultPayload(1),
      role,
    };

    await fetchGleamJob(payload);
    setRole(role);
    setParam({ ...param, role });
  };

  const getDefaultPayload = (page: number) => {
    setPage(page);
    setParam({ ...param, page });
    return {
      ...param,
      page,
    };
  };

  const handleSort = useCallback(
    async (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(newPage),
        sortBy: newSortBy,
        sortType: newSortType,
      };

      await fetchGleamJob(payload);
      setSortBy(newSortBy);
      setSortType(newSortType);
    },
    [role, search, page, pageSize],
  );

  const hiddenOnXS: any = ['created_at', 'email_verified_at'];
  const hiddenOnSM: any = ['created_at'];
  const hiddenOnMD: any = [];
  const hiddenOnLG: any = [];

  if (!users) return <></>;
  return (
    <>
      <CustomTable
        headerCSS="fw-normal bg-header-table"
        columns={columns}
        data={data}
        page={page}
        pageSize={pageSize}
        total={users.totalCount}
        handlePageChange={handlePageChange}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
        onSort={handleSort}
      >
        <TransactionDetailModal
          data={
            // eslint-disable-next-line no-nested-ternary
            !isEmpty(users) && !isEmpty(users.items)
              ? users.items[selectedRow].withdraw_transaction
                ? users.items[selectedRow].withdraw_transaction
                : users.items[selectedRow].deposit_transaction
              : null
          }
          isOpen={modalOpen}
          handleToggle={handleModalToggle}
          handleClose={handleModalClose}
          title={t('Deposit Detail')}
        />
        <Breadcrumb
          pageTitle={t('Page.All User')}
          menus={[{ menu: menu.DASHBOARD }, { menu: menu.ALL_USER, active: true }]}
        />
        <div className="user-page-filter-table">
          <PageSizeOptions handlePageSizeChange={handlePageSizeChange} />
          <div className="role-email-container">
            <UserRoleOptions
              defaultValue={role}
              handleRoleChange={handleRoleChange}
            />
            <div className="search-bar">
              <SearchBar
                placeholder={t('table:Users Search Placeholder')}
                handleSearch={handleSearch}
                handleClear={handleClear}
                disablePadding
              />
            </div>
          </div>
        </div>
        <Modalban
          isOpen={isOpen}
          toggle={toggle}
          uuid={uuidState}
          onBanAccount={handleBan}
        />
      </CustomTable>
    </>
  );
};

export default UsersTable;
