import Select from 'react-select';
import { format } from 'date-fns';
import { ErrorMessage } from 'formik';
import { useEffect, useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { useTranslation } from 'react-i18next';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Label } from 'reactstrap';
import FloatingDateInputComponent from 'src/components/shared/FloatingDateInput/FloatingDateInput.component';
import FloatingInputComponent from 'src/components/shared/FloatingInput/FloatingInput.component';
import i18n from 'src/i18n';
import config from 'src/config';
import moment from 'moment-timezone';
import {
  createUserInfo,
  updateUserInfo,
  uploadProfile,
} from 'src/store/users/actions';
import {
  FullNameToShortName,
  ReactFlagsSelectCountry,
  shortPhoneDigit,
} from 'src/utils/country';
import ErrorFocus from 'src/utils/ErrorFocus';
import notify from 'src/utils/notify';
import * as Yup from 'yup';
import TimeZone from '../../../../utils/timezone.json';
import 'yup-phone';
import lgbtIcon from '../../../../assets/images/lgbt_icon.svg';
import menIcon from '../../../../assets/images/men_icon.svg';
import womenIcon from '../../../../assets/images/women_icon.svg';
import FormInputType from './FormInputType.enum';
import countryCodes from '../../../../utils/countryCodes.json';

const InfluencerAccountControl = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { influencerData, profileImageKey, user } = useSelector((state: any) => ({
    influencerData: state.Users.userInfo,
    profileImageKey: state.login.user?.image_storage?.key,
    user: state.login.user,
  }));
  const dq = 'xwx';
  const [value, setValue] = useState(dq);
  //? Form Controls
  const formControls: FormControl[] = [
    {
      label: 'influencer:account.personal_information',
      name: 'personal_information',
      type: FormInputType.HEADER,
      required: true,
    },
    {
      label: 'influencer:account.name',
      name: 'name',
      type: FormInputType.STRING,
      required: true,
    },
    {
      label: 'influencer:account.phone_number',
      name: 'phone_number',
      type: FormInputType.STRING,
      required: true,
    },
    {
      label: 'influencer:account.country',
      name: 'country',
      type: FormInputType.COUNTRY,
      required: true,
    },
    {
      label: 'influencer:account.date_of_birth',
      name: 'date_of_birth',
      type: FormInputType.BIRTH_DAY,
      required: true,
    },
    {
      label: 'influencer:account.timezone',
      name: 'timezone',
      type: FormInputType.TIMEZONE,
    },
    {
      label: 'influencer:account.nationality',
      name: 'nationality',
      type: FormInputType.STRING,
      required: true,
    },
    {
      label: 'influencer:account.gender',
      name: 'gender',
      type: FormInputType.GENDER,
      required: true,
    },
    {
      label: 'influencer:account.isLGBT',
      name: 'isLGBT',
      type: FormInputType.IS_LGBT,
      required: true,
    },
    {
      label: 'influencer:account.hr',
      name: 'hr',
      type: FormInputType.HR,
    },
    {
      label: 'influencer:account.shipping_address',
      name: 'shipping_address',
      type: FormInputType.HEADER,
    },
    // {
    //   label: 'influencer:account.address_phone_number',
    //   name: 'address_phone_number',
    //   type: FormInputType.STRING,
    // },
    {
      label: 'influencer:account.address',
      name: 'address',
      type: FormInputType.STRING,
      input_type: 'address_text_area',
    },

    {
      label: 'influencer:account.postcode',
      name: 'postcode',
      type: FormInputType.STRING,
    },
    {
      label: 'influencer:account.province',
      name: 'province',
      type: FormInputType.STRING,
    },
  ];

  const initialValues = {
    // user_name: '',
    phone_number: '',
    gender: '',
    isLGBT: false,
    nationality: '',
    date_of_birth: '',
    name: '',
    address: '',
    postcode: '',
    province: '',
    country: '',
    address_phone_number: '',
    short_country: '',
    country_phone_digit: '+66',
    country_phone_number: '',
    timezone: influencerData?.timezone,
    timezone_offset: 0,
  };
  const [formValues, setFormValues] = useState(initialValues);
  const validationSchema = Yup.object({
    name: Yup.string().required(t('influencer:account.required')),
    country_phone_number: Yup.string().required(t('influencer:account.required')),
    gender: Yup.string()
      .required(t('influencer:account.required'))
      .typeError('gender is required'),
    nationality: Yup.string().required(t('influencer:account.required')),
    date_of_birth: Yup.date().required(t('influencer:account.required')),
    country: Yup.string().required(t('influencer:account.required')),
    timezone: Yup.string().required(t('influencer:account.required')),
  });

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      borderBottom: 'px dotted pink',
      color: state.isSelected ? 'black' : 'black',
      padding: 20,
    }),
  };

  //? Form Logic
  const [onEdit, setOnEdit] = useState(false);

  const haveDataInDataBase = influencerData
    ? Object.keys(influencerData)?.length > 1
    : false;
  const showInfo = !onEdit && haveDataInDataBase;

  //? Functions
  const convertLocalToUTCDate = (d: any) => {
    const newDate = new Date(d);
    const utcDate: any = new Date(
      Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()),
    );
    return utcDate;
  };

  const handleSubmit = async () => {
    const {
      phone_number,
      name,
      gender,
      isLGBT,
      nationality,
      date_of_birth,
      address,
      postcode,
      province,
      country,
      country_phone_digit,
      timezone,
      timezone_offset,
    } = { ...formValues };

    const input: any = {
      phone_number,
      // user_name,
      name,
      gender,
      isLGBT,
      nationality,
      date_of_birth: date_of_birth ? new Date(date_of_birth) : '',
      address,
      postcode,
      province,
      country: formValues.short_country ? formValues.country : 'Thailand',
      address_phone_number: '',
      country_phone_digit: country_phone_digit || '66',
      timezone,
      timezone_offset,
    };

    if (!haveDataInDataBase) {
      dispatch(createUserInfo(input));
    } else {
      for (let i = 0; i < Object.keys(influencerData)?.length; i++) {
        const key = Object.keys(influencerData)[i];
        if (key === 'date_of_birth') {
          influencerData[key] = new Date(influencerData[key]);
          if (influencerData[key].getTime() === input[key].getTime()) {
            delete input[key];
          }
        } else {
          if (influencerData[key] === input[key]) {
            delete input[key];
          }
        }
      }

      if (Object.keys(input)?.length) {
        dispatch(updateUserInfo(input));
      }
    }
    setValue(influencerData.timezone);
    setOnEdit(false);
  };

  useEffect(() => {
    if (!haveDataInDataBase) {
      const getInitial = async () => {
        const response = await fetch(`${config.backendUrl}api/getCountry`, {
          method: 'GET',
        });
        const { country_name } = await response.json();
        const country = country_name || 'Hong Kong';

        const dialCode = countryCodes.find((data) => data.name === country);

        const data: any = {
          short_country: FullNameToShortName[country],
          country_phone_digit: dialCode ? dialCode.dial_code : '+66',
          country,
        };

        setFormValues({ ...data });
      };

      getInitial();
    }
  }, []);

  useEffect(() => {
    if (onEdit === true) {
      setValue(influencerData.timezone);
    }
  }, [onEdit]);

  const temp = [...TimeZone];
  useEffect(() => {
    if (haveDataInDataBase) {
      const { date_of_birth, country_phone_digit, phone_number } = influencerData;
      const birthDay = date_of_birth ? new Date(date_of_birth) : '';
      const fullCountries: any = { ...FullNameToShortName };
      const data: any = {
        ...influencerData,
        date_of_birth: birthDay,
        short_country: fullCountries[influencerData.country],
        country_phone_number: `${country_phone_digit}${phone_number}` || '',
      };
      // eslint-disable-next-line no-restricted-syntax
      for (const [key] of Object.entries(initialValues)) {
        (initialValues as any)[key] = data[key];
      }
      setFormValues({ ...data });
    }
  }, [influencerData]);
  let shortCountry = '';
  if (shortPhoneDigit[formValues.country_phone_digit]?.length) {
    [shortCountry] = shortPhoneDigit[formValues.country_phone_digit];
  }
  const inputs = (errors: any, touched: boolean, handleChange: any) => {
    const test = (input: FormControl) => {
      switch (input.type) {
        case FormInputType.STRING:
          return textInput(input, errors, touched, handleChange);
        case FormInputType.GENDER:
          return genderInput(input);
        case FormInputType.IS_LGBT:
          return isLGBTInput(input);
        case FormInputType.BIRTH_DAY:
          return birthDayInput(input, errors, touched);
        case FormInputType.HEADER:
          return headerDisplay(input.label);
        case FormInputType.HR:
          return hrDisplay();
        case FormInputType.COUNTRY:
          return countrySelect(input, errors, touched);
        case FormInputType.TIMEZONE:
          return selectTimeZone(input);
        default:
          return textInput(input, errors, touched, handleChange);
      }
    };
    const items = [];
    let itemsInRow: any = [];

    for (let i = 0; i < formControls?.length; i++) {
      const item = formControls[i];

      itemsInRow.push(item);
      if (
        itemsInRow?.length === 2 ||
        i === formControls?.length - 1 ||
        item.type === FormInputType.HEADER ||
        (item.name === 'address' && !showInfo) ||
        item.type === FormInputType.HR ||
        item.name === 'nationality'
      ) {
        items.push(itemsInRow);
        itemsInRow = [];
      }
    }

    const genderJustify = !showInfo ? 'center' : '';
    return items.map((itemInRow, index) => {
      const itemWidth =
        itemInRow?.length === 1 &&
        index !== items?.length - 1 &&
        itemInRow[0].name !== 'nationality'
          ? 'item-width-100'
          : 'item-width-50';
      const genderWidth = showInfo ? 'item-width-50' : '';
      return (
        <div
          style={{
            justifyContent: index === 4 ? genderJustify : 'space-between',
          }}
          className="item-from-controls"
        >
          {itemInRow.map((input: any) => (
            <div
              style={{
                padding: '0 1rem',
              }}
              className={`${index === 4 ? genderWidth : itemWidth} `}
            >
              {test(input)}
            </div>
          ))}
        </div>
      );
    });
    // return formControls.map((input: FormControl, index) => {
    //   return <Col md={6}>{test(input)}</Col>;
    // });
  };

  const handleFileSelected = async (e: any): Promise<void> => {
    const files: any = Array.from(e.target.files);

    if (files[0]?.size / 1024 <= 400) {
      dispatch(uploadProfile(files));
    } else {
      notify(i18n.t('error:Error profile size limit is 400kb.'), 'error');
    }
  };
  //? Input UI Function
  const genderInput = (input: FormControl) =>
    showInfo ? (
      <p className="text-display-container">
        <span className="text-header">{t(input.label)}:</span>
        <span className="text-content">
          {t(`influencer:account.${influencerData.gender?.toLowerCase()}`)}
          {influencerData.isLGBT ? (
            <span className="lgbt">( {t('influencer:account.lgbt')} )</span>
          ) : (
            <></>
          )}
        </span>
      </p>
    ) : (
      <div className="radio-container">
        {[
          {
            label: 'influencer:account.men',
            value: 'MEN',
            icon: menIcon,
            bgColor: '#ABC9FF',
          },
          {
            label: 'influencer:account.female',
            value: 'WOMEN',
            icon: womenIcon,
            bgColor: '#FF8B8B',
          },
        ].map((gender) => (
          <div
            className="radio radio-input"
            id="gender"
            onClick={() => {
              setFormValues({ ...formValues, gender: gender.value });
            }}
          >
            <input
              id={`radio ${gender.value}`}
              type="radio"
              checked={formValues?.gender === gender.value}
            />
            <img
              src={gender.icon}
              alt="icon radio"
              className="image-icon"
              style={{
                background: gender.bgColor,
              }}
            />
            <i className="fas fa-check " />
            <Label for={`radio ${gender.value}`}>{t(gender.label)}</Label>
          </div>
        ))}
        <ErrorMessage
          name={input.name}
          component="div"
          className="invalid-message"
        />
        <ErrorFocus name={input.name} />
      </div>
    );

  const isLGBTInput = (input: FormControl) =>
    showInfo ? (
      <></>
    ) : (
      <div className="radio-container">
        <div className="border-left">
          <div
            className="radio radio-input"
            onClick={() => {
              setFormValues({ ...formValues, isLGBT: !formValues.isLGBT });
            }}
          >
            <input id="radio lgbt" type="radio" checked={formValues.isLGBT} />
            <img className="image-full" src={lgbtIcon} alt="icon radio" />
            <i className="fas fa-check" />
            <Label for="radio lgbt">{t(input.label)}</Label>
          </div>
        </div>
      </div>
    );

  const birthDayInput = (input: FormControl, errors: any, touched: any) => {
    const isInvalid = errors[input.name] && touched[input.name];
    return showInfo ? (
      <>
        <p className="text-display-container">
          <span className="text-header">{t(input.label)}:</span>
          <span className="text-content">
            {influencerData[input.name]
              ? format(new Date(influencerData[input.name]), 'dd/MM/yyyy')
              : t('influencer:account.not_specified')}
          </span>
        </p>
      </>
    ) : (
      <FormGroup
        className={`${
          !isInvalid ? 'float-input-container' : 'float-input-container error-input'
        } `}
        style={{ marginBottom: '4.7px' }}
      >
        <FloatingDateInputComponent
          name={input.name}
          value={formValues.date_of_birth ? formValues.date_of_birth : undefined}
          isInvalid={isInvalid}
          onChange={(e: any) => {
            setFormValues({
              ...formValues,
              date_of_birth: convertLocalToUTCDate(e),
            });
          }}
          isRequired={input.required}
          label={input.label}
          disableMinDate
        />
        <ErrorMessage
          name="date_of_birth"
          component="div"
          className="invalid-message"
        />
        <ErrorFocus name="date_of_birth" />
      </FormGroup>
    );
  };

  const textInput = (
    input: FormControl,
    errors: any,
    touched: any,
    handleChange: any,
  ) => {
    // eslint-disable-next-line no-nested-ternary
    if (showInfo) {
      return (
        <p className="text-display-container">
          <span className="text-header">{t(input.label)}:</span>
          <span className="text-content">
            {input.name === 'phone_number'
              ? `${influencerData.country_phone_digit} ${influencerData.phone_number}`
              : influencerData[input.name] || '-'}
          </span>
        </p>
      );
    }
    if (input.name === 'phone_number') {
      return (
        <FormGroup className="phone-number-input-container">
          <Label>
            {t(input.label)}
            {input.required && <span className="input-required">*</span>}
          </Label>
          <div className="phone-input" style={{ display: 'flex' }}>
            <ReactPhoneInput
              autocompleteSearch={false}
              enableSearch
              autoFormat={false}
              disableSearchIcon
              excludeCountries={['vc']}
              countryCodeEditable
              country={shortCountry || 'th'}
              inputStyle={{
                height: '35px',
                fontSize: '13px',
                paddingLeft: '48px',
              }}
              inputProps={{
                required: true,
                maxlength: '4',
                autoComplete: 'off',
              }}
              searchStyle={{
                margin: '0',
                width: '100%',
                height: '30px',
              }}
              buttonStyle={{ borderRadius: '5px 0 0 5px' }}
              dropdownStyle={{
                width: '200px',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                zIndex: '99',
              }}
              onChange={(e: any) => {
                setFormValues({
                  ...formValues,
                  country_phone_digit: `+${e}`,
                  country_phone_number: `+${e}${formValues.phone_number}`,
                });
              }}
            />
            <input
              className="phone-number-width"
              type="number"
              value={formValues.phone_number}
              name="country_phone_number"
              onChange={(e: any) => {
                setFormValues({
                  ...formValues,
                  phone_number: e.target.value,
                  country_phone_number: `${formValues.country_phone_digit}${e.target.value}`,
                });
              }}
            />
          </div>
          <div style={{ paddingLeft: '14px' }}>
            <ErrorMessage
              name="country_phone_number"
              component="div"
              className="invalid-message"
            />
            <ErrorFocus name="country_phone_number" />
          </div>
        </FormGroup>
      );
    }
    if (input.name === 'address_phone_number') {
      return '';
    }

    return (
      <FormGroup className="text-start">
        <FloatingInputComponent
          name={input.name}
          type={input?.input_type || 'input'}
          value={formValues[input.name as keyof typeof initialValues] as any}
          isInvalid={errors[input.name] && touched[input.name]}
          onChange={(e: any) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
            handleChange(e);
          }}
          label={input.label}
          isRequired={input.required}
        />
        <ErrorMessage
          name={input.name}
          component="div"
          className="invalid-message"
        />
        <ErrorFocus name={input.name} />
      </FormGroup>
    );
  };
  // eslint-disable-next-line no-unused-vars
  const countrySelect = (input: FormControl, errors: any, touched: any) => {
    return showInfo ? (
      <p className="text-display-container">
        <span className="text-header">{t(input.label)}:</span>
        <span className="text-content">{influencerData[input.name]}</span>
      </p>
    ) : (
      <FormGroup>
        <Label className="country-select" name={input.name}>
          {t(input.label)}
          {input.required && <span className="input-required">*</span>}
        </Label>
        <ReactFlagsSelect
          // disabled
          searchable
          selectedSize={16}
          optionsSize={16}
          fullWidth
          searchPlaceholder="Search countries"
          onSelect={(label: any) => {
            const country: any = ReactFlagsSelectCountry;

            setFormValues({
              ...formValues,
              [input.name]: country[label],
              short_country: label,
            });
          }}
          selected={formValues.short_country || 'TH'}
        />
        <ErrorMessage
          name={input.name}
          component="div"
          className="invalid-message"
        />
        <ErrorFocus name={input.name} />
      </FormGroup>
    );
  };

  const defaultSelected = temp.find((opt) => opt?.abbr === '');
  const [selected, setSelected] = useState(defaultSelected);
  const onChange = (option: any) => {
    setSelected(option);
    const timeZoneLabel = option.label.split(') ');
    setFormValues({
      ...formValues,
      timezone: timeZoneLabel[1],
      timezone_offset: option?.offset * 60,
    });
  };

  const selectTimeZone = (input: FormControl) => {
    return showInfo ? (
      <p className="text-display-container">
        <span className="text-header">{t(input.label)}:</span>
        <span className="text-content">{influencerData.timezone}</span>
      </p>
    ) : (
      // <p className="input-container">
      <FormGroup className="time-zone-input-container" style={{ marginTop: '20px' }}>
        <Label>
          {t(input.label)}
          <span className="input-required">*</span>
        </Label>
        <Select options={temp} value={selected} onChange={onChange} />
        <br />
        <ErrorMessage
          name={input.name}
          component="div"
          className="invalid-message"
        />
        <ErrorFocus name={input.name} />
      </FormGroup>
      // </p>
    );
  };

  const headerDisplay = (text: string) => {
    return <p className="text-header">{t(text)}</p>;
  };

  const hrDisplay = () => {
    return <hr className="hr" />;
  };

  return {
    initialValues,
    handleSubmit,
    validationSchema,
    influencerData,
    profileImageKey,
    handleFileSelected,
    showInfo,
    formValues,
    inputs,
    setOnEdit,
    user,
    t,
    selectTimeZone,
  };
};

export default InfluencerAccountControl;

interface FormControl {
  label: string;
  name: any;
  type: FormInputType;
  required?: boolean;
  input_type?: string;
}
