import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip,
} from 'reactstrap';
import choice from 'src/assets/images/choice.png';
import editPriceStartGraph from 'src/assets/images/graph/editPriceStartGraph.jpg';
import editPriceEndGraph from 'src/assets/images/graph/editPriceEndGraph.jpg';
import editPriceMidGraph from 'src/assets/images/graph/editPriceMidGraph.jpg';
import editEngagementMidGraph from 'src/assets/images/graph/editEngagementMidGraph.jpg';
import defaultEquation from 'src/assets/images/graph/defaultEquation.png';
import { OnLoading } from 'src/components/Common/Loading';
import { calculateInfluencerPrice } from 'src/components/Publisher/Influencer/ChannelDetail/ContentPrice/ContentPrice.control';
import { SocialMediaType } from 'src/components/Publisher/Influencer/Dashboard/SocialChannel/SocialChannel.control';
import {
  batchUpdateSocialMediaPriceGql,
  getBasePriceAndEngagementPerFollowerGQL,
  updateSocialPriceRate,
} from 'src/graphql/advertiser/socialMediaInfo';
import { getInfluencerPriceRate, setLoading, unsetLoading } from 'src/store/actions';
import Swal from 'sweetalert2';
import './manageInfluencerPage.style.scss';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import menu from 'src/constants/menu';
import { floorToFixed } from 'src/common/data/mathToFixed';
import SearchBar from 'src/components/Table/SearchBar';
import TopUpTable from 'src/pages/Advertiser/Marketer/Jobnew/TopUpTable';
import TopUpTableAdmin from 'src/pages/Advertiser/Marketer/Jobnew/TopUpTable copy';

const ManageInfluencerPage = () => {
  const followers = [500, 1000, 5000, 10000, 50000, 100000, 500000];
  const [selectedSocial, setSelectedSocial] = useState(SocialMediaType.FACEBOOK);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [openToolTip, setOpenToolTip] = useState<number | null>(null);
  const toggle = () => setDropdownOpen((prevState: any) => !prevState);
  const [testEngagement, setTestEngagement] = useState(0);
  const { t } = useTranslation();
  const defaultRank = {
    // start_rank: 5,
    // end_rank: 5,
    // multiply: 1,
    // engagement_multiply: 1,
    share_multiply: 1,
    post_multiply: 1,
    video_multiply: 1,
    power: 0.5,
    offset: 0,
    like_post: 1,
    comment_post: 2,
    share_post: 3,
    day: 15,
    weight0: 0.2,
    weight1: 0.8,
  };
  const [rank, setRank] = useState(defaultRank);
  const [socialName, setSocialName] = useState('');
  const [oldRank, setOldRank] = useState<any>(defaultRank);
  const graphImage = [
    editPriceStartGraph,
    editPriceEndGraph,
    editPriceMidGraph,
    editEngagementMidGraph,
  ];

  const initialBasePrice = {
    basePrice: 0,
    engagementPerFollower: 0,
    socialName: '',
    follower: null,
  };

  const [basePrice_engagement, setBasePrice_engagement] = useState(initialBasePrice);

  const dispatch = useDispatch();
  const { influencerPriceRate$ } = useSelector((state: any) => ({
    influencerPriceRate$: state.SocialMediaInfo.influencerPriceRate,
  }));

  useEffect(() => {
    dispatch(getInfluencerPriceRate());
  }, []);

  useEffect(() => {
    for (let i = 0; i < influencerPriceRate$?.length; i++) {
      const {
        // start_rank,
        // multiply,
        // end_rank,
        // engagement_multiply,
        social_type,
        share_multiply,
        post_multiply,
        video_multiply,
        power,
        offset,
        like_post,
        comment_post,
        share_post,
        day,
        weight0,
        weight1,
      } = influencerPriceRate$[i];

      if (social_type === selectedSocial) {
        const newRank = {
          // start_rank,
          // end_rank,
          // multiply,
          // engagement_multiply,
          share_multiply,
          post_multiply,
          video_multiply,
          power,
          offset,
          like_post,
          comment_post,
          share_post,
          day,
          weight0,
          weight1,
        };
        setRank(newRank);
        setOldRank(newRank);
      }
    }
  }, [influencerPriceRate$]);

  const tableBodyData = [];
  for (let i = 0; i < followers?.length; i++) {
    const follower = followers[i];
    const { price, engagementPrice, totalPrice } = calculateInfluencerPrice({
      follower,
      engagement: testEngagement,
      // start_rank: rank.start_rank,
      // multiply: rank.multiply,
      // end_rank: rank.end_rank,
      // engagement_multiply: rank.engagement_multiply,
      power: rank.power,
      offset: rank.offset,
    });
    //console.log('debug: ', rank.offset, price, engagementPrice, totalPrice);
    tableBodyData.push({
      follower,
      totalPrice,
      price,
      engagementPrice,
    });
  }

  // postPrice: totalPrice * 2,
  // videoPrice: totalPrice * 5,

  if (!influencerPriceRate$) return <OnLoading />;

  const editRanks = (rankKey: string, newRank: any, symbol: string) => {
    const multi = symbol === '+' ? 1 : -1;
    const newRankData = newRank[rankKey];
    if (
      (newRankData >= 10 && symbol === '+') ||
      (newRankData <= 0 && symbol === '-')
    )
      return;

    setRank({ ...newRank, [rankKey]: newRankData + 0.5 * multi });
  };

  const handleSearch = async (search: string) => {
    dispatch(setLoading());
    const response = await getBasePriceAndEngagementPerFollowerGQL({
      search,
      social_media_type: selectedSocial,
    });
    //dispatch(socialMediaFilterTable(payload));
    setSocialName(search);
    setBasePrice_engagement(response);
    dispatch(unsetLoading());
  };

  const handleClear = () => {
    //dispatch(socialMediaFilterTable(payload));
    setSocialName('');
    setBasePrice_engagement({
      basePrice: 0,
      engagementPerFollower: 0,
      socialName: '',
      follower: null,
    });
  };

  const editRateDisplay = (rankKey: string, newRank: any, inputType: string) => {
    if (inputType === 'buttonGroup') {
      return (
        <div className="btn-group-control-rank" key={rankKey}>
          <button
            type="button"
            className="btn-plus"
            onClick={() => {
              editRanks(rankKey, newRank, '+');
            }}
          >
            +
          </button>
          <span className="text-value">{floorToFixed(newRank[rankKey], 1)}</span>
          <button
            type="button"
            className="btn-minus"
            onClick={() => {
              editRanks(rankKey, newRank, '-');
            }}
          >
            -
          </button>
        </div>
      );
    }
    return (
      <input
        type="number"
        value={newRank[rankKey]}
        onChange={(e: any) => {
          setRank({ ...newRank, [rankKey]: e.target.value });
        }}
      />
    );
  };

  const toggleTooltip = (index: number) => {
    if (openToolTip === null) {
      setOpenToolTip(index);
    } else {
      setOpenToolTip(null);
    }
  };

  return (
    <div className="page-content manage-influencer-page-container">
      <Breadcrumb
        pageTitle={t('Page.Influencer Price Rate')}
        menus={[
          { menu: menu.DASHBOARD },
          { menu: menu.INFLUENCER_RATE_PRICE, active: true },
        ]}
      />

      <div
        style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}
      >
        <div style={{ maxWidth: '1060px', display: 'flex' }}>
          <div className="social-rank-card">
            <div className="custom-rate-title-header">
              <div className="title-text">
                Change rate by social : {selectedSocial}
              </div>
              <div className="default-equation-container">
                <p>default equation :</p>
                <img src={defaultEquation} alt="defaultEquation" />
              </div>
            </div>
            <div className="rate-by-social-items">
              {Object.keys(rank).map((rankKey: string, index: number) => {
                const newRank: any = { ...rank };
                const includeRank = rankKey.includes('rank');
                // eslint-disable-next-line no-nested-ternary
                const inputType = includeRank ? 'buttonGroup' : '';
                let defaultNumber = includeRank ? 5 : 1;
                if (rankKey === 'post_multiply') defaultNumber = 2;
                if (rankKey === 'video_multiply') defaultNumber = 5;
                return (
                  <div className="edit-group-control-rank">
                    {/* {graphImage[index] && (
                      <img src={graphImage[index]} alt="addStartGraph" />
                    )} */}
                    {editRateDisplay(rankKey, newRank, inputType)}
                    <p>{t(`description_${rankKey}`)}</p>
                    <div className="tooltip-price-rate">
                      <i
                        id={`tooltip_${rankKey}`}
                        className="far fa-question-circle info-icon"
                      />
                      <Tooltip
                        placement="left"
                        isOpen={openToolTip === index + 1}
                        target={`tooltip_${rankKey}`}
                        toggle={() => toggleTooltip(index + 1)}
                      >
                        default value = {defaultNumber}
                      </Tooltip>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="search-sample-social">
              <div className="edit-group-control-rank">
                <div>
                  <SearchBar
                    placeholder={t('table:Search name')}
                    handleSearch={handleSearch}
                    handleClear={handleClear}
                    disablePadding
                  />
                  <div>
                    <p>BasePrice: {basePrice_engagement.basePrice}</p>
                  </div>
                  <div>
                    <p>
                      Engagement/Follower:{' '}
                      {basePrice_engagement.engagementPerFollower}
                    </p>
                  </div>
                </div>
                <div>
                  <p style={{ textAlign: 'center' }}>
                    {basePrice_engagement.socialName !== ''
                      ? basePrice_engagement.socialName
                      : t(`description_social_name`)}
                  </p>
                  {basePrice_engagement.follower && (
                    <p style={{ textAlign: 'center' }}>
                      {basePrice_engagement.follower}
                    </p>
                  )}
                </div>
                <div className="tooltip-price-rate">
                  <i
                    id="tooltip_social_name"
                    className="far fa-question-circle info-icon"
                  />
                  <Tooltip
                    placement="left"
                    isOpen={openToolTip === 99}
                    target="tooltip_social_name"
                    toggle={() => toggleTooltip(99)}
                  >
                    Ex: AFBrother
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="submit-social-rate">
          <div className="admin-select-social">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle
                caret
                style={{
                  width: '120px',
                  height: '34px',
                  background: '#009a78',
                  borderRadius: '8px',
                  border: 'none',
                  color: 'white',
                }}
                className="job-button-filter"
              >
                <i className="fa fa-filter" style={{ marginRight: '10px' }} />
                {selectedSocial}
              </DropdownToggle>
              <DropdownMenu>
                {influencerPriceRate$.map((socialData: any) => (
                  <DropdownItem
                    key={socialData.social_type}
                    type="button"
                    className={`btn outline-sm m-1 ${
                      socialData.social_type === selectedSocial && 'active'
                    }`}
                    onClick={() => {
                      setSelectedSocial(socialData.social_type);
                      setRank({
                        ...rank,
                        // start_rank: socialData.start_rank,
                        // multiply: socialData.multiply,
                        // end_rank: socialData.end_rank,
                        // engagement_multiply: socialData.engagement_multiply,
                        share_multiply: socialData.share_multiply,
                        post_multiply: socialData.post_multiply,
                        video_multiply: socialData.video_multiply,
                        power: socialData.power,
                        offset: socialData.offset,
                        like_post: socialData.like_post,
                        comment_post: socialData.comment_post,
                        share_post: socialData.share_post,
                        weight0: socialData.weight0,
                        weight1: socialData.weight1,
                        day: socialData.day,
                      });
                      setOldRank({
                        ...rank,
                        share_multiply: socialData.share_multiply,
                        post_multiply: socialData.post_multiply,
                        video_multiply: socialData.video_multiply,
                        power: socialData.power,
                        offset: socialData.offset,
                        like_post: socialData.like_post,
                        comment_post: socialData.comment_post,
                        share_post: socialData.share_post,
                        weight0: socialData.weight0,
                        weight1: socialData.weight1,
                        day: socialData.day,
                      });
                      setSocialName('');
                    }}
                  >
                    {socialData.social_type}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={async () => {
            await Swal.fire({
              title: t('swal:Are you sure ?'),
              text: t(`swal:Do you want to change social price rate !`),
              imageUrl: choice,
              imageHeight: 200,
              imageAlt: 'A tall image',
              customClass: {},
              cancelButtonColor: 'red',
              showCancelButton: true,
              confirmButtonColor: '#009a78',
              confirmButtonText: t(`swal:Yes,`),
              cancelButtonText: t('swal:Cancel'),
            }).then(async (result) => {
              if (result.isConfirmed) {
                const newRank: any = { ...rank };
                for (let i = 0; i < Object.keys(rank)?.length; i++) {
                  const keyRank = Object.keys(rank)[i];
                  newRank[keyRank] = +newRank[keyRank];
                  if (newRank[keyRank] === oldRank[keyRank]) delete newRank[keyRank];
                }
                if (Object.keys(newRank)?.length) {
                  dispatch(setLoading());
                  await updateSocialPriceRate({
                    social_type: selectedSocial,
                    ...newRank,
                  });
                  await batchUpdateSocialMediaPriceGql({
                    social_type: selectedSocial,
                  });
                  dispatch(unsetLoading());
                  dispatch(getInfluencerPriceRate());
                }
              }
            });
          }}
        >
          Confirm
        </button>
      </div>

      {/* <div className="price-rate-container">
        <div className="engagement-input-container">
          <p>Test engagement</p>
          <input
            type="number"
            value={testEngagement}
            onChange={(e: any) => {
              const { value } = e.currentTarget;
              if (value === 0 || value) {
                setTestEngagement(+e.currentTarget.value);
              } else {
                setTestEngagement(e.currentTarget.value);
              }
            }}
          />
        </div>
        <Table>
          <thead>
            <tr>
              <th>follower</th>
              <th>share price{testEngagement ? ' + engagement' : ''}</th>
              <th>post price{testEngagement ? ' + engagement' : ''}</th>
              <th>video price{testEngagement ? ' + engagement' : ''}</th>
            </tr>
          </thead>
          <tbody>
            {tableBodyData.map((bodyDatum) => (
              <tr key={bodyDatum.follower}>
                <th scope="row">{bodyDatum.follower}</th>
                <td>
                  {`${floorToFixed(
                    bodyDatum.price * rank.share_multiply + +rank.offset,
                    2,
                  )}${
                    testEngagement
                      ? ` + 
                  ${floorToFixed(
                    bodyDatum.engagementPrice * rank.share_multiply + +rank.offset,
                    2,
                  )} = ${floorToFixed(
                          bodyDatum.totalPrice * rank.share_multiply + +rank.offset,
                          2,
                        )}`
                      : ''
                  } `}
                </td>
                <td>
                  {`${floorToFixed(
                    bodyDatum.price * rank.post_multiply + +rank.offset,
                    2,
                  )}${
                    testEngagement
                      ? ` + 
                  ${floorToFixed(
                    bodyDatum.engagementPrice * rank.post_multiply + +rank.offset,
                    2,
                  )} = ${floorToFixed(
                          bodyDatum.totalPrice * rank.post_multiply + +rank.offset,
                          2,
                        )}`
                      : ''
                  }`}
                </td>
                <td>
                  {`${floorToFixed(
                    bodyDatum.price * rank.video_multiply + +rank.offset,
                    2,
                  )}${
                    testEngagement
                      ? ` + 
                  ${floorToFixed(
                    bodyDatum.engagementPrice * rank.video_multiply + +rank.offset,
                    2,
                  )} = ${floorToFixed(
                          bodyDatum.totalPrice * rank.video_multiply + +rank.offset,
                          2,
                        )}`
                      : ''
                  }`}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div> */}
      <TopUpTableAdmin topup={0} />
    </div>
  );
};

export default ManageInfluencerPage;
