import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import ProfitBalanceChart from 'src/components/Admin/SystemBalance/ProfitBalanceChart';
import ProfitBalanceLogTransactionsTable from 'src/components/Admin/SystemBalance/ProfitBalanceLogTransactionsTable';
import { withTranslation } from 'react-i18next';
import menu from 'src/constants/menu';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getProfitBalanceLogTransactions } from '../../store/systembalance/actions';
import balanceLogRangeEnum from '../../constants/balanceLogRangeEnum';

const ProfitBalanceLogPage = ({ t }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getProfitBalanceLogTransactions({
        limit: 10,
        page: 1,
        log_range: balanceLogRangeEnum.DAILY,
      }),
    );
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            pageTitle={t('Menus.Profit Balance Log')}
            menus={[
              { menu: menu.DASHBOARD },
              { menu: menu.PROFIT_BALANCE_LOG, active: true },
            ]}
          />

          <Row className="justify-content-center">
            <Col className="mb-4" xl={8}>
              <ProfitBalanceChart />
            </Col>
            <Col xl={12}>
              <ProfitBalanceLogTransactionsTable />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(ProfitBalanceLogPage);
