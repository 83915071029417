import { isEmpty } from 'lodash';
import { type } from 'os';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Col, Row } from 'reactstrap';
import VerifyStatus from 'src/constants/verifyStatusEnum';
import { getBanHistory } from 'src/store/actions';
import CustomTable from '../Common/CustomTable';
import PageSizeOptions from '../Table/PageSizeOptions';
import CoinImage from '../Common/CoinImage';

const BanHistoryTable = ({ t, userId }: any) => {
  const defaultPageSize = 10;
  const dispatch = useDispatch();
  const history = useHistory();

  // Local State
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  //const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');

  const [param, setParam] = useState<any>({
    uuid: userId,
    limit: 10,
    page: 1,
    sortBy: '',
    sortType: '',
  });

  const { txn } = useSelector((state: any) => ({
    txn: state.Ban.banList,
  }));

  useEffect(() => {
    dispatch(getBanHistory(param));
  }, []);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...param,
      page: newPage,
    };

    dispatch(getBanHistory(payload));
    setPage(newPage);
    setParam(payload);
  };

  const handlePageSizeChange = (size: string) => {
    const newPageSize = parseInt(size, 10);

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    const payload = {
      ...getDefaultPayload(newPage),
      limit: newPageSize,
    };

    dispatch(getBanHistory(payload));

    setPageSize(newPageSize);
  };

  const getDefaultPayload = (pages: number) => {
    setPage(pages);

    return {
      uuid: userId,
      limit: pageSize,
      page,
      sortBy: '',
      sortType: '',
    };
  };

  const columns = useMemo(() => {
    return [
      {
        Header: t('table:No.'),
        accessor: (row: any, index: number) =>
          index + 1 + (param.page - 1) * param.limit,
        id: 'index',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('create at'),
        accessor: ({ created_at }: any) => {
          if (!created_at) return 'null';
          const created_at_date = new Date(created_at);
          const Day = created_at_date.getDate();
          const month = created_at_date.getMonth() + 1;
          const year = created_at_date.getFullYear();

          return `${Day}/${month}/${year}`;
        },
        id: 'created_at',
        disableSort: false,
        width: 150,
      },
      {
        Header: t('reason'),
        accessor: ({ reason }: any) => reason,
        id: 'reasonl',
        disableSort: true,
        width: 100,
      },
      {
        Header: t(`ban day`),
        accessor: ({ day }: any) => day,
        id: 'day',
        disableSort: false,
      },
      {
        Header: t(`release`),
        accessor: ({ is_complete }: any) => {
          let badgeColor: string = 'warning';
          switch (is_complete) {
            case true:
              badgeColor = 'success';
              break;
            case false:
              badgeColor = 'danger';
              break;
            default:
              badgeColor = 'success';
          }

          return (
            <div className="mt-1">
              <span
                className={`badge rounded-pill badge-soft-${badgeColor} font-size-12`}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
                id="span1"
              >
                {t(`enum:${is_complete ? 'Yes' : 'No'}`)}
              </span>
            </div>
          );
        },
        id: 'is_complete',
        disableSort: true,
      },
      {
        Header: t('release date'),
        accessor: ({ release_date }: any) => {
          if (!release_date) return 'null';
          const release_at_date = new Date(release_date);
          const Day = release_at_date.getDate();
          const month = release_at_date.getMonth() + 1;
          const year = release_at_date.getFullYear();

          return `${Day}/${month}/${year}`;
        },
        id: 'release_date',
        disableSort: false,
      },
      {
        Header: t('banned by'),
        accessor: ({ ban_by_admin }: any) => ban_by_admin.email,
        id: 'currency.rate_in_usd',
        disableSort: true,
      },
    ];
  }, [t, txn]);

  const data = React.useMemo(() => {
    return !isEmpty(txn) ? txn.items : [];
  }, [txn]);

  const hiddenOnXS = ['date', 'email', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnSM = ['email', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnMD = ['CRYPTO_amount', 'dest_address'];
  const hiddenOnLG: any = [];

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(newPage),
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(getBanHistory(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
      setParam(payload);
    },
    [page, pageSize],
  );

  if (!txn) return <></>;

  return (
    <>
      <CustomTable
        columns={columns}
        data={data}
        page={page}
        pageSize={pageSize}
        total={txn.totalCount}
        handlePageChange={handlePageChange}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
        onSort={handleSort}
      >
        <Row className="justify-content-between m-0">
          <Col xs={12} md={3} className="d-flex align-items-center mb-3 mb-md-0">
            <PageSizeOptions
              defaultValue={pageSize}
              handlePageSizeChange={handlePageSizeChange}
            />
          </Col>
        </Row>
      </CustomTable>
    </>
  );
};

export default withTranslation()(BanHistoryTable);
