import Carousel from 'react-multi-carousel';
import 'src/components/Home/Card/CardSlider/CardSlider.scss';
import Warning from 'src/assets/images/warning-icon.svg';
import Check from 'src/assets/images/check.svg';
import Image1 from 'src/assets/images/job-AFbrother.png';
import FacebookIcon from 'src/assets/images/facebook-icon.svg';
import { Card as CardEl, CardBody, CardTitle } from 'reactstrap';
import TiktokIcon from 'src/assets/images/tiktok-logo.png';
import config from 'src/config';
import { getDateTime } from 'src/utils/dateTime';
import { InfluencerSocialIcon } from 'src/components/Common/status/InfluencerJobStatus';
import PictureIcon from 'src/assets/images/photo-gallery-icon.svg';
import VideoIcon from 'src/assets/images/other/video-content.png';
import Thinking from 'src/assets/images/thinking.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { getSuggestJobOnHomePage } from 'src/store/marketerJob/actions';
import InfluencerMarketer from 'src/assets/images/influencer-marketer.jpg';
import SocialMedia from 'src/assets/images/social-media.jpg';
import WandIcon from 'src/assets/images/wand.svg';
import UpdateIcon from 'src/assets/images/essay.svg';
import Influencer1 from 'src/assets/images/influencer-1.jpg';
import Influencer2 from 'src/assets/images/influencer-2.jpg';
import Influencer3 from 'src/assets/images/influencer-3.jpg';
import Instagram from 'src/assets/images/instagram-logo.png';
import noimage from 'src/assets/images/404.png';
import Facebook from 'src/assets/images/home/fb@2x.4f64514.png';
import { useTranslation } from 'react-i18next';
import '../../../../pages/mock/mockPublishJob/mockInfluPublishJob.style.scss';
import { useEffect, useState } from 'react';
import { getAllPostDataGql } from 'src/graphql/advertiser/socialMediaInfo';

const CardSlider = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showcase, setpostData] = useState<any>([]);
  const { states, suggestHome } = useSelector((state: any) => ({
    states: state.MarketerJob,
    suggestHome: state.MarketerJob.suggestHomepage,
  }));
  const [social_type, setSocialType] = useState<any>([]);

  const getHomepageShowcase = async () => {
    const postData = await getAllPostDataGql({ page: 1, limit: 3 });
    setpostData(postData.items);
  };
  useEffect(() => {
    dispatch(getSuggestJobOnHomePage());
    getHomepageShowcase();
  }, []);

  useEffect(() => {
    if (suggestHome?.items) {
      const social_type_dict = [];
      for (let i = 0; i < suggestHome.items?.length; i++) {
        const job_task_type_dict: Map<string, string> = new Map();
        const job_task_type = [];
        for (let j = 0; j < suggestHome.items[i].job_tasks?.length; j++) {
          if (
            !job_task_type_dict.get(
              suggestHome.items[i].job_tasks[j].task_type.socialmedia_type,
            )
          ) {
            job_task_type_dict.set(
              suggestHome.items[i].job_tasks[j].task_type.socialmedia_type,
              suggestHome.items[i].job_tasks[j].task_type.socialmedia_type,
            );
            job_task_type.push(
              suggestHome.items[i].job_tasks[j].task_type.socialmedia_type,
            );
          }
        }
        social_type_dict.push(job_task_type);
      }
      setSocialType(social_type_dict);
    }
  }, [suggestHome]);

  // console.log('showcase', showcase);
  // console.log('suggestHomapage', suggestHome);
  // console.log('states', states);
  const [able_get_job, setAbleGetjob] = useState(true);

  const review = [
    {
      src: Image1,
      header: t('AFbrother'),
      title: t('AFbrother make your dream on your desire'),
      subtitle1: t('card.Job qualifications'),
      subtitle2: t('card.do not match you.'),
      subtitle3: t('card.Facebook Profile'),
      subtitle4: t('card.Photo'),
      icon: Warning,
      social: FacebookIcon,
      content: PictureIcon,
      key: 1,
    },
    {
      src: Image1,
      header: t('AFbrother'),
      title: t('AFbrother make your dream on your desire'),
      subtitle1: t('card.Job qualifications'),
      subtitle2: t('card.is right for you.'),
      subtitle3: t('Instagram'),
      subtitle4: t('card.Photo'),
      icon: Check,
      social: Instagram,
      content: PictureIcon,
      key: 2,
    },
    {
      src: Image1,
      header: t('AFbrother'),
      title: t('AFbrother make your dream on your desire'),
      subtitle1: t('card.Job qualifications'),
      subtitle2: t('card.is right for you.'),
      subtitle3: t('Tiktok'),
      subtitle4: t('Video'),
      icon: Check,
      social: TiktokIcon,
      content: VideoIcon,
      key: 3,
    },
  ];
  const blogs = [
    {
      src: Thinking,
      header: t('card.Good Content vs. Bad Content!?'),
      title: t('card.Good Content vs. Bad Content!?'),
      icon: Thinking,
      key: 1,
    },
    {
      src: InfluencerMarketer,
      header: t(
        'card.What is influencer marketing? How is influencer marketing essential for Business?',
      ),
      title: t(
        'card.What is influencer marketing? How is influencer marketing essential for Business?',
      ),
      icon: InfluencerMarketer,
      key: 2,
    },
    {
      src: SocialMedia,
      header: t('card.Which is the most popular social media for Thai people!?'),
      title: t('card.Which is the most popular social media for Thai people!?'),
      icon: SocialMedia,
      key: 3,
    },
  ];
  const influencer = [
    {
      src: Influencer2,
      header: 'Prinn Mahawongta',
      title: t(
        'รวมตัวท็อปของอินฟลูเอนเซอร์มาไว้ในงานหลายคนเลย ไม่ได้มาร่วมงานคือพลาดมากค่ะ #AFBrother #AFBrotherthailand #AFBrothershare',
      ),
      icon: Facebook,
      key: 1,
    },
    {
      src: Influencer1,
      header: 'nn.ganya',
      title: t(
        'AFbrother is Grand Opening day #AFBrother #AFBrotherthailand #AFBrothershare',
      ),
      icon: Instagram,
      key: 2,
    },
    {
      src: Influencer3,
      header: 'แรงบันดาลใจประเทศไทย',
      title: t(
        'การลงทุนที่ดีที่สุด คือ การลงทุนในตัวคุณเอง แอดไป งาน เปิดตัว แพลตฟอร์ม AFbrother #AFBrother #AFBrotherthailand #AFBrothershare #อินฟลูเอนเซอร์ #เเพลตฟอร์ม #influencer #digital #app #thailand #platform',
      ),
      icon: Facebook,
      key: 3,
    },
  ];
  return (
    <div className="card-slider">
      <div className="card-section">
        <h4 style={{ color: 'white', textAlign: 'center' }}>
          {t('card.Job Announcement')}
        </h4>
        <div className="card-review-container1">
          <div className="card-review-box">
            <div>
              <div className="image-carousel-container">
                <Carousel
                  arrows
                  centerMode={false}
                  focusOnSelect={false}
                  infinite={false}
                  pauseOnHover
                  renderArrowsWhenDisabled={false}
                  renderButtonGroupOutside={false}
                  renderDotsOutside={false}
                  responsive={{
                    desktop: {
                      breakpoint: {
                        max: 3000,
                        min: 1024,
                      },
                      items: 1,
                    },
                    mobile: {
                      breakpoint: {
                        max: 464,
                        min: 0,
                      },
                      items: 1,
                    },
                    tablet: {
                      breakpoint: {
                        max: 1024,
                        min: 464,
                      },
                      items: 1,
                    },
                  }}
                  showDots
                  slidesToSlide={1}
                >
                  {suggestHome?.items?.length > 0 &&
                    suggestHome?.items?.map((item: any, index: number) => (
                      <div key={item.key}>
                        <div
                          className="card-review-top"
                          style={{
                            display: 'flex',
                            position: 'relative',
                            height: '80px',
                          }}
                        >
                          <h4>{item.name}</h4>{' '}
                          <h6
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              width: '200px',
                            }}
                          >
                            {item.marketer_campaign.user.user_info.company}
                          </h6>
                          <span
                            style={{
                              display: 'flex',
                              position: 'absolute',
                              top: '20px',
                            }}
                          >
                            <span style={{}}>Get job by</span>
                            <span style={{ marginTop: '29px' }}>
                              {' '}
                              {getDateTime(item?.last_day_accept_job, 'TH')}{' '}
                            </span>
                          </span>
                        </div>
                        <img
                          src={`${config.backendUrl}api/jobThumbnail/${item?.thumbnail_cover}`}
                          style={{
                            height: '220px',
                            width: '100%',
                          }}
                          alt="job examination"
                        />

                        <div
                          className="card-review-bottom"
                          style={{ height: '100px' }}
                        >
                          <div style={{ display: 'flex' }}>
                            {social_type[index]?.map((type: any) => {
                              return (
                                <InfluencerSocialIcon social_media_type={type} />
                              );
                            })}
                          </div>
                          <div
                            style={{
                              color: 'black',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginLeft: 'auto',
                            }}
                          >
                            <div style={{ textAlign: 'right' }}>
                              <h5>
                                {item.job_tasks?.length ?? 0}{' '}
                                {item.job_tasks?.length > 1 ? 'Tasks' : 'Tasks'}
                              </h5>
                              <h5>{item?.currency?.main_symbol ?? 'USDT'}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-section"
        style={{
          background:
            'linear-gradient(122.98deg, #FCD73E 14.38%, #FF969D 55.6%, #AC5DD2 83.81%)',
        }}
      >
        <h4 style={{ color: 'white', marginBottom: '15px' }}> {t('card.Blog')}</h4>
        <div className="card-review-container2">
          <div className="card-blog-box">
            <div className="card-blog-middle">
              <div className="image-carousel-container">
                <Carousel
                  arrows
                  centerMode={false}
                  focusOnSelect={false}
                  infinite={false}
                  pauseOnHover
                  renderArrowsWhenDisabled={false}
                  renderButtonGroupOutside={false}
                  renderDotsOutside={false}
                  responsive={{
                    desktop: {
                      breakpoint: {
                        max: 3000,
                        min: 1024,
                      },
                      items: 1,
                    },
                    mobile: {
                      breakpoint: {
                        max: 464,
                        min: 0,
                      },
                      items: 1,
                    },
                    tablet: {
                      breakpoint: {
                        max: 1024,
                        min: 464,
                      },
                      items: 1,
                    },
                  }}
                  showDots
                  slidesToSlide={1}
                >
                  {blogs.map((item: any) => (
                    <div key={item.key}>
                      <img
                        src={item.src}
                        style={{
                          height: '280px',
                          width: '100%',
                        }}
                        alt="job examination"
                      />
                      <div className="card-blog-bottom">
                        <div
                          className="card-blog-title"
                          onClick={() => {
                            if (item.key === 1) {
                              window.open(
                                'https://blog.afbrother.com/good-content-vs-bad-content/',
                              );
                            } else if (item.key === 2) {
                              window.open(
                                'https://blog.afbrother.com/what-is-influencer-marketing-how-is-influencer-marketing-essential-for-business/',
                              );
                            } else {
                              window.open(
                                'https://blog.afbrother.com/which-is-the-most-popular-social-media-for-thai-people/',
                              );
                            }
                          }}
                        >
                          <img src={item.icon} alt="" />
                          <div>
                            <h4 style={{ fontSize: '18px' }}>{item.header}</h4>
                            <h6 style={{ color: '#7e7e7e', fontSize: '12px' }}>
                              {item.title}
                            </h6>
                          </div>
                        </div>
                        <div className="card-blog-detail">
                          <div>{t('card.14 Oct 65')}</div>
                          <div style={{ color: 'rgb(0, 202, 255)' }}>
                            <img src={WandIcon} alt="" style={{ width: '12px' }} />{' '}
                            {t('card.Marketer should know')}
                            <span style={{ color: 'rgb(215, 102, 255)' }}>
                              <img
                                src={UpdateIcon}
                                alt=""
                                style={{ width: '12px', marginLeft: '5px' }}
                              />
                              {t('card.Update')}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-section"
        style={{
          background: 'linear-gradient(122.98deg, #7F8EEF 14.38%, #FA465E 79.1%)',
        }}
      >
        <h4 style={{ color: 'white', marginBottom: '15px' }}>{t('Showcase')}</h4>
        <div className="card-review-container3">
          <div className="card-influencer-box">
            <div className="card-influencer-middle">
              <div className="image-carousel-container">
                <Carousel
                  arrows
                  centerMode={false}
                  focusOnSelect={false}
                  infinite={false}
                  pauseOnHover
                  renderArrowsWhenDisabled={false}
                  renderButtonGroupOutside={false}
                  renderDotsOutside={false}
                  responsive={{
                    desktop: {
                      breakpoint: {
                        max: 3000,
                        min: 1024,
                      },
                      items: 1,
                    },
                    mobile: {
                      breakpoint: {
                        max: 464,
                        min: 0,
                      },
                      items: 1,
                    },
                    tablet: {
                      breakpoint: {
                        max: 1024,
                        min: 464,
                      },
                      items: 1,
                    },
                  }}
                  showDots
                  slidesToSlide={1}
                >
                  {/* {influencer.map((item: any) => (
                    <>
                      <div key={item.key}>
                        <img
                          src={item.src}
                          style={{
                            height: '310px',
                            width: '100%',
                          }}
                          alt="job examination"
                        />
                      </div>
                      <div className="card-influencer-bottom">
                        <div className="card-influencer-content">
                          <img src={item.icon} alt="" />
                          <div>
                            <h4
                              style={{ fontSize: '18px', marginBottom: '0.25rem' }}
                            >
                              {item.header}
                            </h4>
                            <h6
                              style={{
                                color: '#7e7e7e',
                                fontSize: '10px',
                                wordBreak: 'break-all',
                                // overflow: 'hidden',
                                // textOverflow: 'ellipsis',
                                // whiteSpace: 'nowrap',
                              }}
                            >
                              {item.title}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </>
                  ))} */}
                  {showcase.map((item: any) => (
                    <>
                      <div>
                        <img
                          src={item.post_images_url[0] ?? noimage}
                          style={{
                            height: '310px',
                            width: '100%',
                          }}
                          alt="job examination"
                        />
                      </div>
                      <div className="card-influencer-bottom">
                        <div className="card-influencer-content">
                          <img
                            src={item.influencer_image_url}
                            alt=""
                            style={{ marginLeft: '0', marginRight: '0' }}
                          />
                          <div>
                            <h4
                              style={{ fontSize: '18px', marginBottom: '0.25rem' }}
                            >
                              {item.influencer_name}
                            </h4>
                            <h6
                              style={{
                                color: '#7e7e7e',
                                fontSize: '10px',
                                wordBreak: 'break-all',
                                // overflow: 'hidden',
                                // textOverflow: 'ellipsis',
                                // whiteSpace: 'nowrap',
                              }}
                            >
                              {item.caption}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CardSlider;
