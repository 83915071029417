import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { checkWebsiteByUserId } from 'src/graphql';
import notify from 'src/utils/notify';
import { useTranslation } from 'react-i18next';
import Role from 'src/constants/enum/role';
import { getUserInfo } from 'src/store/users/actions';
import { checkApplicationByUserId } from 'src/graphql/publisher/application';
import useWindowSize from 'src/utils/windowSize';
import { changelayoutMode, changeSidebarTheme } from '../../store/actions';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';

const Layout = (props: any) => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const history = useHistory();

  const { leftSideBarTheme, userInfo, layoutMode, layoutType, user } = useSelector(
    (state: any) => ({
      isPreloader: state.Layout.isPreloader,
      layoutWidth: state.Layout.layoutWidth,
      topbarTheme: state.Layout.topbarTheme,
      leftSideBarTheme: state.Layout.leftSideBarTheme,
      layoutMode: state.Layout.layoutMode,
      layoutType: state.Layout.layoutType,
      leftSidebarTypes: state.Layout.leftSidebarTypes,
      userInfo: state.Users.userInfo,
      user: state.login.user,
    }),
  );

  const isMobile: any = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme));
    }
  }, [leftSideBarTheme, dispatch]);

  useEffect(() => {
    if (layoutMode) {
      dispatch(changelayoutMode(layoutMode, layoutType));
    }
  }, [layoutMode, dispatch]);

  // useEffect(() => {
  //   if (!userInfo.name) {
  //     console.log('dispatce');
  //     dispatch(getUserInfo());
  //   }
  // }, [user]);

  // useEffect(() => {
  //   if (user.role) {
  //     if (user.role === Role.MARKETER || user.role === Role.ADVERTISER)
  //       if (!userInfo.name) {
  //         setTimeout(() => {
  //           notify(t('error:please fill your information.'), 'warning');
  //           history.push('/profile');
  //         }, 1200);
  //       }
  //   }
  // }, []);

  if (window.location.pathname.includes('mywebsite/websiteinfo')) {
    const { id }: any = useParams();
    const [pageStatus, setPageStatus]: any = useState();
    const history = useHistory();

    useEffect(() => {
      checkWebsite();
      window.scrollTo(0, 0);
    }, []);
    const checkWebsite = async () => {
      if (id) setPageStatus(await checkWebsiteByUserId(+id));
    };
    if (!pageStatus) return <></>;
    if (pageStatus.Status !== 'SUCCESS') history.push('/forbidden');
  }

  if (
    window.location.pathname.includes('Application') &&
    !window.location.pathname.includes('myApplication')
  ) {
    const { id }: any = useParams();
    const [pageStatus, setPageStatus]: any = useState();

    useEffect(() => {
      checkApplication();
    }, []);

    const checkApplication = async () => {
      if (id) setPageStatus(await checkApplicationByUserId(id));
    };
    if (!pageStatus) return <></>;
    if (pageStatus.Status !== 'SUCCESS') history.push('/forbidden');
  }

  const isAdmin = user?.role
    ? user.role === 'ADMIN' || user.role === 'OWNER'
    : false;
  const isMaketer = user?.role ? user.role === 'ADVERTISER' : false;
  const isInfluencer = user?.role ? user.role === 'PUBLISHER' : false;
  const contentStyle =
    isAdmin || (isMaketer && width > 992) ? { marginLeft: '300px' } : {};

  return (
    <div>
      {isInfluencer && <Header isMobile={isMobile} />}
      {isAdmin && <Header isMobile={isMobile} />}
      {/* <Header isMobile={isMobile} /> */}
      {isMaketer && <Sidebar theme={leftSideBarTheme} isMobile={isMobile} />}
      {isAdmin && <Sidebar theme={leftSideBarTheme} isMobile={isMobile} />}
      <div style={contentStyle}>{props.children}</div>
      {/* <div
        style={{
          display: 'flex',
          position: 'fixed',
          width: '80px',
          // height: '10px',
          padding: '10px',
          background: 'blue',
          color: 'white',
          bottom: '50px',
          right: '40px',
          justifyContent: 'center',
        }}
      >
        Chat
      </div> */}
      <Footer />
    </div>
  );
};

export default Layout;
