import { ErrorMessage, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { uniqueId } from 'lodash';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import CustomButton from 'src/components/Common/CustomButton';
import { OnLoading } from 'src/components/Common/Loading';
import menu from 'src/constants/menu';
import * as formatter from 'src/utils/convert-value';
import choice from 'src/assets/images/choice.png';
import background from 'src/assets/images/background-socialinfluencer.png';
import follower from 'src/assets/images/follower.png';
import activefollower from 'src/assets/images/active-follower.png';
import insightLogo from 'src/assets/images/insightpng.png';
import ProgressBarCircleComponent from 'src/components/shared/ProgressBarCircle/ProgressBarCircle.component';
import audienceLogo from 'src/assets/images/Audience-follower.png';
import dropdown from 'src/assets/images/arrow-down-sign-to-navigate-black.png';
import lessdetails from 'src/assets/images/arrow-down-sign-to-navigate-blackup.png';
import like from 'src/assets/images/data.png';
import Swal from 'sweetalert2';
import engagement from 'src/assets/images/line-chart.png';
import reach from 'src/assets/images/meeting.png';
import view from 'src/assets/images/eye3.png';
import impression from 'src/assets/images/Pon.png';
import likeperpost from 'src/assets/images/like1234.png';
import Role from 'src/constants/enum/role';
import comment from 'src/assets/images/talking.png';
import { floorToFixed } from 'src/common/data/mathToFixed';
import share from 'src/assets/images/structure.png';
import {
  onSubmit,
  validationSchema,
  onBack,
} from 'src/pages/Publisher/Influencer/ChannelDetail/control/formControl';
import ErrorFocus from 'src/utils/ErrorFocus';
import { useState } from 'react';
import { SocialMediaType } from '../Dashboard/SocialChannel/SocialChannel.control';
import AptitudesComponent from './Aptitudes/Aptitudes.component';
import ChannelLogoComponent from './ChannelLogo';
import ChannelStaticComponent from './ChannelStatic';
import ContentPriceComponent from './ContentPrice/ContentPrice.component';
import ImageProfileComponent from './ImageProfile';
import IntroduceComponent from './Introduce/Introduce.component';
import {
  // CardContainer,
  ChanelDetailContainer,
  NameUser,
  PageContent,
  // PriceContainer,
  // StaticContainer,
  StaticContent,
} from './SocialProfile.style';

const NewSocialProfile = ({ setFormValues, socialMediaLogo, formValues }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [editNumFollower, setEditNumFollower] = useState(0);
  const [editFollower, setEditFollower] = useState(false);
  const [toggleMore, setToggleMore] = useState(false);

  const {
    userProfile$,
    userProfileSelected$,
    userProfileList$,
    influencerPriceRate$,
    user,
  } = useSelector((state: any) => ({
    userProfile$: state.SocialMediaInfo.socialMediaInfoData,
    userProfileList$: state.CalculateSocialMedia.userProfileList,
    userProfileSelected$: state.CalculateSocialMedia.userProfileSelected,
    influencerPriceRate$: state.SocialMediaInfo.influencerPriceRate,
    user: state.login.user,
  }));
  // eslint-disable-next-line no-console
  const profile = userProfile$;

  if (!userProfileSelected$) return <OnLoading />;
  const handleMore = () => setToggleMore((prev) => !prev);
  return (
    <PageContent className="page-content">
      <button
        type="submit"
        onClick={() => {
          onBack({ userProfileList$, dispatch, history, formValues });
        }}
        className="btn "
        style={{ color: 'black', backgroundColor: 'white', fontWeight: 'bold' }}
      >
        {t('<Back')}
      </button>
      <div className="bread-crumb">
        <Breadcrumb
          pageTitle={t('Menus:Create Social Channel')}
          menus={[
            { menu: menu.HOME, active: true },
            { menu: menu.OVERVIEW },
            { menu: menu.CREATESOCIALCHANNEL, isSamePage: true },
          ]}
        />
      </div>
      <ChanelDetailContainer>
        <CardContainer className="card-container">
          <ImageProfileComponent userProfile={userProfileSelected$} />
          <ChannelLogoComponent socialMediaLogo={socialMediaLogo} />
          <Formik
            initialValues={formValues}
            onSubmit={() => {
              onSubmit({
                userProfileList$,
                dispatch,
                history,
                formValues,
              });
            }}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ errors, touched }) => {
              return (
                <Form style={{ textAlign: 'center' }}>
                  <StaticContainer>
                    <NameUser className="name">
                      {userProfileSelected$?.profile_path ? (
                        <a
                          href={userProfileSelected$?.profile_path}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {userProfileSelected$?.name}
                        </a>
                      ) : (
                        userProfileSelected$?.name
                      )}
                    </NameUser>

                    {!editFollower && (
                      <FollowerContainer>
                        <img src={follower} alt="" />
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <span>Follower</span>
                        </div>
                      </FollowerContainer>
                    )}
                    {userProfileSelected$.social_media_type ===
                    SocialMediaType.FACEBOOK_PERSONAL ? (
                      <>
                        <input
                          min={1}
                          name="fan_count"
                          type="text"
                          onFocus={(e) => {
                            e.target.type = 'number';
                          }}
                          onBlur={(e) => {
                            e.target.type = 'text';
                          }}
                          onChange={(e) => {
                            const { value } = e.target;
                            setFormValues((current: any) => ({
                              ...current,
                              fan_count: Number(value),
                            }));
                          }}
                          value={formValues.fan_count || 0}
                          style={{
                            display: 'block',
                            width: '100%',
                            maxWidth: '180px',
                            outline: 'none',
                            border: '1px solid #CFD2CF',
                            borderRadius: '8px',
                            marginTop: '10px',
                            textAlign: 'center',
                          }}
                        />
                        <ErrorMessage
                          name="fan_count"
                          component="div"
                          className="invalid-message"
                        />
                        <ErrorFocus name="fan_count" />
                        <p style={{ fontSize: '1rem' }}>
                          <span style={{ color: 'red' }}>*</span>
                        </p>
                      </>
                    ) : (
                      <>
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: '20px',
                            marginTop: '10px',
                          }}
                        >
                          {formatter.nFormatter(formValues?.fan_count, 1 || 0)}
                        </span>
                        <ErrorMessage
                          name="fan_count"
                          component="div"
                          className="invalid-message"
                        />
                        <ErrorFocus name="fan_count" />
                      </>
                    )}
                    {/* <ActiveFollowerContainer>
                      <img src={activefollower} alt="" />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <span>Active Follower</span>
                        <ProgressBarCircleComponent
                          key={uniqueId()}
                          animation_timer="1.2s"
                          background_color="#DFDFDE"
                          percent={profile?.active_follower}
                          progress_color="#9FB4FF"
                          text={profile?.active_follower}
                          name="active_follower"
                        />
                      </div>
                    </ActiveFollowerContainer> */}
                  </StaticContainer>
                  <div style={{ marginBottom: '40px' }}> </div>
                  <PriceContainer className="price-container">
                    <ContentPriceComponent
                      formValues={formValues}
                      setFormValues={setFormValues}
                      influencerPriceRate={influencerPriceRate$}
                      firstCalculate
                      disableBtn
                    />
                  </PriceContainer>
                  <PriceContainer>
                    <AptitudesComponent
                      formValues={formValues}
                      setFormValues={setFormValues}
                      userProfile={userProfile$}
                    />
                  </PriceContainer>
                  {/* <PriceContainer>
                    <KeywordAptitudesComponent
                      formValues={formValues}
                      setFormValues={setFormValues}
                      userProfile={userProfile$}
                      validateField={validateField}
                    />
                  </PriceContainer> */}
                  <PriceContainer>
                    <IntroduceComponent
                      errors={errors}
                      touched={touched}
                      formValues={formValues}
                      setFormValues={setFormValues}
                    />
                  </PriceContainer>
                  <CustomButton
                    status="submit"
                    size="lg"
                    label={t('influencer:account:send_request')}
                  />
                  {/* <CustomButton
                    size="md"
                    label={t('influencer:account:send_request')}
                  /> */}
                </Form>
              );
            }}
          </Formik>
        </CardContainer>
      </ChanelDetailContainer>
    </PageContent>
  );
};

const ActiveFollowerContainer = styled.div`
  display: flex;
  /* gap: 0.5rem; */
  margin-top: 0.5rem;

  img {
    width: 35px;
    height: 35px;
    @media (max-width: 500px) {
      width: 32px;
      height: 32px;
    }
  }
  span {
    font-size: 24px;
    font-weight: bold;
    @media (max-width: 500px) {
      font-size: 20px;
    }
  }
`;
const FollowerContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;

  .btn-edit {
    margin-left: 1rem;
    color: white;
    font-size: 14px;
    width: 90px;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
    border-radius: 5px;
    background: linear-gradient(104deg, #6b99ca, #6bc6b3);
    cursor: pointer;
  }

  img {
    width: 35px;
    height: 35px;
    @media (max-width: 500px) {
      width: 32px;
      height: 32px;
    }
  }
  span {
    font-size: 24px;
    font-weight: bold;
    @media (max-width: 500px) {
      font-size: 20px;
    }
  }
`;

const AudienceBox = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  width: 100px;

  @media (max-width: 500px) {
    margin-top: 5px;
  }
  span {
    min-width: 35px;
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
`;

const AudienceLogo = styled.span`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 3rem;
  /* position: relative;
  top: -1rem; */
  span {
    font-size: 30px;
    font-weight: bold;
    color: white;

    @media (max-width: 550px) {
      font-size: 20px;
    }
  }
`;

const IconBox = styled.span`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  span {
    font-weight: bold;
  }
`;

const InsightsBox = styled.span`
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  line-height: 2rem;

  @media (max-width: 500px) {
    min-width: 100px;
  }
  span {
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
`;

const PriceContainer = styled.div`
  text-align: center;
  padding-top: 0.5rem;
  width: 100%;
  border-top: 1px solid rgb(221, 218, 218);
  margin-top: 0.5rem;
`;

const IndicatorBar = styled.span<{ value: any }>`
  height: 10px;
  background: white;
  border-radius: 5px;
  /* border: 3px solid; */
  width: ${({ value }) => (value ? `${value}%` : '0')};
  /* padding: 0 2rem; */

  @media (max-width: 500px) {
    height: 7px;
  }
`;

const MoreDetailContainer = styled.div<{ toggleMore: boolean }>`
  /* border: 5px solid; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
  max-height: ${({ toggleMore }) => (toggleMore ? '880px' : '0')};
  overflow: hidden;
  opacity: ${({ toggleMore }) => (toggleMore ? '1' : '0')};
  transition: all 0.5s ease;
`;

const InsightLogo = styled.span`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 3rem;
  /* position: relative;
  top: -1rem; */
  span {
    font-size: 30px;
    font-weight: bold;
    color: white;

    @media (max-width: 550px) {
      font-size: 20px;
    }
  }
`;

const AudienceContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  border-radius: 10px;
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  div {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 2rem;
    padding: 0 2rem;
    border-bottom: 1px solid rgb(221, 218, 218);
    /* border-width: 80%; */
    width: 80%;

    @media (max-width: 550px) {
      width: 100%;
    }
    & > span:nth-last-of-type(1) {
      margin-left: auto;
    }
  }
`;

const InsightContainer = styled.div`
  position: relative;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 80%;
  border-radius: 10px;
  /* border: 2px solid yellow; */
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* border: 1px solid; */
    width: 100%;
    margin-bottom: 1rem;
  }
`;

const CardContainer = styled.div`
  position: relative;
  height: max-content;
  min-height: 300px;
  width: 100%;
  max-width: 50rem;
  margin-top: 2.5rem;
  margin-bottom: 3rem;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  background-color: #fbfaff;
`;

const NameContainer = styled.div`
  text-align: center;
  padding-top: 4rem;
  width: 100%;
  /* border: 2px solid black; */
`;

const StaticContainer = styled.div`
  position: relative;
  background-color: #fbfaff;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  margin-top: 70px;
  /* border: 2px solid pink; */
  .btn {
    margin-left: 1rem;
    color: white;
    font-size: 14px;
    width: 90px;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
    border-radius: 5px;
    background: linear-gradient(104deg, #6b99ca, #6bc6b3);
    cursor: pointer;
  }
  span {
    @media (max-width: 500px) {
      /* font-size: px; */
    }
  }
`;

export default NewSocialProfile;
