import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import InviteOnlyTransactionsTable from 'src/components/Admin/InviteOnlyTransactionsTable';
import PaymentTransactionsTable from 'src/components/Admin/PaymentTransactionsTable';
import PublishTransactionTable from 'src/components/Admin/PublishTransactionTable';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import menu from 'src/constants/menu';

const PaymentPage = ({ t }: any) => {
  const [tab, setTab] = useState(1);

  const renderTabContect = () => {
    switch (tab) {
      case 1:
        return <PaymentTransactionsTable />;
      case 2:
        return <PublishTransactionTable />;
      case 3:
        return <InviteOnlyTransactionsTable />;
      default:
        return <PaymentTransactionsTable />;
    }
  };
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb
          pageTitle={t('Page.Payment Transactions')}
          menus={[
            { menu: menu.DASHBOARD },
            { menu: menu.PAYMENT_TRANSACTION, active: true },
          ]}
        />
        <Row>
          <Col lg={12}>
            <Card className="mt-3">
              <CardHeader>
                <Nav tabs className="nav-tabs-custom mb-3">
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setTab(1);
                      }}
                      className={`${tab === 1 && 'active'}`}
                    >
                      <h5>{t('Menus.Payment Transactions')}</h5>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setTab(2);
                      }}
                      className={`${tab === 2 && 'active'}`}
                    >
                      <h5>{t('Menus.Publish')}</h5>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setTab(3);
                      }}
                      className={`${tab === 3 && 'active'}`}
                    >
                      <h5>{t('Menus.Invite Only')}</h5>
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody>{renderTabContect()}</CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withTranslation()(PaymentPage);
