import { gql, useSubscription } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import notificationSound from 'src/assets/Facebook Sound.mp3';
import { authRoutes, commonRoutes } from './routes/allRoutes';
import ChangePasswordConfirmPage from './pages/ChangePasswordConfirmPage';
import ForbiddenPage from './pages/ForbiddenPage';
import NotFoundPage from './pages/NotFoundPage';
import TwoFactorDisableConfirmPage from './pages/TwoFactorDisableConfirmPage';
import adminRoutes from './routes/adminRoutes';
import advertiserRoutes from './routes/advertiserRoutes';
import publisherRoutes from './routes/publisherRoutes';
import { roleText } from './constants/role';
import InfluencerJobDetail from './pages/Advertiser/Marketer/InfluencerJobDetail/influencerJobDetail';
import InfluencerPublicJobDetail from './pages/Advertiser/Marketer/InfluencerJobDetail/influencerPublicJobDetail';
import {
  getAllAdminApproveLog,
  getNewActivityLogs,
  getRecentActivityLogs,
} from './store/actions';
import AuthMiddleWare from './routes/middleware/authMiddleWare';
import './assets/scss/theme.scss';
import './assets/scss/preloader.scss';
import ScrollToTop from './common/ScrollToTop';
import Role from '../src/constants/enum/role';

const SUBSCRIBE_ACTIVITY_ADDED = gql`
  subscription {
    activityLogAdded {
      activity_log_key
      activity_log_type
      context
      is_read
    }
  }
`;

const theme = createTheme({
  typography: {
    fontFamily: ['RSU'].join(','),
  },
});

const App = () => {
  const dispatch = useDispatch();
  const { layoutMode, user } = useSelector((state: any) => ({
    layoutMode: state.Layout.layoutMode,
    user: state.login.user,
  }));

  const { data } = useSubscription<any>(SUBSCRIBE_ACTIVITY_ADDED);

  const isLoggedIn = useSelector((state: any) => state.login.isLoggedIn);

  useEffect(() => {
    if (!data || !data?.activityLogAdded || !isLoggedIn) return;

    const btn = document.createElement('button');
    document.body.appendChild(btn);
    btn.click();
    Play();
    dispatch(
      getNewActivityLogs({
        ...data.activityLogAdded,
        created_at: new Date().toDateString(),
      }),
    );
    if (user.role === Role.ADMIN) {
      dispatch(getAllAdminApproveLog());
    }
  }, [data]);

  const Play = () => {
    const audio = new Audio(notificationSound);
    // audio.load();
    audio.play();
  };

  useEffect(() => {
    if (isLoggedIn) dispatch(getRecentActivityLogs());
  }, [isLoggedIn]);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/forbidden" component={ForbiddenPage} />
          <Route
            exact
            path="/two-factor/disable"
            component={TwoFactorDisableConfirmPage}
          />
          <Route
            exact
            path="/change-password/:type"
            component={ChangePasswordConfirmPage}
          />
          <Route exact path="/job/:jobId" component={InfluencerPublicJobDetail} />

          {authRoutes.map((route) =>
            AuthMiddleWare({
              route,
              showNav: route.showNav,
              isAuthProtected: false,
              preventRoles: false,
              role: null,
              preventOnLoggedIn: route.preventOnLoggedIn,
              type: 'auth',
            }),
          )}

          {commonRoutes.map((route) =>
            AuthMiddleWare({
              route,
              showNav: false,
              isAuthProtected: true,
              preventRoles: route.preventRoles,
              role: null,
              preventOnLoggedIn: null,
              type: 'common',
            }),
          )}

          {adminRoutes.map((route) =>
            AuthMiddleWare({
              route,
              isAuthProtected: true,
              showNav: false,
              preventRoles: null,
              role: roleText.ADMIN,
              preventOnLoggedIn: null,
              type: 'admin',
            }),
          )}

          {advertiserRoutes.map((route) =>
            AuthMiddleWare({
              route,
              isAuthProtected: true,
              preventRoles: null,
              showNav: false,
              role: roleText.ADVERTISER,
              preventOnLoggedIn: null,
              type: 'advertiser',
            }),
          )}

          {publisherRoutes.map((route) =>
            AuthMiddleWare({
              route,
              isAuthProtected: true,
              preventRoles: null,
              showNav: false,
              role: roleText.PUBLISHER,
              preventOnLoggedIn: null,
              type: 'publisher',
            }),
          )}

          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
