import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { OnLoading } from 'src/components/Common/Loading';
import config from 'src/config';
import authByThirdParty from './authByThirdParty';

const authByFacebook = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);

  const socialProcess = async () => {
    const state: string | null = params.get('state');
    const stateData = state?.split('-ref');
    const code: string | null = params.get('code');

    const role: string = stateData ? stateData[0] : '';
    const ref: string = stateData ? stateData[1] : '';
    const authData = {
      role,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Bangkok',
      ref,
    };

    if (!code) {
      return null;
    }

    const access_token: any = await getAccessMetaToken(code);

    const { email, name, id }: any = await getDataFromFacebook(access_token);
    if (!id) {
      history.push('/');
      return null;
    }
    await authByThirdParty({
      data: authData,
      email,
      dispatch,
      history,
      name,
      social_id: id,
      loginType: 'FACEBOOK',
    });
    return null;
  };

  useEffect(() => {
    socialProcess();
  }, []);

  const getAccessMetaToken = async (code: string) => {
    const url: string = `${config.backendUrl}social/auth/facebook?code=${code}`;

    const response = await fetch(url, {
      method: 'GET',
      redirect: 'follow',
    });

    return response.text();
  };

  const getDataFromFacebook = async (access_token: string) => {
    try {
      const url: string = `https://graph.facebook.com/v15.0/me?fields=id,email,name&access_token=${access_token}`;

      const response = await fetch(url, {
        method: 'GET',
      });

      const responseText: string = await response.text();

      const { email, name, id }: any = JSON.parse(responseText);

      return { email, name, id };
    } catch (error) {
      history.push('/');
      return error;
    }
  };

  return <OnLoading />;
};

export default authByFacebook;
