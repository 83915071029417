/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Column } from 'react-table';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import CoinImage from '../Common/CoinImage';
import { transformStringAmountInput } from '../../utils/input';
import { transformAddressInShortFrom } from '../../utils/address';
import CustomTable from '../Common/CustomTable';
import { getAllDepositTransactionsRequest as onGetAllDepositTransactionsRequest } from '../../store/finance/deposit/actions';
import PageSizeOptions from '../Table/PageSizeOptions';
import CurrencyOptions from '../Table/CurrencyOptions';
import SearchBar from '../Table/SearchBar';
import TransactionDetailModal from '../Table/TransactionDetailModal';
import { splitUnderscoreWithTuple } from '../../utils/currency';
import DateTimeTypes from '../../constants/dateTimeTypes';
import DateTimeComponent from '../Common/DateTimeComponent';

const DepositTransactionsTable = ({ t, tab, userId }: any) => {
  const defaultPageSize = 10;
  const dispatch = useDispatch();

  // Local State
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currency, setCurrency] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');
  const [param, setParam] = useState({
    page,
    limit: pageSize,
    currencySymbol: currency,
    search,
    sortBy,
    sortType,
    userId,
  });

  // Redux
  const { txn } = useSelector((state: any) => ({
    txn: state.Deposit.transactions,
  }));

  useEffect(() => {
    setPage(1);
    setPageSize(defaultPageSize);
    setCurrency('');

    setParam({
      ...param,
      page: 1,
      limit: defaultPageSize,
      currencySymbol: '',
    });
    // Fetch Data With Default Payload When Tab Changed
    dispatch(
      onGetAllDepositTransactionsRequest({
        limit: defaultPageSize,
        page: 1,
        userId,
      }),
    );
  }, [tab]);

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleGetDetail = (rowIndex: any) => {
    setSelectedRow(rowIndex);
    setModalOpen(!modalOpen);
  };

  const handlePageSizeChange = (size: string) => {
    const newPageSize = parseInt(size, 10);

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    const payload = {
      ...getDefaultPayload(newPage),
      limit: newPageSize,
    };

    dispatch(onGetAllDepositTransactionsRequest(payload));

    setPageSize(newPageSize);
    setParam(payload);
  };

  const handleCurrencyChange = (currency: string) => {
    const payload = {
      ...getDefaultPayload(1),
      currencySymbol: currency,
    };

    dispatch(onGetAllDepositTransactionsRequest(payload));
    setCurrency(currency);
    setParam(payload);
  };

  const handleSearch = (val: string) => {
    const payload = {
      ...getDefaultPayload(1),
      search: val,
    };

    dispatch(onGetAllDepositTransactionsRequest(payload));
    setSearch(search);
    setParam(payload);
  };

  const handleClear = () => {
    const payload = {
      ...getDefaultPayload(1),
      search: '',
    };

    dispatch(onGetAllDepositTransactionsRequest(payload));
    setSearch('');
    setParam(payload);
  };
  const dataTimezone = (created_at: any) => {
    return moment(created_at);
  };
  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('table:Date'),
        accessor: ({ created_at }) => {
          return (
            <div style={{ width: '200px' }}>
              <DateTimeComponent
                dateTime={dataTimezone(created_at)}
                dateTimeType={DateTimeTypes.GET_DATE_TIME_FULL_FORMAT}
              />
            </div>
          );
        },
        id: 'created_at',
      },
      {
        Header: t('table:uuid'),
        accessor: ({ uuid }) => {
          return uuid;
        },
        id: 'uuid',
      },
      {
        Header: t('table:Email'),
        accessor: ({ user }) => {
          return user.email;
        },
        id: 'email',
        disableSortBy: true,
      },
      {
        Header: t('table:Currency'),
        accessor: ({ currency }) => {
          return (
            <>
              <CoinImage symbol={currency.symbol} />
            </>
          );
        },
        id: 'currency',
        width: 100,
        disableSortBy: true,
      },
      {
        Header: t('table:Crypto Amount'),
        // eslint-disable-next-line camelcase
        accessor: ({ CRYPTO_amount, currency }) => {
          return `${transformStringAmountInput(
            CRYPTO_amount,
          )} ${splitUnderscoreWithTuple(currency.symbol)}`;
        },
        id: 'CRYPTO_amount',
      },
      {
        Header: t('table:Crypto Fiat'),
        // eslint-disable-next-line camelcase
        accessor: ({ FIAT_amount }) => {
          return <>${transformStringAmountInput(FIAT_amount)}</>;
        },
        id: 'FIAT_amount',
      },
      {
        Header: t('table:Dest Address'),
        accessor: ({ fromAddress }) => {
          return fromAddress ? transformAddressInShortFrom(fromAddress, 12) : '';
        },
        id: 'fromAddress',
        disableSortBy: true,
      },
      {
        Header: t('table:Transfer Type'),
        accessor: ({ transferType }) => {
          return transferType;
        },
        id: 'transferType',
        width: 150,
        disableSortBy: true,
      },
      {
        Header: t('table:Action'),
        accessor: ({ user }, rowIndex) => {
          return (
            <>
              <UncontrolledDropdown>
                <DropdownToggle className="text-muted font-size-28" tag="a">
                  <i className="fas fa-file-alt fa-1x c-pointer" />
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-start">
                  {isEmpty(userId) && (
                    <>
                      <Link to={`/admin/users/${user.uuid}`}>
                        <DropdownItem>
                          <i className="fas fa-user" />
                          &nbsp;&nbsp;
                          {t('table:User Detail')}
                        </DropdownItem>
                      </Link>
                      <div className="dropdown-divider" />
                    </>
                  )}
                  <DropdownItem
                    onClick={() => {
                      handleGetDetail(rowIndex);
                    }}
                  >
                    <i className="fas fa-file-alt" />
                    &nbsp;&nbsp;{t('Detail')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          );
        },
        id: 'action',
        width: 100,
        disableSortBy: true,
      },
    ],
    [t, txn],
  );

  const data = React.useMemo(() => {
    return !isEmpty(txn) ? txn.items : [];
  }, [txn]);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...getDefaultPayload(newPage),
    };

    dispatch(onGetAllDepositTransactionsRequest(payload));
    setParam(payload);
  };

  const getDefaultPayload = (page: number) => {
    setPage(page);
    setParam({
      ...param,
      page,
    });
    return {
      ...param,
      page,
    };
  };

  const hiddenOnXS: any = ['created_at', 'email', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnSM: any = ['email', 'CRYPTO_amount', 'dest_address'];
  const hiddenOnMD: any = ['CRYPTO_amount', 'dest_address'];
  const hiddenOnLG: any = [];

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(newPage),
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(onGetAllDepositTransactionsRequest(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
    },
    [search, currency, page, pageSize],
  );

  return (
    <>
      <CustomTable
        headerCSS="fw-normal bg-header-table"
        columns={columns}
        data={data}
        page={page}
        pageSize={pageSize}
        total={txn.totalCount}
        handlePageChange={handlePageChange}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
        onSort={handleSort}
      >
        <TransactionDetailModal
          data={!isEmpty(txn) ? txn.items[selectedRow] : null}
          isOpen={modalOpen}
          handleToggle={handleModalToggle}
          handleClose={handleModalClose}
          title={t('table:Deposit Detail')}
        />
        <Row className="justify-content-between m-0">
          <Col xs={12} md={3} className="d-flex align-items-center mb-3 mb-md-0">
            <PageSizeOptions
              defaultValue={pageSize}
              handlePageSizeChange={handlePageSizeChange}
            />
          </Col>
          <Col xs={12} md={9} lg={9}>
            <Row className="align-items-center justify-content-end">
              <Col sm={12} md={4} lg={3}>
                <CurrencyOptions
                  defaultValue={currency}
                  handleCurrencyChange={handleCurrencyChange}
                />
              </Col>
              <Col sm={12} md={8} lg={5} className="mx-5">
                <SearchBar
                  placeholder={t('table:Search Placeholder')}
                  handleSearch={handleSearch}
                  handleClear={handleClear}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </CustomTable>
    </>
  );
};

export default withTranslation()(DepositTransactionsTable);
