import { FormGroup } from 'reactstrap';
import { ErrorMessage } from 'formik';
import ErrorFocus from 'src/utils/ErrorFocus';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltips from '@mui/material/Tooltip';
import instagramLogo from 'src/assets/images/instagram-logo.png';
import facebookLogo from 'src/assets/images/facebook-logo.png';
import twitterLogo from 'src/assets/images/twitter_logo.png';
import youtubeLogo from 'src/assets/images/youtube-logo.png';
import tikTokLogo from 'src/assets/images/tiktok-logo.png';
import { createJobGql, updateJobGql } from 'src/graphql/advertiser/job';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { clearMarketerJob, getMarketerJob } from 'src/store/marketerJob/actions';
import FloatingInputComponent from 'src/components/shared/FloatingInput/FloatingInput.component';
import IconRadioInputComponent from 'src/components/shared/IconRadioInput/IconRadioInput.component';
import paramsFromURL from 'src/constants/paramsFromURL';

const CreateMarketerJobCampaignControl = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dataIsCleared, setDataIsCleared] = useState(false);
  const history = useHistory();
  const [campaignId, jobId] = paramsFromURL(['campaign', 'job']);
  const data = useSelector((state: any) => state.MarketerJob.marketerJob);

  //? Form Controls
  const formControls: FormControl[] = [
    {
      label: 'marketer:create_job_campaign.job_name',
      name: 'name',
      type: FormInputType.STRING,
      required: true,
      tooltip: t(
        'marketer:create_job_campaign.Your job name for example, Promote AFbrother website',
      ),
    },
    {
      label: 'marketer:create_job_campaign.social_media_type',
      name: 'social_media_type',
      type: FormInputType.SOCIAL_MEDIA_TYPE,
      categories: [
        {
          label: 'marketer:create_job_campaign.facebook',
          value: 'FACEBOOK',
          image: facebookLogo,
          background_color: '#ffffff',
        },
        {
          label: 'marketer:create_job_campaign.instagram',
          value: 'INSTAGRAM',
          image: instagramLogo,
          background_color: '#ffffff',
        },
        {
          label: 'marketer:create_job_campaign.twitter',
          value: 'TWITTER',
          image: twitterLogo,
          background_color: '#ffffff',
        },
        {
          label: 'marketer:create_job_campaign.youtube',
          value: 'YOUTUBE',
          image: youtubeLogo,
          background_color: '#ffffff',
        },
        {
          label: 'marketer:create_job_campaign.tiktok',
          value: 'TIKTOK',
          image: tikTokLogo,
          background_color: '#ffffff',
        },
      ],
    },
    {
      label: 'marketer:create_job_campaign.content_type',
      name: 'content_type',
      type: FormInputType.CONTENT_TYPE,
      categories: [
        {
          label: 'marketer:create_job_campaign.share',
          value: 'SHARE',
          icon: 'fas fa-share',
          background_color: '#3AB0FF',
        },
        {
          label: 'marketer:create_job_campaign.photo',
          value: 'PHOTO',
          icon: 'fas fa-image',
          background_color: '#C689C6',
        },
        {
          label: 'marketer:create_job_campaign.video',
          value: 'VIDEO',
          icon: 'fas fa-video',
          background_color: '#FF0000',
        },
      ],
    },
  ];
  const initialValues = {
    name: '',
    social_media_type: '',
    content_type: '',
  };
  const [formValues, setFormValues] = useState(initialValues);
  const validationSchema = Yup.object({
    name: Yup.string().required(t('marketer:create_job_campaign.required')),
    social_media_type: Yup.string().required(
      t('marketer:create_job_campaign.please_select_social_media_type'),
    ),
    content_type: Yup.string().required(
      t('marketer:create_job_campaign.please_select_content_type'),
    ),
  });

  //? Logic
  useEffect(() => {
    if (jobId) dispatch(getMarketerJob(jobId));
  }, [jobId]);

  useEffect(() => {
    if (data && jobId) {
      const { name, social_media_type, content_type } = data;
      const initialData = { name, social_media_type, content_type };

      setFormValues(initialData);
    }
  }, [data]);

  useEffect(() => {
    if (!dataIsCleared) {
      dispatch(clearMarketerJob());
      setDataIsCleared(true);
    }
  }, []);

  //? Functions
  const handleSubmit = async (jobType: any) => {
    if (jobId) {
      const submitData: any = formValues;

      for (let i = 0; i < Object.keys(initialValues)?.length; i++) {
        const valueKey = Object.keys(initialValues)[i];
        if (submitData[valueKey] === data[valueKey]) {
          delete submitData[valueKey];
        }
      }
      if (Object.keys(submitData)?.length) {
        await updateJobGql({
          ...submitData,
          key: jobId,
        });
      }
      if (jobId) {
        history.push(`/Marketer/AddJobDetail?job=${jobId}`);
      }
    } else {
      const baseCreateInputJob: any = {
        ...formValues,
        marketer_campaign_key: campaignId,
      };

      if (jobType === 'PUBLISH') {
        baseCreateInputJob.job_type = 'PUBLISH';
      }
      const res = await createJobGql(baseCreateInputJob);

      if (res.key) {
        history.replace(`/Marketer/EditJobCampaign?job=${res.key}`);
        history.push(`/Marketer/AddJobDetail?job=${res.key}`);
      }
    }
  };

  const inputs = (errors: any, touched: boolean, handleChange: any) => {
    return formControls.map((input: FormControl) => {
      switch (input.type) {
        case FormInputType.STRING:
          return textInput(input, errors, touched, handleChange);
        case FormInputType.SOCIAL_MEDIA_TYPE:
          return socialMediaTypeInput(input);
        case FormInputType.CONTENT_TYPE:
          return contentTypeInput(input);
        default:
          return textInput(input, errors, touched, handleChange);
      }
    });
  };

  //? Input UI Function
  const socialMediaTypeInput = (input: FormControl) => {
    // eslint-disable-next-line no-console
    return (
      <>
        <div className="radio-container">
          <h5>1. {t('marketer:create_job_campaign:Choose_channel_review')}</h5>
          <div className="social-grid-container">
            {input &&
              input.categories &&
              input.categories.map((socialMediaType) => (
                <IconRadioInputComponent
                  key={`radio ${socialMediaType.value}`}
                  id={`radio ${socialMediaType.value}`}
                  onClick={() => {
                    setFormValues({
                      ...formValues,
                      social_media_type: socialMediaType.value,
                      content_type: '',
                    });
                  }}
                  isChecked={formValues?.social_media_type === socialMediaType.value}
                  icon={socialMediaType?.icon || null}
                  images={socialMediaType?.image || null}
                  label={socialMediaType.label}
                  background={socialMediaType.background_color}
                  iconColor={socialMediaType.icon_color}
                />
              ))}
          </div>
        </div>

        <ErrorMessage
          name={input.name}
          component="div"
          className="invalid-message text-start"
        />
        <ErrorFocus name={input.name} />
      </>
    );
  };

  const contentTypeInput = (input: FormControl) => {
    const showContentType = (types: any) => {
      return (
        <>
          <div className="radio-container ">
            <h5>2.{t('marketer:create_job_campaign:Choose_content')}</h5>
            <div className="content-grid-container">
              {input?.categories?.map((socialMediaType) => {
                if (types.includes(socialMediaType.value)) {
                  return (
                    <IconRadioInputComponent
                      key={`radio ${socialMediaType.value}`}
                      id={`radio ${socialMediaType.value}`}
                      onClick={() => {
                        setFormValues({
                          ...formValues,
                          content_type: socialMediaType.value,
                        });
                      }}
                      isChecked={formValues?.content_type === socialMediaType.value}
                      icon={socialMediaType?.icon || null}
                      imageUrl={socialMediaType?.image || null}
                      label={socialMediaType.label}
                      background={socialMediaType.background_color}
                      iconColor={socialMediaType.icon_color}
                    />
                  );
                }
                return '';
              })}
            </div>
          </div>
          <ErrorMessage
            name={input.name}
            component="div"
            className="invalid-message text-start"
          />
          <ErrorFocus name={input.name} />
        </>
      );
    };
    switch (formValues.social_media_type) {
      case 'TIKTOK':
        return showContentType(['VIDEO']);
      case 'FACEBOOK':
        return showContentType(['SHARE', 'PHOTO']);
      case 'INSTAGRAM':
        return showContentType(['SHARE', 'PHOTO']);
      case 'YOUTUBE':
        return showContentType(['VIDEO']);
      case 'TWITTER':
        return showContentType(['SHARE', 'PHOTO']);
      default:
        return '';
    }
  };

  const textInput = (
    input: FormControl,
    errors: any,
    touched: any,
    handleChange: any,
  ) => {
    return (
      <FormGroup
        className="text-start"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <FloatingInputComponent
          name={input.name}
          // type={input.input_type || 'input'}
          value={formValues[input.name as keyof typeof initialValues] as any}
          isInvalid={errors[input.name] && touched[input.name]}
          onChange={(e: any) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
            handleChange(e);
          }}
          label={input.label}
        />
        {input.tooltip && input.name !== 'textarea' ? (
          <Tooltips title={t(input.tooltip || '-')} placement="top-start">
            <i
              className="fas fa-info-circle ms-2 font-size-16"
              style={{
                cursor: 'pointer',
                marginTop: '2.9rem',
              }}
              id="tooltipInfo"
            />
          </Tooltips>
        ) : (
          ''
        )}
      </FormGroup>
    );
  };

  //? Return
  return {
    initialValues,
    handleSubmit,
    validationSchema,
    inputs,
    data,
    formValues,
    dataIsCleared,
  };
};

export default CreateMarketerJobCampaignControl;

interface Category {
  label: string;
  value: string;
  icon?: any;
  image?: any;
  icon_color?: string;
  background_color?: string;
}

// eslint-disable-next-line no-unused-vars, no-shadow
enum FormInputType {
  // eslint-disable-next-line no-unused-vars
  STRING,
  // eslint-disable-next-line no-unused-vars
  SOCIAL_MEDIA_TYPE,
  // eslint-disable-next-line no-unused-vars
  CONTENT_TYPE,
}

interface FormControl {
  label: string;
  name: any;
  type: FormInputType;
  required?: boolean;
  disabled?: boolean;
  max_length?: number;
  min_length?: number;
  tooltip?: string;
  categories?: Category[];
}
