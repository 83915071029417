/* eslint-disable jsx-a11y/anchor-is-valid */
import { Table } from 'reactstrap';
import Pagination from '@mui/material/Pagination';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { usePagination, useRowSelect, useTable, useSortBy } from 'react-table';
import { isEmpty } from 'lodash';
import i18n from '../../i18n';

const CustomTableJob = (props: any) => {
  const {
    children,
    columns,
    data,
    page,
    pageSize,
    total,
    headerStyle,
    headerCSS,
    bodyCSS,
    handlePageChange,
    hiddenColumn,
    hiddenOnXS,
    hiddenOnSM,
    hiddenOnMD,
    hiddenOnLG,
    autoSort,
    hidePagination,
    onSort,
    marginTopClassName,
    firstRowStyle,
    isLoading,
  } = props;

  const history = useHistory();
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      manualSortBy: !autoSort,
      disableMultiSort: true,
    },
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const skeletonLength: number[] = [0, 0, 0, 0, 0];

  useEffect(() => {
    handleResize();
  }, [rows]);

  useEffect(() => {
    if (onSort) {
      onSort(sortBy);
    }
  }, [onSort, sortBy]);

  const handleResize = () => {
    const { width } = window.screen;
    if (hiddenColumn) {
      setHiddenColumns(hiddenColumn);
    }
    if (hiddenOnXS) {
      if (width >= 992) {
        setHiddenColumns(hiddenOnLG);
      } else if (width >= 768) {
        setHiddenColumns(hiddenOnMD);
      } else if (width >= 576) {
        setHiddenColumns(hiddenOnSM);
      } else {
        setHiddenColumns(hiddenOnXS);
      }
    }
  };

  return (
    <div
      className="table-responsive b"
      style={{
        minHeight: '250px',
        height: 'fit-content',
        overflowX: 'auto',
        overflowY: 'auto',
        margin: '0px',
        padding: '0px',
        position: 'relative',
        paddingBottom: '60px',
      }}
    >
      {children}
      <div className={`react-bootstrap-table ${marginTopClassName ?? 'mt-4'}`}>
        <Table {...getTableProps()}>
          <thead className="text-center">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any, columnIndex) => {
                  const headerTableProps = {
                    ...column.getHeaderProps(column.getSortByToggleProps()),
                  };
                  headerTableProps.style = {
                    ...headerTableProps.style,
                    width:
                      column.isSorted || !column.disableSort
                        ? column.width + 40
                        : column.width,
                    ...headerStyle,
                  };

                  headerTableProps.className = `${headerCSS}`;

                  if (column.disableSort) {
                    delete headerTableProps.onClick;
                    delete headerTableProps.style.cursor;
                  }
                  let firstColumn = false;
                  let lastColumn = false;
                  if (columnIndex === 0) {
                    firstColumn = true;
                  }
                  if (columnIndex === headerGroup.headers?.length - 1) {
                    lastColumn = true;
                  }

                  return (
                    <th
                      {...headerTableProps}
                      style={{
                        borderRadius: `${firstColumn ? '5px 0 0 0 ' : ''} ${
                          lastColumn ? '0 5px 0 0' : ''
                        }`,
                        height: '47px',
                        width: `${
                          column.isSorted || !column.disableSort
                            ? column.width + 40
                            : column.width
                        }px`,
                        minWidth: `${
                          column.isSorted || !column.disableSort
                            ? column.width + 40
                            : column.width
                        }px`,
                        cursor: !column.disableSort ? 'pointer' : 'auto',
                      }}
                    >
                      {column.render('Header')}
                      {!column.disableSort ? (
                        <>
                          {!column.isSorted && column.isSortedDesc === undefined ? (
                            <i className="ms-2 fas fa-sort" />
                          ) : (
                            <></>
                          )}
                          {column.isSorted && column.isSortedDesc ? (
                            <i className="ms-2 fas fa-sort-up" />
                          ) : (
                            <></>
                          )}
                          {column.isSorted && !column.isSortedDesc ? (
                            <i className="ms-2 fas fa-sort-down" />
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          {/* TableLoading */}
          {isLoading ? (
            <tbody {...getTableBodyProps()}>
              {skeletonLength.map(() => {
                return headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => {
                      const headerTableProps = {
                        ...column.getHeaderProps(column.getSortByToggleProps()),
                      };
                      headerTableProps.style = {
                        ...headerTableProps.style,
                        width:
                          column.isSorted || !column.disableSort
                            ? column.width + 40
                            : column.width,
                        ...headerStyle,
                      };

                      headerTableProps.className = `${headerCSS}`;

                      if (column.disableSort) {
                        delete headerTableProps.onClick;
                        delete headerTableProps.style.cursor;
                      }
                      return (
                        <td
                          {...headerTableProps}
                          className="text-center"
                          style={{
                            height: '47px',
                            cursor: !column.disableSort ? 'pointer' : 'auto',
                          }}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="skeleton skeleton-text-sm" />
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                ));
              })}
            </tbody>
          ) : (
            <tbody {...getTableBodyProps()}>
              {!isEmpty(rows) ? (
                rows.map((row: any, index: number) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell: any) => {
                        const bodyTableProps = cell.getCellProps();

                        bodyTableProps.className = `text-center ${bodyCSS} `;
                        if (index === 0) bodyTableProps.className += firstRowStyle;

                        return (
                          <td
                            {...bodyTableProps}
                            style={{
                              height: '47px',
                              ...(index === 0 && firstRowStyle
                                ? firstRowStyle
                                : null),
                            }}
                          >
                            <div
                              className={cell.column.rowCss}
                              onClick={() => {
                                console.log(
                                  'cell =  ',
                                  cell.value.props.children.props.to,
                                );
                                history.push(
                                  `${cell.value.props.children.props.to}`,
                                );
                              }}
                            >
                              {cell.render('Cell')}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={100} className="text-center">
                    {i18n.t('table:No Data Found')}
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </Table>
        {!hidePagination && (
          <div className="d-flex justify-content-center mb-3">
            {!isEmpty(rows) && (
              <Pagination
                count={Math.ceil(total / pageSize)}
                page={page}
                color="standard"
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                style={{ width: 'fit-content', margin: 'auto', marginTop: '30px' }}
                boundaryCount={2}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomTableJob;
