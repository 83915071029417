import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Swal from 'sweetalert2';
import CustomTable from 'src/components/Common/CustomTable';
import {
  getDefaultWebsite,
  getGleamJobSummary,
  getLoggedInUser,
  getMarketerFollowUp,
  getMarketerJobSummary,
  getSocialJobByJobKey,
  getUserWalletByCurrency,
  getWallet,
  setLoading,
  unsetLoading,
  updateMarketerJob,
} from 'src/store/actions';
import './jobDetail.style.scss';
import {
  Approve,
  Pending,
  Reject,
} from 'src/components/Common/approval/approvalStatus';

import FavoriteIcon from '@mui/icons-material/Favorite';
import userAvatar from 'src/assets/images/users/avatar-1.jpg';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { pink } from '@mui/material/colors';
import Rating from '@mui/material/Rating';
import {
  rejectSocialJob,
  updateSocialMediaHasJobGql,
  requesPriceOnSuggestJobGQL,
} from 'src/graphql/advertiser/socialMediaInfo';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import menu from 'src/constants/menu';
import Tooltip from '@mui/material/Tooltip';
import { OnLoading } from 'src/components/Common/Loading';
import Role from 'src/constants/enum/role';
import { Dialog, DialogContent } from '@mui/material';
import JobDetailPost from 'src/components/Publisher/Influencer/JobDetail/jobDetailPost';
import paramsFromURL from 'src/constants/paramsFromURL';
import { floorToFixed } from 'src/common/data/mathToFixed';
import notify from 'src/utils/notify';
import i18n from 'src/i18n';
import { result } from 'lodash';
import Pagination from '@mui/material/Pagination';
import SearchBar from 'src/components/Table/SearchBarJobDetail';
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
} from 'reactstrap';
import { formatDateForm } from 'src/utils/dateTime';
import GleamJobTimeline from 'src/components/Common/GleamJobTimeline';
import GleamJobComponent from 'src/components/Common/GleamJobComponent';
import GleamJobRequestTable from 'src/components/Common/GleamJobRequestTable';
import GleamJobReviewTable from 'src/components/Common/GleamJobReviewTable';
import config from 'src/config';
import RequestPrice from './RequestPrice.componant';
import JobDetail from './jobDetail';

export enum JobStatus {
  DRAFT = 'DRAFT',
  NEW_JOB = 'NEW_JOB',
  INPROGRESS = 'INPROGRESS',
  FINISHED = 'FINISHED',
  CANCEL = 'CANCEL',
  ALL = 'ALL',
  PAUSE = 'PAUSE',
}

const GleamJobDetail = ({ jobType }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [jobId, TabParam]: any = paramsFromURL(['job', 'tab']);
  const history = useHistory();
  const isPublish = jobType === '-';
  const [switchCheck, setSwitchCheck] = useState(true);
  const [changePrice, setChangePrice] = useState(true);
  const [isDisabledAppove, setIsDisabledAppove] = useState(false);
  const defaultPostModalData = {
    isOpen: false,
    link_post: '',
    social_media_info: {
      social_media_type: '',
      key: '',
    },
  };
  const [postModalData, setPostModalData] = useState(defaultPostModalData);
  const [param, setParam] = useState<any>({
    limit: 20,
    page: 1,
    job_key: jobId,
    sortBy: '',
    sortType: '',
  });
  const [tab, setTab] = useState(1);
  const [pendingAccept, setPendingAccept] = useState(0);
  const [penddingApprove, setPendingApprove] = useState(0);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    dispatch(setLoading());
    //dispatch(getSocialJobByJobKey(param));
    // if (isPublish) {
    //   dispatch(getWallet());
    // }
    if (jobId) {
      dispatch(getMarketerJobSummary(jobId));
      dispatch(getMarketerFollowUp({ key: jobId }));
    }
    // dispatch(getLoggedInUser());
    dispatch(unsetLoading());
  }, []);

  useEffect(() => {
    switch (TabParam) {
      case 'ReviewJob':
        return setTab(3);
      case 'Request':
        return setTab(2);
      case 'Preview':
        return setTab(1);
      default:
        return setTab(1);
    }
  }, []);

  //table
  const { data, totalCount, defaultTax, user, wallet, job, followUp } = useSelector(
    (state: any) => ({
      data: state.SocialMediaInfo.socialJob?.items,
      totalCount: state.SocialMediaInfo.socialJob?.totalCount,
      // campaign_key: state.SocialMediaInfo.socialJob?.marketer_campaign_key,
      defaultTax: state.DefaultValue.defaultWebsite.valueAddedTax,
      user: state.login.user,
      wallet: state?.Wallet?.wallet,
      job: state?.MarketerJob?.marketerJob,
      followUp: state.MarketerJob.followUp,
    }),
  );
  const balance = wallet?.balance || 0;
  //const tax = defaultTax / 100 + 1;
  const onFavorite = async (social_key: string, isFavorite: boolean) => {
    const res: any = await updateSocialMediaHasJobGql({
      key: social_key,
      isFavorite,
    });
    if (res) dispatch(getSocialJobByJobKey(param));
  };

  useEffect(() => {
    if (job) {
      if (job.status === 'PAUSE') {
        setSwitchCheck(false);
      } else {
        setSwitchCheck(true);
      }

      if (isPublish && job?.currency?.id) {
        dispatch(getUserWalletByCurrency(job?.currency?.id));
      }
    }
  }, [job]);

  useEffect(() => {
    if (followUp) {
      setPendingAccept(followUp?.pendingAccept ?? 0);
      setPendingApprove(followUp?.pendingReviewApprove ?? 0);
    }
  }, [followUp]);

  const displayModal = () => {
    // eslint-disable-next-line no-unused-vars
    const { isOpen, link_post, social_media_info } = postModalData;
    return (
      <Dialog maxWidth={false} onClose={handleClose} open={isOpen}>
        <DialogContent style={{ cursor: 'pointer' }}>
          <JobDetailPost socialJobItem={postModalData} />
        </DialogContent>
      </Dialog>
    );
  };

  const handleCopy = (val: string) => {
    navigator.clipboard.writeText(val);

    setCopied(true);
  };

  const renderPreview = () => {
    return (
      <>
        {' '}
        {['INPROGRESS', 'PAUSE'].includes(job.status) && (
          <div
            className="form-check form-switch form-switch-lg"
            style={{ marginRight: '10px', float: 'right' }}
          >
            <input
              type="checkbox"
              className="form-check-input"
              id="customSwitchsizelg"
              checked={switchCheck}
              onChange={() => {
                if (switchCheck === false) {
                  setSwitchCheck(true);
                  // setPauseStatusOff();
                  setPauseStatusOn();
                }
                if (switchCheck === true) {
                  setSwitchCheck(false);
                  setPauseStatusOff();
                }
              }}
            />
            <span style={{ fontSize: '16px' }}>
              {' '}
              {switchCheck === true ? 'On' : 'Off'}
            </span>
          </div>
        )}
        <Row>
          <Col lg="5" md="12" xs="12">
            <GleamJobTimeline job={job} />
            <h5>Link Promote</h5>
            <InputGroup className="my-3">
              <Input
                disabled
                className="disabled-input-deposit"
                placeholder={`${config.appUrl}job/${jobId}`}
              />
              <InputGroupAddon addonType="append">
                <Button
                  color="primary"
                  onClick={() => {
                    handleCopy(`${config.appUrl}job/${jobId}`);
                  }}
                >
                  <i className="fas fa-copy" />
                  &nbsp;{copied ? 'Copied' : 'Copy'}
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col lg="7" md="12" xs="12">
            <GleamJobComponent job={job} />
          </Col>
        </Row>
      </>
    );
  };

  const renderRequest = () => {
    return (
      <>
        {' '}
        {['INPROGRESS', 'PAUSE'].includes(job.status) && (
          <div
            className="form-check form-switch form-switch-lg"
            style={{ marginRight: '10px', float: 'right' }}
          >
            <input
              type="checkbox"
              className="form-check-input cursor-pointer"
              id="customSwitchsizelg"
              checked={switchCheck}
              onChange={() => {
                if (switchCheck === false) {
                  setSwitchCheck(true);
                  // setPauseStatusOff();
                  setPauseStatusOn();
                }
                if (switchCheck === true) {
                  setSwitchCheck(false);
                  setPauseStatusOff();
                }
              }}
            />
            <span style={{ fontSize: '16px' }}>
              {' '}
              {switchCheck === true ? 'On' : 'Off'}
            </span>
          </div>
        )}
        <GleamJobRequestTable job={job} />
      </>
    );
  };

  const renderReviewJob = () => {
    if (job.is_gleam_job) {
      return (
        <div>
          {' '}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ visibility: 'hidden' }}>space</div>
            {['INPROGRESS', 'PAUSE'].includes(job.status) && (
              <div
                className="form-check form-switch form-switch-lg"
                style={{ float: 'right' }}
              >
                <input
                  type="checkbox"
                  className="form-check-input cursor-pointer"
                  id="customSwitchsizelg"
                  checked={switchCheck}
                  onChange={() => {
                    if (switchCheck === false) {
                      setSwitchCheck(true);
                      // setPauseStatusOff();
                      setPauseStatusOn();
                    }
                    if (switchCheck === true) {
                      setSwitchCheck(false);
                      setPauseStatusOff();
                    }
                  }}
                />
                <span style={{ fontSize: '16px' }}>
                  {' '}
                  {switchCheck === true ? 'On' : 'Off'}
                </span>
              </div>
            )}
          </div>
          <div style={{ width: '100%' }}>
            <GleamJobReviewTable />
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div>
            {' '}
            <JobDetail jobType={job.job_type} />
          </div>
        </>
      );
    }
  };

  const handleClose = () => {
    setPostModalData({ ...postModalData, isOpen: false });
  };

  const setPauseStatusOn = () => {
    const payload = {
      key: jobId,
      status: 'INPROGRESS',
    };
    dispatch(updateMarketerJob(payload));
  };
  const setPauseStatusOff = () => {
    const payload = {
      key: jobId,
      status: 'PAUSE',
    };
    dispatch(updateMarketerJob(payload));
  };

  const onGoBack = () => {
    history.push('/campaigns/markerter/job');
  };

  //if (!data) return <OnLoading />;

  return (
    <div style={{ position: 'relative' }}>
      {user.role !== 'ADMIN' && (
        <i
          className="fa fa-chevron-left fa-icon"
          onClick={() => onGoBack()}
          style={{ cursor: 'pointer', position: 'absolute', top: '5vh' }}
        />
      )}
      <div
        className="dashboard-wraper "
        style={{
          marginLeft: '2vh',
        }}
      >
        <MetaTags>
          <title>Job: {job?.name}</title>
        </MetaTags>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '14px',
            marginBottom: '20px',
          }}
        >
          <div
            className={` ${user.role === Role.ADVERTISER ? '' : ''} h-100`}
            style={{
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
              marginTop: `${user.role === Role.ADMIN ? '10vh' : ''}`,
              paddingTop: `${user.role === Role.ADVERTISER ? '4vh' : ''}`,
            }}
          >
            <div
              style={{ cursor: 'pointer', paddingLeft: '0' }}
              onClick={() => {
                history.push('/');
              }}
            >
              {t('Home')}
            </div>
            <div>&gt; </div>
            {user.role !== 'ADMIN' && (
              <>
                <div
                  style={{ cursor: 'pointer', paddingLeft: '0' }}
                  onClick={() => {
                    history.push('/campaigns/markerter/job');
                  }}
                >
                  {t('Campaign')}
                </div>{' '}
                <div> &gt; </div>
              </>
            )}
            <div>{job?.name}</div>
          </div>
        </div>
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardHeader>
                  <div className="nav-scrollbar-none nav-tabs-custom">
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setTab(1);
                        }}
                        className={`${tab === 1 && 'active'}`}
                      >
                        <h5 className="mb-0">{t('table:Preview')}</h5>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setTab(2);
                        }}
                        className={`${tab === 2 && 'active'}`}
                      >
                        <div style={{ display: 'flex' }}>
                          <h5 className="mb-0">{t('table:Request')} </h5>
                          {tab !== 2 && pendingAccept !== 0 && (
                            <div
                              className="notification-icon"
                              style={{ marginLeft: '5px' }}
                            >
                              {pendingAccept}
                            </div>
                          )}
                        </div>
                      </NavLink>
                    </NavItem>
                    {user.role === Role.ADVERTISER && (
                      <>
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setTab(3);
                            }}
                            className={`${tab === 3 && 'active'}`}
                          >
                            <div style={{ display: 'flex' }}>
                              <h5 className="mb-0">{t('table:Review job t')}</h5>
                              {tab !== 3 && penddingApprove !== 0 && (
                                <div
                                  className="notification-icon"
                                  style={{ marginLeft: '5px' }}
                                >
                                  {penddingApprove}
                                </div>
                              )}
                            </div>
                          </NavLink>
                        </NavItem>
                      </>
                    )}
                  </div>
                </CardHeader>
                <CardBody style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                  {tab === 1 && renderPreview()}
                  {tab === 2 && renderRequest()}
                  {tab === 3 && renderReviewJob()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default GleamJobDetail;
