import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { floorToFixed } from 'src/common/data/mathToFixed';
import { Button, Tooltip } from '@mui/material';
import { useHistory } from 'react-router';
import './jobNew.style.scss';
import CustomTableCampaign from 'src/components/Common/CustomTable copy';
import { Link } from 'react-router-dom';
import CustomTableJob from 'src/components/Common/CustomTableJob';

const JobNewTable = ({ activeTab, data }: any) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { _data, defaultTax } = useSelector((state: any) => ({
    _data: state.MarketerJob.filter,
    defaultTax: state.DefaultValue.defaultWebsite.valueAddedTax,
  }));

  const [dataResult, setDataDefualt] = useState<any>([]);

  useEffect(() => {
    if (data?.items?.length >= 0) {
      setDataDefualt(data?.items);
    }
  }, [data]);

  const tax = defaultTax / 100 + 1;
  if (dataResult) {
    if (Object.keys(dataResult)?.length) {
      for (let j = 0; j < dataResult?.length; j++) {
        let num_influencer = 0;
        let num_follower = 0;
        let total_price = 0;
        const job = dataResult[j];
        const keys: any[] = [];
        num_influencer += job.social_media_has_jobs?.length || 0;
        for (let k = 0; k < job.social_media_has_jobs?.length; k++) {
          const socialJob = job.social_media_has_jobs[k];
          if (!keys.includes(socialJob.social_media_info.key)) {
            keys.push(socialJob.social_media_info.key);
            num_follower += socialJob.social_media_info.follower || 0;
          }
          total_price += socialJob.price || 0;
        }
        job.num_influencer = num_influencer;
        job.num_follower = num_follower;
        job.total_price = total_price * tax;
      }
    }
  }

  const rendorVerifyStatus = (
    status: string,
    verify_status: string,
    report_status: string,
    ban_reason: string,
  ) => {
    if (status === 'FINISHED' || status === 'CANCEL') {
      if (report_status === 'BAN') {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <>{t('BAN')}</>
            <Tooltip
              title={
                <h4 style={{ color: 'white' }}>{ban_reason ?? 'no reason'} </h4>
              }
              placement="right"
            >
              <i
                className="fas fa-info-circle ms-2 font-size-16]"
                style={{
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          </div>
        );
      } else {
        return t(verify_status);
      }
    } else {
      return t(verify_status);
    }
  };

  const goCampaign = (path: any) => {
    history.push(`${path}`);
  };
  const columns = useMemo(() => {
    // Your column definition remains the same
    // ...
    return [
      {
        Header: t('marketer:create_campaign:campaign_name'),
        accessor: ({ name, key, job_type }: any) => {
          const path =
            activeTab === 2
              ? `/campaigns/jobnew/addnewjob?job=${key}`
              : `/campaigns/markerter/job/${
                  job_type === 'PUBLISH' ? 'publishDetail' : 'detail'
                }?job=${key}`;
          return (
            <div className="">
              <Link to={`${path}`} style={{ color: '#495057' }}>
                {name}
              </Link>
            </div>
          );
        },
        width: 50,
        disableSort: true,
      },
      {
        Header: t('marketer:create_campaign:Type'),
        accessor: ({ job_type, key }: any) => {
          const path =
            activeTab === 2
              ? `/campaigns/jobnew/addnewjob?job=${key}`
              : `/campaigns/markerter/job/${
                  job_type === 'PUBLISH' ? 'publishDetail' : 'detail'
                }?job=${key}`;
          return (
            <div className="">
              <Link to={`${path}`} style={{ color: '#495057' }}>
                {job_type === 'PUBLISH' ? 'Campaign' : 'SEND JOB'}
              </Link>
            </div>
          );
        },
        id: 'Type',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('marketer:create_campaign:Usage_Prize'),
        accessor: ({ total_price, currency, key, job_type }: any) => {
          const path =
            activeTab === 2
              ? `/campaigns/jobnew/addnewjob?job=${key}`
              : `/campaigns/markerter/job/${
                  job_type === 'PUBLISH' ? 'publishDetail' : 'detail'
                }?job=${key}`;
          return (
            <div className="">
              <Link to={`${path}`} style={{ color: '#495057' }}>
                {floorToFixed(total_price, 2)} {currency.main_symbol ?? 'USDT'}
              </Link>
            </div>
          );
        },
        id: 'Usage_Prize',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('marketer:create_campaign:Accept_Request'),
        accessor: ({ social_media_has_jobs, key, job_type }: any) => {
          const path =
            activeTab === 2
              ? `/campaigns/jobnew/addnewjob?job=${key}`
              : `/campaigns/markerter/job/${
                  job_type === 'PUBLISH' ? 'publishDetail' : 'detail'
                }?job=${key}`;
          const socialDict: Map<number, number> = new Map();
          let request = 0;
          let accecpt = 0;
          for (let i = 0; i < social_media_has_jobs?.length; i++) {
            if (!socialDict.get(social_media_has_jobs[i].social_media_info_id)) {
              request += 1;
              socialDict.set(
                social_media_has_jobs[i].social_media_info_id,
                social_media_has_jobs[i].social_media_info_id,
              );
              if (social_media_has_jobs[i].status !== 'DRAFT') {
                accecpt += 1;
              }
            }
          }
          return (
            <div className="">
              <Link to={`${path}`} style={{ color: '#495057' }}>
                {accecpt} / {request}
              </Link>
            </div>
          );
        },
        id: 'Accept_Request',
        width: 30,
        disableSort: true,
      },
      {
        Header: t('marketer:create_campaign:Pending_Review'),
        accessor: ({ social_media_has_jobs, key, job_type }: any) => {
          const path =
            activeTab === 2
              ? `/campaigns/jobnew/addnewjob?job=${key}`
              : `/campaigns/markerter/job/${
                  job_type === 'PUBLISH' ? 'publishDetail' : 'detail'
                }?job=${key}`;
          const socialDict: Map<number, number> = new Map();
          let review = 0;
          for (let i = 0; i < social_media_has_jobs?.length; i++) {
            if (!socialDict.get(social_media_has_jobs[i].social_media_info_id)) {
              socialDict.set(
                social_media_has_jobs[i].social_media_info_id,
                social_media_has_jobs[i].social_media_info_id,
              );
              if (
                social_media_has_jobs[i].status === 'INPROGRESS' &&
                social_media_has_jobs[i].post_date &&
                social_media_has_jobs[i].marketer_approve_date === null
              ) {
                review += 1;
              }
            }
          }
          return (
            <div className="">
              <Link to={`${path}`} style={{ color: '#495057' }}>
                {review}
              </Link>
            </div>
          );
        },
        id: 'Pending_Review',
        width: 30,
        disableSort: true,
      },
      {
        Header: t('marketer:create_campaign.Verify_Status'),
        accessor: ({
          status,
          verify_status,
          report_status,
          ban_reason,
          key,
          job_type,
        }: any) => {
          const path =
            activeTab === 2
              ? `/campaigns/jobnew/addnewjob?job=${key}`
              : `/campaigns/markerter/job/${
                  job_type === 'PUBLISH' ? 'publishDetail' : 'detail'
                }?job=${key}`;
          return (
            <div className="">
              <Link to={`${path}`} style={{ color: '#495057' }}>
                {rendorVerifyStatus(
                  status,
                  verify_status,
                  report_status,
                  ban_reason,
                )}
              </Link>
            </div>
          );
        },
        id: 'Verify_Status',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('marketer:create_job_campaign.Date End'),
        accessor: ({ finish_internal_work, key, job_type }: any) => {
          const path =
            activeTab === 2
              ? `/campaigns/jobnew/addnewjob?job=${key}`
              : `/campaigns/markerter/job/${
                  job_type === 'PUBLISH' ? 'publishDetail' : 'detail'
                }?job=${key}`;
          let day_left = '';
          if (finish_internal_work) {
            const finishDate = new Date(finish_internal_work);
            const diffTime = finishDate.getTime() - Date.now();
            const diffDate = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if (diffDate > 0) {
              day_left = `${diffDate} ${t('jobNew.days')}`;
            } else {
              day_left = t('jobNew.Time out');
            }
          }

          return (
            <div className="">
              <Link to={`${path}`} style={{ color: '#495057' }}>
                {day_left}
              </Link>
            </div>
          );
        },
        id: 'Date',
        width: 50,
        disableSort: true,
      },
    ];
  }, [dataResult]);

  return (
    <>
      <CustomTableJob
        showPaginationBottom={false}
        columns={columns || []}
        bodyCSS="body-table"
        data={dataResult}
        hidePagination
        headerCSS="fw-normal bg-header-table"
      />
    </>
  );
};

export default JobNewTable;
