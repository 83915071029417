/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { Collapse } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { SocialMediaType } from 'src/store/marketerJobNew/reducer';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDefaultWebsite,
  getMarketerJob,
  getUserWalletByCurrency,
  getWallet,
} from 'src/store/actions';
import {
  getCurrencyAndMinMaxBudgetGQL,
  getCurrencyByIdGQL,
} from 'src/graphql/common';
import i18n from '../../../../i18n';
import notify from '../../../../utils/notify';
import './CreateMarketerJobCampaignNew2.style.scss';
import AddHashtagCard from './HashtagCard.component';
import UserCards from './UserCard';
import { SocialJobType } from './CreateMarketerJobCampaignNew2.control';

interface SubCollapseProps {
  title: 'SHARE' | 'PHOTO' | 'VIDEO';
  job_type: 'PUBLISH' | 'INVITE_ONLY' | '';
  social: SocialMediaType;
  // eslint-disable-next-line react/require-default-props
  formValues?: SocialJobType;
  setFormValues: any;
}

const SubCollapse = ({
  title,
  job_type,
  social,
  formValues,
  setFormValues,
}: SubCollapseProps) => {
  const [collaspse, setCollaspse] = useState(false);
  const { t } = useTranslation();
  const [currency, setCurrency] = useState<any>(null);
  const [min, setMin] = useState<any>(null);
  const [max, setMax] = useState<any>(null);

  const [hashTags, setHashTags] = useState(['#AFBrother']);
  const [addText, setAddText] = useState(false);
  const dispatch = useDispatch();
  // const [selectValue, setSelectValue] = useState<SocialMediaType | null>(null);
  const [isActive, setIsActive] = useState(false);
  const handleClick = () => setIsActive(true);

  const { descriptionJobNew } = useSelector((state: any) => ({
    descriptionJobNew: state.MarketerCampaignJobsNew.descriptionJobNew,
  }));
  useEffect(() => {
    const { currency_id } = descriptionJobNew;
    if (currency_id) {
      fetchCurrency(currency_id);
      fetchMinMax(currency_id);
    }
  }, [descriptionJobNew]);

  const fetchCurrency = async (currency_id: number) => {
    const _currency = await getCurrencyByIdGQL(currency_id);
    setCurrency(_currency);
  };

  const fetchMinMax = async (currency_id: number) => {
    const _MinMax = await getCurrencyAndMinMaxBudgetGQL(currency_id);
    setMin(Number(_MinMax?.minBudget.value));
    setMax(Number(_MinMax?.maxBudget.value));
  };

  useEffect(() => {
    if (formValues && formValues![social]![title]?.hashtag) {
      setHashTags(
        (formValues && formValues![social]![title]!?.hashtag?.split(',')) || [
          '#AFBrother',
        ],
      );
    }
  }, [formValues && formValues![social]![title]?.hashtag]);

  const handleCheck = () => {
    const obj: any = { ...formValues };
    if (job_type === 'INVITE_ONLY') {
      Object.keys(obj).forEach((socialName) => {
        if (social === socialName) {
          obj![socialName]!.isSelected = true;
        } else {
          obj![socialName]!.isSelected = false;
        }
        Object.keys(obj[socialName]).forEach(async (content) => {
          if (content !== 'isSelected') {
            if (title === content && socialName === social) {
              obj![socialName]!.isSelected = true;
              obj![socialName][content]!.isSelected = true;
            } else {
              obj![socialName][content]!.isSelected = false;
            }
          }
        });
      });
    } else {
      obj[social][title].isSelected = !obj[social][title].isSelected;
    }
    setFormValues(obj);
  };

  const setInputMinMax = ({ type, e }: { type: 'min' | 'max'; e: number }) => {};
  setInputMinMax({ type: 'min', e: 20 });

  const handleChange = (e: any) => {
    const obj: any = { ...formValues };
    const { name } = e.target;
    let { value } = e.target;
    if (name === 'min_budget' || name === 'max_budget') {
      value = parseFloat(value);
    }
    if (obj) {
      obj[social][title][name] = value;
    }
    setFormValues(obj);
  };

  const handleChangeTextEditor = (value: string) => {
    const obj: any = { ...formValues };
    if (obj) {
      obj[social][title].work_order = value;
    }
    setFormValues(obj);
  };

  const AddUserHandler = (hashtag: any) => {
    const newHashTag = [...hashTags, hashtag];
    setHashTags(newHashTag);
    const obj: any = { ...formValues };
    if (obj) {
      obj[social][title].hashtag = newHashTag.join(',');
    }
    setFormValues(obj);
  };
  const onDelete = (myId: any) => {
    if (myId !== '#AFBrother') {
      const update = hashTags?.filter((i) => i !== myId);
      setHashTags(update);
      const obj: any = { ...formValues };
      if (obj) {
        obj[social][title].hashtag = update.join(',');
      }
      setFormValues(obj);
    }
  };

  const openInput = () => {
    return <AddHashtagCard add={AddUserHandler} id={hashTags?.length - 1} />;
  };

  const intitialFormValue =
    `<Br>` +
    `<div>` +
    `${t('1) Select')}` +
    `</div>` +
    `<Br>` +
    `<div>` +
    `${t('For example')}` +
    `</div>` +
    `<Br>` +
    `<div>` +
    `${t('Link post')}` +
    `</div>` +
    `<Br>` +
    `<div>` +
    `${t('2) Create')}` +
    `</div>` +
    `<div>` +
    `<Br>` +
    `${t('For')}` +
    `</div>` +
    `<Br>` +
    `<div>` +
    `${t('Mention')}` +
    `</div>` +
    `<Br>` +
    `<div>` +
    `${t('Instragram TH: https://www.instagram.com/afbrother.thailand/')}` +
    `</div>` +
    `<div>` +
    `${t('Twitter TH: https://twitter.com/AfbrotherGloble')}` +
    `</div>` +
    `<div>` +
    `${t('TikTok TH: https://www.tiktok.com/@afbrother_thailand')}` +
    `</div>` +
    `<div>` +
    `${t('Youtube TH: https://www.youtube.com/@afbrother_thailand')}` +
    `</div>` +
    `<Br>` +
    `<div>` +
    `${t('4) Use all of our Hashtag')}` +
    `</div>`;

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      // [{ script: 'sub' }, { script: 'super' }],
      // ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      // ['link', 'image', 'video'],
      // ['clean'],
    ],
  };

  useEffect(() => {
    if (max || min) {
      if (formValues) {
        const obj: any = { ...formValues };
        if (obj) {
          obj[social][title].min_budget = min;
          obj[social]![title].max_budget = max;
        }
        setFormValues(obj);
      }
    }
  }, [max || min]);

  return (
    <>
      <div className="share-containers" style={{ height: '40px' }}>
        <div className="tab-left" style={{ color: 'white', fontWeight: 'bold' }}>
          <input
            type="checkbox"
            onChange={handleCheck}
            className="check-box-social"
            onClick={() => {
              if (
                job_type === 'INVITE_ONLY' &&
                formValues &&
                !formValues[social]![title]?.isSelected
              ) {
                setCollaspse(true);
              } else {
                setCollaspse(true);
              }
              setCollaspse(!collaspse);
              // if (formValues && formValues[social]![title]?.isSelected)
              //   setCollaspse(false);
            }}
            checked={formValues && formValues[social]![title]?.isSelected}
            disabled={formValues && !formValues[social]?.isSelected}
          />
          <div>{title === 'PHOTO' ? 'POST' : title}</div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '16px',
          }}
          onClick={() => setCollaspse(!collaspse)}
        >
          <div style={{ paddingRight: '10px' }}>
            {collaspse ? t('Close') : t('Expand')}
          </div>
          <i
            onClick={() => setCollaspse(!collaspse)}
            className={`${
              collaspse === true ? 'fas fa-chevron-up' : 'fas fa-chevron-down'
            }`}
          />
        </div>
      </div>
      <Collapse isOpen={collaspse} style={{ width: '100%' }}>
        <div
          style={{
            width: '100%',
            paddingTop: '30px',
            paddingBottom: '30px',
            borderRadius: '8px',
            backgroundColor: 'aliceblue',
          }}
        >
          <div
            className="collapse-container"
            onClick={() => {
              if (formValues && !formValues[social]![title]?.isSelected) {
                notify(
                  i18n.t(
                    'Please select social media and social type before enter infomation',
                  ),
                  'error',
                );
              }
            }}
          >
            <div className={`${job_type === 'PUBLISH' ? 'budget-title' : 'price'}`}>
              {t('Budget per influencer')}
            </div>
            <div
              className={`${job_type === 'PUBLISH' ? 'collapse-price' : 'price'}`}
            >
              <div>{t('Minimum Price')}</div>
              <div>
                <input
                  min={0.00000000000000000000000000000000000001}
                  name="min_budget"
                  type="number"
                  step="any"
                  defaultValue={min}
                  {...(formValues &&
                    formValues[social]![title]?.min_budget !== 0 && {
                      value: formValues[social]![title]?.min_budget,
                    })}
                  style={{ width: '200px', marginRight: '10px' }}
                  onChange={handleChange}
                  disabled={formValues && !formValues[social]![title]?.isSelected}
                />
                <label>{currency?.main_symbol}</label>
              </div>
            </div>

            <div
              className={`${job_type === 'PUBLISH' ? 'collapse-price' : 'price'}`}
            >
              <div>{t('Maximum Price')}</div>
              <div>
                <input
                  min={0.00000000000000000000000000000000000001}
                  name="max_budget"
                  type="number"
                  defaultValue={max}
                  {...(formValues &&
                    formValues[social]![title]?.max_budget !== 0 && {
                      value: formValues[social]![title]?.max_budget,
                    })}
                  style={{ width: '200px', marginRight: '10px' }}
                  onChange={handleChange}
                  disabled={formValues && !formValues[social]![title]?.isSelected}
                />
                <label>{currency?.main_symbol}</label>
              </div>
            </div>

            <div style={{ width: '80%' }}>
              <label className="budget-title " style={{ marginRight: '5px' }}>
                {t('WorkOrder')}
              </label>
              <label
                className="budget-title "
                style={{ color: '#7167c2', margin: '0' }}
              >
                {t('( Please add your work order infomation here )')}
              </label>
            </div>
            <div className="quill" style={{ width: '80%' }}>
              <div
                style={{ color: isActive ? 'black' : '#838385' }}
                // onClick={handleClick}
              >
                <ReactQuill
                  modules={modules}
                  value={
                    (formValues && formValues[social]![title]?.work_order) ||
                    intitialFormValue
                  }
                  onKeyPress={handleClick}
                  onChange={handleChangeTextEditor}
                  readOnly={formValues && !formValues[social]![title]?.isSelected}
                />
              </div>
            </div>
            <div style={{ width: '80%', marginBottom: '10px' }}>
              <div className="budget-title">
                {t(
                  'Link to download the accompanying work order file (if applicable)',
                )}
              </div>
            </div>
            <input
              type=""
              name="link_accompanying_work"
              value={
                formValues && formValues[social]![title]?.link_accompanying_work
              }
              onChange={handleChange}
              style={{ width: '80%' }}
              disabled={formValues && !formValues[social]![title]?.isSelected}
            />
          </div>
          <div className="collapse-footer">
            <div className="budget-title" style={{ width: '80%' }}>
              {t('Hashtag')}
            </div>
            <div className="hashtag-hover">
              {hashTags?.map((ele: any) => {
                return (
                  <>
                    <UserCards title={ele} />
                    <span className="exit-button" onClick={() => onDelete(ele)}>
                      x
                    </span>
                  </>
                );
              })}
              {addText && formValues && formValues[social]![title]?.isSelected
                ? openInput()
                : ''}
              <div
                className="collapse-hashtag"
                style={{ display: `${addText ? 'none' : ''}` }}
                onClick={() => {
                  if (formValues && !formValues[social]![title]?.isSelected) {
                    notify(
                      i18n.t(
                        'Please select socialmedia and social type before enter infomation',
                      ),
                      'error',
                    );
                  } else {
                    setAddText(!addText);
                  }
                }}
              >
                {t('+Add Hashtag')}
              </div>

              {/* <div className="collapse-hashtag">
                #AFbrother <span>x</span>
              </div>
              <div className="collapse-hashtag">+Add Hashtag</div> */}
            </div>
          </div>
        </div>
      </Collapse>
    </>
  );
};

export default SubCollapse;
