import styled from 'styled-components';
import userAvatar from 'src/assets/images/users/avatar-1.jpg';
import { OnLoading } from 'src/components/Common/Loading';

const ImageProfileComponent = (props: any) => {
  if (props.socialLoading) return <OnLoading />;

  const { userProfile } = props;
  const imageSrc =
    userProfile?.image_profile_url || userProfile?.picture_url || userAvatar;
  return (
    <Profile
      src={imageSrc}
      alt="Influencer Profile"
      className="profile skeleton"
      onLoad={(e) => {
        e.currentTarget.classList.remove('skeleton');
      }}
      onError={(e) => {
        e.currentTarget.classList.remove('skeleton');
        e.currentTarget.src = userAvatar;
      }}
    />
  );
};

const Profile = styled.img`
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 130px;
  height: 130px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  animation-delay: 2.5s;
  animation: profile 0.5s linear alternate;

  @keyframes profile {
    0% {
      top: -50px;
      left: 50%;
      transform: translateX(-50%) scale(0.5);
      width: 130px;
      height: 130px;
    }
    100% {
      top: -50px;
      left: 50%;
      transform: translateX(-50%) scale(1);
      width: 130px;
      height: 130px;
    }
  }
`;

export default ImageProfileComponent;
