import React, { useEffect, useState, useCallback } from 'react';
import { Column } from 'react-table';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { floorToFixed } from 'src/common/data/mathToFixed';
import { transformStringAmountInput } from '../../utils/input';
import CustomTable from '../Common/CustomTable';
import SearchBar from '../Table/SearchBar';
import PageSizeOptions from '../Table/PageSizeOptions';
import { getReferralTransactions } from '../../store/actions';

const ReferralEarningsTable = ({ t, tab, userId }: any) => {
  const defaultPageSize = 10;
  const dispatch = useDispatch();

  // Local State
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');

  const { txn } = useSelector((state: any) => ({
    txn: state.Referral.referralTransactions,
  }));

  useEffect(() => {
    setPage(1);
    setPageSize(defaultPageSize);

    // Fetch Data With Default Payload When Tab Changed
    dispatch(
      getReferralTransactions({
        limit: defaultPageSize,
        page: 1,
        user_uuid: userId,
      }),
    );
  }, [tab]);

  // @ts-ignore
  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: t('table:Email'),
        accessor: ({ child }) => {
          return child.email;
        },
        id: 'email',
        width: 180,
      },
      {
        Header: t('table:Role'),
        accessor: ({ child }) => {
          return t(`${child.role}`);
        },
        id: 'role',
        width: 60,
      },
      {
        Header: t('table:Earnings'),
        accessor: ({ total_earnings }) => {
          const earnings_floor = floorToFixed(Number(total_earnings), 4);
          return Number(earnings_floor) >= 0.0001 ? earnings_floor : '0.0000';
        },
        id: 'earnings',
        width: 60,
      },
    ],
    [t, txn],
  );

  const data = React.useMemo(() => {
    return !isEmpty(txn) ? txn.items : [];
  }, [txn]);

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : page;

    const payload = {
      ...getDefaultPayload(newPage),
    };

    dispatch(getReferralTransactions(payload));
  };

  const handlePageSizeChange = (size: string) => {
    const newPageSize = parseInt(size, 10);

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    const payload = {
      ...getDefaultPayload(newPage),
      limit: newPageSize,
    };

    dispatch(getReferralTransactions(payload));

    setPageSize(newPageSize);
  };

  const handleSearch = (val: string) => {
    const payload = {
      ...getDefaultPayload(1),
      search: val,
    };

    dispatch(getReferralTransactions(payload));
    setSearch(search);
  };

  const handleClear = () => {
    const payload = {
      ...getDefaultPayload(1),
      search: '',
    };

    dispatch(getReferralTransactions(payload));
    setSearch('');
  };

  // eslint-disable-next-line no-shadow
  const getDefaultPayload = (page: number) => {
    setPage(page);

    return {
      limit: pageSize,
      search,
      page,
      user_uuid: userId,
    };
  };

  const hiddenOnXS: any = [];
  const hiddenOnSM: any = [];
  const hiddenOnMD: any = [];
  const hiddenOnLG: any = [];

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(newPage),
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(getReferralTransactions(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
    },
    [search, page, pageSize],
  );

  return (
    <>
      <CustomTable
        headerCSS="fw-normal bg-header-table"
        columns={columns}
        data={data}
        page={page}
        pageSize={pageSize}
        total={txn.totalCount}
        handlePageChange={handlePageChange}
        hiddenOnXS={hiddenOnXS}
        hiddenOnSM={hiddenOnSM}
        hiddenOnMD={hiddenOnMD}
        hiddenOnLG={hiddenOnLG}
        onSort={handleSort}
      >
        <Row className="justify-content-between m-0">
          <Col xs={12} md={3} className="d-flex align-items-center mb-3 mb-md-0">
            <PageSizeOptions
              defaultValue={pageSize}
              handlePageSizeChange={handlePageSizeChange}
            />
          </Col>
          <Col xs={12} md={9} lg={9}>
            <Row className="align-items-center justify-content-end">
              <Col sm={12} md={8} lg={5}>
                <SearchBar
                  placeholder={t('table:Search Placeholder')}
                  handleSearch={handleSearch}
                  handleClear={handleClear}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </CustomTable>
    </>
  );
};
export default withTranslation()(ReferralEarningsTable);
