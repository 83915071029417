import { Form } from 'reactstrap';
import { LegacyRef, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import './SearchBar.style.scss';

const SearchBar = ({
  t,
  placeholder,
  handleSearch,
  handleClear,
  disableClear,
  disablePadding,
}: any) => {
  // Ref
  const searchInput: LegacyRef<any> = useRef('');

  const handleClickSearch = (e: any) => {
    e.preventDefault();
    const val = searchInput.current.value ? `%${searchInput.current.value}%` : '';

    handleSearch(val);
  };

  const handleClickClear = () => {
    searchInput.current.value = '';
    handleClear();
  };

  return (
    <div className="search-bar-clear-container">
      <div className="block-container">
        <Form
          className={`app-search d-block ${disablePadding ? 'p-0' : ''}`}
          onSubmit={(e) => handleClickSearch(e)}
        >
          <div className="position-relative " style={{ marginRight: '16px' }}>
            <input
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                background: 'white',
                minWidth: '250px',
              }}
              ref={searchInput}
              type="text"
              className="form-control text-color-primary"
              placeholder={placeholder}
            />
            <button
              className="btn btn-secondary border-0"
              type="submit"
              style={{
                background: 'linear-gradient(104deg,#6B99CA, #6BC6B3)',
                height: '31px',
                borderRadius: '8px',
              }}
            >
              <i className="bx bx-search-alt align-middle" />
            </button>
          </div>
        </Form>

        {!disableClear && (
          <button className="clear-button" type="button" onClick={handleClickClear}>
            {t('table:Clear Search')}
          </button>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(SearchBar);
