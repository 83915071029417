//react
import React, { useState } from 'react';
//react style
import MetaTags from 'react-meta-tags';
import {
  Container,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
//general api
import { withTranslation } from 'react-i18next';
//component
import ApproveCampaign from '../../components/Admin/ApproveCreatives';
import menu from '../../constants/menu';
import Breadcrumbs from '../../components/Common/Breadcrumb';

const approvalAdvertiser = ({ t }: any) => {
  //state variables
  const [manageAdvertiserTab, setManageCreativeTab] = useState('1');

  return (
    <div className="page-content">
      <MetaTags>
        <title>{t('Approval Creative')}</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          pageTitle={t('Page.Advertiser Campaign')}
          menus={[
            { menu: menu.DASHBOARD },
            { menu: menu.ADVERTISER_CAMPAIGN, active: true },
          ]}
        />
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader>
                <Nav className="nav-tabs-custom mb-3">
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={`${manageAdvertiserTab === '1' && 'active'}`}
                      onClick={() => {
                        setManageCreativeTab('1');
                      }}
                    >
                      <h5>{t('swal:All Approval')}</h5>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={`${manageAdvertiserTab === '2' && 'active'}`}
                      onClick={() => {
                        setManageCreativeTab('2');
                      }}
                    >
                      <h5>{t('swal:Pending Approval')}</h5>
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody>
                {manageAdvertiserTab === '1' && <ApproveCampaign />}
                {manageAdvertiserTab === '2' && (
                  <ApproveCampaign verifyStatus="PENDING" />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withTranslation()(approvalAdvertiser);
