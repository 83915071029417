/* eslint-disable react/self-closing-comp */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import paramsFromURL from 'src/constants/paramsFromURL';
import { getMarketerJobSummary, getSocialGleamJob } from 'src/store/actions';
import io, { Socket } from 'socket.io-client';
import Role from 'src/constants/enum/role';
import config from 'src/config';
import MessageInput from './MessageInput';
import Messages from './Messages';

export interface Message {
  uuid: string;
  name: string;
  roomId: string;
  message: string;
  created_at: string;
  is_read: boolean;
}

const ChatDetails = () => {
  const dispatch = useDispatch();
  const [jobId, tasked] = paramsFromURL(['job', 'task']);
  const [messages, setMessages] = useState<Message[]>([]);
  const [socket, setSocket] = useState<Socket>();
  const [payload, setPayload] = useState({
    uuid: '',
    name: '',
    roomId: tasked,
    message: '',
  });
  const [typingDisplay, setTypingDisplay] = useState('');
  const socketOptions = {
    transportOptions: {
      polling: {
        extraHeaders: {
          Authorization: `Bearer ${localStorage.getItem('access-token') ?? ''}`,
        },
      },
    },
  };

  useEffect(() => {
    if (config.appEnv === 'production') {
      const endpoint = config.backendSocket ?? 'https://ws.afbrother.com/';
      // const newSocket = io(endpoint, {
      //   reconnectionDelay: 1000,
      //   reconnection: true,
      //   reconnectionAttempts: 10,
      //   //transports: ['websocket'],
      //   agent: false,
      //   upgrade: false,
      //   rejectUnauthorized: false,
      //   withCredentials: true,
      // });
      const newSocket = io(endpoint, socketOptions);
      setSocket(newSocket);
    } else {
      const endpoint = config.backendSocket ?? 'http://localhost:3002';
      // const newSocket = io(endpoint, {
      //   reconnectionDelay: 1000,
      //   reconnection: true,
      //   reconnectionAttempts: 10,
      //   //transports: ['http'],
      //   agent: false,
      //   upgrade: false,
      //   rejectUnauthorized: false,
      //   withCredentials: true,
      // });
      const newSocket = io(endpoint, socketOptions);
      setSocket(newSocket);
    }
  }, [setSocket]);

  useEffect(() => {
    socket?.emit('findAllMessages', { roomId: tasked }, (response: Message[]) => {
      setMessages(response);
    });
  }, [socket]);

  const send = (value: string) => {
    const param = {
      ...payload,
      message: value,
    };
    socket?.emit('createMessage', param);
  };

  const read = () => {
    socket?.emit('is_read', { uuid: payload.uuid, roomId: payload.roomId });
  };

  const messageListener = (message: Message) => {
    setMessages([...messages, message]);
  };

  useEffect(() => {
    socket?.on('message', messageListener);
    socket?.on('connect_error', (err) => {
      console.log(`connect_error due to ${err}`);
    });
    socket?.on('typing', ({ user, isTyping }) => {
      if (isTyping) {
        setTypingDisplay(`${user.name} is typing`);
      } else {
        setTypingDisplay('');
      }
    });
    return () => {
      socket?.off('message', messageListener);
    };
  }, [messageListener]);

  const { data, totalCount, defaultTax, user, wallet, job, task } = useSelector(
    (state: any) => ({
      data: state.SocialMediaInfo.socialJob?.items,
      totalCount: state.SocialMediaInfo.socialJob?.totalCount,
      // campaign_key: state.SocialMediaInfo.socialJob?.marketer_campaign_key,
      defaultTax: state.DefaultValue.defaultWebsite.valueAddedTax,
      user: state.login.user,
      wallet: state?.Wallet?.wallet,
      job: state?.MarketerJob?.marketerJob,
      task: state.SocialMediaInfo.socialHasJobTask,
    }),
  );
  useEffect(() => {
    if (tasked) {
      dispatch(getSocialGleamJob(tasked));
    }
    if (jobId) {
      dispatch(getMarketerJobSummary(jobId));
    }
  }, []);

  useEffect(() => {
    if (user) {
      if (user.role === Role.PUBLISHER || user.role === Role.INFLUENCER) {
        console.log('chat task', task);
        const _payload = {
          ...payload,
          uuid: user.uuid,
          name: task[0]?.social_info?.name || '',
        };
        socket?.emit('join', {
          uuid: _payload.uuid,
          name: _payload.name,
          roomId: _payload.roomId,
        });
        setPayload(_payload);
      } else if (user.role === Role.MARKETER || user.role === Role.ADVERTISER) {
        console.log('chat job', job);
        const _payload = {
          ...payload,
          uuid: user.uuid,
          name: job?.marketer_campaign?.user?.user_info?.company,
        };
        socket?.emit('join', {
          uuid: _payload.uuid,
          name: _payload.name,
          roomId: _payload.roomId,
        });
        setPayload(_payload);
      }
    }
  }, [job, task]);

  return (
    <div
      style={{
        width: '100%',
        marginTop: '10px',
        boxShadow: 'box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;',
      }}
    >
      <div style={{ background: 'white', color: 'black' }}>
        <Messages
          messages={messages}
          send={send}
          typing={typingDisplay}
          read={read}
        />
      </div>
    </div>
  );
};

export default ChatDetails;
