import { SocialMediaType } from 'src/components/Publisher/Influencer/Dashboard/SocialChannel/SocialChannel.control';
import config from 'src/config';

export const onFacebookConnect = async (
  path: string | null = 'social-calculate',
) => {
  const permissionScope: string =
    'public_profile,email,pages_show_list,pages_read_engagement,pages_read_user_content';
  // 'pages_read_engagement,instagram_basic'
  const clientId: string | undefined = config.facebook.id;
  if (!clientId) return;

  const redirectUrl: string = `${config.appUrl}influencer/${path}/${SocialMediaType.FACEBOOK}/`;

  window.location.href = `https://www.facebook.com/v16.0/dialog/oauth?client_id=${clientId}&scope=${permissionScope}&redirect_uri=${redirectUrl}`;
};

export const onFacebookPersonalConnect = async (
  path: string | null = 'social-calculate',
) => {
  const permissionScope: string = 'public_profile,email,user_link,user_posts';

  const clientId: string | undefined = config.facebookConsumer.id;
  if (!clientId) return;

  const redirectUrl: string = `${config.appUrl}influencer/${path}/${SocialMediaType.FACEBOOK_PERSONAL}/`;

  window.location.href = `https://www.facebook.com/v16.0/dialog/oauth?client_id=${clientId}&scope=${permissionScope}&redirect_uri=${redirectUrl}&nocache=${new Date().getTime()}`;
};
