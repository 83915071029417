import { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Company1 from '../assets/images/pages/contact/company-1.jpg';
import Company2 from '../assets/images/pages/contact/company-2.jpg';
import Company3 from '../assets/images/pages/contact/company-3.jpg';
import CallIcon from '../assets/images/pages/contact/fluent_call-12-filled.svg';
import MailIcon from '../assets/images/pages/contact/clarity_email-solid.svg';
import MessengerIcon from '../assets/images/pages/contact/bxl_messenger.svg';
import MapIcon from '../assets/images/pages/contact/logos_google-maps.svg';

const CompanyList = [Company1, Company2, Company3];

const ContactPage = () => {
  const [showCompany, setShowCpmpany] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      if (showCompany === 2) {
        setShowCpmpany(0);
      } else {
        setShowCpmpany(showCompany + 1);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [showCompany]);

  return (
    <div
      style={{
        minHeight: '100vh',
        background:
          'linear-gradient(rgba(151, 235, 154, 0.45) 0%, rgba(68, 208, 164, 0.45) 100%)',
      }}
    >
      <Row style={{ padding: '0 30px' }}>
        <Col lg={12}>
          <Row className="p-4">
            <div className="back-btn">
              <Link to="/">
                <i className="mdi mdi-arrow-left" /> {t('Back To Homepage')}
              </Link>
            </div>
          </Row>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <h1>{t('Contact Us')}</h1>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '8px',
              }}
            >
              <img
                src={CompanyList[showCompany]}
                alt="Company"
                style={{
                  width: '100%',
                  maxWidth: '450px',
                  maxHeight: '250px',
                  borderRadius: '5px',
                }}
              />
              <h2>AFbrother Hongkong</h2>
              <h5>11/F WHEELOCK HSE 20 PEDDER ST CENTRAL HONG KONG</h5>
              <div
                style={{ display: 'flex', flexDirection: 'column', rowGap: '8px' }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    columnGap: '6px',
                  }}
                >
                  <img src={CallIcon} alt="Call Icon" />
                  <h5>+852 3628 0000</h5>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    columnGap: '6px',
                  }}
                >
                  <img src={MailIcon} alt="Call Icon" />
                  <h5>AFbrother@email.com</h5>
                </div>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <a
                    href="http://m.me/AFbrotherOfficial"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        columnGap: '6px',
                      }}
                    >
                      <img src={MessengerIcon} alt="Call Icon" />
                      <h5>@AFbrother</h5>
                    </div>
                  </a>
                </div>

                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <a
                    href="https://goo.gl/maps/FbH7Fxp6A8k7F8kW9"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <div
                      style={{
                        display: 'flex',
                        columnGap: '6px',
                        alignItems: 'flex-start',
                      }}
                    >
                      <img src={MapIcon} alt="Call Icon" />
                      <h5>View Map</h5>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ContactPage;
