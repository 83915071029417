import { Card, Col, Tooltip } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import {
  getSumDailyCurrentBudget as onGetSumDailyCurrentBudget,
  getWallet,
} from 'src/store/actions';
import widgetBackground from 'src/assets/images/widget-background.png';
import { floorToFixed } from 'src/common/data/mathToFixed';
import moment from 'moment';
import Role from 'src/constants/enum/role';
import { roleText } from '../../constants/role';

const WalletWidgetHome = ({ t }: any) => {
  const dispatch = useDispatch();
  const [toggleTooltip, setToggleTooltip] = useState<boolean>(false);

  const {
    wallet,
    role,
    sumDailyCurrentBudget,
    influencerData,
    sumWallet,
    socialJobSummary,
    marketerPendingBalance,
  } = useSelector((state: any) => ({
    wallet: state.Wallet.wallet,
    role: state.login.user.role,
    sumDailyCurrentBudget: state.Campaigns.SumDailyCurrentBudget,
    influencerData: state.Users.userInfo,
    sumWallet: state.Wallet.sumWallet,
    socialJobSummary: state.SocialMediaInfo.socialJobSummary,
    marketerPendingBalance: state.SocialMediaInfo.marketerPendingBalance,
  }));

  useEffect(() => {
    dispatch(onGetSumDailyCurrentBudget());
    dispatch(getWallet());
  }, []);

  // const primeZone = moment.tz('2013-11-18 00:00:00', 'Iceland');
  const startTime = moment('0:00 AM', 'h:mm A');
  const currentTimeZone = moment(startTime)
    .add(influencerData.timezone_offset, 'minutes')
    .format('HH:mm A');

  const rendorPending = () => {
    if (role === Role.PUBLISHER) {
      return (
        <div
          style={{
            fontSize: '16px',
            fontWeight: 500,
            letterSpacing: '0em',
            color: '#495057',
          }}
        >
          {t('sum_income')}{' '}
          {!isEmpty(socialJobSummary)
            ? floorToFixed(socialJobSummary?.sum_income, 2)
            : 0}{' '}
          <span
            style={{
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '29px',
              letterSpacing: '0em',
              color: '#495057',
              marginLeft: '5px',
            }}
          >
            USDT
          </span>
        </div>
      );
    } else if (role === Role.ADVERTISER) {
      return (
        <div
          style={{
            fontSize: '16px',
            fontWeight: 500,
            letterSpacing: '0em',
            color: '#495057',
          }}
        >
          {t('sum_hold')}{' '}
          {!isEmpty(marketerPendingBalance)
            ? floorToFixed(marketerPendingBalance?.balance, 2)
            : 0}{' '}
          <span
            style={{
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '29px',
              letterSpacing: '0em',
              color: '#495057',
              marginLeft: '5px',
            }}
          >
            USDT
          </span>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Col
      xs={12}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Card
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '730px',
          height: 'auto',
          borderRadius: '10px',
          // backgroundImage: `url(${widgetBackground})`,
          background: '#53c6b7',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          boxShadow: '0px 4px 5px 3px #00000040',
          border: 'none',
        }}
      >
        <div
          style={{
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '25px',
            letterSpacing: '0em',
            color: 'white',
            marginTop: '8px',
            marginBottom: '0',
          }}
        >
          {t('CurrentBalance')}
          {role === 'PUBLISHER' ? (
            <>
              <i
                className="fas fa-info-circle ms-2 font-size-16"
                style={{ cursor: 'pointer' }}
                id="tooltipInfo"
              />
              <Tooltip
                placement="right"
                isOpen={toggleTooltip}
                target="tooltipInfo"
                toggle={() => setToggleTooltip(!toggleTooltip)}
              >
                <span className="fs-6">
                  {t('tooltip.balance update info')}
                  {currentTimeZone}
                </span>
              </Tooltip>
            </>
          ) : (
            <></>
          )}
        </div>

        <div
          style={{
            fontSize: '35px',
            fontWeight: 500,
            lineHeight: '65px',
            letterSpacing: '0em',
            color: '#495057',
          }}
        >
          {!isEmpty(sumWallet)
            ? floorToFixed(sumWallet?.balance - sumDailyCurrentBudget, 2)
            : 0}{' '}
          <span
            style={{
              fontSize: '35px',
              fontWeight: 500,
              lineHeight: '29px',
              letterSpacing: '0em',
              color: '#495057',
              marginLeft: '5px',
            }}
          >
            USDT
          </span>
        </div>
        {rendorPending()}
      </Card>
    </Col>
  );
};

export default withTranslation()(WalletWidgetHome);
