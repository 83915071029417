import { Pagination, Tooltip } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { formatDateForm, calculateDayLeft } from 'src/utils/dateTime';
import choice from 'src/assets/images/choice.png';
import {
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import paramsFromURL from 'src/constants/paramsFromURL';
import {
  getLoggedInUser,
  getSocialGleamJobReviewByJobKey,
  setLoading,
  unsetLoading,
} from 'src/store/actions';
import {
  socialHasGleamJobReviewByJobKeyGQL,
  updateSocialMediaHasGleamJobGql,
} from 'src/graphql/advertiser/socialMediaInfo';
import notify from 'src/utils/notify';
import i18n from 'src/i18n';
import Swal from 'sweetalert2';
import { Approve, Reject } from './approval/approvalStatus';
import { InfluencerSocialIcon } from './status/InfluencerJobStatus';
import CustomTable from './CustomTable';
import './GleamJob.style.scss';
import CustomTableCampaign from './CustomTable copy';

const GleamJobReviewTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [jobId]: any = paramsFromURL(['job']);
  const [dataResult, setDataDefualt] = useState<any>([]);
  const [socialHasJobReview, setSocialHasJobReview] = useState<any>({});
  const { _socialHasJobReview, job } = useSelector((state: any) => ({
    _socialHasJobReview: state.SocialMediaInfo.socialHasJobReview,
    job: state?.MarketerJob?.marketerJob,
  }));
  const [disableChat, setDisableChat] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const [param, setParam] = useState<any>({
    limit: 10,
    page: 1,
    job_key: jobId,
    sortBy: '',
    sortType: '',
  });

  const fetchSocialGleamJobReview = async (payload: any) => {
    try {
      dispatch(setLoading());
      const response = await socialHasGleamJobReviewByJobKeyGQL(payload);
      setSocialHasJobReview(response);
    } catch (error) {
      setSocialHasJobReview({});
    } finally {
      dispatch(unsetLoading());
    }
  };

  useEffect(() => {
    // dispatch(getSocialGleamJobReviewByJobKey(param));
    fetchSocialGleamJobReview(param);
    // dispatch(getLoggedInUser());
  }, []);

  useEffect(() => {
    const dayLeft = calculateDayLeft(job?.finish_internal_work);
    if (dayLeft === null) {
      setDisableChat(true);
    } else {
      if (dayLeft > 0) {
        setDisableChat(false);
      } else {
        setDisableChat(true);
      }
    }
  }, [job]);

  useEffect(() => {
    if (socialHasJobReview?.items) {
      setData(socialHasJobReview);
    }
  }, [socialHasJobReview]);

  useEffect(() => {
    if (data?.items?.length > 0) {
      setDataDefualt([...data?.items]);
    }
  }, [data]);

  const handlePageChange = async (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : param.page;
    const payload = {
      ...param,
      page: newPage,
    };
    // dispatch(getSocialGleamJobReviewByJobKey(payload));
    await fetchSocialGleamJobReview(payload);
    setParam(payload);
  };

  const renderStatus = (item: any, key: string) => {
    if (item.status === 'CANCEL' || item.status === 'DECLINE') {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Reject message="Rejected" />
        </div>
      );
    } else if (item.marketer_approve_date) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Approve message="Approve" />
        </div>
      );
    } else {
      return (
        <UncontrolledDropdown className="">
          <DropdownToggle
            className="text-muted font-size-28"
            style={{
              cursor: 'pointer',
            }}
            tag="a"
          >
            <i
              className="bx bx-pencil"
              style={{
                cursor: 'pointer',
              }}
            />
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu-end ">
            <DropdownItem
              onClick={async () => {
                await Swal.fire({
                  title: t('swal:Are you sure ?'),
                  text: t(`swal:Do you want to Approve!`),
                  imageUrl: choice,
                  width: 300,
                  imageHeight: 200,
                  imageAlt: 'A tall image',
                  showCancelButton: true,
                  cancelButtonColor: 'red',
                  confirmButtonColor: '#009a78',
                  customClass: {},
                  confirmButtonText: t(`button:Yes`),
                  cancelButtonText: t('swal:Cancel'),
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    await onApproveJob(key, item.social_key);
                  }
                });
              }}
              className="text-success"
            >
              <i className="mdi mdi-account-check text-success" />
              &nbsp;&nbsp;{t('table:Approve')}
            </DropdownItem>
            <DropdownItem
              onClick={async () => {
                await Swal.fire({
                  title: t('Reject'),
                  text: t(`Tell influcencer why you reject ( If any )`),
                  input: 'textarea',
                  inputPlaceholder: 'input here',
                  inputAttributes: {
                    'aria-label': 'Type your message here',
                  },
                  width: 600,
                  imageHeight: 200,
                  imageAlt: 'A tall image',
                  showCancelButton: true,
                  cancelButtonColor: 'red',
                  confirmButtonColor: '#009a78',
                  customClass: {},
                  confirmButtonText: t(`button:Yes`),
                  cancelButtonText: t('swal:Cancel'),
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    const reason = result.value;
                    await onRejectJob(key, item.social_key, reason);
                  }
                });
              }}
              className="text-danger"
            >
              <i className="mdi mdi-account-cancel text-danger" />
              &nbsp;&nbsp;{t('table:Reject')}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
  };

  const clickToReviewPage = (item: any) => {
    history.push(
      `/campaigns/markerter/job/review?job=${item.job_key}&task=${item.group}&key=${item.social_key}`,
    );
  };

  const onApproveJob = async (group: string, social_key: string) => {
    const payload: any = {
      key: group,
      marketer_approve_date: new Date(),
      social_key,
    };
    dispatch(setLoading());
    try {
      await updateSocialMediaHasGleamJobGql(payload);
      await fetchSocialGleamJobReview(param);
      // dispatch(getSocialGleamJobReviewByJobKey(param));
      dispatch(unsetLoading());
      notify(i18n.t('success:Job has approved.'), 'success');
    } catch (error) {
      dispatch(unsetLoading());
      notify(i18n.t('error:Job has not approve .'), 'error');
    }
  };

  const onRejectJob = async (group: string, social_key: string, reason: any) => {
    dispatch(setLoading());
    const res: any = await updateSocialMediaHasGleamJobGql({
      key: group,
      reject_message: reason,
      status: 'DECLINE',
      social_key,
    });
    if (res) {
      //dispatch(getSocialGleamJobReviewByJobKey(param));
      await fetchSocialGleamJobReview(param);
      dispatch(unsetLoading());
      notify(i18n.t('success:Job has rejected.'), 'success');
    } else notify(i18n.t('error:Job has not reject .'), 'error');
  };

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage =
        newSortBy === param.sortBy && newSortType === param.sortType
          ? param.page
          : 1;

      const payload = {
        ...param,
        page: newPage,
        sortBy: newSortBy,
        sortType: newSortType,
      };
      // dispatch(getSocialGleamJobReviewByJobKey(payload));
      fetchSocialGleamJobReview(payload);
      setParam(payload);
    },
    [param.page, param.limit],
  );

  const renderChat = (unreadMessage: number) => {
    if (unreadMessage > 0) {
      if (unreadMessage > 9) {
        return (
          <>
            <div className="notification-icon" style={{ margin: 'auto' }}>
              9
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="notification-icon" style={{ margin: 'auto' }}>
              {unreadMessage}
            </div>
          </>
        );
      }
    } else {
      return <i className="fa fa-comments" aria-hidden="true" />;
    }
  };

  const columns = useMemo(() => {
    return [
      {
        Header: t('table:Influencer name'),
        accessor: ({ names, job_key, group, social_key }: any) => {
          const item = { job_key, group, social_key };
          return (
            <Link
              to={`/campaigns/markerter/job/review?job=${item.job_key}&task=${item.group}&key=${item.social_key}`}
              onClick={() => {
                clickToReviewPage(item);
              }}
              style={{ color: '#495057' }}
            >
              {names}
            </Link>
            // <div>
            //   <div
            //     className="col-center"
            //     onClick={() => {
            //       clickToReviewPage(item);
            //     }}
            //   >
            //     {names}
            //   </div>
            // </div>
          );
        },
        width: 50,
        disableSort: true,
        id: 'name',
      },
      {
        Header: t('table:Social'),
        accessor: ({
          names,
          job_key,
          group,
          social_key,
          social_media_types,
        }: any) => {
          const item = { job_key, group, social_key };
          return (
            <Link
              to={`/campaigns/markerter/job/review?job=${item.job_key}&task=${item.group}&key=${item.social_key}`}
              onClick={() => {
                clickToReviewPage(item);
              }}
              style={{ color: '#495057' }}
            >
              {social_media_types.map((social_type: any) => {
                return (
                  <InfluencerSocialIcon
                    social_media_type={social_type}
                    alt={social_type}
                    size={24}
                  />
                );
              })}
            </Link>
          );
        },
        width: 50,
        disableSort: true,
        id: 'Social',
      },
      {
        Header: () => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div>{t('table:Progress')}</div>{' '}
              <Tooltip
                title={<h4 style={{ color: 'white' }}>{t('tooltip.Progress')} </h4>}
                placement="right"
              >
                <i
                  className="fas fa-info-circle ms-2 font-size-16]"
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            </div>
          );
        },
        accessor: ({
          names,
          job_key,
          group,
          social_key,
          social_media_types,
          progress,
        }: any) => {
          const item = { job_key, group, social_key };
          return (
            <Link
              to={`/campaigns/markerter/job/review?job=${item.job_key}&task=${item.group}&key=${item.social_key}`}
              onClick={() => {
                clickToReviewPage(item);
              }}
              style={{ color: '#495057' }}
            >
              {progress}
            </Link>
          );
        },
        width: 50,
        disableSort: true,
        id: 'progress',
      },
      {
        Header: () => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div>{t('table:Prize')}</div>{' '}
              <Tooltip
                title={
                  <h4 style={{ color: 'white' }}>{t('tooltip.Review Prize')} </h4>
                }
                placement="right"
              >
                <i
                  className="fas fa-info-circle ms-2 font-size-16]"
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            </div>
          );
        },
        accessor: ({
          price,
          job_key,
          group,
          social_key,
          social_media_types,
          progress,
        }: any) => {
          const item = { job_key, group, social_key };
          return (
            <Link
              to={`/campaigns/markerter/job/review?job=${item.job_key}&task=${item.group}&key=${item.social_key}`}
              onClick={() => {
                clickToReviewPage(item);
              }}
              style={{ color: '#495057' }}
            >
              {price}
            </Link>
          );
        },
        width: 50,
        disableSort: true,
        id: 'Prize',
      },
      {
        Header: t('table:Status'),
        accessor: ({
          names,
          job_key,
          group,
          social_key,
          social_media_types,
          progress,
          status,
        }: any) => {
          const item = { job_key, group, social_key };
          return (
            <Link
              to={`/campaigns/markerter/job/review?job=${item.job_key}&task=${item.group}&key=${item.social_key}`}
              onClick={() => {
                clickToReviewPage(item);
              }}
              style={{ color: '#495057' }}
            >
              {status === 'INPROGRESS' ? t('ACTIVE') : t(status)}
            </Link>
          );
        },
        width: 50,
        id: 'status',
      },
      {
        Header: t('table:Post date'),
        accessor: ({ job_key, group, social_key, post_date }: any) => {
          const item = { job_key, group, social_key };
          return (
            <Link
              to={`/campaigns/markerter/job/review?job=${item.job_key}&task=${item.group}&key=${item.social_key}`}
              onClick={() => {
                clickToReviewPage(item);
              }}
              style={{ color: '#495057' }}
            >
              {post_date ? formatDateForm(post_date) : t('PENDING')}
            </Link>
          );
        },
        width: 50,
        id: 'post_date',
      },
      {
        Header: () => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div> {t('table:Approved Date')}</div>{' '}
              <Tooltip
                title={
                  <h4 style={{ color: 'white' }}>{t('tooltip.Approve Date')} </h4>
                }
                placement="right"
              >
                <i
                  className="fas fa-info-circle ms-2 font-size-16]"
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            </div>
          );
        },
        accessor: ({ job_key, group, social_key, marketer_approve_date }: any) => {
          const item = { job_key, group, social_key };
          return (
            <Link
              to={`/campaigns/markerter/job/review?job=${item.job_key}&task=${item.group}&key=${item.social_key}`}
              onClick={() => {
                clickToReviewPage(item);
              }}
              style={{ color: '#495057' }}
            >
              {marketer_approve_date
                ? formatDateForm(marketer_approve_date)
                : t('PENDING')}
            </Link>
          );
        },
        width: 50,
        id: 'marketer_approve_date',
      },
      {
        Header: t('table:Action'),
        accessor: ({
          names,
          job_key,
          group,
          social_key,
          social_media_types,
          progress,
          status,
          marketer_approve_date,
        }: any) => {
          const item = { job_key, group, social_key, marketer_approve_date, status };
          return (
            <div>
              <Col className="col-center">{renderStatus(item, item.group)}</Col>
            </div>
          );
        },
        width: 50,
        disableSort: true,
        id: 'Action',
      },
      {
        Header: 'chat',
        accessor: ({ job_key, group, social_key, unreadMessage }: any) => {
          const item = { job_key, group, social_key };
          return (
            <Link
              to={`/campaign/JobDetail/CampaignChat?job=${item.job_key}&task=${item.group}&key=${item.social_key}`}
              target="_"
              style={{ color: '#495057' }}
            >
              {renderChat(unreadMessage)}
            </Link>
          );
        },
        width: 50,
        disableSort: true,
        id: 'Chat',
      },
    ];
  }, [dataResult, param]);
  return (
    <div style={{ paddingTop: '0px', marginTop: '0px' }}>
      <CustomTableCampaign
        showPaginationBottom={false}
        columns={columns || []}
        bodyCSS="body-table margin-bot"
        data={dataResult || []}
        hidePagination
        headerCSS="fw-normal bg-header-table"
        onSort={handleSort}
        hiddenColumn={disableChat ? ['Chat'] : []}
      />
      <Pagination
        count={Math.ceil(data?.totalCount / param.limit)}
        page={param.page}
        color="standard"
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        style={{
          width: 'fit-content',
          margin: 'auto',
          marginTop: '30px',
        }}
        boundaryCount={2}
      />
    </div>
  );
};

export default GleamJobReviewTable;
