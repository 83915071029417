import React from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useSelector } from 'react-redux';

export const Loading = () => {
  const { loading } = useSelector((state: any) => ({
    loading: state.Global.loading,
  }));

  return (
    <div className={`loading-container ${loading ? 'd-block' : 'd-none'}`}>
      <div className="spinner-container">
        <ScaleLoader
          color="#009a78"
          height={35}
          width={4}
          radius={2}
          margin={2}
          loading
        />
      </div>
    </div>
  );
};

export const OnLoading = () => {
  return (
    <div className="loading-container">
      <div className="spinner-container">
        <ScaleLoader
          color="#009a78"
          height={35}
          width={4}
          radius={2}
          margin={2}
          loading
        />
      </div>
    </div>
  );
};
