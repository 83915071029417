import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import LanguageDropdown from 'src/components/CommonForBoth/TopbarDropdown/LanguageDropdown';
import arrow_down from 'src/assets/images/other/arrow-up.svg';
import arrow_right from 'src/assets/images/other/arrow-right.svg';
import logoColorful from 'src/assets/images/logo-colorful.svg';
import colorfulIcon from 'src/assets/images/favicon.svg';
import Tiktok1 from 'src/assets/images/home/tiktok-svg.svg';
import Warning from 'src/assets/images/other/warning.png';
import { useHistory } from 'react-router';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ADMIN_MENU,
  ADVERTISER_MENU,
  PUBLISHER_MENU,
  ADVERTISER_MENU_NEW,
} from 'src/constants/sidebarMenu';
import { roleText as role } from 'src/constants/role';
import { useTranslation } from 'react-i18next';
import logo from 'src/assets/images/logo-AFbrother-white.svg';
import { Link } from 'react-router-dom';
import { changelayoutMode } from 'src/store/layout/actions';
import './drawer.style.scss';
// import Tiktok from 'src/assets/images/home/tiktok-svg.svg';
// import { suggestAndOtherJobCountGql } from 'src/graphql/common';
// import LightDark from './LightDark';

const AppMenu = ({ menus }: any) => {
  const { t } = useTranslation();
  const data = useSelector((state: any) => ({
    filter: state.SocialMediaInfo.filter,
    suggestjobs: state?.SuggestJobs?.suggestJobs,
  }));
  return (
    <List component="nav" disablePadding>
      <div className="d-flex justify-content-center align-items-center p-5">
        <img className="home-page-footer__logo" src={logoColorful} alt="logo" />
      </div>
      {menus.map((item: any) => {
        const newItem = {
          text: t(`${item.text}`),
          icon: item.icon,
          push: item.push,
          match: item.match,
          isMainMenu: item.isMainMenu,
          items:
            item.items &&
            item.items.map((subItem: any) => {
              return {
                text: t(`${subItem.text}`),
                icon: subItem.icon,
                push: subItem.push,
                match: subItem.match,
              };
            }),
        };
        return <AppMenuItem {...newItem} key={item.text} />;
      })}
    </List>
  );
};
const AppMenuInfluencer = ({ menus }: any) => {
  const { t } = useTranslation();
  const data = useSelector((state: any) => ({
    filter: state.SocialMediaInfo.filter,
    suggestjobs: state?.SuggestJobs?.suggestJobs,
  }));
  return (
    <List
      component="nav"
      disablePadding
      style={{ background: 'rgb(73, 80, 87)', color: 'white', height: '100vh' }}
    >
      <div
        className="d-flex justify-content-center align-items-center p-5"
        style={{ color: 'white' }}
      >
        <img className="home-page-footer__logo" src={logo} alt="logo" />
      </div>
      {menus.map((item: any) => {
        const newItem = {
          text: t(`${item.text}`),
          icon: item.icon,
          push: item.push,
          match: item.match,
          isMainMenu: item.isMainMenu,
          items:
            item.items &&
            item.items.map((subItem: any) => {
              return {
                text: t(`${subItem.text}`),
                icon: subItem.icon,
                push: subItem.push,
                match: subItem.match,
              };
            }),
        };
        return <AppMenuItem {...newItem} key={item.text} />;
      })}
    </List>
  );
};

function TemporaryDrawer() {
  const [state, setState]: any = React.useState({
    left: false,
  });

  const { user } = useSelector((reduxState: any) => ({
    user: reduxState.login.user,
  }));

  const renderSidebarContent = () => {
    if (user) {
      switch (user.role) {
        case role.ADVERTISER:
          return ADVERTISER_MENU;
        case role.PUBLISHER:
          return PUBLISHER_MENU;
        case role.ADMIN:
          return ADMIN_MENU;
        default:
          return '';
      }
    } else {
      return PUBLISHER_MENU;
    }
  };

  const toggleDrawer = (anchor: any, open: any) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div className="Drawer">
      <Button onClick={toggleDrawer('left', true)} style={{ color: '#3aad96' }}>
        <i className="fa fa-fw fa-bars fa-2x" />
      </Button>
      <Drawer
        anchor="left"
        open={state['left']}
        onClose={toggleDrawer('left', false)}
      >
        <AppMenu menus={renderSidebarContent()} />
      </Drawer>
    </div>
  );
}
function TemporaryDrawerAdvertiser() {
  const [state, setState]: any = React.useState({
    left: false,
  });

  const { user } = useSelector((reduxState: any) => ({
    user: reduxState.login.user,
  }));

  const renderSidebarContent = () => {
    switch (user.role) {
      case role.ADVERTISER:
        return ADVERTISER_MENU_NEW;
      case role.PUBLISHER:
        return PUBLISHER_MENU;
      case role.ADMIN:
        return ADMIN_MENU;
      default:
        return '';
    }
  };

  const toggleDrawer = (anchor: any, open: any) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div className="Drawer">
      <Button onClick={toggleDrawer('left', true)} style={{ color: '#050606' }}>
        <i className="fa fa-fw fa-bars fa-2x" />
      </Button>
      <Drawer
        anchor="left"
        open={state['left']}
        onClose={toggleDrawer('left', false)}
      >
        <AppMenuInfluencer menus={renderSidebarContent()} />
      </Drawer>
    </div>
  );
}

interface NotiJobType {
  advertiser_campaign: number;
  new_influencer: number;
  new_influencer_request: number;
  new_job: number;
  publisher_website: number;
  withdraw: number;
  delete_social: number;
}
const notificationJob = {
  advertiser_campaign: 0,
  new_influencer: 0,
  new_influencer_request: 0,
  new_job: 0,
  publisher_website: 0,
  withdraw: 0,
  delete_social: 0,
};

const AppMenuItem: React.FC = (props: any) => {
  const history = useHistory();
  const { text, icon, items = [], push, isMainMenu }: any = props;
  const { match } = props;

  const isExpandable = items && items?.length > 0;

  const [open, setOpen] = useState(false);

  const [approveLogCount, setApproveLogCount] = useState(notificationJob);

  const initialValue = 0;
  const approveLogCountArray = Object.values(approveLogCount);
  const sumNotify = approveLogCountArray.reduce(
    (accumulator: number, currentValue: number) => accumulator + currentValue,
    initialValue,
  );

  // const sum = approveLogCount[match as keyof NotiJobType].reduce(function (
  //   accumulator: any,
  //   currentValue: any,
  // ) {
  //   return accumulator + currentValue;
  // },
  // 0);

  const { ApproveLog } = useSelector((state: any) => ({
    ApproveLog: state.ActivityLogs.allAdminApproveLog,
  }));
  const data = useSelector((state: any) => ({
    filter: state.SocialMediaInfo.filter,
    suggestjobs: state?.SuggestJobs?.suggestJobs,
  }));
  const checkApproveLog = () => {
    if (!ApproveLog?.length) return;
    const value: any = {};
    for (let i = 0; i < ApproveLog?.length; i++) {
      const log = ApproveLog[i];
      value[log.admin_approve_log.name] = log.value;
    }
    setApproveLogCount(value);
  };
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously

  React.useEffect(() => {
    if (!ApproveLog) return;
    checkApproveLog();
  }, [ApproveLog]);
  function handleClick() {
    setOpen(!open);
  }

  const influncerAlert = () => {
    if (props.match === 'influencer')
      if (data?.filter?.totalCount === 0 || data.suggestjobs.suggestJobs > 0) {
        return (
          <span className="alert-suggest-job" style={{ marginLeft: '7px' }}>
            {data.suggestjobs.suggestJobs === 1 ? '1' : '1+'}
          </span>
        );
      }
    return ' ';
  };
  const myJobsAlert = () => {
    if (props.match === 'My Jobs')
      if (data?.filter?.totalCount <= 0) {
        return (
          <img
            src={Warning}
            className="enable-img"
            alt="warning"
            width="25px"
            height="25px"
          />
        );
      }
    return '';
  };
  const suggestAlert = () => {
    if (props.match === 'Suggested jobs')
      if (data.suggestjobs.suggestJobs > 0) {
        return (
          <span className="alert-suggest-job">{data.suggestjobs.suggestJobs}</span>
        );
      }
    return '';
  };
  const MenuItemRoot = (
    <ListItem
      className="nav-drawer d-flex justify-content-center align-items-center bg-hover-green"
      button
      onClick={() => {
        handleClick();
      }}
    >
      {icon && (
        <div
          className="font-size-20 d-flex justify-content-center align-items-center"
          style={{ width: 32 }}
        >
          <i className={icon} style={{ color: 'white' }} />
        </div>
      )}
      <ListItemText
        primary={text}
        inset={!icon}
        onClick={() => history.push(push)}
        style={{ paddingLeft: 0 }}
        className="d-flex justify-content-start align-items-center"
      />
      {myJobsAlert()}
      {suggestAlert()}
      {(match in approveLogCount &&
        approveLogCount[match as keyof NotiJobType] > 0 && (
          <div className="notification-icon">
            {approveLogCount[match as keyof NotiJobType] > 99
              ? ' 99+'
              : approveLogCount[match as keyof NotiJobType]}
          </div>
        )) ||
        (isMainMenu && (
          <div className="notification-icon">
            {sumNotify > 99 ? ' 99+' : sumNotify}
          </div>
        ))}
      {isExpandable && !open && <img src={arrow_right} alt="Arrow down" />}
      {isExpandable && open && <img src={arrow_down} alt="Arrow up" />}
      {influncerAlert()}
    </ListItem>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List
        component="div"
        disablePadding
        style={{ paddingLeft: 32, backgroundColor: '#fbfaff' }}
      >
        {items.map((item: any, index: any) => (
          // eslint-disable-next-line react/no-array-index-key
          <AppMenuItem {...item} key={index} />
        ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

function HomepageAppMenu() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { layoutType } = useSelector((state: any) => ({
    user: state.login.user,
    layoutMode: state.Layout.layoutMode,
    layoutType: state.Layout.layoutType,
  }));

  const onChangeLayoutMode = (value: any) => {
    if (changelayoutMode) {
      dispatch(changelayoutMode(value, layoutType));
    }
  };
  const history = useHistory();
  return (
    <List component="nav" disablePadding style={{ width: '200px' }}>
      <div className="d-flex flex-direction-column justify-content-center align-items-center pt-5">
        <img
          className="home-page-footer__logo"
          src={colorfulIcon}
          alt="logo"
          style={{ width: '70px' }}
        />
      </div>

      <div data-simplebar className="h-100">
        <div
          className="p-3"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <div
            onClick={() => {
              history.push('/register');
            }}
            className="d-flex justify-content-center align-items-center p-2"
          >
            <div className="btn btn-primary btn-md font-size-14 d-flex justify-content-center align-items-center text-nowrap">
              {t('Register')}
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center p-2">
            <div
              onClick={() => {
                history.push('/login');
              }}
              className="btn btn-primary btn-md font-size-14 d-flex justify-content-center align-items-center text-nowrap"
            >
              {t('Login')}
            </div>
          </div>
        </div>
        <div style={{ marginRight: '1rem' }}>
          <div id="sidebar-menu" className="d-flex justify-content-center p-0">
            <ul className="metis-menu list-unstyled">
              <li className="pl-5">
                <Link to="/">
                  <span className="font-size-16">{t('Home')}</span>
                </Link>
                <Link to="/login">
                  <span className="font-size-16">{t('Influencer')}</span>
                </Link>
                <Link to="/login">
                  <span className="font-size-16">{t('Marketer')}</span>
                </Link>
                <Link to="/faqs">
                  <span className="font-size-16">{t('FAQ')}</span>
                </Link>
              </li>
              <li>
                <a
                  href="https://blog.afbrother.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="font-size-16">{t('Blog')}</span>
                </a>
                <Link to="/contact">
                  <span className="font-size-16">{t('Contact Us')}</span>
                </Link>
              </li>{' '}
              <div
                style={{
                  display: 'flex',
                  gap: '0.5rem',
                  color: 'red !important',
                }}
              >
                <a href="https://www.facebook.com/AFBrotherGlobal">
                  <i
                    className="fab fa-facebook fa-2x text-color-primary"
                    // style={{ color: 'initial' }}
                  />
                </a>
                <a href="https://twitter.com/AFBrotherGlobal">
                  <i
                    className="fab fa-twitter fa-2x text-color-primary"
                    // style={{ color: 'initial' }}
                  />
                </a>
                <a href="https://www.youtube.com/@afbrotherglobal/">
                  <i
                    className="fab fa-youtube fa-2x text-color-primary"
                    // style={{ color: 'initial' }}
                  />
                </a>
                <a href="https://www.tiktok.com/@afbrother_global">
                  <img
                    src={Tiktok1}
                    alt=""
                    style={{
                      width: '28px',
                      height: '29px',
                      // color: '#495057 !important',
                    }}
                  />
                  {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" />
                  </svg> */}
                </a>
              </div>
              <li
                className=""
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 24,
                }}
              >
                {' '}
                <LanguageDropdown bodyCSS="dropdown-language" />
              </li>
              <li style={{ paddingTop: 10, paddingLeft: 24 }}>
                {/* <LightDark
                layoutMode={layoutMode}
                onChangeLayoutMode={onChangeLayoutMode}
              /> */}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </List>
  );
}

function HomepageDrawer() {
  const [state, setState]: any = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor: any, open: any) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  return (
    <div className="Drawer">
      <button
        className="drawer-button"
        type="button"
        onClick={toggleDrawer('right', true)}
      >
        <i className="fa fa-fw fa-bars fa-2x drawer-icon" />
      </button>
      <Drawer
        anchor="right"
        open={state['right']}
        onClose={toggleDrawer('right', false)}
      >
        <HomepageAppMenu />
      </Drawer>
    </div>
  );
}

export { TemporaryDrawer, HomepageDrawer, TemporaryDrawerAdvertiser };
