import MetaTags from 'react-meta-tags';
import { Link, withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

//import images
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Role from 'src/constants/enum/role';
import { resendEmail } from 'src/store/actions';
import logo from '../../assets/images/logo-colorful.svg';
import CarouselPage from './CarouselPage';
import { layoutTheme } from '../../constants/layout';
import logoWhite from '../../assets/images/logo-white.svg';

import config from '../../config';

const ConfirmMail = ({ t, location }: any) => {
  const { user, layoutMode } = useSelector((state: any) => ({
    user: state.register.user,
    layoutMode: state.Layout.layoutMode,
  }));
  const dispatch = useDispatch();
  const { role } = location.state;

  if (!location.state) {
    window.location.href = '/';
  }
  const handleResendEmail = () => {
    // const email = localStorage.getItem('email') ?? '';
    dispatch(resendEmail(user.email, user.role));
  };
  return (
    <>
      <MetaTags>
        <title>Confirm Mail | {config.appName}</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-4">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-800">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/" className="d-block auth-logo">
                        <img
                          src={
                            layoutMode === layoutTheme.DARKMODE ? logoWhite : logo
                          }
                          alt=""
                          height="28"
                        />{' '}
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <div className="avatar-lg mx-auto">
                          <div className="avatar-title rounded-circle bg-light">
                            <i className="bx bx-mail-send font-size-70 mb-0 text-primary" />
                          </div>
                        </div>
                        <div className="p-2 mt-4">
                          <h4>{t('Success')}</h4>
                          <p className="text-muted">
                            {t('Please confirm your email', { email: user.email })}
                          </p>

                          <div className="mt-4">
                            {' '}
                            <p style={{ fontWeight: 'bold', margin: '0' }}>
                              Gmail User
                            </p>
                            <p>
                              {t(
                                'Please check the promotional tab if you can not find it in the main inbox.',
                              )}
                            </p>
                            <p style={{ fontWeight: 'bold', margin: '0' }}>
                              Hotmail User
                            </p>
                            <p>
                              {t(
                                'Please check your junk box and sometimes the mail gets dropped. If the email is not found within 10 minutes, press the Resend email button.',
                              )}
                            </p>
                            <p style={{ fontWeight: 'bold', margin: '0' }}>
                              Other mail User
                            </p>
                            <p>
                              {t(
                                'Please check your junk box and sometimes there may be a delay of 5-10 minutes.',
                              )}
                            </p>
                            <Link
                              to={
                                role === Role.PUBLISHER
                                  ? `/login/${Role.INFLUENCER.toLowerCase()}`
                                  : `/login/${Role.MARKETER.toLowerCase()}`
                              }
                              className="btn btn-primary w-100"
                              style={{ marginTop: '1rem' }}
                            >
                              {t('Back To Login')}
                            </Link>
                          </div>
                          <div style={{ marginTop: '1rem' }}>
                            <p className="text-muted mt-2">
                              {t(`Don't you received an verify email ?`)}
                              <a
                                onClick={() => {
                                  handleResendEmail();
                                }}
                                style={{ cursor: 'pointer', color: '#009a78' }}
                              >
                                {'  '} {t('Resend Email')}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(withRouter(ConfirmMail));
