import { isEmpty } from 'lodash';
import { type } from 'os';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Col, Row } from 'reactstrap';
import VerifyStatus from 'src/constants/verifyStatusEnum';
import { getBanHistory } from 'src/store/actions';
import CustomTable from '../Common/CustomTable';
import PageSizeOptions from '../Table/PageSizeOptions';
import CoinImage from '../Common/CoinImage';
import ChecksumBadge from '../Common/ChecksumBadge';
import { transformStringAmountInput } from '../../utils/input';

const WalletTable = ({ t, userId }: any) => {
  const defaultPageSize = 20;
  const dispatch = useDispatch();
  const history = useHistory();

  // Local State
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  //const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');

  const [param, setParam] = useState<any>({
    uuid: userId,
    limit: 10,
    page: 1,
    sortBy: '',
    sortType: '',
  });

  const { eachWallet } = useSelector((state: any) => ({
    eachWallet: state.Wallet.eachWallet,
  }));

  // const handlePageChange = (e: any, value: any) => {
  //   const newPage = value ? parseInt(value, 10) : page;

  //   const payload = {
  //     ...param,
  //     page: newPage,
  //   };

  //   dispatch(getBanHistory(payload));
  //   setPage(newPage);
  //   setParam(payload);
  // };

  const handlePageSizeChange = (size: string) => {
    const newPageSize = parseInt(size, 10);

    const firstRowIndexOfOldPage = page * pageSize - (pageSize - 1);
    const newPage = Math.ceil(firstRowIndexOfOldPage / newPageSize);

    const payload = {
      ...getDefaultPayload(newPage),
      limit: newPageSize,
    };

    dispatch(getBanHistory(payload));

    setPageSize(newPageSize);
  };

  const getDefaultPayload = (pages: number) => {
    setPage(pages);

    return {
      uuid: userId,
      limit: pageSize,
      page,
      sortBy: '',
      sortType: '',
    };
  };

  const columns = useMemo(() => {
    return [
      {
        Header: t('table:Token'),
        accessor: ({ symbol }: any) => symbol,
        id: 'symbol',
        disableSort: true,
        width: 100,
      },
      {
        Header: t('table:Balance'),
        accessor: ({ balance }: any) => transformStringAmountInput(balance),
        id: 'balance',
        disableSort: true,
        width: 100,
      },
      {
        Header: t('table:checksum_status'),
        accessor: ({ checksum_status }: any) => (
          <ChecksumBadge checksum_status={checksum_status} />
        ),
        id: 'checksum_status',
        disableSort: true,
        width: 100,
      },
    ];
  }, [t]);

  // const data = React.useMemo(() => {
  //   return !isEmpty(txn) ? txn.items : [];
  // }, [txn]);

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';

      const newPage = newSortBy === sortBy && newSortType === sortType ? page : 1;

      const payload = {
        ...getDefaultPayload(newPage),
        sortBy: newSortBy,
        sortType: newSortType,
      };

      dispatch(getBanHistory(payload));
      setSortBy(newSortBy);
      setSortType(newSortType);
      setParam(payload);
    },
    [page, pageSize],
  );

  return (
    <>
      <CustomTable
        columns={columns}
        data={eachWallet}
        page={page}
        pageSize={pageSize}
        total={eachWallet?.length}
        // handlePageChange={handlePageChange}
        // onSort={handleSort}
      >
        <Row className="justify-content-between m-0">
          <Col xs={12} md={3} className="d-flex align-items-center mb-3 mb-md-0">
            <PageSizeOptions
              defaultValue={pageSize}
              handlePageSizeChange={handlePageSizeChange}
            />
          </Col>
        </Row>
      </CustomTable>
    </>
  );
};

export default withTranslation()(WalletTable);
