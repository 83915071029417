import React from 'react';
import { FormGroup } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import withdrawStatusesEnum from '../../constants/withdrawStatusesEnum';

const WithdrawStatusOptions = ({ t, defaultValue, handleStatusChange }: any) => {
  const handleChange = (e: any) => {
    const status = e.currentTarget.value;

    handleStatusChange(status);
  };

  const renderWithdrawStatusesOption = () => {
    return Object.keys(withdrawStatusesEnum).map((key: string) => {
      const text = key === 'ALL' ? t('table:Select Status') : t(`table:${key}`);

      return (
        <option
          // @ts-ignore
          value={withdrawStatusesEnum[key]}
          defaultValue={defaultValue}
        >
          {text}
        </option>
      );
    });
  };

  return (
    <FormGroup>
      <select className="mb-md-0 form-control" onChange={handleChange}>
        {renderWithdrawStatusesOption()}
      </select>
    </FormGroup>
  );
};

export default withTranslation()(WithdrawStatusOptions);
