import { useState } from 'react';
//react style
import VerifyStatus from 'src/constants/verifyStatusEnum';
import {
  Container,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
//general api
import { useTranslation } from 'react-i18next';
//component
import menu from 'src/constants/menu';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import DeleteSocialMediaComponent from 'src/components/Admin/DeleteSocialMediaComponent';
import CurrencyTransactionPage from 'src/components/Admin/CurrencyTransactionPage';
import CurrencyTransaction from 'src/components/Admin/CurrencyTransaction';

const CurrencyApproval = () => {
  const [tab, setTab] = useState('1');
  const { t }: any = useTranslation();
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb
          pageTitle={t('Currency Approval')}
          menus={[
            { menu: menu.DASHBOARD },
            { menu: menu.REQUEST_DELETE_SOCIAL_MEDIA, active: true },
          ]}
        />
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader>
                <Nav className="nav-tabs-custom mb-3">
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={`${tab === '1' && 'active'}`}
                      onClick={() => {
                        setTab('1');
                      }}
                    >
                      <h5>{t('swal:Currency')}</h5>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={`${tab === '2' && 'active'}`}
                      onClick={() => {
                        setTab('2');
                      }}
                    >
                      <h5>{t('swal:Pending Approval')}</h5>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={`${tab === '3' && 'active'}`}
                      onClick={() => {
                        setTab('3');
                      }}
                    >
                      <h5>{t('swal:All Approval')}</h5>
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody>
                {tab === '1' && <CurrencyTransactionPage />}

                {tab === '2' && <CurrencyTransaction status="PENDING" />}
                {tab === '3' && <CurrencyTransaction />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CurrencyApproval;
