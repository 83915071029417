import food from 'src/assets/images/food.png';
import other from 'src/assets/images/other.png';
import healthy from 'src/assets/images/healthy.png';
import house from 'src/assets/images/house-garden.png';
import car from 'src/assets/images/car-motorcycle.png';
import family from 'src/assets/images/kids-and-family.png';
import lifestyle from 'src/assets/images/lifestyle.png';
import travel from 'src/assets/images/travel.png';
import pet from 'src/assets/images/pet.png';
import itproduct from 'src/assets/images/ITproduct.png';
import sport from 'src/assets/images/sport.png';
import fetish from 'src/assets/images/fetish-amulet.png';
import beauty from 'src/assets/images/beauty.png';
import finance from 'src/assets/images/finance-stock.png';
import game from 'src/assets/images/game.png';
import crypto from 'src/assets/images/crypto.png';

/* eslint-disable import/prefer-default-export */
const CategoriesMarketerCampaign: Category[] = [
  {
    label: 'marketer:create_campaign:categories.crypto',
    value: 1,
    icon: crypto,
    // icon_color: '#ffffff',
    // background_color: '#FF8C32',
  },
  {
    label: 'marketer:create_campaign:categories.finance',
    value: 2,
    icon: finance,
    // icon_color: '#ffffff',
    // background_color: '#D61C4E',
  },
  {
    label: 'marketer:create_campaign:categories.itproduct',
    value: 3,
    icon: itproduct,
    // icon_color: '#ffffff',
    // background_color: '#F637EC',
  },
  {
    label: 'marketer:create_campaign:categories.game',
    value: 4,
    icon: game,
    // icon_color: '#ffffff',
    // background_color: '#9DD6DF',
  },
  {
    label: 'marketer:create_campaign:categories.food',
    value: 5,
    icon: food,
    // icon_color: '#ffffff',
    // background_color: '#3AB0FF',
  },
  {
    label: 'marketer:create_campaign:categories.healthy',
    value: 6,
    icon: healthy,
    // icon_color: '#ffffff',
    // background_color: '#A85CF9',
  },
  {
    label: 'marketer:create_campaign:categories.beauty',
    value: 7,
    icon: beauty,
    // icon_color: '#ffffff',
    // background_color: '#001E6C',
  },
  {
    label: 'marketer:create_campaign:categories.sport',
    value: 8,
    icon: sport,
    // icon_color: '#ffffff',
    // background_color: '#4B7BE5',
  },
  {
    label: 'marketer:create_campaign:categories.life_style',
    value: 9,
    icon: lifestyle,
    // icon_color: '#ffffff',
    // background_color: '#83BD75',
  },
  {
    label: 'marketer:create_campaign:categories.travel',
    value: 10,
    icon: travel,
    // icon_color: '#ffffff',
    // background_color: '#F8B400',
  },
  {
    label: 'marketer:create_campaign:categories.pet',
    value: 11,
    icon: pet,
    // icon_color: '#ffffff',
    // background_color: '#A97155',
  },
  {
    label: 'marketer:create_campaign:categories.car',
    value: 12,
    icon: car,
    // icon_color: '#ffffff',
    // background_color: '#A97155',
  },
  {
    label: 'marketer:create_campaign:categories.family',
    value: 13,
    icon: family,
    // icon_color: '#ffffff',
    // background_color: '#A97155',
  },
  {
    label: 'marketer:create_campaign:categories.fetish',
    value: 14,
    icon: fetish,
    // icon_color: '#ffffff',
    // background_color: '#A97155',
  },
  {
    label: 'marketer:create_campaign:categories.house',
    value: 15,
    icon: house,
    // icon_color: '#ffffff',
    // background_color: '#A97155',
  },
  {
    label: 'marketer:create_campaign:categories.other',
    value: 16,
    icon: other,
    // icon_color: '#ffffff',
    // background_color: '#CFD2CF',
  },
];

export interface Category {
  label: string;
  value: number;
  icon: any;
  // icon_color?: string;
  // background_color?: string;
}

export { CategoriesMarketerCampaign };
