import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { getAllAdminApproveLog } from 'src/store/actions';
import MetaTags from 'react-meta-tags';
import { resetApproveLogValueByName } from 'src/graphql/common/activity-logs';
import { useEffect, useMemo, useState } from 'react';
import { getFilterMarketerOwnCurrency } from 'src/store/own-currency/actions';
import config from 'src/config';
import CustomTable from '../Common/CustomTable';
import PageSizeOptions from '../Table/PageSizeOptions';
import SearchBar from '../Table/SearchBar';
import { Approve, Reject, Pending } from '../Common/approval/approvalStatus';
import CoinImage from '../Common/CoinImage';

const RequestOwnCurrencyHistory = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { marketerOwnCurrencies } = useSelector((state: any) => ({
    marketerOwnCurrencies: state.OwnCurrency.marketerOwnCurrencies,
  }));
  const [page, setPage] = useState(1);
  // const [verifyStatus, setVerifyStatus] = useState(Status || null);
  const [param, setParam] = useState<any>({
    page: 1,
    limit: 10,
  });

  const handleChangePageSize = (newPagesize: string) => {
    const payload = { ...param, limit: +newPagesize };
    setParam(payload);
    dispatch(getFilterMarketerOwnCurrency(payload));
  };

  const handlePageChange = (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : 0;
    const payload = {
      ...param,
      page: newPage,
    };
    setParam(payload);
    dispatch(getFilterMarketerOwnCurrency(payload));
  };

  const handleSearch = (keyword: string) => {
    const payload = { ...param, name: keyword };
    setParam(payload);
    dispatch(getFilterMarketerOwnCurrency(payload));
  };
  const handleClear = () => {
    const payload = { ...param, name: undefined, page: 1, limit: 10 };
    setParam(payload);
    dispatch(getFilterMarketerOwnCurrency(payload));
  };

  useEffect(() => {
    dispatch(getFilterMarketerOwnCurrency(param));
  }, []);

  const columns = useMemo(() => {
    return [
      {
        Header: t('No.'),
        accessor: (row: any, index: number) =>
          index + 1 + (param.page - 1) * param.limit,
        id: 'index',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('Token Logo'),
        accessor: ({ logo, symbol }: any) => {
          if (!logo) {
            return <></>;
          } else {
            return (
              <img
                src={`${config.backendUrl}api/currencyImage/${logo}`}
                alt={symbol}
                style={{ width: 32, height: 32, borderRadius: '50%' }}
              />
            );
          }
        },
        id: 'logo',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('Token Name'),
        accessor: ({ name }: any) => <div>{name}</div>,
        id: 'name',
        width: 70,
        disableSort: true,
      },
      {
        Header: t('Token Symbol'),
        accessor: ({ symbol }: any) => <div>{symbol}</div>,
        id: 'symbol',
        width: 70,
        disableSort: true,
      },
      {
        Header: t('Contract Address'),
        accessor: ({ contract_address }: any) => <div>{contract_address}</div>,
        id: 'ContractAddress',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('Network'),
        accessor: ({ network }: any) => <div>{network}</div>,
        id: 'Network',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('Reference Link'),
        accessor: ({ ref_link }: any) => <a href={ref_link}>{ref_link}</a>,
        id: 'ref_link',
        width: 150,
        disableSort: true,
      },
      {
        Header: t('Status'),
        accessor: ({ status }: any) => <div className="">{status}</div>,
        id: 'Status',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('Verify Status'),
        accessor: ({ verify_status }: any) => {
          if (verify_status === 'PENDING') {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  gap: '5px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Pending message="Pendding" />
                </div>
              </div>
            );
          } else if (verify_status === 'APPROVED') {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  gap: '5px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Approve message="Approved" />
                </div>
              </div>
            );
          }
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: '5px',
              }}
            >
              <div>
                <Reject message="Rejected" />
              </div>
            </div>
          );
        },
        id: 'verify_status',
        width: 50,
        disableSort: true,
      },
      {
        Header: t('table:Rejected reason'),
        accessor: ({ reject_message }: any) => {
          return reject_message;
        },
        id: 'reject_reason',
        width: 150,
        disableSort: true,
      },
    ];
  }, [t, param.page, param.limit, param]);

  return (
    <div>
      <MetaTags>
        <title>{t('Currency Approval')}</title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card className="h-100">
              <CardBody className="h-100">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <PageSizeOptions
                    defaultValue={10}
                    handlePageSizeChange={handleChangePageSize}
                  />
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SearchBar
                      placeholder={t('table:Search name')}
                      handleSearch={handleSearch}
                      handleClear={handleClear}
                      disablePadding
                    />
                  </div>
                </div>
                {/*dsdw */}
                <CardBody>
                  <CustomTable
                    columns={columns || []}
                    data={marketerOwnCurrencies?.items || []}
                    page={param.page}
                    pageSize={param.limit}
                    total={marketerOwnCurrencies?.totalCount}
                    handlePageChange={handlePageChange}
                    headerCSS="fw-normal bg-header-table"
                  />
                </CardBody>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RequestOwnCurrencyHistory;
