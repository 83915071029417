import { Row, Col } from 'reactstrap';

import { has } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import noCampaign from 'src/assets/images/no-campaign-image.png';
import { getDepositAddresses } from 'src/graphql/advertiser/depositAddress';
import { setLoading, unsetLoading } from 'src/store/actions';
import DepositAddress from './DepositAddress';
import SearchBar from '../Table/SearchBar';
import { getDepositAddress as onGetDepositAddresses } from '../../store/finance/depositAddresses/actions';

const DepositAddressesNew = ({ t, addresses }: any) => {
  const dispatch = useDispatch();
  const array: any = [];
  const [param, setParam] = useState<any>({
    search: '',
  });

  const [_addresses, setAddresses] = useState(addresses);
  const [addressList, setAddressList] = useState<any>([]);

  const { _addressList } = useSelector((state: any) => ({
    _addressList: state.DepositAddresses.addresses,
  }));

  const notFound = () => {
    return (
      <div className="mid">
        <img src={noCampaign} alt="" />
        <div className="no-camaign-title mt-2"> no coin to show</div>
      </div>
    );
  };

  const fetchDepositAddress = async (payload: any) => {
    try {
      dispatch(setLoading());
      const response = await getDepositAddresses(payload);
      setAddressList(response);
    } catch (error) {
      setAddressList([]);
    } finally {
      dispatch(unsetLoading());
    }
  };

  useEffect(() => {
    fetchDepositAddress(param);
  }, []);

  useEffect(() => {
    if (addressList) {
      setAddresses(addressList);
    }
  }, [addressList]);

  const renderAddress = () => {
    const html: any = [];
    _addresses.map((address: any) => {
      if (!has(array, address.currency.main_symbol)) {
        array[address.currency.main_symbol] = [];
        array[address.currency.main_symbol].push(address);
      } else {
        array[address.currency.main_symbol].push(address);
      }
      return true;
    });
    // eslint-disable-next-line guard-for-in
    Object.keys(array).forEach((key: any) => {
      html.push(
        <Col xl={6}>
          <DepositAddress addresses={array[key]} key={key} />
        </Col>,
      );
    });

    return html;
  };
  const handleSearch = async (name: string) => {
    const payload = {
      ...param,
      search: name,
    };
    // dispatch(onGetDepositAddresses(payload));
    await fetchDepositAddress(payload);
    setParam(payload);
  };

  const handleClear = async () => {
    const payload = {
      ...param,
      search: '',
    };
    // dispatch(onGetDepositAddresses(payload));
    await fetchDepositAddress(payload);
    setParam(payload);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '30px',
        }}
      >
        <SearchBar
          placeholder={t('table:Search name')}
          handleSearch={handleSearch}
          handleClear={handleClear}
          disablePadding
        />
      </div>
      <Row className="g-0" />
      <Row className="g-0">{renderAddress()}</Row>
      {!addressList?.length && notFound()}
    </>
  );
};

export default withTranslation()(DepositAddressesNew);
