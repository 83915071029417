import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { get, map } from 'lodash';
import { withTranslation } from 'react-i18next';
import { Arrow_down as ArrowDown } from 'src/assets/images/other/arrow-icon';

// i18n
import languages from '../../../common/languages';

const LanguageDropdownLeft = (props: any) => {
  // Declare a new state variable, which we'll call "menu"
  const [selectedLang, setSelectedLang] = useState<string>('');
  const [menu, setMenu] = useState<boolean>(false);

  useEffect(() => {
    const currentLanguage: any = localStorage.getItem('I18N_LANGUAGE');
    setSelectedLang(currentLanguage);
  }, []);

  const changeLanguageAction = (lang: string) => {
    localStorage.setItem('I18N_LANGUAGE', lang);
    setSelectedLang(lang);
    window.location.reload();
  };

  const toggle = () => {
    setMenu(!menu);
  };

  const [language, setLanguage] = useState(false);
  const [languageState, setLanguageState] = useState('TH');

  // console.log('languages', languages);
  return (
    <>
      <div
        style={{ display: 'flex', cursor: 'pointer' }}
        onClick={() => {
          setLanguage(!language);
        }}
        className="fs-5 "
      >
        {selectedLang.toUpperCase()}
        <div style={{ marginLeft: 10, color: 'white' }}>
          <i className={`fa fa-chevron-${!language ? 'right' : 'down'}`} />
        </div>
      </div>
      {language && (
        <div
          style={{
            marginLeft: '20px',
            marginTop: '5px',
            border: '1px solid',
          }}
        >
          <div
            onClick={async () => {
              await changeLanguageAction('th');
              setLanguageState('TH');
              setLanguage(false);
            }}
            style={{
              background: 'white',
              padding: '10px',
              color: 'black',
              borderBottom: '1px solid black',
              cursor: 'pointer',
            }}
          >
            TH
          </div>
          <div
            onClick={async () => {
              await changeLanguageAction('en');
              setLanguageState('EN');
              setLanguage(false);
            }}
            style={{
              background: 'white',
              padding: '10px',
              color: 'black',
              border: '1px #c1bdbdf',
              cursor: 'pointer',
            }}
          >
            EN
          </div>
        </div>
      )}
    </>
  );
};

export default withTranslation()(LanguageDropdownLeft);
