import SocialMediaInfoTypes from './actionTypes';
import { Aptitude, KeywordAptitude } from './reducer';

export const getSocialMediaInfo = () => ({
  type: SocialMediaInfoTypes.GET_SOCIAL_MEDIA_INFO,
});

export const getSocialMediaInfoSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_SOCIAL_MEDIA_INFO_SUCCESS,
  payload,
});

export const getOneSocialMediaInfo = (key: string) => ({
  type: SocialMediaInfoTypes.GET_ONE_SOCIAL_MEDIA_INFO,
  key,
});

export const getOneSocialMediaInfoSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_ONE_SOCIAL_MEDIA_INFO_SUCCESS,
  payload,
});

export const getYoutubeVideoData = (path: string, social_key: string) => ({
  type: SocialMediaInfoTypes.GET_YOUTUBE_VIDEO_DATA,
  path,
  social_key,
});

export const getDataSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_SOCIAL_POST_SUCCESS,
  payload,
});

// export const getTwitterPostData = (path: string, social_key: string) => ({
//   type: SocialMediaInfoTypes.GET_TWITTER_DATA,
//   path,
//   social_key,
// });

export const socialJobCardData = (payload: any) => ({
  type: SocialMediaInfoTypes.SOCIAL_JOB_CARD_DATA,
  payload,
});

export const getTiktokPostData = (social_key: string, path: string) => ({
  type: SocialMediaInfoTypes.GET_TIKTOK_DATA,
  social_key,
  path,
});

export const getFacebookData = (social_key: string, path: string) => ({
  type: SocialMediaInfoTypes.GET_FACEBOOK_DATA,
  social_key,
  path,
});

export const getInstagramData = (social_key: string, path: string) => ({
  type: SocialMediaInfoTypes.GET_INSTAGRAM_DATA,
  social_key,
  path,
});

export const getSocialJobByJobKey = (input: any) => ({
  type: SocialMediaInfoTypes.GET_SOCIAL_JOB_BY_JOB_KEY,
  input,
});

export const getSocialJobByJobKeySuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_SOCIAL_JOB_BY_JOB_KEY_SUCCESS,
  payload,
});

export const getSocialJob = (key: string) => ({
  type: SocialMediaInfoTypes.GET_SOCIAL_JOB,
  key,
});

export const getSocialJobSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_SOCIAL_JOB_SUCCESS,
  payload,
});

export const resetSocialJob = () => ({
  type: SocialMediaInfoTypes.RESET_SOCIAL_JOB,
});

export const filterSocialJob = (payload: any) => ({
  type: SocialMediaInfoTypes.FILTER_SOCIAL_JOB,
  payload,
});

export const filterSocialJobSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.FILTER_SOCIAL_JOB_SUCCESS,
  payload,
});

export const getAllAptitude = () => ({
  type: SocialMediaInfoTypes.GET_ALL_APTITUDE,
});

export const getAllAptitudeSuccess = (payload: Aptitude[]) => ({
  type: SocialMediaInfoTypes.GET_ALL_APTITUDE_SUCCESS,
  payload,
});

export const getAllAptitudeFail = () => ({
  type: SocialMediaInfoTypes.GET_ALL_APTITUDE_FAIL,
});

export const getAllKeywordAptitude = () => ({
  type: SocialMediaInfoTypes.GET_ALL_KEYWORD_APTITUDE,
});

export const getAllKeywordAptitudeSuccess = (payload: KeywordAptitude[]) => ({
  type: SocialMediaInfoTypes.GET_ALL_KEYWORD_APTITUDE_SUCCESS,
  payload,
});

export const getAllKeywordAptitudeFail = () => ({
  type: SocialMediaInfoTypes.GET_ALL_KEYWORD_APTITUDE_FAIL,
});

export const getInfluencerPriceRate = () => ({
  type: SocialMediaInfoTypes.GET_INFLUENCER_PRICE_RATE,
});

export const getInfluencerPriceRateSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_INFLUENCER_PRICE_RATE_SUCCESS,
  payload,
});

export const getInfluencerRequestNewPrice = (key: string) => ({
  type: SocialMediaInfoTypes.GET_INFLUENCER_REQUEST_NEW_PRICE,
  key,
});

export const getInfluencerRequestNewPriceSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_INFLUENCER_REQUEST_NEW_PRICE_SUCCESS,
  payload,
});

export const socialMediaFilterTable = (payload: any) => ({
  type: SocialMediaInfoTypes.SOCIAL_MEDIA_FILTER_TABLE,
  payload,
});

export const socialMediaFilterTableSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.SOCIAL_MEDIA_FILTER_TABLE_SUCCESS,
  payload,
});

export const requestNewPriceFilterTable = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_REQUEST_NEW_PRICE_FILTER_TABLE,
  payload,
});

export const requestNewPriceFilterTableSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_REQUEST_NEW_PRICE_FILTER_TABLE_SUCCESS,
  payload,
});

export const socialJobSummary = () => ({
  type: SocialMediaInfoTypes.SOCIAL_JOB_SUMMARY,
});

export const socialJobSummarySuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.SOCIAL_JOB_SUMMARY_SUCCESS,
  payload,
});

export const getSocialMediaInfoByUUID = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_SOCIAL_MEDIA_INFO_By_UUID,
  payload,
});

export const getSocialMediaInfoByUUIDSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_SOCIAL_MEDIA_INFO_By_UUID_SUCCESS,
  payload,
});

export const getExpiredSocialMediaToken = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_EXPIRED_SOCIAL_MEDIA_TOKEN,
  payload,
});

export const getExpiredSocialMediaTokenSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_EXPIRED_SOCIAL_MEDIA_TOKEN_SUCCESS,
  payload,
});

export const clearExpiredSocialMediaToken = () => ({
  type: SocialMediaInfoTypes.CLEAR_EXPIRED_SOCIAL_MEDIA_TOKEN,
});

export const socialJobCount = () => ({
  type: SocialMediaInfoTypes.SOCIAL_JOB_COUNT,
});

export const socialJobCountSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.SOCIAL_JOB_COUNT_SUCCESS,
  payload,
});

export const requestDeleteSocialMediaFilterTable = (payload: any) => ({
  type: SocialMediaInfoTypes.REQUEST_DELETE_SOCIAL_MEDIA_FILTER_TABLE,
  payload,
});

export const requestDeleteSocialMediaFilterTableSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.REQUEST_DELETE_SOCIAL_MEDIA_FILTER_TABLE_SUCCESS,
  payload,
});

export const getSocialMediaInfoGroupByAptitude = () => ({
  type: SocialMediaInfoTypes.GET_SOCIAL_MEDIA_INFO_GROUP_BY_APTITUDE,
});

export const getSocialMediaInfoGroupByAptitudeSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_SOCIAL_MEDIA_INFO_GROUP_BY_APTITUDE_SUCCESS,
  payload,
});

export const getRecommendContentPrice = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_RECOMMEND_CONTENT_PRICE,
  payload,
});

export const getRecommendContentPriceSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_RECOMMEND_CONTENT_PRICE_SUCCESS,
  payload,
});

export const updateSocialMediaInfoFrom3rdParty = (payload: any) => ({
  type: SocialMediaInfoTypes.UPDATE_SOCIAL_MEDIA_INFO_FROM_3RD_PARTY,
  payload,
});

export const updateSocialMediaInfoFrom3rdPartySuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.UPDATE_SOCIAL_MEDIA_INFO_FROM_3RD_PARTY_SUCCESS,
  payload,
});

export const filterSocialGleamJob = (payload: any) => ({
  type: SocialMediaInfoTypes.FILTER_SOCIAL_GLEAM_JOB,
  payload,
});

export const filterSocialGleamJobSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.FILTER_SOCIAL_GLEAM_JOB_SUCCESS,
  payload,
});

export const getSocialGleamJobByJobKey = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_SOCIAL_GLEAM_JOB_BY_JOB_KEY,
  payload,
});

export const getSocialGleamJobByJobKeySuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_SOCIAL_GLEAM_JOB_BY_JOB_KEY_SUCCESS,
  payload,
});

export const getSocialGleamJob = (key: any) => ({
  type: SocialMediaInfoTypes.GET_SOCIAL_GLEAM_JOB_FOR_WORK,
  key,
});

export const getSocialGleamJobSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_SOCIAL_GLEAM_JOB_FOR_WORK_SUCCESS,
  payload,
});

export const getSocialGleamJobReviewByJobKey = (param: any) => ({
  type: SocialMediaInfoTypes.GET_SOCIAL_GLEAM_JOB_REVIEW_BY_JOB_KEY,
  param,
});

export const getSocialGleamJobReviewByJobKeySuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_SOCIAL_GLEAM_JOB_REVIEW_BY_JOB_KEY_SUCCESS,
  payload,
});

export const marketerSumPendingBalance = () => ({
  type: SocialMediaInfoTypes.MARKETER_SUM_PENDING_BALANCE,
});

export const marketerSumPendingBalanceSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.MARKETER_SUM_PENDING_BALANCE_SUCCESS,
  payload,
});

export const getSocialGleamJobForReview = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_SOCIAL_GLEAM_JOB_FOR_REVIEW,
  payload,
});

export const getSocialGleamJobForReviewSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_SOCIAL_GLEAM_JOB_FOR_REVIEW_SUCCESS,
  payload,
});

export const influencerActivePendingJobCount = () => ({
  type: SocialMediaInfoTypes.INFLUENCER_ACTIVE_PENDING_JOB_COUNT,
});

export const influencerActivePendingJobCountSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.INFLUENCER_ACTIVE_PENDING_JOB_COUNT_SUCCESS,
  payload,
});

export const reportJobDecline = (key: any) => ({
  type: SocialMediaInfoTypes.REPORT_JOB_DECLINE,
  key,
});

export const reportJobDeclineSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.REPORT_JOB_DECLINE_SUCCESS,
  payload,
});

export const getReportJobDecline = (key: any) => ({
  type: SocialMediaInfoTypes.GET_REPORT_JOB_DECLINE,
  key,
});

export const getReportJobDeclineSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_REPORT_JOB_DECLINE_SUCCESS,
  payload,
});

export const getAdminReportJobDecline = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_ADMIN_REPORT_JOB_DECLINE,
  payload,
});

export const getAdminReportJobDeclineSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_ADMIN_REPORT_JOB_DECLINE_SUCCESS,
  payload,
});

export const adminUpdateReportDecline = (payload: any) => ({
  type: SocialMediaInfoTypes.ADMIN_UPDATE_REPORT_DECLINE,
  payload,
});

export const adminUpdateReportDeclineSuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.ADMIN_UPDATE_REPORT_DECLINE_SUCCESS,
  payload,
});

export const getSocialGleamJobByKey = (key: any) => ({
  type: SocialMediaInfoTypes.GET_SOCIAL_GLEAM_JOB_BY_KEY,
  key,
});

export const getSocialGleamJobByKeySuccess = (payload: any) => ({
  type: SocialMediaInfoTypes.GET_SOCIAL_GLEAM_JOB_BY_KEY_SUCCESS,
  payload,
});
