import { useState, ChangeEvent, useEffect } from 'react';
import stepCreate from 'src/assets/images/Step1.png';
import BTC from 'src/assets/images/crypto/btc.svg';
import { floorToFixed } from 'src/common/data/mathToFixed';
import CoinImage from 'src/components/Common/CoinImage';

interface Option {
  id: number;
  is_marketer_currency: boolean;
  network: string;
  price_in_USDT: number;
  symbol: string;
}

interface DropdownSearchProps {
  options: any;
  formData: any;
  setFormData: any;
  currencySymbol: any;
  jobId: any;
}
// eslint-disable-next-line react/prop-types
const DropdownSearch = ({
  options,
  formData,
  setFormData,
  currencySymbol,
  jobId,
}: DropdownSearchProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [searchValueOutput, setSearchValueOutput] = useState(
    jobId ? currencySymbol : 'USDT_BEP20',
  );

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  const x = true;

  return (
    <div className="dropdown" style={{ display: 'flex', flexDirection: 'row' }}>
      <input
        className="form-control"
        type="text"
        disabled={x}
        value={currencySymbol}
        onClick={handleToggle}
      />
      <button
        className="btn btn-primary dropdown-toggle"
        type="button"
        onClick={handleToggle}
      >
        <i
          onClick={() => handleToggle}
          className={`${isOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}`}
        />
      </button>
      {isOpen && (
        <div className="dropdown-menu show" style={{ width: '100%' }}>
          <input
            className="form-control"
            type=""
            placeholder="Search..."
            value={searchValue}
            onChange={handleSearchChange}
          />
          {options.map((option: any) => (
            <button
              type="button"
              className="dropdown-item"
              key={option.currency.id}
              onClick={() => {
                setSearchValueOutput(option.currency.symbol);
                setFormData({ ...formData, currency_id: option.currency.id });
                setIsOpen(false);
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <CoinImage
                    symbol={option.currency.symbol.split('_')[0]}
                    size="md"
                  />
                  {'   '}
                  <span style={{ marginLeft: '5px' }}>{option.currency.symbol}</span>
                  <span style={{ marginLeft: '5px' }}>
                    ({floorToFixed(option.wallet.balance, 2)}{' '}
                    {option.currency.main_symbol})
                  </span>
                </div>
                <div style={{ marginLeft: '5px' }}>
                  {option?.currency.is_marketer_currency
                    ? floorToFixed(option.currency.price_in_USDT, 12)
                    : floorToFixed(option?.currency.rate_in_USD, 12) ?? 1}{' '}
                  USDT
                </div>
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownSearch;
