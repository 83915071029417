import { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import paramsFromURL from 'src/constants/paramsFromURL';
import {
  getLoggedInUser,
  getSocialGleamJobByJobKey,
  getUserWalletByCurrency,
  setLoading,
  unsetLoading,
} from 'src/store/actions';
import Pagination from '@mui/material/Pagination';
import choice from 'src/assets/images/choice.png';
import userAvatar from 'src/assets/images/users/avatar-1.jpg';
import { floorToFixed } from 'src/common/data/mathToFixed';
import Swal from 'sweetalert2';
import {
  approveSocialGleamJob,
  autoAcceptJobRequest,
  rejectSocialGleamJob,
  rejectSocialJob,
  socialHasGleamJobRequestByJobKeyGQL,
  updateSocialMediaHasJobGql,
} from 'src/graphql/advertiser/socialMediaInfo';
import notify from 'src/utils/notify';
import i18n from 'src/i18n';
import { Link } from 'react-router-dom';
import { Dialog, DialogContent, Tooltip } from '@mui/material';
import { formatDateForm } from 'src/utils/dateTime';
import Role from 'src/constants/enum/role';
import JobStatus from 'src/constants/enum/socialMediaHasJob_JobStatus';
import { Approve, Pending, Reject } from './approval/approvalStatus';
import { InfluencerSocialIcon } from './status/InfluencerJobStatus';
import CustomTableCampaign from './CustomTable copy';

const GleamJobRequestTable = (prop: any) => {
  const { job } = prop;
  const { t } = useTranslation();
  const [jobId]: any = paramsFromURL(['job']);
  const isPublish = job.job_type === 'PUBLISH';
  const dispatch = useDispatch();
  const [dataResult, setDataDefualt] = useState<any>([]);
  const [socialHasJobRequest, setSocialHasJobRequest] = useState<any>({});
  const { _socialHasJobRequest, wallet, user } = useSelector((state: any) => ({
    _socialHasJobRequest: state.SocialMediaInfo.socialHasJobRequest,
    wallet: state?.Wallet?.wallet,
    user: state.login.user,
  }));
  useEffect(() => {
    if (job) {
      if (isPublish && job?.currency?.id) {
        dispatch(getUserWalletByCurrency(job?.currency?.id));
      }
    }
  }, [job]);
  const [data, setData] = useState<any>({});
  const [param, setParam] = useState<any>({
    limit: 10,
    page: 1,
    job_key: jobId,
    sortBy: '',
    sortType: '',
    verify_status: null,
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [socialInfoHasJob, setSocialInfoHasJob] = useState<any>([]);
  const [jobSymbol, setJobSymbol] = useState('');
  const [selectedIndex, setSelectedIndex] = useState<any>(0);
  const [autoAccpect, setAutoAccpect] = useState<number>(0);
  const [mostFollowerBudget, setBudget] = useState(0);
  const balance = wallet?.balance || 0;

  const Status = [
    {
      value: 'PENDING',
      label: t('table:PENDING'),
    },
    { value: 'APPROVED', label: 'Active' },
    { value: 'REJECTED', label: t('table:REJECTED') },
    { value: null, label: t('All') },
  ];

  const fetchSocialGleamJobByJobKey = async (payload: any) => {
    try {
      dispatch(setLoading());
      const response = await socialHasGleamJobRequestByJobKeyGQL(payload);
      setSocialHasJobRequest(response);
    } catch (error) {
      setSocialHasJobRequest({});
    } finally {
      dispatch(unsetLoading());
    }
  };

  useEffect(() => {
    fetchSocialGleamJobByJobKey(param);
    //dispatch(getSocialGleamJobByJobKey(param));
    // dispatch(getLoggedInUser());
    if (isPublish && job?.currency?.id) {
      dispatch(getUserWalletByCurrency(job?.currency?.id));
    }
  }, []);

  useEffect(() => {
    if (socialHasJobRequest?.items) {
      setData(socialHasJobRequest);
      // setSocialInfoHasJob(
      //   socialHasJobRequest?.items[selectedIndex]?.social_media_info_has_job,
      // );
    }
  }, [socialHasJobRequest]);

  useEffect(() => {
    if (data?.items?.length > 0) {
      // const dataResultSort = data?.items.sort((a: any, b: any) => {
      //   const dateA = new Date(
      //     a.social_media_info_has_job[0]?.social_media_has_job?.items[0]?.accept_date,
      //   ).getTime();
      //   const dateB = new Date(
      //     b.social_media_info_has_job[0]?.social_media_has_job?.items[0]?.accept_date,
      //   ).getTime();
      //   return dateA - dateB;
      // });
      setDataDefualt([...data?.items]);
    }
  }, [data]);

  const handlePageChange = async (e: any, value: any) => {
    const newPage = value ? parseInt(value, 10) : param.page;
    const payload = {
      ...param,
      page: newPage,
    };
    // dispatch(getSocialGleamJobByJobKey(payload));
    await fetchSocialGleamJobByJobKey(payload);
    setParam(payload);
  };

  const handleStatusChange = async (event: any) => {
    const payload = {
      ...param,
      page: 1,
      verify_status: event.value,
    };
    // dispatch(getSocialGleamJobByJobKey(payload));
    await fetchSocialGleamJobByJobKey(payload);
    setParam(payload);
  };

  const renderStatus = (status: string) => {
    if (status === 'DRAFT') {
      return t('PENDING');
    } else if (status === 'CANCEL') {
      return t('REJECTED');
    } else {
      return t('ACTIVE');
    }
  };

  const renderVerifyStatus = (
    status: any,
    key: string,
    price: number,
    tax: number,
    social_key: any,
  ) => {
    if (status === 'DRAFT') {
      if (user?.role === Role.ADMIN) {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Pending message="Pending" />
          </div>
        );
      } else {
        return (
          <UncontrolledDropdown className="">
            <DropdownToggle
              className="text-muted font-size-28 cursor-pointer"
              tag="a"
              style={{ cursor: 'pointer' }}
            >
              <i
                className="bx bx-pencil cursor-pointer"
                style={{ cursor: 'pointer' }}
              />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                onClick={async () => {
                  clickVerifyStatus('APPROVED', key, price, tax, social_key);
                }}
                className="text-success"
              >
                <i className="mdi mdi-account-check text-success" />
                &nbsp;&nbsp;{t('table:Accept')}
              </DropdownItem>
              <DropdownItem
                onClick={() => clickRejected(key, social_key)}
                className="text-danger"
              >
                <i className="mdi mdi-account-cancel text-danger" />
                &nbsp;&nbsp;{t('table:Reject')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      }
    } else {
      if (status === 'CANCEL') {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Reject message="Rejected" />
          </div>
        );
      } else {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Approve message="Accept" />
          </div>
        );
      }
    }
  };

  const clickRejected = async (key: string, social_key: any) => {
    await Swal.fire({
      title: t('Reject'),
      text: t(`Tell influcencer why you reject ( If any )`),
      input: 'textarea',
      inputPlaceholder: 'input here',
      inputAttributes: {
        'aria-label': 'Type your message here',
      },
      width: 600,
      imageHeight: 200,
      imageAlt: 'A tall image',
      showCancelButton: true,
      cancelButtonColor: 'red',
      confirmButtonColor: '#009a78',
      customClass: {
        container: 'accept-swal',
      },
      confirmButtonText: t('swal:confirm'),
      cancelButtonText: t('swal:Cancel'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(setLoading());
        const reason = result.value;
        if (job.is_gleam_job) {
          await rejectSocialGleamJob({
            key,
            reject_message: reason,
            social_key,
          });
        } else {
          await rejectSocialJob(key, reason);
        }
        //dispatch(getSocialGleamJobByJobKey(param));
        await fetchSocialGleamJobByJobKey(param);
        notify(i18n.t('success:reject influencer has accepted.'), 'success');
        Swal.fire('Success');
        dispatch(unsetLoading());
      }
    });
  };

  const clickVerifyStatus = async (
    verify: string,
    key: string,
    price: number,
    tax: number,
    social_key: any,
  ) => {
    await Swal.fire({
      title: t('swal:Are you sure ?'),
      text: t(`swal:Do you want to ${verify} !`),
      imageUrl: choice,
      width: 300,
      imageHeight: 200,
      imageAlt: 'A tall image',
      showCancelButton: true,
      cancelButtonColor: 'red',
      confirmButtonColor: '#009a78',
      customClass: {
        container: 'accept-swal',
      },
      confirmButtonText: t(`button:Yes`),
      cancelButtonText: t('swal:Cancel'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (balance - (price + tax) < 0) {
          notify(i18n.t('error:your balance is not enough.'), 'error');
        } else {
          dispatch(setLoading());
          if (job.is_gleam_job) {
            const res = await approveSocialGleamJob({
              key,
              approve_job_date: new Date(),
              social_key,
            });
            if (res) {
              //dispatch(getSocialGleamJobByJobKey(param));
              await fetchSocialGleamJobByJobKey(param);
              dispatch(getUserWalletByCurrency(job?.currency?.id));
              dispatch(unsetLoading());
              notify(i18n.t('success:approve influencer has accepted.'), 'success');
            } else {
              dispatch(unsetLoading());
              notify(
                i18n.t('error:approve influencer has accepted has not accept .'),
                'error',
              );
            }
          } else {
            const res = await updateSocialMediaHasJobGql({
              key,
              approve_job_date: new Date(),
            });
            if (res) {
              // dispatch(getSocialGleamJobByJobKey(param));
              await fetchSocialGleamJobByJobKey(param);
              dispatch(getUserWalletByCurrency(job?.currency?.id));
              dispatch(unsetLoading());
              notify(i18n.t('success:approve influencer has accepted.'), 'success');
            } else {
              dispatch(unsetLoading());
              notify(
                i18n.t('error:approve influencer has accepted has not accept .'),
                'error',
              );
            }
          }
        }
      }
    });
  };

  const singleSumPrice = (social_media_has_job: any, symbol: any) => {
    let priceTax = 0;
    for (let i = 0; i < social_media_has_job.items?.length; i++) {
      priceTax +=
        social_media_has_job.items[i].price + social_media_has_job.items[i].tax;
    }
    return `${floorToFixed(priceTax, 2)} ${symbol ?? 'USDT'}`;
  };

  const openSocialsDetailChannel = (
    social_info_has_job: any,
    symbol: any,
    index: any,
  ) => {
    setSocialInfoHasJob(social_info_has_job);
    setJobSymbol(symbol ?? 'USDT');
    setSelectedIndex(index);
    setModalOpen(true);
  };

  const onAutuAccept = async () => {
    try {
      dispatch(setLoading());
      const response = await autoAcceptJobRequest({
        job_key: jobId,
        quantity: autoAccpect,
        approve_job_date: new Date(),
      });
      if (response.is_success) {
        notify(response.message, 'success');
        //dispatch(getSocialGleamJobByJobKey(param));
        await fetchSocialGleamJobByJobKey(param);
      } else {
        notify(response.message, 'error');
      }
      dispatch(unsetLoading());
    } catch (error: any) {
      dispatch(unsetLoading());
      notify(error?.message, 'error');
    } finally {
      setModalOpen(false);
    }
  };

  const displayModal = () => {
    // eslint-disable-next-line no-unused-vars
    // const { isOpen, link_post, social_media_info } = postModalData;
    return (
      <Dialog
        maxWidth={false}
        onClose={handleClose}
        open={isModalOpen}
        disableEnforceFocus
      >
        <DialogContent style={{ cursor: 'pointer' }}>
          <div
            style={{
              maxHeight: '480px',
              minWidth: '720px',
            }}
          >
            <div
              style={{
                margin: '10px 3% 0 3%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <span>
                    {t('Influencers can accept')}: {socialInfoHasJob?.length},{' '}
                  </span>
                  {t('Total budget')}: {mostFollowerBudget.toFixed(2)} {jobSymbol}{' '}
                  {wallet.symbol}
                  {balance < mostFollowerBudget && (
                    <span style={{ color: 'red' }}>({t('not enough')})</span>
                  )}
                </div>
                <div>
                  <button
                    type="submit"
                    className="form-control"
                    disabled={
                      balance < mostFollowerBudget || socialInfoHasJob?.length === 0
                    }
                    onClick={async () => {
                      await onAutuAccept();
                    }}
                    style={{
                      width: 'fit-content',
                      border: '1px solid white ',
                      borderRadius: '10px',
                      padding: '5px 20px',
                      fontWeight: 'bold',
                      color: `${balance < mostFollowerBudget ? 'grey' : 'white'}`,
                      background: 'linear-gradient(104deg,#6B99CA, #6BC6B3)',
                    }}
                  >
                    {t('Accept')}
                  </button>
                </div>
              </div>
              <div
                style={{
                  overflowX: 'auto',
                  overflowY: 'auto',
                  paddingBottom: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  //flex: '0 0 100%',
                  margin: '10px 0 0 0',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#e9e9ef',
                    borderRadius: '6px 6px 0 0',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '13px 0',
                  }}
                >
                  <div className="col-center" style={{ width: '140px' }}>
                    {t('table:Influencer photo')}
                  </div>
                  <div className="col-center" style={{ width: '140px' }}>
                    {t('table:Influencer name')}
                  </div>
                  <div className="col-center" style={{ width: '100px' }}>
                    {t('table:Social')}
                  </div>
                  {job.job_type === 'PUBLISH' && (
                    <div className="col-center" style={{ width: '90px' }}>
                      {t('table:Match')}
                    </div>
                  )}
                  <div className="col-center" style={{ width: '90px' }}>
                    {t('table:Influencer follower')}
                  </div>
                  <div className="col-center" style={{ width: '190px' }}>
                    {t('table:Price')}
                  </div>
                  <div className="col-center" style={{ width: '90px' }}>
                    {t('table:Task')}
                  </div>
                  <div className="col-center" style={{ width: '140px' }}>
                    {t('table:Status')}
                  </div>
                </div>
                {socialInfoHasJob?.map((social: any) => {
                  return (
                    <div style={{ marginTop: '5px' }}>
                      <div className="gleam-job-container-detail">
                        <div className="col-center" style={{ width: '140px' }}>
                          <Link
                            to={`/InfluencerChannel?channel=${social?.social_media_info_has_job[0]?.social_media_info?.key}`}
                            target="_blank"
                            style={{ color: '#495057' }}
                          >
                            <img
                              src={
                                social?.social_media_info_has_job[0]
                                  ?.social_media_info?.image_profile_url ||
                                userAvatar
                              }
                              alt="icons"
                              className="image-icon"
                              style={{
                                width: '30px',
                                height: '30px',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                borderRadius: '50%',
                                objectFit: 'cover',
                              }}
                            />
                          </Link>
                        </div>
                        <div className="col-center" style={{ width: '140px' }}>
                          <Link
                            to={`/InfluencerChannel?channel=${social?.social_media_info_has_job[0]?.social_media_info?.key}`}
                            target="_blank"
                            style={{ color: '#495057' }}
                          >
                            {
                              social?.social_media_info_has_job[0]?.social_media_info
                                ?.name
                            }
                          </Link>
                        </div>
                        <div className="col-center" style={{ width: '100px' }}>
                          <Link
                            to={`/InfluencerChannel?channel=${social?.social_media_info_has_job[0]?.social_media_info?.key}`}
                            target="_blank"
                            style={{ color: '#495057' }}
                          >
                            <InfluencerSocialIcon
                              social_media_type={
                                social?.social_media_info_has_job[0]
                                  ?.social_media_info?.social_media_type
                              }
                              alt={
                                social?.social_media_info_has_job[0]
                                  ?.social_media_info?.social_media_types
                              }
                              size={24}
                            />
                          </Link>
                        </div>
                        <div className="col-center" style={{ width: '90px' }}>
                          <Link
                            to={`/InfluencerChannel?channel=${social?.social_media_info_has_job[0]?.social_media_info?.key}`}
                            target="_blank"
                            style={{ color: '#495057' }}
                          >
                            {social.is_match ? (
                              <i className="fas fa-check" />
                            ) : (
                              <i className="fas fa-ban" />
                            )}
                          </Link>
                        </div>
                        <div className="col-center" style={{ width: '90px' }}>
                          <Link
                            to={`/InfluencerChannel?channel=${social?.social_media_info_has_job[0]?.social_media_info?.key}`}
                            target="_blank"
                            style={{ color: '#495057' }}
                          >
                            {
                              social?.social_media_info_has_job[0]?.social_media_info
                                ?.follower
                            }
                          </Link>
                        </div>
                        <div className="col-center" style={{ width: '190px' }}>
                          <Link
                            to={`/InfluencerChannel?channel=${social?.social_media_info_has_job[0]?.social_media_info?.key}`}
                            target="_blank"
                            style={{ color: '#495057' }}
                          >
                            {singleSumPrice(
                              social?.social_media_info_has_job[0]
                                ?.social_media_has_job,
                              social.symbol,
                            )}
                          </Link>
                        </div>
                        <div className="col-center" style={{ width: '90px' }}>
                          <Link
                            to={`/InfluencerChannel?channel=${social?.social_media_info_has_job[0]?.social_media_info?.key}`}
                            target="_blank"
                            style={{ color: '#495057' }}
                          >
                            {
                              social?.social_media_info_has_job[0]
                                ?.social_media_has_job?.items?.length
                            }
                          </Link>
                        </div>
                        <div className="col-center" style={{ width: '140px' }}>
                          <Link
                            to={`/InfluencerChannel?channel=${social.key}`}
                            target="_blank"
                            style={{ color: '#495057' }}
                          >
                            {renderStatus(
                              social?.social_media_info_has_job[0]
                                ?.social_media_has_job.items[0].status,
                            )}
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const verify_column = (
    item: any,
    key: string,
    is_1_social: boolean,
    index: any,
  ) => {
    if (is_1_social) {
      return (
        <Col md="1" className="col-center">
          {renderVerifyStatus(
            item.status,
            key,
            item.price,
            item.tax,
            item.social_media_info_has_job[0].social_media_info.key,
          )}
        </Col>
      );
    } else {
      return (
        <Col
          md="1"
          className="col-center"
          onClick={() => {
            openSocialsDetailChannel(
              item.social_media_info_has_job,
              item.symbol,
              index,
            );
          }}
        >
          More Detail
        </Col>
      );
    }
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSort = useCallback(
    (sorting: any) => {
      const newSortBy = sorting[0] ? sorting[0].id : '';
      const newSortType = sorting[0] && sorting[0].desc ? 'DESC' : 'ASC';
      const newPage =
        newSortBy === param.sortBy && newSortType === param.sortType
          ? param.page
          : 1;
      const payload = {
        ...param,
        page: newPage,
        sortBy: newSortBy,
        sortType: newSortType,
      };
      // dispatch(getSocialGleamJobByJobKey(payload));
      fetchSocialGleamJobByJobKey(payload);
      setParam(payload);
    },
    [param.page, param.limit],
  );

  const columns = useMemo(() => {
    return [
      {
        Header: t('table:Influencer photo'),
        accessor: (
          {
            social_media_info_has_job,
            social_media_key,
            image_profile_url,
            symbol,
          }: any,
          index: number,
        ) => {
          const profile_path = job.is_gleam_job
            ? social_media_info_has_job[0].social_media_info.key
            : social_media_key;
          const is_1_social =
            !social_media_info_has_job || social_media_info_has_job?.length === 1;
          return (
            <div>
              {' '}
              {is_1_social ? (
                <Link
                  to={`/InfluencerChannel?channel=${profile_path}`}
                  target="_blank"
                >
                  {image_profile_url?.map((imageURL: any) => {
                    return (
                      <img
                        src={imageURL}
                        alt="icons"
                        className="image-icon"
                        style={{
                          width: '30px',
                          height: '30px',
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          borderRadius: '50%',
                          objectFit: 'cover',
                        }}
                        onError={({ currentTarget }) => {
                          // eslint-disable-next-line no-param-reassign
                          currentTarget.onerror = null;
                          // eslint-disable-next-line no-param-reassign
                          currentTarget.src = userAvatar;
                        }}
                      />
                    );
                  })}
                </Link>
              ) : (
                <div
                  onClick={() => {
                    openSocialsDetailChannel(
                      social_media_info_has_job,
                      symbol,
                      index,
                    );
                  }}
                >
                  {image_profile_url?.map((imageURL: any) => {
                    return (
                      <img
                        src={imageURL}
                        alt="icons"
                        className="image-icon"
                        style={{
                          width: '30px',
                          height: '30px',
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          borderRadius: '50%',
                          objectFit: 'cover',
                        }}
                        onError={({ currentTarget }) => {
                          // eslint-disable-next-line no-param-reassign
                          currentTarget.onerror = null;
                          // eslint-disable-next-line no-param-reassign
                          currentTarget.src = userAvatar;
                        }}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          );
        },
        width: 50,
        disableSort: true,
        id: 'photo',
      },
      {
        Header: t('table:Influencer name'),
        accessor: (
          { social_media_info_has_job, social_media_key, symbol, names }: any,
          index: number,
        ) => {
          const profile_path = job.is_gleam_job
            ? social_media_info_has_job[0].social_media_info.key
            : social_media_key;
          const is_1_social =
            !social_media_info_has_job || social_media_info_has_job?.length === 1;
          return (
            <div>
              {is_1_social ? (
                <div>
                  <Link
                    to={`/InfluencerChannel?channel=${profile_path}`}
                    target="_blank"
                    style={{ color: '#495057' }}
                  >
                    {names}
                  </Link>
                </div>
              ) : (
                <div
                  onClick={() => {
                    openSocialsDetailChannel(
                      social_media_info_has_job,
                      symbol,
                      index,
                    );
                  }}
                >
                  {names}
                </div>
              )}
            </div>
          );
        },
        width: 50,
        disableSort: true,
        id: 'Name',
      },
      {
        Header: t('table:Social'),
        accessor: (
          {
            social_media_info_has_job,
            social_media_key,
            symbol,
            social_media_types,
          }: any,
          index: number,
        ) => {
          const profile_path = job.is_gleam_job
            ? social_media_info_has_job[0].social_media_info.key
            : social_media_key;
          const is_1_social =
            !social_media_info_has_job || social_media_info_has_job?.length === 1;
          return (
            <div>
              {is_1_social ? (
                <div>
                  <Link
                    to={`/InfluencerChannel?channel=${profile_path}`}
                    target="_blank"
                  >
                    {social_media_types.map((social_type: any) => {
                      return (
                        <InfluencerSocialIcon
                          social_media_type={social_type}
                          alt={social_type}
                          size={24}
                        />
                      );
                    })}
                  </Link>
                </div>
              ) : (
                <div
                  onClick={() => {
                    openSocialsDetailChannel(
                      social_media_info_has_job,
                      symbol,
                      index,
                    );
                  }}
                >
                  {social_media_types.map((social_type: any) => {
                    return (
                      <InfluencerSocialIcon
                        social_media_type={social_type}
                        alt={social_type}
                        size={24}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          );
        },
        width: 50,
        disableSort: true,
        id: 'social',
      },
      {
        Header: () => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div> {t('table:Match')} </div>{' '}
              <Tooltip
                title={<h4 style={{ color: 'white' }}>{t('tooltip.Match')} </h4>}
                placement="right"
              >
                <i
                  className="fas fa-info-circle ms-2 font-size-16]"
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            </div>
          );
        },
        accessor: ({ is_match }: any) => {
          return (
            <div>
              {is_match ? (
                <i className="fas fa-check" />
              ) : (
                <i className="fas fa-ban" />
              )}
            </div>
          );
        },
        width: 50,
        disableSort: true,
        id: 'Match',
      },
      {
        Header: t('table:Influencer follower'),
        accessor: (
          { social_media_info_has_job, social_media_key, symbol }: any,
          index: number,
        ) => {
          const profile_path = job.is_gleam_job
            ? social_media_info_has_job[0].social_media_info.key
            : social_media_key;
          const is_1_social =
            !social_media_info_has_job || social_media_info_has_job?.length === 1;
          return (
            <div>
              {' '}
              {is_1_social ? (
                <div>
                  <Link
                    to={`/InfluencerChannel?channel=${profile_path}`}
                    target="_blank"
                    style={{ color: '#495057' }}
                  >
                    {social_media_info_has_job[0]?.social_media_info?.follower ?? 0}
                  </Link>
                </div>
              ) : (
                <div
                  onClick={() => {
                    openSocialsDetailChannel(
                      social_media_info_has_job,
                      symbol,
                      index,
                    );
                  }}
                >
                  {social_media_info_has_job[0]?.social_media_info?.follower ?? 0}
                </div>
              )}
            </div>
          );
        },
        width: 50,
        id: 'follower',
      },
      {
        Header: () => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div>{t('table:Prize')}</div>{' '}
              <Tooltip
                title={<h4 style={{ color: 'white' }}>{t('tooltip.Prize')} </h4>}
                placement="right"
              >
                <i
                  className="fas fa-info-circle ms-2 font-size-16]"
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            </div>
          );
        },
        accessor: (
          { social_media_info_has_job, social_media_key, symbol, price, tax }: any,
          index: number,
        ) => {
          const profile_path = job.is_gleam_job
            ? social_media_info_has_job[0].social_media_info.key
            : social_media_key;
          const is_1_social =
            !social_media_info_has_job || social_media_info_has_job?.length === 1;
          return (
            <div>
              {is_1_social ? (
                <div>
                  <Link
                    to={`/InfluencerChannel?channel=${profile_path}`}
                    target="_blank"
                    style={{ color: '#495057' }}
                  >
                    {floorToFixed(price + tax, 2)} {symbol}
                  </Link>
                </div>
              ) : (
                <div
                  onClick={() => {
                    openSocialsDetailChannel(
                      social_media_info_has_job,
                      symbol,
                      index,
                    );
                  }}
                >
                  {floorToFixed(price + tax, 2)} {symbol}
                </div>
              )}
            </div>
          );
        },
        width: 50,
        disableSort: true,
        id: 'rate',
      },
      {
        Header: () => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div> {t('table:Task')}</div>{' '}
              <Tooltip
                title={<h4 style={{ color: 'white' }}>{t('tooltip.Task')} </h4>}
                placement="right"
              >
                <i
                  className="fas fa-info-circle ms-2 font-size-16]"
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            </div>
          );
        },
        accessor: (
          {
            social_media_info_has_job,
            social_media_key,
            symbol,
            job_task_count,
          }: any,
          index: number,
        ) => {
          const profile_path = job.is_gleam_job
            ? social_media_info_has_job[0].social_media_info.key
            : social_media_key;
          const is_1_social =
            !social_media_info_has_job || social_media_info_has_job?.length === 1;
          return (
            <div>
              {is_1_social ? (
                <div>
                  <Link
                    to={`/InfluencerChannel?channel=${profile_path}`}
                    target="_blank"
                    style={{ color: '#495057' }}
                  >
                    {job_task_count}
                  </Link>
                </div>
              ) : (
                <div
                  onClick={() => {
                    openSocialsDetailChannel(
                      social_media_info_has_job,
                      symbol,
                      index,
                    );
                  }}
                >
                  {job_task_count}
                </div>
              )}
            </div>
          );
        },
        width: 50,
        disableSort: true,
        id: 'task',
      },
      {
        Header: t('table:Status'),
        accessor: (
          { social_media_info_has_job, social_media_key, symbol, status }: any,
          index: number,
        ) => {
          const profile_path = job.is_gleam_job
            ? social_media_info_has_job[0].social_media_info.key
            : social_media_key;
          const is_1_social =
            !social_media_info_has_job || social_media_info_has_job?.length === 1;
          return (
            <div>
              {is_1_social ? (
                <div>
                  <Link
                    to={`/InfluencerChannel?channel=${profile_path}`}
                    target="_blank"
                    style={{ color: '#495057' }}
                  >
                    {renderStatus(status)}
                  </Link>
                </div>
              ) : (
                <div
                  onClick={() => {
                    openSocialsDetailChannel(
                      social_media_info_has_job,
                      symbol,
                      index,
                    );
                  }}
                >
                  {renderStatus(status)}
                </div>
              )}
            </div>
          );
        },
        width: 50,
        id: 'status',
      },
      {
        Header: () => {
          return (
            <div
              style={{
                display: 'inline-block',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span>{t('table:Request Date')}</span>{' '}
              <Tooltip
                title={
                  <h4 style={{ color: 'white' }}>{t('tooltip.Request date')} </h4>
                }
                placement="right"
              >
                <i
                  className="fas fa-info-circle ms-2 font-size-16]"
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            </div>
          );
        },
        accessor: (
          { social_media_info_has_job, social_media_key, symbol }: any,
          index: number,
        ) => {
          const profile_path = job.is_gleam_job
            ? social_media_info_has_job[0].social_media_info.key
            : social_media_key;
          const is_1_social =
            !social_media_info_has_job || social_media_info_has_job?.length === 1;
          return (
            <div>
              {is_1_social ? (
                <div>
                  <Link
                    to={`/InfluencerChannel?channel=${profile_path}`}
                    target="_blank"
                    style={{ color: '#495057' }}
                  >
                    {social_media_info_has_job[0]?.social_media_has_job?.items[0]
                      ?.accept_date
                      ? formatDateForm(
                          social_media_info_has_job[0]?.social_media_has_job
                            ?.items[0]?.accept_date,
                        )
                      : ''}
                  </Link>
                </div>
              ) : (
                <div
                  onClick={() => {
                    openSocialsDetailChannel(
                      social_media_info_has_job,
                      symbol,
                      index,
                    );
                  }}
                >
                  {social_media_info_has_job[0]?.social_media_has_job?.items[0]
                    ?.accept_date
                    ? formatDateForm(
                        social_media_info_has_job[0]?.social_media_has_job?.items[0]
                          ?.accept_date,
                      )
                    : ''}
                </div>
              )}
            </div>
          );
        },
        width: 140,
        id: 'accept_date',
      },
      {
        Header: () => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div>{t('table:Action')}</div>{' '}
              <Tooltip
                title={<h4 style={{ color: 'white' }}>{t('tooltip.Action')} </h4>}
                placement="right"
              >
                <i
                  className="fas fa-info-circle ms-2 font-size-16]"
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            </div>
          );
        },
        accessor: ({
          social_media_info_has_job,
          group,
          key,
          status,
          price,
          tax,
        }: any) => {
          const _key = job.is_gleam_job ? group : key;

          const is_1_social =
            !social_media_info_has_job || social_media_info_has_job?.length === 1;
          const item = {
            status,
            price,
            tax,
            social_media_info_has_job,
          };
          return (
            <div>
              <div className="center">
                {' '}
                {job.job_type === 'PUBLISH' && (
                  <>{verify_column(item, _key, is_1_social, 1)}</>
                )}
              </div>
            </div>
          );
        },
        width: 50,
        disableSort: true,
        id: 'action',
      },
    ];
  }, [dataResult, wallet]);

  const query_social_has_job_sort_by_follower = async (quantity: number) => {
    try {
      dispatch(setLoading());
      const socialHasJob_most_follower = await socialHasGleamJobRequestByJobKeyGQL({
        limit: quantity,
        page: 1,
        job_key: jobId,
        sortBy: 'follower',
        sortType: 'DESC',
        verify_status: null,
        status: JobStatus.DRAFT,
      });
      let budget = 0;

      for (let i = 0; i < socialHasJob_most_follower?.items?.length; i++) {
        const { social_media_info_has_job } = socialHasJob_most_follower.items[i];
        for (
          let j = 0;
          j < social_media_info_has_job[0].social_media_has_job.items?.length;
          j++
        ) {
          const { price, tax } =
            social_media_info_has_job[0].social_media_has_job.items[j];
          budget += price + tax;
        }
      }
      setBudget(budget);
      dispatch(unsetLoading());
      setSocialInfoHasJob(socialHasJob_most_follower?.items);
      setModalOpen(true);
    } catch (error) {
      dispatch(unsetLoading());
    }
  };

  return (
    <>
      {displayModal()}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {/* <div style={{ width: '150px', marginRight: '20px' }}>
          <div>{t('Status')}</div>
          <Dropdown
            options={Status}
            action={handleStatusChange}
            default_value={Status[5]}
          />
        </div> */}
        {user?.role !== Role.ADMIN && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              marginTop: '5px',
            }}
          >
            {' '}
            <div>
              <div>{t('Auto Accept')} : </div>
            </div>
            <input
              type="number"
              className="form-control"
              min={0}
              // max={}
              pattern="[0-9]*"
              value={autoAccpect || undefined}
              onChange={(e) => {
                setAutoAccpect(Number(e.target.value));
              }}
              onKeyDown={(e) => {
                if (e.key === '+' || e.key === '-') {
                  e.preventDefault();
                }
              }}
              style={{ width: '20%', textAlign: 'right' }}
            />
            <div>{t('Influencers')} </div>
            <button
              type="submit"
              className="form-control"
              disabled={!autoAccpect}
              onClick={() => {
                query_social_has_job_sort_by_follower(autoAccpect);
              }}
              style={{
                width: 'fit-content',
                border: '1px solid white ',
                borderRadius: '10px',
                padding: '5px 20px',
                fontWeight: 'bold',
                color: 'white',
                background: 'linear-gradient(104deg,#6B99CA, #6BC6B3)',
              }}
            >
              {t('Accept')}
            </button>
          </div>
        )}

        <div
          style={{
            marginTop: '10px',
            marginBottom: '10px',
            marginRight: '10px',
            textAlign: 'right',
          }}
        >
          {user.role === Role.ADVERTISER && (
            <>
              Available: {floorToFixed(balance, 2)} {wallet.symbol}
            </>
          )}
        </div>
        {/* <Row className="gleam-job-container-header">
          <Col md="1" className="col-center">
            {t('table:Influencer photo')}
          </Col>
          <Col md="2" className="col-center">
            {t('table:Influencer name')}
          </Col>
          <Col md="1" className="col-center">
            {t('table:Social')}
          </Col>
          {job.job_type === 'PUBLISH' && (
            <Col md="1" className="col-center">
              {t('table:Match')}
              <Tooltip
                title={<h4 style={{ color: 'white' }}>{t('tooltip.Match')} </h4>}
                placement="right"
              >
                <i
                  className="fas fa-info-circle ms-2 font-size-16]"
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            </Col>
          )}
          <Col md="1" className="col-center">
            {t('table:Influencer follower')}
          </Col>
          <Col md="1" className="col-center">
            {t('table:Prize')}
            <Tooltip
              title={<h4 style={{ color: 'white' }}>{t('tooltip.Prize')} </h4>}
              placement="right"
            >
              <i
                className="fas fa-info-circle ms-2 font-size-16]"
                style={{
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          </Col>
          <Col md="1" className="col-center">
            {t('table:Task')}
            <Tooltip
              title={<h4 style={{ color: 'white' }}>{t('tooltip.Task')} </h4>}
              placement="right"
            >
              <i
                className="fas fa-info-circle ms-2 font-size-16]"
                style={{
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          </Col>
          <Col md="1" className="col-center">
            {t('table:Status')}
          </Col>
          <Col md="1" className="col-center">
            {t('table:Request Date')}
            <Tooltip
              title={
                <h4 style={{ color: 'white' }}>{t('tooltip.Request date')} </h4>
              }
              placement="right"
            >
              <i
                className="fas fa-info-circle ms-2 font-size-16]"
                style={{
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          </Col>
          {job.job_type === 'PUBLISH' && (
            <Col md="1" className="col-center">
              {t('table:Action')}
              <Tooltip
                title={<h4 style={{ color: 'white' }}>{t('tooltip.Action')} </h4>}
                placement="right"
              >
                <i
                  className="fas fa-info-circle ms-2 font-size-16]"
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            </Col>
          )}
        </Row> */}
      </div>
      <>
        <CustomTableCampaign
          showPaginationBottom={false}
          columns={columns || []}
          bodyCSS="body-table"
          data={dataResult || []}
          hidePagination
          headerCSS="fw-normal bg-header-table"
          onSort={handleSort}
        />

        <Pagination
          count={Math.ceil(data?.totalCount / param.limit)}
          page={param.page}
          color="standard"
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          style={{
            width: 'fit-content',
            margin: 'auto',
            marginTop: '30px',
          }}
          boundaryCount={2}
        />
      </>
    </>
  );
};

export default GleamJobRequestTable;
