import { FormGroup, Tooltip } from 'reactstrap';
import { ErrorMessage } from 'formik';
import ErrorFocus from 'src/utils/ErrorFocus';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Tooltips from '@mui/material/Tooltip';
import {
  createMarketerCampaignSuccess,
  getMarketerCampaign,
  updateMarketerCampaign,
} from 'src/store/marketerCampaign/actions';
import choice from 'src/assets/images/choice.png';
import { useHistory } from 'react-router';
import { createMarketerCampaignGql } from 'src/graphql/advertiser/marketerCampaign';
import FloatingInputComponent from 'src/components/shared/FloatingInput/FloatingInput.component';
import IconRadioInputComponent from 'src/components/shared/IconRadioInput/IconRadioInput.component';
import { CategoriesMarketerCampaign } from 'src/common/data/categories-marketer-campaign';
import paramsFromURL from 'src/constants/paramsFromURL';
import TextEditorInput from 'src/components/Common/input/textEditorInput';
import Swal from 'sweetalert2';
import { clearMarketerJob } from 'src/store/marketerJob/actions';

const CreateMarketerCampaignControl = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [campaignId] = paramsFromURL(['campaign']);
  const [toggleTooltip, setToggleTooltip] = useState<boolean>(false);

  const { t } = useTranslation();

  //? Form Controls
  const formControls: FormControl[] = [
    {
      label: 'marketer:create_campaign.campaign_name',
      name: 'name',
      type: FormInputType.STRING,
      required: true,
      tooltip: t(
        'marketer:create_job_campaign.Your campaign name for example, AFBrother new feature',
      ),
    },
    {
      label: 'marketer:create_campaign.brand_name',
      name: 'brand_name',
      type: FormInputType.STRING,
      required: true,
      tooltip: t(
        'marketer:create_job_campaign.Your brand name for example, AFbrother brand',
      ),
    },
    {
      label: 'marketer:create_campaign.product_name',
      name: 'product_name',
      type: FormInputType.STRING,
      required: true,
      tooltip: t(
        'marketer:create_job_campaign.Your product name for example, AFbrother website',
      ),
    },
    {
      label: 'marketer:create_campaign.product_detail',
      name: 'product_detail',
      type: FormInputType.TEXT_EDITOR_INPUT,
      input_type: 'textarea',
      required: true,
      tooltip: t('Your product detail for example, product Highlights'),
    },

    // {
    //   label: 'marketer:create_campaign.marketer_category',
    //   name: 'marketer_category_ids',
    //   type: FormInputType.MARKETER_CATEGORIES,
    //   required: true,
    //   categories: [...CategoriesMarketerCampaign],
    //   min_length: 1,
    //   max_length: 3,
    // },
  ];
  const initialValues = {
    name: '',
    brand_name: '',
    product_name: '',
    product_detail: '',
    // marketer_category_ids: [] as number[],
  };
  const [formValues, setFormValues] = useState(initialValues);
  const validationSchema = Yup.object({
    name: Yup.string().required(t('marketer:create_campaign.required')),
    brand_name: Yup.string().required(t('marketer:create_campaign.required')),
    product_name: Yup.string().required(t('marketer:create_campaign.required')),
    product_detail: Yup.string().required(t('marketer:create_campaign.required')),
    // marketer_category_ids: Yup.array()
    //   .required(t('marketer:create_campaign.required'))
    //   .max(3, t('marketer:create_campaign.categories_max_length', { length: 3 }))
    //   .min(1, t('marketer:create_campaign.categories_min_length', { length: 1 })),
  });

  //? Form Logic
  const data = useSelector((state: any) => state.MarketerCampaign.marketerCampaign);
  useEffect(() => {
    if (campaignId) dispatch(getMarketerCampaign(campaignId));
  }, [campaignId]);
  useEffect(() => {
    dispatch(clearMarketerJob());
    if (!campaignId) setFormValues(initialValues);
  }, []);
  // useEffect(() => {
  //   if (!Object.keys(data)?.length || !campaignId) return;

  //   const newData = { ...data };

  // let data_marketer_category_ids = [];
  // if (newData?.marketerCategories) {
  //   data_marketer_category_ids = newData?.marketerCategories?.map(
  //     (category: any) => category.id - 1,
  //   );
  // }

  //   delete newData.marketerCategories;
  //   setFormValues({
  //     ...formValues,
  //     ...newData,
  //     marketer_category_ids: data_marketer_category_ids,
  //   });
  // }, [data]);

  //? Functions
  const handleSubmit = async (campaignType: any) => {
    if (campaignId) {
      // const updateValues: any = { ...formValues };
      const onUpdateData: any = {};
      // for (let i = 0; i < Object.keys(updateValues).length; i++) {
      //   const updateKey = Object.keys(updateValues)[i];

      //   if (updateKey === 'marketer_category_ids') {
      //     const category_same =
      //       updateValues.marketer_category_ids.length ===
      //         data.marketerCategories.length &&
      //       updateValues.marketer_category_ids.every(
      //         (category: any, index: number) => {
      //           return category === data.marketerCategories[index].id - 1;
      //         },
      //       );

      //     if (!category_same) {
      //       const category_ids = updateValues.marketer_category_ids.map(
      //         (category_id: number) => category_id + 1,
      //       );

      //       onUpdateData.marketer_category_ids = category_ids;
      //     }
      //   } else {
      //     if (updateValues[updateKey] !== data[updateKey]) {
      //       onUpdateData[updateKey] = updateValues[updateKey];
      //     }
      //   }
      // }

      if (Object.keys(onUpdateData)?.length) {
        dispatch(updateMarketerCampaign({ ...onUpdateData, key: campaignId }));
      }
      if (window.location.pathname.includes('/EditMarketerCampaign')) {
        history.push('/');
      }
    } else {
      await Swal.fire({
        title: t('swal:Are you sure ?'),
        text: t("swal:You won't be able to revert this!"),
        imageUrl: choice,
        imageHeight: 200,
        width: 300,
        imageAlt: 'A tall image',
        customClass: {},
        cancelButtonColor: 'red',
        showCancelButton: true,
        confirmButtonColor: '#009a78',
        confirmButtonText: t('button:Yes'),
        cancelButtonText: t('swal:Cancel'),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const baseCreateInput: any = {
            ...formValues,
          };
          const res = await createMarketerCampaignGql(baseCreateInput);
          if (!res.key) return;
          history.push(`/Marketer/AddJobDetail?campaign=${res.key}`);
          dispatch(createMarketerCampaignSuccess(res));
        }
      });
    }
  };

  const inputs = (errors: any, touched: boolean, handleChange: any) => {
    return formControls.map((input: FormControl) => {
      switch (input.type) {
        case FormInputType.STRING:
          return textInput(input, errors, touched, handleChange);
        // case FormInputType.MARKETER_CATEGORIES:
        //   return marketerCategoriesInput(input);
        case FormInputType.TEXT_EDITOR_INPUT:
          return productDetailInput(input, errors, touched, handleChange);
        default:
          return textInput(input, errors, touched, handleChange);
      }
    });
  };

  //? Input UI Function
  // const marketerCategoriesInput = (input: FormControl) => {
  //   return (
  //     <>
  //       <div className="category-card">
  //         <h4>{t('marketer:create_campaign.header_category')}</h4>
  //         <p>{t('marketer:create_campaign.detail_category_1')}</p>
  //         <p>{t('marketer:create_campaign.detail_category_2')}</p>
  //         <div className="icon-radio-container">
  //           {input.categories &&
  //             input.categories.map((category: Category, index: number) => (
  //               <IconRadioInputComponent
  //                 key={`radio ${category.value}`}
  //                 id={`radio ${category.value}`}
  //                 onClick={() => {
  //                   const marketerCategories: number[] =
  //                     formValues.marketer_category_ids;
  //                   if (formValues.marketer_category_ids.includes(index)) {
  //                     const newMarketerCategories: number[] =
  //                       marketerCategories.filter(
  //                         (value: number) => value !== index,
  //                       );
  //                     const newForm = {
  //                       ...formValues,
  //                       marketer_category_ids: newMarketerCategories,
  //                     };
  //                     setFormValues(newForm);
  //                   } else {
  //                     if (marketerCategories.length >= 3) return;
  //                     marketerCategories.push(index);
  //                     const newForm = {
  //                       ...formValues,
  //                       marketer_category_ids: marketerCategories,
  //                     };
  //                     setFormValues(newForm);
  //                   }
  //                 }}
  //                 isChecked={formValues.marketer_category_ids.includes(index)}
  //                 icon={category.icon}
  //                 // label={category.label}
  //                 // background={category.background_color}
  //                 // iconColor={category.icon_color}
  //               />
  //             ))}
  //         </div>
  //       </div>
  //       <ErrorMessage
  //         name={input.name}
  //         component="div"
  //         className="invalid-message"
  //       />
  //       <ErrorFocus name={input.name} />
  //     </>
  //   );
  // };
  const initialValue = `<div>ProductDetail : </div>`;
  const place =
    `${t('ProductDetail')}` +
    `\n` +
    `${t('We ready to speed up')}` +
    ` \n` +
    `${t('If interested in you')}`;
  const textInput = (
    input: FormControl,
    errors: any,
    touched: any,
    handleChange: any,
  ) => {
    return (
      <FormGroup
        className="text-start"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <FloatingInputComponent
          name={input.name}
          type={input.input_type || 'input'}
          value={formValues[input.name as keyof typeof initialValues] as any}
          isInvalid={errors[input.name] && touched[input.name]}
          onChange={(e: any) => {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
            handleChange(e);
          }}
          label={input.label}
          tooltip={
            input?.tooltip && t(`marketer:create_job_campaign:${input.tooltip}`)
          }
        />
        {input.tooltip && input.input_type !== 'textarea' ? (
          <Tooltips title={t(input.tooltip || '-')} placement="top-start">
            <i
              className="fas fa-info-circle ms-2 font-size-16"
              style={{
                cursor: 'pointer',
                marginTop: '2.9rem',
              }}
              id="tooltipInfo"
            />
          </Tooltips>
        ) : (
          ''
        )}
        <ErrorMessage
          name={input.name}
          component="div"
          className="invalid-message"
        />
        <ErrorFocus name={input.name} />
      </FormGroup>
    );
  };

  const productDetailInput = (
    input: FormControl,
    errors: any,
    touched: any,
    handleChange: any,
  ) => {
    return (
      <>
        <div className="floating-input-container">
          <div
            style={{
              display: 'flex',
              background: 'linear-gradient(180deg, #9CEFEE 0%, #C2F2CE 100%)',
              alignItems: 'center',
              minHeight: '40px',
              padding: '10px 0',
            }}
          >
            <label
              id="tooltipInfo"
              htmlFor="textareaInput"
              title={t(input.label)}
              className="text-area-label"
              style={{ lineHeight: '0.9' }}
            >
              {t(input.label)}
            </label>

            <i
              id={input.name || 'Tooltip'}
              className="far fa-question-circle info-icon"
            />
            <Tooltip
              placement="right"
              target={input.name || 'Tooltip'}
              style={{ background: '#6D6D6D' }}
              isOpen={toggleTooltip}
              toggle={() => setToggleTooltip(!toggleTooltip)}
            >
              <span>{t(input.tooltip || '-')}</span>
            </Tooltip>
          </div>

          <TextEditorInput
            placeholder={place}
            setValue={(value: any) => {
              setFormValues({
                ...formValues,
                [input.name]: value,
              });
              // setFormValues((prevState) => ({
              //   ...prevState,
              //   [input.name]: value,
              // }));
            }}
            // handleChange={(value: any) => {
            //   setFormValues({ ...formValues, [input.name]: value });
            // }}
            value={formValues[input.name as keyof typeof initialValues] as any}
            handleChange={(value: any) => {
              setFormValues({ ...formValues, [input.name]: value });
              handleChange({ target: { name: input.name, value } });
            }}
          />
        </div>
      </>
    );
  };
  //? Return
  return {
    initialValues,
    handleSubmit,
    validationSchema,
    inputs,
    formValues,
    setFormValues,
  };
};

export default CreateMarketerCampaignControl;

// eslint-disable-next-line no-unused-vars, no-shadow
enum FormInputType {
  // eslint-disable-next-line no-unused-vars
  STRING,
  // eslint-disable-next-line no-unused-vars
  MARKETER_CATEGORIES,
  // eslint-disable-next-line no-unused-vars
  TEXT_EDITOR_INPUT,
}

interface Category {
  // label: string;
  value: number;
  icon: any;
  // icon_color?: string;
  // background_color?: string;
}

interface FormControl {
  label: string;
  name: string;
  type: FormInputType;
  required?: boolean;
  disabled?: boolean;
  max_length?: number;
  min_length?: number;
  categories?: Category[];
  input_type?: string;
  MinDate?: any;
  tooltip?: string;
  icon?: string;
  iconColor?: string;
  textColor?: string;
  backgroundColor?: string;
}
